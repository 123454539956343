import {
	useState, useContext,
	useRef,
	useMemo,
	useEffect
} from 'react';
import {
	TextField, Box, Button, Switch, FormControlLabel,
	InputLabel, Typography, Paper, Select, FormControl, MenuItem, FormHelperText, CircularProgress,
	Dialog,
	DialogTitle,
	DialogContent,
	LinearProgress,
	CardHeader,
	CardContent,
} from '@mui/material';
import * as _ from 'lodash';
import { fetchAuthSession, fetchUserAttributes } from 'aws-amplify/auth';
import { Formik } from 'formik';
import { expandedContactSearch, } from '../../graphql/queries';
import MaterialTable from '@material-table/core';
import QueryBuilder from '../../components/queryBuilder';
import { UserContext } from '../../contexts/UserContext';
import { enqueueSnackbar } from 'notistack';
import { contactValidation } from '../../components/yupValidations'
import { lazy, mixed, object, array, string, reach } from 'yup';
import { US } from 'country-region-data';
import { ConfirmDialog } from 'src/components/confirmDialog/confirmDialog';
import { generateClient, post } from 'aws-amplify/api';
import { PageAppBar } from 'src/components/pageAppBar';
import { Card } from '@aws-amplify/ui-react';
import { actionOneButtonStyle, cardStyle, destructiveButtonStyle } from 'src/theme';
import { useNavigate } from 'react-router-dom';



const types = {
	String: 0,
	ISODateTime: 1,
	Boolean: 2,
	Timezone: 3,
	State: 4,
	Epoch: 5,
	DateTime: 5, // The custom field DateTime is an epoch.
	Number: 6,
	Phone: 7
};

const modes = {
	Delete: 0,
	Update: 1
};

export function BulkEdit() {
	const navigate = useNavigate();
	const usStates = US[2];
	const client = generateClient();
	const userContext = useContext(UserContext);
	const [total, setTotal] = useState(0);
	const [retrievedCount, setRetrievedCount] = useState(0);
	const newSearchFilter = useRef({});
	const [contacts, setContacts] = useState([]);
	const [confirmOpen, setConfirmOpen] = useState(false);
	const [queryFields, setQueryFields] = useState([]);
	const [mode, setMode] = useState('Update');
	const [columns, setColumns] = useState([
		{ title: 'First Name', field: 'firstName' },
		{ title: 'Last Name', field: 'lastName' },
		{ title: 'Source', field: 'source' },
		{ title: 'Phone', field: 'phone' },
		{ title: 'Cell', field: 'cell' },
		{ title: 'Email', field: 'email' },
		{ title: 'State', field: 'state' },

	]);

	const progressCountRef = useRef(0);
	const [progressCount, setProgressCount] = useState(0);
	const errorCountRef = useRef(0);
	const [errorCount, setErrorCount] = useState(0);
	const [progressDialogOpen, setProgressDialogOpen] = useState(false);
	const [processDialogTitle, setProcessDialogTitle] = useState('');
	const queryPreviewThrottler = useRef(_.throttle((query) => logQuery(query), 1000, { 'leading': false, 'trailing': true }));
	const [customFieldColumns, setCustomFieldColumns] = useState([]);

	const valueInputTypes = useMemo(() => ({
		firstName: types.String,
		lastName: types.String,
		phone: types.Phone,
		source: types.String,
		cell: types.Phone,
		email: types.String,
		address1: types.String,
		address2: types.String,
		city: types.String,
		state: types.State,
		zip: types.String,
		/* expireDt: types.ISODateTime, */
		outboundCallerId: types.String,
		...userContext.customFields?.reduce((acc, cur) => Object.assign(acc, { [`custom:${cur.name}`]: types[cur.type] }), {})
	}), [userContext.customFields]);

	useEffect(() => {
		if (customFieldColumns.length > 0) {
			const columns = [
				{ title: 'First Name', field: 'firstName' },
				{ title: 'Last Name', field: 'lastName' },
				{ title: 'Source', field: 'source' },
				{ title: 'Phone', field: 'phone' },
				{ title: 'Cell', field: 'cell' },
				{ title: 'Email', field: 'email' },
				{ title: 'State', field: 'state' },
			];
			for (const customField of customFieldColumns) {
				const title = userContext.customFields.find(field => field.name === customField).displayName;
				columns.push({ title, field: `customFields.${customField}` });
			}
			setColumns(columns);
		}
	}, [customFieldColumns]);


	useEffect(() => {
		if (userContext.queryFields.list) {
			setQueryFields([...userContext.queryFields.list])
		}
	}, [userContext.queryFields])

	function standardizeValue(values) {
		try {
			let value
			switch (valueInputTypes[values.fieldName]) {
				case types.String:
				case types.Phone:
					return values.fieldValue;
				case types.ISODateTime:
					return new Date(values.fieldValue).toISOString();
				case types.Epoch:
					return new Date(values.fieldValue).getTime();
				case types.Number:
					return Number(values.fieldValue)
				case types.Boolean:
					return !!values.fieldName;
				default:
					return null;
			}

		} catch (err) {
			console.error('Unable to get value', err)
		}

	}

	const logQuery = (query) => {
		if (query.rules) {
			if (query.rules.length > 0) {
				newSearchFilter.current = processFilterArray(query.rules, query.combinator);
				previewContacts();
			} else {
				//If we are missing any rules, clear the data
				setTotal(0);
			}
		}

		return;
	}

	const processFilterArray = (items, combinator) => {
		const ruleArray = [];
		if (userContext.tenantId) {
			const ruleGroup = { [combinator]: ruleArray };
			//the rule array always has to have the tenant included for security
			_.forEach(items, (item) => { ruleArray.push(processFilterItem(item, items.combinator)) });
			const tenantRuleGroup = { and: [{ tenant: { eq: userContext.tenantId } }, ruleGroup] };
			return tenantRuleGroup;
		} else {
			return {}
		}
	}

	const processFilterItem = (item) => {
		const cpyItem = { ...item };
		if (item.field) {
			//item.value = item.value.toLowerCase();
			if (!item.field.startsWith('custom:')) {
				if (cpyItem.operator === 'wildcard') {
					cpyItem.value = cpyItem.value.toLowerCase();
					cpyItem.value = `*${cpyItem.value}*`;
				}
				if (cpyItem.operator === 'wildcard*') {
					cpyItem.value = cpyItem.value.toLowerCase();
					cpyItem.value = `${cpyItem.value}*`;
					cpyItem.operator = 'wildcard';
				}
				if (cpyItem.operator === '*wildcard') {
					cpyItem.value = cpyItem.value.toLowerCase();
					cpyItem.value = `*${cpyItem.value}`;
					cpyItem.operator = 'wildcard';
				}
				const ruleItem = { [cpyItem.field]: { [cpyItem.operator]: cpyItem.value } };
				return ruleItem;
			} else {
				let fieldName = cpyItem.field.split(':')[1];
				if (cpyItem.operator === 'wildcard') {
					cpyItem.value = `*${cpyItem.value}*`;
					fieldName += '.keyword';
				} else if (cpyItem.operator === 'wildcard*') {
					cpyItem.value = `${cpyItem.value}*`;
					cpyItem.operator = 'wildcard';
					fieldName += '.keyword';
				} else if (cpyItem.operator === '*wildcard') {
					cpyItem.value = `*${cpyItem.value}`;
					cpyItem.operator = 'wildcard';
					fieldName += '.keyword';
				} else {
					if (cpyItem.value === true || cpyItem.value === false || cpyItem.value === 'true' || cpyItem === 'false') {
						if (_.isString(cpyItem.value)) {
							cpyItem.value = cpyItem.value === 'true';
						} else {
							cpyItem.value = cpyItem.value === true;
						}
					} else if (!isNaN(cpyItem.value)) {
						cpyItem.value = +cpyItem.value;
					} else if (isCustomFieldDate(fieldName) && !!Date.parse(cpyItem.value)) {
						cpyItem.value = Date.parse(cpyItem.value);
					}
				}
				const ruleItem = { [`customFields.${fieldName}`]: { [cpyItem.operator]: cpyItem.value } };
				return ruleItem;
			}
		}

		return processFilterArray(cpyItem.rules, cpyItem.combinator);
	}

	const isCustomFieldDate = (fieldName) => {
		const customField = _.find(userContext.customFields, ['name', fieldName]);
		if (customField) {
			return customField.type === 'Date';
		} else {
			return false;
		}
	}

	const previewContacts = async () => {
		try {
			let options = {
				limit: 250
			};
			options.filter = JSON.stringify(newSearchFilter.current);

			const listData = await client.graphql({
				query: expandedContactSearch,
				variables: options,
			});

			// We need to parse the custom fields out and NOT stringify them when we send them back out
			// because when these contacts are put back into DynamoDB, it is done through a DocumentClient.
			// The DocumentClient takes AWSJSON as is (not stringified).
			for (const contact of listData.data.expandedContactSearch.items) {
				if (contact.customFields != null && typeof (contact.customFields) === 'string') {
					try {
						contact.customFields = JSON.parse(contact.customFields);
					} catch (_) { }
				}
			}

			const contacts = listData.data.expandedContactSearch.items;

			setRetrievedCount(contacts.length ? contacts.length : 0);
			setTotal(listData.data.expandedContactSearch.total ? listData.data.expandedContactSearch.total : 0);

			setContacts([...contacts]);
		} catch (error) {
			console.error(error);
		}
	}

	function handleTypeChange(formikProps, e) {
		switch (valueInputTypes[e.target.value]) {
			default:
			case types.String:
			case types.ISODateTime:
			case types.DateTime:
			case types.Number:
			case types.Phone:
			case types.State:
			case types.Timezone:
				formikProps.setFieldValue('fieldValue', '');
				break;
			case types.Boolean:
				formikProps.setFieldValue('fieldValue', false);
				break;
		}

		formikProps.handleChange(e);
	}

	function customFieldUpdate(contact, field, value) {
		if (!('customFields' in contact)) {
			contact.customFields = {};
		}

		contact.customFields[field] = value;
	}

	function regularUpdate(contact, field, value) {
		contact[field] = value;
	}

	async function processContacts(values) {

		progressCountRef.current = 0;
		errorCountRef.current = 0;
		setProgressCount(0);
		setErrorCount(0);

		setConfirmOpen(false);
		// Retrieve each page of contacts and update them.
		if (mode === 'Update') {
			setProcessDialogTitle('Updating Contacts...');
		} else {
			setProcessDialogTitle('Deleting Contacts...');
		}
		setProgressDialogOpen(true);
		let options = {
			limit: 500
		};
		let nextToken = null;

		options.filter = JSON.stringify(newSearchFilter.current);
		do {
			if (nextToken) {
				options.nextToken = nextToken;
			}

			try {

				let listData = await client.graphql({
					query: expandedContactSearch,
					variables: options,
				});

				// We need to parse the custom fields out and NOT stringify them when we send them back out
				// because when these contacts are put back into DynamoDB, it is done through a DocumentClient.
				// The DocumentClient takes AWSJSON as is (not stringified).
				for (const contact of listData.data.expandedContactSearch.items) {
					if (contact.customFields != null && typeof (contact.customFields) === 'string') {
						try {
							contact.customFields = JSON.parse(contact.customFields);
						} catch (_) { }
					}
				}

				const retrievedContacts = listData.data.expandedContactSearch.items;

				nextToken = listData.data.expandedContactSearch.nextToken;

				const chunkedContacts = _.chunk(retrievedContacts, 100);

				const promises = [];
				for (const chunk of chunkedContacts) {
					promises.push(processBulkEdit(values, chunk));
				}

				await Promise.all(promises);

				progressCountRef.current += retrievedContacts.length;
				setProgressCount(progressCountRef.current);

			} catch (error) {
				const retrievedContacts = error.data.expandedContactSearch.items;
				const cleanedContacts = retrievedContacts.filter(contact => contact?.id);

				nextToken = error.data.expandedContactSearch.nextToken;

				const chunkedContacts = _.chunk(cleanedContacts, 100);
				const promises = [];
				for (const chunk of chunkedContacts) {
					promises.push(processBulkEdit(values, chunk));
				}

				await Promise.all(promises);

				errorCountRef.current += retrievedContacts.length - cleanedContacts.length;

				progressCountRef.current += retrievedContacts.length;
				setProgressCount(progressCountRef.current);
				setErrorCount(errorCountRef.current);
			}

		} while (nextToken);



		setProgressDialogOpen(false);
		enqueueSnackbar(`Successful Contacts: ${progressCountRef.current} | Error Contacts: ${errorCountRef.current}`, {
			variant: 'success',
			autoHideDuration: 5000
		});

		setContacts([]);
		progressCountRef.current = 0;
		setTotal(0);
		setRetrievedCount(0);
	}

	async function processBulkEdit(values, contacts) {
		if (mode === 'Update') {
			const value = standardizeValue(values);
			// for (const chunk of chunkedContacts) {
			try {
				const response = await post({
					apiName: 'cdyxoutreach',
					path: '/bulk-edit/edit',
					options: {
						headers: {
							Authorization: `Bearer ${(await fetchAuthSession()).tokens.idToken}`,
							'x-api-key': userContext.apiKey
						},
						body: {
							contacts,
							updateField: values.fieldName,
							updateValue: value,
							updatedBy: (await fetchUserAttributes()).name
						}
					}
				}).response;

			} catch (err) {
				console.error('Unable to update contacts', err);
				enqueueSnackbar('Unable to update contacts', {
					variant: 'error',
					autoHideDuration: 5000
				});
			}
			// }
		} else {
			try {
				const response = await post({
					apiName: 'cdyxoutreach',
					path: '/bulk-edit/delete',
					options: {
						headers: {
							Authorization: `Bearer ${(await fetchAuthSession()).tokens.idToken}`,
							'x-api-key': userContext.apiKey
						},
						body: {
							contacts,
							updatedBy: (await fetchUserAttributes()).name
						}
					}
				}).response;
			} catch (err) {
				console.error('Unable to delete contacts', err);
				enqueueSnackbar('Unable to delete contacts', {
					variant: 'error',
					autoHideDuration: 5000
				});
			}
		}

	}

	const handleCancelUpdate = () => {
		setConfirmOpen(false);
	}

	return (
		<Box display='flex' flexDirection='column'>
			<Formik
				initialValues={{
					fieldName: '',
					fieldValue: '',
					query: {
						rules: [],
						combinator: 'and'
					}
				}}
				enableReinitialize={true}
				validationSchema={lazy(value => {
					let subSchema = mixed();

					try {
						subSchema = typeof (value.fieldName) === 'string' ? reach(contactValidation(userContext.customFields), value.fieldName.startsWith('custom:') ? `customFields.${value.fieldName.substring(7)}` : value.fieldName) : mixed();
					} catch (_) { }

					if (mode === 'Delete') {
						return object({
							query: object({
								rules: array().min(1, 'You must have at least one rule in your query')
							})
						})
					}

					return object({
						fieldName: string().required('Please specify a field name'),
						fieldValue: subSchema,
						query: object({
							rules: array().min(1, 'You must have at least one rule in your query')
						})
					})
				})}
				onSubmit={values => {
					setConfirmOpen(true);
				}}
			>
				{formikProps => (
					<form onSubmit={formikProps.handleSubmit}>
						<Box display='flex' flexDirection='column' gap={2}>
							<PageAppBar
								title='Bulk Edit & Delete'
								description="Bulk edit or delete contacts based on a query."
							// actionTwoText="Delete"
							// actionTwoHandler={() => { mode.current = modes.Delete; formikProps.submitForm(); }}
							// actionOneText="Update"
							// actionOneHandler={() => { mode.current = modes.Update; formikProps.submitForm(); }}
							/>
							<Card
								style={cardStyle}
								elevation={0}
							>
								<CardHeader title="Query Builder"
									titleTypographyProps={
										{
											variant: 'h6',
										}}
									subheader={formikProps.errors?.query?.rules && formikProps.touched?.query?.rules ? formikProps.errors.query.rules : ''}
								/>
								<CardContent>
									<QueryBuilder
										name='query'
										query={formikProps.values.query}
										fields={userContext.queryFields.list}
										onQueryChange={e => {
											formikProps.setFieldValue('query', e);
											queryPreviewThrottler.current(e);
											const customFields = [];
											for (const field of e.rules) {
												if (field.field.startsWith('custom:')) {
													const customField = field.field.split(':')[1];
													if (!customFieldColumns.includes(customField)) {
														customFields.push(customField);
													}
												}
											}
											setCustomFieldColumns(customFields);
										}}
										showCombinatorsBetweenRules
										getValueEditorType={field => userContext.queryFields.typeLookup(field)}
									/>
								</CardContent>
							</Card>
							<Box display="grid" gridTemplateColumns="2fr 1fr" gap={2}>

								<Card
									style={cardStyle}
									elevation={0}
								>
									<CardHeader title='Bulk Edit' titleTypographyProps={
										{
											variant: 'h6',
										}} />
									<CardContent>
										<Box display='flex' alignItems='center' gap={2} >
											<Typography variant="body" >Update</Typography>
											<Box>
												<FormControl>
													<InputLabel error={formikProps.errors.fieldName && formikProps.touched.fieldName}>Field Name</InputLabel>
													<Select
														labelId='field-name'
														label='Field Name'
														sx={{ minWidth: '200px' }}
														error={formikProps.errors.fieldName && formikProps.touched.fieldName}
														name="fieldName"
														value={formikProps.values.fieldName}
														onChange={e => handleTypeChange(formikProps, e)}
														onBlur={formikProps.handleBlur}>
														{queryFields.map((field, index) =>
															<MenuItem key={index} value={field.name}>{field.label}</MenuItem>)}
													</Select>
													{formikProps.errors.fieldName && formikProps.touched.fieldName &&
														<FormHelperText error>{formikProps.errors.fieldName}</FormHelperText>}
												</FormControl>
											</Box>
											<Typography variant='body' >to</Typography>
											<Box>
												{function () {
													switch (valueInputTypes[formikProps.values.fieldName]) {
														case types.String:
														case types.ISODateTime:
														case types.DateTime:
														case types.Number:
														case types.Phone:
															return (
																<TextField
																	label='Field Value'
																	name='fieldValue'
																	type={valueInputTypes[formikProps.values.fieldName] === types.String || valueInputTypes[formikProps.values.fieldName] === types.Phone
																		? 'text'
																		: valueInputTypes[formikProps.values.fieldName] === types.ISODateTime || valueInputTypes[formikProps.values.fieldName] === types.DateTime
																			? 'datetime-local'
																			: 'number'}
																	value={formikProps.values.fieldValue}
																	onChange={formikProps.handleChange}
																	onBlur={formikProps.handleBlur}
																	error={formikProps.errors?.fieldValue && formikProps.touched?.fieldValue}
																	helperText={formikProps.touched?.fieldValue && formikProps.errors?.fieldValue}
																/>
															);
														case types.State:
															return (
																<FormControl>
																	<InputLabel error={formikProps.errors?.fieldValue && formikProps.touched?.fieldValue}>Field Value</InputLabel>
																	<Select sx={{ minWidth: '200px' }} name='fieldValue' label="Field Value" onChange={formikProps.handleChange} onBlur={formikProps.handleBlur} value={formikProps.values.fieldValue}>
																		{usStates.map(state =>
																			<MenuItem key={state[1]} value={state[1]}>{state[0]}</MenuItem>)}
																	</Select>
																	{formikProps.errors?.fieldValue && formikProps.touched?.fieldValue &&
																		<FormHelperText error>{formikProps.errors.fieldValue}</FormHelperText>}
																</FormControl>
															);
														case types.Boolean:
															return (
																<FormControlLabel label='Field Value' control={
																	<Switch name='fieldValue' checked={formikProps.values.fieldValue} onChange={formikProps.handleChange} />
																} />
															);
														default:
															return null;
													}
												}()}
											</Box>
											{formikProps.values.fieldName && <Button sx={actionOneButtonStyle} type='submit'>Update {retrievedCount} Contacts</Button>}
										</Box>
									</CardContent>
								</Card>
								<Card
									style={cardStyle}
									elevation={0}
								>
									<CardHeader title='Bulk Delete' titleTypographyProps={
										{
											variant: 'h6',
										}} />
									<CardContent>
										<Button sx={destructiveButtonStyle} type='submit'>{`Delete ${retrievedCount} Contacts`}</Button>
									</CardContent>
								</Card>
							</Box>

							<Box item>
								<MaterialTable title={`Preview ${retrievedCount} of ${total} matched contacts.`}
									columns={columns}
									data={contacts}
									options={{
										actionsColumnIndex: -1,
										pageSize: 5
									}}
									onRowClick={(event, rowData) => {
										navigate(`/contacts/${rowData.id}`);
									}}
								/>
							</Box>
						</Box>

						{mode.current === modes.Update &&
							<ConfirmDialog
								open={confirmOpen}
								title="Update Contacts"
								description={`Are you sure you want to update these contacts?`}
								actionOneText="Update"
								actionOneHandler={() => processContacts(formikProps.values)}
								actionTwoText="Cancel"
								actionTwoHandler={handleCancelUpdate}
							/>}
						{mode.current === modes.Delete &&
							<ConfirmDialog
								open={confirmOpen}
								title="Delete Contacts"
								description={`Are you sure you want to delete these contacts?`}
								actionOneText="Delete"
								actionOneHandler={() => processContacts(formikProps.values)}
								actionTwoText="Cancel"
								actionTwoHandler={handleCancelUpdate}
							/>}
						<Dialog open={progressDialogOpen}>
							<DialogTitle>{processDialogTitle}</DialogTitle>
							<DialogContent>
								<Typography variant='subtitle2' >Processed {progressCount} of {total} contacts...</Typography>
								<LinearProgress variant='determinate' value={progressCount / total * 100} />
							</DialogContent>
						</Dialog>
					</form>
				)}
			</Formik>
		</Box>
	)
}

import { useEffect, useState, useContext, useRef } from "react";
import { UserContext } from "../../contexts/UserContext";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Drawer,
	IconButton,
	Card,
	CardHeader,
	CardContent,
	Box,
	CircularProgress,
	Tabs,
	Tab,
	Button
} from '@mui/material';
import { ChevronLeftOutlined, ChevronRightOutlined } from "@mui/icons-material";
import { generateClient, get, post } from "aws-amplify/api";
import { fetchAuthSession } from "aws-amplify/auth";
import { getContact } from "src/graphql/queries";
import { actionOneButtonStyle } from "src/theme";
import { ActivityLog } from "../contacts/activityLog";

export default function CallBacks(props) {
	const client = generateClient();
	const userContext = useContext(UserContext);
	const [callBacks, setCallBacks] = useState(null);
	const interval = useRef(null);
	const [previewContactId, setPreviewContactId] = useState(null);
	const [previewContactDetails, setPreviewContactDetails] = useState(null);
	const [previewContact, setPreviewContact] = useState(null);
	const [currentTab, setCurrentTab] = useState(0);

	useEffect(() => {
		async function getContactDetails() {
			try {
				const contact = await client.graphql({ query: getContact, variables: { id: previewContactId } });
				let contactData = contact.data.getContact;
				let customFields = JSON.parse(contactData.customFields);
				contactData.customFields = [];
				for (const field in customFields) {
					contactData.customFields.push({ key: field, value: customFields[field] });
				}
				setPreviewContact(contactData);
			} catch (error) {
				console.error('Error getting contact', error);
			}
		}
		if (previewContactId) {
			getContactDetails();
		}
	}, [previewContactId]);


	async function getCallBacks() {
		try {

			const getCallBacksResponse = await get({
				apiName: 'cdyxoutreach',
				path: `/cache/callBacks/${props.campaignId}`,
				options: {
					headers: {
						Authorization: `Bearer ${(await fetchAuthSession()).tokens.idToken}`,
						'x-api-key': userContext.apiKey
					}
				}
			}).response;

			const getCallBacks = await getCallBacksResponse.body.json();

			let calls = [];

			console.log(getCallBacks);

			for (const call in getCallBacks.data) {
				calls.push(JSON.parse(getCallBacks.data[call]));
			}

			// Sort by the closest call back date
			const sortedCalls = calls.sort((a, b) => new Date(a.callDateTime) - new Date(b.callDateTime));

			setCallBacks(sortedCalls);

		} catch (error) {
			console.error('Error getting call backs', error);
		}
	}

	useEffect(() => {
		try {
			if (props.campaignId && props.open) {
				getCallBacks();
			}
		} catch (error) {
			console.error('Error getting callBacks', error);
		}
	}, [props.campaignId, props.open]);

	async function releaseCallBack(callback) {
		try {
			await post({
				apiName: 'cdyxoutreach',
				path: '/cache/createCallback',
				options: {
					headers: {
						Authorization: `Bearer ${(await fetchAuthSession()).tokens.idToken}`,
						'x-api-key': userContext.apiKey
					},
					body: {
						...callback,
						agentId: null
					}
				}
			}).response;

			getCallBacks();

		} catch (error) {
			console.error('Error releasing call back', error);
		}
	}

	return (
		<>
			<Drawer
				sx={{ width: 400 }}
				anchor="right"
				open={props.open}
				onClose={props.onClose}
			>
				{!previewContact &&
					<Card>
						<CardHeader title={`Call Backs for ${props.campaignName} (${callBacks ? callBacks.length : '0'})`} />
						<CardContent>
							{callBacks && <Table>
								<TableHead>
									<TableRow>
										<TableCell>Agent</TableCell>
										<TableCell align="center">Date</TableCell>
										<TableCell align="center">View Details</TableCell>
										<TableCell align="center">Release</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{callBacks.map((call) => (
										<TableRow>
											<TableCell>{call.agentId ? call.createdByAgentName : 'Unassigned'}</TableCell>
											<TableCell align="center">{new Date(call.callDateTime).toLocaleString()}</TableCell>
											<TableCell align="center">
												<IconButton onClick={() => {
													setPreviewContactDetails(call);
													setPreviewContactId(call.contactId);
												}}>
													<ChevronRightOutlined />
												</IconButton>
											</TableCell>
											<TableCell align="center">
												{call.agentId && <Button onClick={() => releaseCallBack(call)} sx={actionOneButtonStyle}>Release</Button>}
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>}
							{!callBacks && <CircularProgress color="primary" />}
						</CardContent>
					</Card>}
				{previewContact && <Card>
					<CardHeader title="Call Back Details"
						action={
							<IconButton onClick={() => {
								setPreviewContactId(null);
								setPreviewContact(null);
							}}>
								<ChevronLeftOutlined />
							</IconButton>
						}
					/>
					<CardContent>
						<Box display="grid" gridTemplateColumns="1fr 1fr" gap={2}>
							<Card variant="outlined">
								<CardHeader title="Call Back Info" />
								<CardContent>
									<Box display="flex" flexDirection="column" gap={2}>
										<Box display="grid" gridTemplateColumns="1fr 1fr" gap={2}>
											<Box>
												<strong>Agent</strong>: {previewContactDetails.agentId ? previewContactDetails.createdByAgentName : 'Unassigned'}
											</Box>
											{<Box>
												<strong>Created By</strong>: {previewContactDetails.createdByAgentName}
											</Box>}
											{<Box>
												<strong>Date</strong>: {new Date(previewContactDetails.callDateTime).toLocaleString()}
											</Box>}
											{<Box>
												<strong>Call Back Number</strong>: {previewContactDetails.callBackPOC}
											</Box>}
										</Box>
										<Box>
											<strong>Notes</strong>: {previewContactDetails.note}
										</Box>
									</Box>
								</CardContent>
							</Card>
							<Card variant="outlined">
								<CardHeader title="Contact Info" />
								<CardContent>
									<Box display="flex" flexDirection="column" gap={2}>
										<Tabs indicatorColor='primary' value={currentTab} onChange={(e, value) => setCurrentTab(value)}>
											<Tab label="Contact Info" value={0} />
											<Tab label="Contact Custom Fields" value={1} />
											<Tab label="Contact Activity" value={2} />
										</Tabs>
										{currentTab === 0 && <Box display="grid" gridTemplateColumns="1fr 1fr" gap={2}>
											{previewContact?.firstName && <Box>
												<strong>First Name</strong>: {previewContact?.firstName}
											</Box>}
											{previewContact?.lastName && <Box>
												<strong>Last Name</strong>: {previewContact?.lastName}
											</Box>}
											{previewContact?.externalId && <Box>
												<strong>External Id</strong>: {previewContact.externalId}
											</Box>}

											{previewContact?.phone && <Box>
												<strong>Phone</strong>: {previewContact.phone}
											</Box>}
											{previewContact?.cell && <Box>
												<strong>Cell</strong>: {previewContact.cell}
											</Box>}
											{previewContact?.email && <Box>
												<strong>Email</strong>: {previewContact.email}
											</Box>}
											{previewContact?.source && <Box>
												<strong>Source</strong>: {previewContact.source}
											</Box>}
											{previewContact?.address1 && <Box>
												<strong>Address 1</strong>: {previewContact.address1}
											</Box>}
											{previewContact?.address2 && <Box>
												<strong>Address 2</strong>: {previewContact.address2}
											</Box>}
											{previewContact?.city && <Box>
												<strong>City</strong>: {previewContact.city}
											</Box>}
											{previewContact?.state && <Box>
												<strong>State</strong>: {previewContact.state}
											</Box>}
											{previewContact?.zip && <Box>
												<strong>Zip</strong>: {previewContact.zip}
											</Box>}
										</Box>}
										{currentTab === 1 && <Box container spacing={2}>
											{previewContact?.customFields?.map(field =>
												<Box key={field.key}>
													<strong>{field.key}</strong>: {field.value}
												</Box>
											)}
										</Box>}
										{currentTab === 2 &&
											<ActivityLog contactId={previewContactId} />
										}
									</Box>
								</CardContent>
							</Card>
						</Box>
					</CardContent>
				</Card>}
			</Drawer >
		</>
	)
}

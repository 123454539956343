import { useState, useEffect, useContext } from 'react';
import {
	Box,
	Tabs,
	Tab,
	Divider,
} from '@mui/material';
import MaterialTable from '@material-table/core';
import {
	// DeleteOutlined,
	EditOutlined,
} from '@mui/icons-material';
import {
	useNavigate,
} from 'react-router-dom';
import {
	tenantMappings,
	listContactMetadataFieldMappingss,
	listContactCallWindowsFieldMappingss
} from '../../graphql/queries';
import {
	deleteFieldsMapping,
	deleteContactMetadataFieldMappings,
} from '../../graphql/mutations';
import { UserContext } from '../../contexts/UserContext';
import { generateClient } from 'aws-amplify/api';
import { PageAppBar } from 'src/components/pageAppBar';
import { enqueueSnackbar } from 'notistack';
import { tabStyle } from 'src/theme';

export function FieldMappings() {
	const client = generateClient();
	const [mappingPath, setMappingPath] = useState('contact/new');
	const [mappingType, setMappingType] = useState('Contact');
	const navigate = useNavigate();
	const userContext = useContext(UserContext);
	// const { path } = useMatch();
	const [contactMappingList, setContactMappingList] = useState([]);
	const [contactMetadataMappingList, setContactMetadataMappingList] = useState(
		[],
	);
	const [contactCallWindowsMappingList, setContactCallWindowsMappingList] =
		useState([]);
	const [loadingContactMappings, setLoadingContactMappings] = useState(false);
	const [loadingContactMetadataMappings, setLoadingContactMetadataMappings] =
		useState(false);
	const [loadingCallWindowsMappings, setLoadingCallWindowsMappings] =
		useState(false);
	const [selectedTab, setSelectedTab] = useState(
		userContext.fieldMappingTab ? userContext.fieldMappingTab : 0,
	);

	useEffect(() => {
		async function getData() {
			setLoadingContactMappings(true);
			setLoadingContactMetadataMappings(true);
			setLoadingCallWindowsMappings(true);

			const promises = [];

			promises.push(
				client.graphql({
					query: tenantMappings,
					variables: { tenant: userContext.tenantId },
				}),
			);
			promises.push(
				client.graphql({
					query: listContactMetadataFieldMappingss,
					variables: { tenant: userContext.tenantId },
				}),
			);
			promises.push(
				client.graphql({
					query: listContactCallWindowsFieldMappingss,
					variables: { tenant: userContext.tenantId },
				}),
			);

			const results = await Promise.all(promises);

			setContactMappingList(results[0].data.tenantMappings.items);
			setContactMetadataMappingList(results[1].data.listContactMetadataFieldMappingss.items);
			setContactCallWindowsMappingList(results[2].data.listContactCallWindowsFieldMappingss.items)

			setLoadingContactMappings(false);
			setLoadingContactMetadataMappings(false);
			setLoadingCallWindowsMappings(false);
			userContext.fieldMappingTab = 0;
		}
		if (userContext.tenantId) {
			getData();
		}
	}, [userContext.tenantId]);

	function handleSearchChange(value) {
		window.sessionStorage.setItem('FieldMappingsSearch', value);
	}

	async function deleteContactMapping(toDelete) {
		const itemsCopy = JSON.parse(JSON.stringify(contactMappingList));
		await client.graphql({
			query: deleteFieldsMapping,
			variables: { input: { id: toDelete.id } },
		});
		itemsCopy.splice(toDelete.tableData.id, 1);
		setContactMappingList(itemsCopy);
		enqueueSnackbar('Mapping deleted', {
			variant: 'success',
		});
	}

	async function deleteContactMetaDataMappings(toDelete) {
		const itemsCopy = JSON.parse(JSON.stringify(contactMetadataMappingList));
		await client.graphql({
			query: deleteFieldsMapping,
			variables: { input: { id: toDelete.id } },
		});
		itemsCopy.splice(toDelete.tableData.id, 1);
		setContactMetadataMappingList(itemsCopy);
		enqueueSnackbar('Mapping deleted', {
			variant: 'success',
		});
	}

	async function deleteContactCallWindowMapping(toDelete) {
		const itemsCopy = JSON.parse(JSON.stringify(contactCallWindowsMappingList));
		await client.graphql({
			query: deleteContactMetadataFieldMappings,
			variables: { input: { id: toDelete.id } },
		});
		itemsCopy.splice(toDelete.tableData.id, 1);
		setContactCallWindowsMappingList(itemsCopy);
		enqueueSnackbar('Mapping deleted', {
			variant: 'success',
		});
	}

	return (
		<Box>
			<PageAppBar
				title="Field Mappings"
				description={`Manage import field mappings for ${mappingType} data.`}
				actionOneText={`+ ${mappingType} Mapping`}
				actionOneHandler={() => navigate(mappingPath)}
			/>
			<Box>
				<Tabs
					value={selectedTab}
					indicatorColor="primary"
					textColor="primary"
					onChange={(_, newValue) => {
						setSelectedTab(newValue)
						switch (newValue) {
							case 0:
								setMappingPath('contact/new');
								setMappingType('Contact');
								break;
							case 1:
								setMappingPath('metadata/new');
								setMappingType('Contact Metadata');
								break;
							case 2:
								setMappingPath('call-windows/new');
								setMappingType('Contact Call Windows');
								break;
							default:
								break;
						}

					}
					}
				>
					<Tab sx={tabStyle} label="Contact" />
					<Tab sx={tabStyle} label="Contact Metadata" />
					<Tab sx={tabStyle} label="Contact Call Windows" />
				</Tabs>
				<Divider />
				{selectedTab === 0 && (
					<Box>
						<MaterialTable
							components={{
								Container: (props) => <Box {...props} elevation={0} />,
							}}
							title=""
							data={contactMappingList}
							columns={[
								{ title: 'Name', field: 'name' },
								{
									title: 'Created Date',
									field: 'createdAt',
									render: (rowData) =>
										new Date(rowData.createdAt).toDateString(),
								},
								{
									title: 'Updated Date',
									field: 'updatedAt',
									render: (rowData) =>
										new Date(rowData.updatedAt).toDateString(),
								},
							]}
							isLoading={loadingContactMappings}
							options={{
								actionsColumnIndex: -1,
								pageSize: 10,
								searchText: window.sessionStorage.getItem(
									'FieldMappingsSearch',
								),
								searchFieldStyle: {
									marginBottom: '16px',
									marginleft: '-28px',
								},
								headerStyle: {
									borderTop: '1px solid #e0e0e0',
									padding: '16px',
								},
								searchFieldVariant: 'outlined',
								paginationType: 'stepped',
							}}
							onSearchChange={handleSearchChange}
							actions={[
								{
									icon: () => <EditOutlined color="primary" />,
									tooltip: 'Edit Mapping',
									onClick: (_, rowData) => {
										navigate(`contact/${rowData.id}`);
									},
								},
							]}
							editable={{
								onRowDelete: (toDelete) =>
									new Promise((resolve, reject) => {
										try {
											deleteContactMapping(toDelete);
											resolve();
										} catch (err) {
											console.log(err);
											enqueueSnackbar('Mapping could not be deleted', {
												variant: 'error',
											});
											reject();
										}
									}),
							}}
							onRowClick={(_, rowData) => {
								navigate(`contact/${rowData.id}`);
							}}
						/>
					</Box>
				)}
				{selectedTab === 1 && (
					<Box container spacing={2} direction="column">
						<Box item>
							<MaterialTable
								components={{
									Container: (props) => <Box {...props} elevation={0} />,
								}}
								title=""
								isLoading={loadingContactMetadataMappings}
								data={contactMetadataMappingList}
								columns={[
									{ title: 'Name', field: 'name' },
									{
										title: 'Created Date',
										field: 'createdAt',
										render: (rowData) =>
											new Date(rowData.createdAt).toDateString(),
									},
									{
										title: 'Updated Date',
										field: 'updatedAt',
										render: (rowData) =>
											new Date(rowData.updatedAt).toDateString(),
									},
								]}
								options={{
									actionsColumnIndex: -1,
									pageSize: 10,
									searchFieldStyle: {
										marginBottom: '16px',
										marginleft: '-28px',
									},
									headerStyle: {
										borderTop: '1px solid #e0e0e0',
										padding: '16px',
									},
									searchFieldVariant: 'outlined',
									paginationType: 'stepped',
								}}
								actions={[
									{
										icon: () => <EditOutlined />,
										tooltip: 'Edit Profile',
										onClick: (_, rowData) => {
											navigate(`metadata/${rowData.id}`);
										},
									},
								]}
								editable={{
									onRowDelete: (toDelete) =>
										new Promise((resolve, reject) => {
											try {
												deleteContactMetaDataMappings(toDelete);
												resolve();
											} catch (err) {
												console.log(err);
												enqueueSnackbar('Mapping could not be deleted', {
													variant: 'error',
												});
												reject();
											}
										}),
								}}
								onRowClick={(_, rowData) => {
									navigate(`metadata/${rowData.id}`);
								}}
							/>
						</Box>
					</Box>
				)}
				{selectedTab === 2 && (
					<Box container spacing={2} direction="column">
						<Box item>
							<MaterialTable
								components={{
									Container: (props) => <Box {...props} elevation={0} />,
								}}
								title=""
								isLoading={loadingCallWindowsMappings}
								data={contactCallWindowsMappingList}
								columns={[
									{ title: 'Name', field: 'name' },
									{
										title: 'Created Date',
										field: 'createdAt',
										render: (rowData) =>
											new Date(rowData.createdAt).toDateString(),
									},
									{
										title: 'Updated Date',
										field: 'updatedAt',
										render: (rowData) =>
											new Date(rowData.updatedAt).toDateString(),
									},
								]}
								options={{
									actionsColumnIndex: -1,
									pageSize: 10,
									searchFieldStyle: {
										marginBottom: '16px',
										marginleft: '-28px',
									},
									headerStyle: {
										borderTop: '1px solid #e0e0e0',
										padding: '16px',
									},
									searchFieldVariant: 'outlined',
									paginationType: 'stepped',
								}}
								actions={[
									{
										icon: () => <EditOutlined />,
										tooltip: 'Edit Profile',
										onClick: (_, rowData) => {
											navigate(`call-windows/${rowData.id}`);
										},
									},
								]}
								editable={{
									onRowDelete: (toDelete) =>
										new Promise((resolve, reject) => {
											try {
												deleteContactCallWindowMapping(toDelete);
												resolve();
											} catch (err) {
												console.log(err);
												enqueueSnackbar('Mapping could not be deleted', {
													variant: 'error',
												});
												reject();
											}
										}),
								}}
								onRowClick={(_, rowData) => {
									navigate(`call-windows/${rowData.id}`);
								}}
							/>
						</Box>
					</Box>
				)}
			</Box>
		</Box>
	);
}

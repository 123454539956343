import { useState, useEffect, useContext } from 'react';
import {
	CircularProgress,
	Box,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Card,
	CardHeader,
	IconButton,
	Tooltip,
	CardContent,
	Typography,
	FormControl,
	Select,
	MenuItem,
	InputLabel,
	Checkbox,
	Dialog,
	DialogTitle,
	DialogContent,
	TextField,
	DialogActions,
	Button,
} from '@mui/material';
import { UserContext } from '../../contexts/UserContext';
import { PageAppBar } from 'src/components/pageAppBar';
import { del, generateClient, get, post } from 'aws-amplify/api';
import { fetchAuthSession } from 'aws-amplify/auth';
import { CallOutlined, EventOutlined } from '@mui/icons-material';
import { getContact } from 'src/graphql/queries';
import { Cache } from 'aws-amplify/utils';
import { CallStatusPane } from 'src/components/engagement/callStatusPane';
import { I18n } from 'aws-amplify/utils';
import { actionOneButtonStyle, actionTwoButtonStyle } from 'src/theme';

const STRINGS = {
	'en-US': { // English (United States)
		assignee: 'Assignee',
		assignedToMe: 'Assigned to me',
		unassigned: 'Unassigned',
		callBackDetails: 'Callback Details',
		callContact: 'Call Contact',
		rescheduleCallback: 'Reschedule Callback',
		callBackNumber: 'Callback Number',
		callBackTime: 'Callback Time',
		note: 'Note'

	},
	'es-419': { // Spanish (Latin America)
		assignee: 'Asignado',
		assignedToMe: 'Asignado a mí',
		unassigned: 'No asignado',
		callBackDetails: 'Detalles de la devolución de llamada',
		callContact: 'Llamar al contacto',
		rescheduleCallback: 'Reprogramar devolución de llamada',
		callBackNumber: 'Número de devolución de llamada',
		callBackTime: 'Hora de devolución de llamada',
		note: 'Nota'

	},
	'pt-BR': { // Portuguese (Brazil)
		assignee: 'Atribuído',
		assignedToMe: 'Atribuído a mim',
		unassigned: 'Não atribuído',
		callBackDetails: 'Detalhes da devolução de chamada',
		callContact: 'Ligar para o contato',
		rescheduleCallback: 'Reagendar devolução de chamada',
		callBackNumber: 'Número de devolução de chamada',
		callBackTime: 'Hora de devolução de chamada',
		note: 'Nota'

	}
};
I18n.putVocabularies(STRINGS);

export function Callbacks(props) {
	const userContext = useContext(UserContext);

	/** The amplify client */
	const client = generateClient();

	const [filterOptions, setFilterOptions] = useState([]);

	const [contactOnCall, setContactOnCall] = useState(null);

	/** Whether something is currently being loaded in the page. */
	const [loading, setLoading] = useState(true);

	/** The list of callbacks. */
	const [callBacks, setCallBacks] = useState([]);

	const [filteredCallbacks, setFilteredCallbacks] = useState([]);

	const [agentCampaigns, setAgentCampaigns] = useState([]);

	const [selectedCallback, setSelectedCallback] = useState(null);

	const [selCallBackData, setSelCallBackData] = useState(null);

	const [rescheduleCallback, setRescheduleCallback] = useState(false);

	// Get the agent campaigns and check the cache to see if we are on a call back
	useEffect(() => {
		getAgentCampaigns();
		rehydrateFromCache();
		if (userContext.telephonyProvider === 'Webex') {
			addWebexCallEventListeners();
		}

		// Clean up listeners.
		return () => {
			if (userContext.telephonyProvider === 'Webex') {
				removeWebexCallEventListeners();
			}
		};
	}, []);

	useEffect(() => {
		if (agentCampaigns.length > 0) {
			// Get initial list
			getAgentCallbacks();
			// Get the agent callbacks every minute
			const interval = setInterval(() => {
				getAgentCallbacks();
			}, 60000);
			return () => clearInterval(interval);
		}

	}, [agentCampaigns]);

	useEffect(() => {
		if (selectedCallback) {
			getCallbackData();
		}
	}, [selectedCallback]);

	/**
	 * Filter the list of callbacks whenever the filter options change.
	 */
	useEffect(() => {
		if (filterOptions.length === 0 || filterOptions.length === 2) {
			// Just filter out callbacks that are assigned to other agents.
			const visibleCallbacks = callBacks.filter((callback) => {
				return callback.agentId === props.agentId || !callback.agentId;
			});
			setFilteredCallbacks(visibleCallbacks);
			return;
		}

		const filteredCallbacks = callBacks.filter((callback) => {
			if (filterOptions.includes('assignedToMe') && callback.agentId === props.agentId) {
				return true;
			} else if (filterOptions.includes('unassigned') && !callback.agentId) {
				return true;
			}
			return false;
		});
		setFilteredCallbacks(filteredCallbacks);

		// If the selected callback is no longer in the list, clear the selection.
		if (selectedCallback && !filteredCallbacks.find((callback) => callback.contactId === selectedCallback.contactId)) {
			setSelectedCallback(null);
			setSelCallBackData(null);
		}
	}, [filterOptions, callBacks]);

	/**
 * Registers listeners for Webex call events to get call progression. This should only be called if the user is using Webex
 * as the telephony provider.
 */
	async function addWebexCallEventListeners() {
		try {
			const { Desktop } = await import('@wxcc-desktop/sdk');
			Desktop.agentContact.addEventListener('eAgentContactAssigned', async () => {
				setContactOnCall((contactOnCall) => {
					return { ...contactOnCall, callStatus: 'On Call' };
				});
			});
			Desktop.agentContact.addEventListener('eAgentContactEnded', async () => {
				setContactOnCall((contactOnCall) => {
					return { ...contactOnCall, callStatus: 'Call Ended - Awaiting Wrap Up' };
				});
			});
			Desktop.agentContact.addEventListener('eAgentContactWrappedUp', async () => {
				setContactOnCall(null);
				setSelectedCallback(null);
				setSelCallBackData(null);
				getAgentCallbacks();
			});
		} catch (error) {
			console.error('Error setting up Webex agent contact listeners', error);
		}
	}

	/**
 * Removes the Webex call event listeners. This should only be called if the user is using Webex as the telephony provider.
 */
	async function removeWebexCallEventListeners() {
		try {
			const { Desktop } = await import('@wxcc-desktop/sdk');
			Desktop.agentContact.removeAllEventListeners();
		} catch (error) {
			console.error('Error removing Webex agent contact listeners', error);
		}
	}

	async function getCallbackData() {
		// Get Contact Data from Contact ID
		const contactData = await client.graphql({
			query: getContact,
			variables: {
				id: selectedCallback.contactId
			}
		});
		setSelCallBackData({
			contact: contactData.data.getContact,
			callback: selectedCallback
		});
	}


	async function getAgentCallbacks() {
		try {
			const session = await fetchAuthSession();
			const promises = [];

			for (const campaign of agentCampaigns) {
				promises.push(
					get({
						apiName: 'cdyxoutreach',
						path: `/cache/callBacks/${campaign}/`,
						options: {
							headers: {
								Authorization: `Bearer ${session.tokens.idToken}`,
								'x-api-key': userContext.apiKey,
							}
						}
					}).response
				);
			}

			const responses = await Promise.all(promises);
			const callbacksList = [];
			for (const response of responses) {
				const callbacks = await response.body?.json();
				if (callbacks.data) {
					for (const callback in callbacks.data) {
						callbacksList.push(JSON.parse(callbacks.data[callback]));
					}
				}
			}

			setCallBacks(callbacksList);

			setLoading(false);

		} catch (error) {
			console.error('Error getting agent callbacks', error);
		}
	}


	async function getAgentCampaigns() {
		try {
			const campaignsResponse = await get({
				apiName: 'cdyxoutreach',
				path: '/cci/webex/contact-center/agent-campaigns/' + props.teamId,
				options: {
					headers: {
						Authorization: `Bearer ${(await fetchAuthSession()).tokens.idToken}`,
						'x-api-key': userContext.apiKey,
					}
				}
			}).response;

			const campaigns = await campaignsResponse.body.json();

			setAgentCampaigns(campaigns);

			getAgentCallbacks();

		} catch (error) {
			console.error('Error getting agent campaigns', error);
		}
	}

	function getColor(callback) {
		// If the callback hasn't happened yet, color it disabled gray, if it's passed the time and within today, color it green, if it's passed the time and not within today, color it red
		const currentTime = new Date();
		const callbackTime = new Date(callback.callDateTime);

		if (callbackTime > currentTime) {
			return 'gray';
		} else if (callbackTime.toDateString() === currentTime.toDateString()) {
			return 'green';
		} else {
			return 'red';
		}
	}

	async function makeDial() {
		setContactOnCall({
			...selCallBackData.contact,
			phone: selCallBackData.callback.callBackPOC, // Because we can dial a different number than the contact number for a callback.
		});
		try {
			if (userContext.telephonyProvider === 'Webex') {
				const { Desktop } = await import('@wxcc-desktop/sdk');
				const outDial = await Desktop.dialer.startOutdial({
					data: {
						entryPointId: selCallBackData.callback.entryPointId,
						destination: selCallBackData.callback.callBackPOC,
						direction: 'OUTBOUND',
						origin: selCallBackData.callback.outDialNumber,
						attributes: {
							FirstName: selCallBackData.contact.firstName,
							LastName: selCallBackData.contact.lastName,
							contactId: selCallBackData.callback.contactId,
							campaignId: selCallBackData.callback.campaignId,
							segmentId: selCallBackData.callback.segmentId,
							externalId: selCallBackData.contact.externalId,
						},
						mediaType: 'telephony',
						outboundType: 'OUTDIAL',
					}
				});
				console.log('Starting Call Back', outDial);
				setContactOnCall({
					...selCallBackData.contact,
					phone: selCallBackData.callback.callBackPOC, // Because we can dial a different number than the contact number for a callback.
					callStatus: 'Dialing...'
				});
				await Cache.setItem('contactOnCall', selCallBackData.contact);
				await removeCallBack();
			}
		} catch (error) {
			setContactOnCall({
				...selCallBackData.contact,
				phone: selCallBackData.callback.callBackPOC, // Because we can dial a different number than the contact number for a callback.
				callStatus: 'Error'
			});
			console.error('Error making dial', error);
		}
	}

	async function removeCallBack() {
		try {
			await del({
				apiName: 'cdyxoutreach',
				path: `/cache/deleteCallback/${selCallBackData.callback.campaignId}/${selCallBackData.callback.contactId}/`,
				options: {
					headers: {
						Authorization: `Bearer ${(await fetchAuthSession()).tokens.idToken}`,
						'x-api-key': userContext.apiKey
					}
				}
			}).response;
		} catch (err) {
			console.error('Error removing callback', err);
		}

	}

	async function updateCallBackTime() {
		try {
			const response = await post({
				apiName: 'cdyxoutreach',
				path: '/cache/createCallback',
				options: {
					headers: {
						Authorization: `Bearer ${(await fetchAuthSession()).tokens.idToken}`,
						'x-api-key': userContext.apiKey
					},
					body: {
						...selCallBackData.callback,
					}
				}
			}).response;
			const callback = await response.body.json();
			setSelectedCallback(callback);
			setRescheduleCallback(false);

		} catch (err) {
			console.error('Error updating callback time', err);
		}
	}

	/**
	 * Re-hydrates the UI state from the cache.
	 */
	async function rehydrateFromCache() {
		const approvedContact = await Cache.getItem('contactOnCall');
		if (approvedContact) {

			if (userContext.telephonyProvider === 'Webex') {
				const { Desktop } = await import('@wxcc-desktop/sdk');
				const taskMap = await Desktop.actions.getTaskMap();
				const mapKeys = Array.from(taskMap.keys());

				// Not on a call, clear the contact on call.
				if (mapKeys.length === 0) {
					console.debug('The agent is no longer on a call.');
					await Cache.removeItem('contactOnCall');
					return;
				}

				// On a different call, clear the contact on call.
				const taskId = mapKeys[0];
				const task = taskMap.get(taskId);
				if (task.interaction.callAssociatedDetails.dn !== approvedContact.phone) {
					console.debug('The agent is on a different call.');
					await Cache.removeItem('contactOnCall');
					return;
				}

				// Determine the call state for the approved contact.
				const state = task.interaction.state;
				approvedContact.callStatus = state === 'wrapUp' ? 'Call Ended - Awaiting Wrap Up' : 'On Call';
			} else {
				// NICE
				approvedContact.callStatus = 'On Call';
			}
			setContactOnCall(approvedContact);
		}
	}

	return (
		<>
			{/* Callbacks */}
			{!contactOnCall && (
				<>
					<PageAppBar title="Call Backs"
						description='View, Edit, and Handle Call Backs'
					/>
					<Box display="grid" gridTemplateColumns="350px 1fr" gap={2}>
						<Box>

							<FormControl size='small' fullWidth>
								<InputLabel id="assignee">{I18n.get('assignee', STRINGS['en-US'].assignee)}</InputLabel>
								<Select
									color="primary"
									margin="dense"
									name="assignee"
									label={I18n.get('assignee', STRINGS['en-US'].assignee)}
									multiple
									value={filterOptions}
									onChange={(event) => setFilterOptions(event.target.value)}
								>
									<MenuItem key="assignedToMe" value="assignedToMe">
										<Checkbox checked={filterOptions.includes('assignedToMe')} />
										{I18n.get('assignedToMe', STRINGS['en-US'].assignedToMe)}
									</MenuItem>
									<MenuItem key="unassigned" value="unassigned">
										<Checkbox checked={filterOptions.includes('unassigned')} />
										{I18n.get('unassigned', STRINGS['en-US'].unassigned)}
									</MenuItem>
								</Select>
							</FormControl>
							<List>
								{filteredCallbacks.map((callback, index) => {
									return (
										<ListItemButton

											key={index}
											onClick={() => {
												setSelectedCallback(callback);
											}}
										>
											<ListItemIcon>
												<Box
													sx={{
														width: 15,
														height: 15,
														borderRadius: '50%',
														backgroundColor: getColor(callback),
														display: 'flex',
														justifyContent: 'center',
														alignItems: 'center',
													}}
												></Box>
											</ListItemIcon>
											<ListItemText primaryTypographyProps={{
												color: 'primary'
											}} primary={callback.callBackPOC} secondary={callback.agentId ? `${callback.createdByAgentName} - ${new Date(callback.callDateTime).toLocaleString()}` : new Date(callback.callDateTime).toLocaleString()} />
										</ListItemButton>
									);
								})}
							</List>
						</Box>
						{selCallBackData && <Box>
							<Card variant="outlined">
								<CardHeader title={I18n.get('callBackDetails', STRINGS['en-US'].callBackDetails)} action={
									<Box display="flex" gap={2}>
										<Tooltip title={I18n.get('callContact', STRINGS['en-US'].callContact)}>
											<IconButton onClick={makeDial}>
												<CallOutlined color="primary" />
											</IconButton>
										</Tooltip>
										<Tooltip title={I18n.get('rescheduleCallback', STRINGS['en-US'].rescheduleCallback)}>
											<IconButton onClick={() => setRescheduleCallback(true)}>
												<EventOutlined color="primary" />
											</IconButton>
										</Tooltip>
										{/* <Tooltip title="Delete Callback">
											<IconButton onClick={removeCallBack}>
												<DeleteOutline color="primary" />
											</IconButton>
										</Tooltip> */}
									</Box>
								} />
								<CardContent>
									<Box display="flex" flexDirection="column" gap={2}>
										<Box display="flex" flexDirection="column">
											<Typography variant="h6">{`${selCallBackData.contact.firstName} ${selCallBackData.contact.lastName}`}</Typography>
											<Typography variant="body1">{selCallBackData.contact.externalId}</Typography>
											<Typography variant="body1">{selCallBackData.contact.source}</Typography>
										</Box>
										<Box display="grid" gridTemplateColumns="1fr 1fr" gap={2}>
											<Box>
												<Typography variant="overline">{I18n.get('callBackNumber', STRINGS['en-US'].callBackNumber)}</Typography>
												<Typography variant="body1">{selCallBackData.callback.callBackPOC}</Typography>
											</Box>
											<Box>
												<Typography variant="overline">{I18n.get('callBackTime', STRINGS['en-US'].callBackTime)}</Typography>
												<Typography variant="body1">{new Date(selCallBackData.callback.callDateTime).toLocaleString()}</Typography>
											</Box>
											<Box>
												<Typography variant="overline">{I18n.get('note', STRINGS['en-US'].note)}</Typography>
												<Typography variant="body1">{selCallBackData.callback.note}</Typography>
											</Box>
										</Box>
									</Box>
								</CardContent>
							</Card>
						</Box>}
					</Box>
				</>
			)}

			{/* Loading */}
			{loading && (
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						height: '100vh',
					}}
				>
					<CircularProgress
						variant="indeterminate"
						color="primary"
					></CircularProgress>
				</div>
			)}

			{/* Call Status Pane */}
			{contactOnCall && (
				<CallStatusPane
					contact={contactOnCall}
					onDismiss={() => {
						setContactOnCall(null);
					}}
				></CallStatusPane>
			)}

			<Dialog open={rescheduleCallback}>
				<DialogTitle>Reschedule Callback</DialogTitle>
				<DialogContent>
					<TextField
						label="Callback Time"
						type="datetime-local"
						fullWidth
						value={selCallBackData?.callback.callDateTime}
						onChange={(event) => {
							setSelCallBackData({
								...selCallBackData,
								callback: {
									...selCallBackData.callback,
									callDateTime: event.target.value
								}
							});
						}}
					/>
				</DialogContent>
				<DialogActions>
					<Button sx={actionTwoButtonStyle} onClick={() => setRescheduleCallback(false)}>Cancel</Button>
					<Button sx={actionOneButtonStyle} onClick={updateCallBackTime}>Save</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

import React from 'react';
import PropTypes from 'prop-types';
import {
	Select,
	FormControl,
	FormControlLabel,
	MenuItem,
	TextField,
	Radio,
	RadioGroup,
	Grid,
	InputLabel,
	Switch
} from '@mui/material';
import moment from 'moment';

const ValueEditor = ({
    operator,
    value,
    handleOnChange,
    title,
    className,
    type,
    inputType,
    values
}) => {
    if (operator === 'null' || operator === 'notNull') {
        return null;
    }

    switch (type) {
        case 'select':
            return (
                <Grid item>
                    <FormControl>
                        <InputLabel id={title}>{title}</InputLabel>
                        <Select
														labelId={title}
														label={title}
														sx={{ minWidth: '200px' }}
                            value={value}
                            onChange={(e) => handleOnChange(e.target.value)} >
                            {values.map((v) => (
                                <MenuItem key={v.name} value={v.name}> {v.label}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            );

        case 'boolean':
            return (
                <Grid item>
                    <FormControlLabel variant=""
                        control={
                            <Switch
                                checked={!!value}
                                onChange={(e) => handleOnChange(e.target.checked)}
                                value={!!value}
                                color="primary"
                            />
                        }
                        label={title}
                    />
                </Grid>
            );

        case 'radio':
            return (
                <Grid item>
                    <RadioGroup aria-label={title} onChange={(e) => handleOnChange(e.target.value)}>
                        {values.map((v) => (
                            <FormControlLabel variant="" value={v.name} control={<Radio />} label={v.name} />
                        ))}
                    </RadioGroup>
                </Grid>
            );

        case 'date':
            return (
                <Grid item>
                    <TextField InputLabelProps={{ shrink: true }} label={title} value={value} type="datetime-local" color="primary" InputProps={{ inputProps: { step: 1 } }} onChange={e => handleOnChange(e.target.value)} />
                </Grid>
            );

        case 'epoch': // For custom fields of the date type
            return (
                <Grid item>
                    <TextField InputLabelProps={{ shrink: true }} label={title} value={moment(value).format('YYYY-MM-DDTHH:mm:ss')} type="datetime-local" color="primary" InputProps={{ inputProps: { step: 1 } }} onChange={e => handleOnChange(new Date(e.target.value).getTime())} />
                </Grid>
            );

        default:
            return (
                <Grid item>
                    <TextField
                        id={title}
                        label={title}
                        value={value}
                        onChange={(e) => handleOnChange(e.target.value)}
                    />
                </Grid>
            );
    }
};

ValueEditor.displayName = 'ValueEditor';

ValueEditor.propTypes = {
    field: PropTypes.string,
    operator: PropTypes.string,
    value: PropTypes.any,
    handleOnChange: PropTypes.func,
    title: PropTypes.string,
    className: PropTypes.string,
    type: PropTypes.oneOf(['select', 'checkbox', 'radio', 'text']),
    inputType: PropTypes.string,
    values: PropTypes.arrayOf(PropTypes.object)
};

export default ValueEditor;

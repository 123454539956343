import { InputLabel, Switch, Box, TextField, FormControl, Select, MenuItem, Divider, Card, CardHeader, CardContent, FormControlLabel, FormHelperText, Typography } from '@mui/material';
import { useContext } from 'react';
import { UserContext } from "src/contexts/UserContext";

export default function MessagingOptions(props) {
	const { formikProps, availableMessageTemplates, emailMessagingSkills, smsMessagingSkills, smsPhoneNumbers, ivrScripts } = props;
	const userContext = useContext(UserContext);

	return (
		<Card variant='outlined'>
			<CardHeader title='Messaging Options' titleTypographyProps={{
				variant: "body2",
				color: "textSecondary",
			}}
				subheader={userContext.telephonyProvider === 'Webex' ? <Typography variant="body" color="textSecondary" style={{ padding: '6px' }}><span style={{ color: 'yellow' }}>⚠</span> In order to send an email with Webex, you must set up an email app in Webex Connect beforehand.</Typography> : null}
			/>
			<CardContent>
				<Card elevation={0}>
					<CardHeader title={<FormControlLabel
						control={<Switch checked={formikProps.values.channels.emailEnabled} onChange={formikProps.handleChange} name="channels.emailEnabled" color="primary" />}
						label="Email"
					/>}
					/>
					{formikProps.values.channels.emailEnabled && <CardContent>
						<Box display="grid" gridTemplateColumns="1fr 1fr" alignItems="center" gap={2}>
							{/* Message template */}
							<FormControl>
								<InputLabel error={formikProps.errors.channels?.email?.messageTemplate && formikProps.touched?.channels?.email?.messageTemplate}>Message Template</InputLabel>
								<Select
									name="channels.email.messageTemplate"
									label="Message Template"
									value={formikProps.values.channels.email.messageTemplate}
									onBlur={formikProps.handleBlur}
									onChange={formikProps.handleChange}
									error={formikProps.errors.channels?.email?.messageTemplate && formikProps.touched?.channels?.email?.messageTemplate}
								>
									{availableMessageTemplates.map(template =>
										<MenuItem key={template.id} value={template.id}>{template.name}</MenuItem>)}
								</Select>
								{formikProps.touched.channels?.email?.messageTemplate && formikProps.errors.channels?.email?.messageTemplate &&
									<FormHelperText error>{formikProps.errors.channels?.email?.messageTemplate}</FormHelperText>}
							</FormControl>

							{/* Email subject */}
							<TextField
								margin="dense"
								name="channels.email.subject"
								label="Subject"
								type="text"

								value={formikProps.values.channels.email.subject}
								onChange={formikProps.handleChange}
								onBlur={formikProps.handleBlur}
								error={formikProps.errors.channels?.email?.subject && formikProps.touched?.channels?.email?.subject}
								helperText={formikProps.touched.channels?.email?.subject && formikProps.errors.channels?.email?.subject}
							/>
							{/* Sending email */}
							<TextField
								margin="dense"
								name="channels.email.sendingEmail"
								label="Sending Email"
								type="text"

								value={formikProps.values.channels.email.sendingEmail}
								onChange={formikProps.handleChange}
								onBlur={formikProps.handleBlur}
								error={formikProps.errors.channels?.email?.sendingEmail && formikProps.touched?.channels?.email?.sendingEmail}
								helperText={formikProps.touched.channels?.email?.sendingEmail && formikProps.errors.channels?.email?.sendingEmail}
							/>
							{/* Sending email display name */}
							{userContext.telephonyProvider !== 'NiC' &&
								<TextField
									margin="dense"
									name="channels.email.sendingEmailDisplayName"
									label="Sending Email Display Name"
									placeholder='Enter a display name'
									type="text"

									value={formikProps.values.channels.email.sendingEmailDisplayName}
									onChange={formikProps.handleChange}
									onBlur={formikProps.handleBlur}
									error={formikProps.errors.channels?.email?.sendingEmailDisplayName && formikProps.touched?.channels?.email?.sendingEmailDisplayName}
									helperText="Optional. If not provided, the sending email will be used."
								/>
							}
							{/* Email skill */}
							{userContext.telephonyProvider === 'NiC' &&
								<FormControl>
									<InputLabel error={formikProps.errors.channels?.email?.emailSkill && formikProps.touched?.channels?.email?.emailSkill}>Email Skill</InputLabel>
									<Select
										margin="dense"
										name="channels.email.emailSkill"
										label="Email Skill"
										value={formikProps.values.channels.email.emailSkill}
										onChange={formikProps.handleChange}
										onBlur={formikProps.handleBlur}
										error={formikProps.errors.channels?.email?.emailSkill && formikProps.touched?.channels?.email?.emailSkill}
									>
										{emailMessagingSkills.map(skill =>
											<MenuItem key={skill.skillId} value={skill.skillId}>{skill.skillName}</MenuItem>)}
									</Select>
									{formikProps.touched.channels?.email?.emailSkill && formikProps.errors.channels?.email?.emailSkill &&
										<FormHelperText error>{formikProps.errors.channels?.email?.emailSkill}</FormHelperText>}
								</FormControl>
							}
							{/* Email IVR script */}
							{userContext.telephonyProvider === 'NiC' &&
								<FormControl>
									<InputLabel error={formikProps.errors.channels?.email?.emailIVRScript && formikProps.touched?.channels?.email?.emailIVRScript}>Email IVR Script</InputLabel>
									<Select
										margin="dense"
										name="channels.email.emailIVRScript"
										label="Email IVR Script"
										value={formikProps.values.channels.email.emailIVRScript}
										onChange={formikProps.handleChange}
										onBlur={formikProps.handleBlur}
										error={formikProps.errors.channels?.email?.emailIVRScript && formikProps.touched?.channels?.email?.emailIVRScript}
									>
										{ivrScripts.map(script =>
											<MenuItem key={script.scriptId} value={script.scriptName}>{script.scriptName}</MenuItem>)}
									</Select>
									{formikProps.touched.channels?.email?.emailIVRScript && formikProps.errors.channels?.email?.emailIVRScript &&
										<FormHelperText error>{formikProps.errors.channels?.email?.emailIVRScript}</FormHelperText>}
								</FormControl>
							}

						</Box>
					</CardContent>}
				</Card>
				<Divider />
				<Card elevation={0}>
					<CardHeader title={<FormControlLabel
						control={<Switch checked={formikProps.values.channels.smsEnabled} onChange={formikProps.handleChange} name="channels.smsEnabled" color="primary" />}
						label="SMS"
					/>}
					/>
					{formikProps.values.channels.smsEnabled && <CardContent>
						<Box display="grid" gridTemplateColumns="1fr 1fr" gap={2}>
							{/* SMS provider */}
							<FormControl>
								<InputLabel>SMS Provider</InputLabel>
								<Select
									name="channels.sms.providerName"
									label="SMS Provider"
									value={formikProps.values.channels.sms.providerName}
									onBlur={formikProps.handleBlur}
									onChange={formikProps.handleChange}
								>
									{userContext.telephonyProvider === 'Genesys' &&
										<MenuItem key='genesys' value='genesys'>Genesys</MenuItem>
									}
									{userContext.telephonyProvider === 'NiC' &&
										<MenuItem key='incontact' value='incontact'>InContact</MenuItem>
									}
									{userContext.telephonyProvider === 'Webex' &&
										<MenuItem key='webex' value='webex'>Webex</MenuItem>
									}
									<MenuItem key='textel' value='textel'>Textel</MenuItem>
								</Select>
							</FormControl>
							{/* Message template */}
							<FormControl>
								<InputLabel error={formikProps.errors.channels?.sms?.messageTemplate && formikProps.touched?.channels?.sms?.messageTemplate}>Message Template</InputLabel>
								<Select
									name="channels.sms.messageTemplate"
									label="Message Template"
									value={formikProps.values.channels.sms.messageTemplate}
									onBlur={formikProps.handleBlur}
									onChange={formikProps.handleChange}
									error={formikProps.errors.channels?.sms?.messageTemplate && formikProps.touched?.channels?.sms?.messageTemplate}
								>
									{availableMessageTemplates.filter(filter => filter.type === "PlainText").map(template =>
										<MenuItem key={template.id} value={template.id}>{template.name}</MenuItem>)}
								</Select>
								{formikProps.touched.channels?.sms?.messageTemplate && formikProps.errors.channels?.sms?.messageTemplate &&
									<FormHelperText error>{formikProps.errors.channels?.sms?.messageTemplate}</FormHelperText>}
							</FormControl>
							{/* SMS skill */}
							{formikProps.values.channels.sms.providerName === 'incontact' && <FormControl>
								<InputLabel error={formikProps.errors.channels?.sms?.smsSkill && formikProps.touched?.channels?.sms?.smsSkill}>SMS Skill</InputLabel>
								<Select
									margin="dense"
									name="channels.sms.smsSkill"
									value={formikProps.values.channels.sms.smsSkill}

									onChange={formikProps.handleChange}
									error={formikProps.errors.channels?.sms?.smsSkill && formikProps.touched?.channels?.sms?.smsSkill}
								>
									{smsMessagingSkills.map(skill =>
										<MenuItem key={skill.skillId} value={skill.skillId}>{skill.skillName}</MenuItem>)}
								</Select>
								{formikProps.touched.channels?.sms?.smsSkill && formikProps.errors.channels?.sms?.smsSkill &&
									<FormHelperText error>{formikProps.errors.channels?.sms?.smsSkill}</FormHelperText>}
							</FormControl>}
							{/* SMS IVR script */}
							{formikProps.values.channels.sms.providerName === 'incontact' && <FormControl>
								<InputLabel error={formikProps.errors.channels?.sms?.smsSkill && formikProps.touched?.channels?.sms?.smsSkill}>SMS IVR Script</InputLabel>
								<Select
									margin="dense"
									name="channels.sms.smsIVRScript"
									value={formikProps.values.channels.sms.smsIVRScript}

									onChange={formikProps.handleChange}
									error={formikProps.errors.channels?.sms?.smsIVRScript && formikProps.touched?.channels?.sms?.smsIVRScript}
								>
									{ivrScripts.map(script =>
										<MenuItem key={script.scriptId} value={script.scriptName}>{script.scriptName}</MenuItem>)}
								</Select>
								{formikProps.touched.channels?.sms?.smsIVRScript && formikProps.errors.channels?.sms?.smsIVRScript &&
									<FormHelperText error>{formikProps.errors.channels?.sms?.smsIVRScript}</FormHelperText>}
							</FormControl>}
							{/* SMS sending number */}
							{formikProps.values.channels.sms.providerName === 'genesys' &&
								<FormControl>
									<InputLabel error={formikProps.errors.channels?.sms?.sendingNumber && formikProps.touched?.channels?.sms?.sendingNumber}>Sending SMS Number</InputLabel>
									<Select
										margin="dense"
										name="channels.sms.sendingNumber"
										value={formikProps.values.channels.sms.sendingNumber}

										onChange={formikProps.handleChange}
										error={formikProps.errors.channels?.sms?.sendingNumber && formikProps.touched?.channels?.sms?.sendingNumber}
									>
										{smsPhoneNumbers.length === 0 &&
											<MenuItem key='none' value='none' disabled>No SMS numbers available</MenuItem>
										}
										{smsPhoneNumbers.map((phoneNumber) =>
											<MenuItem key={phoneNumber} value={phoneNumber}>{phoneNumber}</MenuItem>)}
									</Select>
									{formikProps.touched.channels?.sms?.smsSkill && formikProps.errors.channels?.sms?.smsSkill &&
										<FormHelperText error>{formikProps.errors.channels?.sms?.smsSkill}</FormHelperText>}
								</FormControl>
							}
							{(formikProps.values.channels.sms.providerName === 'textel' || formikProps.values.channels.sms.providerName === 'webex') &&
								<TextField
									margin="dense"
									name="channels.sms.sendingNumber"
									label="Sending SMS"
									type="text"

									value={formikProps.values.channels.sms.sendingNumber}
									onChange={formikProps.handleChange}
									onBlur={formikProps.handleBlur}
									helperText={
										formikProps.values.channels.sms.providerName === 'webex' ?
											'Enter your Connect SMS number exactly as it appears in Webex Connect' :
											''
									}
								/>
							}
							{/* Textel bearer token */}
							{formikProps.values.channels.sms.providerName === 'textel' &&
								<TextField
									margin="dense"
									name="channels.sms.bearerToken"
									label="Textel Bearer Token"
									type="text"

									value={formikProps.values.channels.sms.bearerToken}
									onChange={formikProps.handleChange}
									onBlur={formikProps.handleBlur}
								/>}
						</Box>
					</CardContent>}
				</Card>

			</CardContent>
		</Card>
	);
}

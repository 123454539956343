import React from 'react';
import PropTypes from 'prop-types';

import { Select, InputLabel, FormControl, MenuItem, Grid, ListSubheader } from '@mui/material';

const ValueSelector = ({ className, handleOnChange, options, title, value }) => (
	<Grid item>
		<FormControl>
			<InputLabel id="value-selector-label" className={className}>{title}</InputLabel>
			<Select
				label={title}
				sx={{ minWidth: '80px' }}
				labelId="value-selector-label"
				labelWidth={0}
				value={value}
				onChange={(e) => handleOnChange(e.target.value)} >
				{options.map((v) => (
					v.type === 'heading' ?
						(<ListSubheader color="primary">{v.label}</ListSubheader>)
						: (<MenuItem key={v.name} value={v.name}> {v.label}</MenuItem>)
				))}

			</Select>
		</FormControl>
	</Grid>
);

ValueSelector.displayName = 'ValueSelector';

ValueSelector.propTypes = {
	value: PropTypes.string,
	options: PropTypes.array.isRequired,
	className: PropTypes.string,
	handleOnChange: PropTypes.func,
	title: PropTypes.string
};

export default ValueSelector;

/**
 * This class is also implemented in cdyxsuppress. The only difference is the usage of the cors proxy URL in the reassignment and litigator APIs.
 */
export default class DNCDotCom {
	/**
	 * The base URL for the scrub API.
	 */
	static ScrubURL = `https://wjqomkhk14.execute-api.us-west-2.amazonaws.com/dev/proxy/${encodeURIComponent('https://www.dncscrub.com/app/main/rpc/scrub')}`;

	/**
	 * The base URL for the reassignment API.
	 */
	static ReassignmentURL = `https://wjqomkhk14.execute-api.us-west-2.amazonaws.com/dev/proxy/${encodeURIComponent('https://api.dncscrub.com/v1.2/data/Verification')}`;

	/**
	 * The base URL for the litigator API.
	 */
	static LitigatorURL = `https://wjqomkhk14.execute-api.us-west-2.amazonaws.com/dev/proxy/${encodeURIComponent('https://api.dncscrub.com/v1.3/scrub/litigator')}`;

	/**
	 * A status code map for the scrub API. True means suppress, false means proceed to dial.
	 */
	static ScrubStatusCodes = {
			'X': false,
			'C': false,
			'O': false,
			'E': false,
			'R': false,

			'W': false,
			'G': false,
			'H': false,

			'L': false,
			'F': false,
			'V': false,

			'I': true,
			'M': true,
			'B': true,
			'P': true,
			'D': true,

			'Y': false
	};

	/**
	 * A status code map for the reassignment API. True means suppress, false means proceed to dial.
	 */
	static ReassignmentStatusCodes = {
			'N': true,
			'YA': false,
			'YPA': false,
			'YL': false,
			'Y': false,
			'YP*': false, // Force date entry in suppression profile (relative).
			'YX': false,
			'U': false // 8-17-2021 at 11:30 AM as per Dan and Geoff and Wade
	};

	/**
	 * A map of input params for the reassignment API.
	 * The key represents the contact attribute in our schema and the value represents the param key and the max length of the value.
	 */
	static ReassignmentInputConfiguration = {
			firstName: { paramKey: 'FirstName', maxLength: 50 },
			lastName: { paramKey: 'LastName', maxLength: 50 },
			address1: { paramKey: 'Address1', maxLength: 30 },
			address2: { paramKey: 'Address2', maxLength: 30 },
			city: { paramKey: 'City', maxLength: 25 },
			state: { paramKey: 'State', maxLength: 2 },
			zip: { paramKey: 'PostalCode', maxLength: 5 }
	};

	/**
	 * The response key to see if a number has a known litigator.
	 */
	static LitigatorKey = 'IsLitigator';

	/**
	 * Generates a params string for the reassignment API.
	 * @param {any} contactData The contact data.
	 * @param {string} currentPhone The phone number to search by.
	 * @returns {string} The params string to append to the base URL.
	 */
	static generateReassignmentParams(contactData, currentPhone, reassignmentSince) {
			let reassignmentSinceDate;
			if (reassignmentSince[0] === 'n') {
					reassignmentSinceDate = new Date();
					reassignmentSinceDate.setUTCDate(reassignmentSinceDate.getUTCDate() - Number(reassignmentSince.substring(4)));
			} else {
					reassignmentSinceDate = new Date(reassignmentSince);
			}

			const params = [
					`Phone=${currentPhone}`,
					`ConsentDate=${reassignmentSinceDate.getUTCFullYear()}${`${reassignmentSinceDate.getUTCMonth() + 1}`.padStart(2, '0')}${`${reassignmentSinceDate.getUTCDate()}`.padStart(2, '0')}`
			];

			for (const key in contactData) {
					if (key in this.ReassignmentInputConfiguration && typeof(contactData[key]) === 'string') {
							params.push(`${encodeURIComponent(this.ReassignmentInputConfiguration[key].paramKey)}=${encodeURIComponent(contactData[key].substring(0, this.ReassignmentInputConfiguration[key].maxLength))}`);
					}
			}

			return params.join('&');
	}

	/**
	 * Generates a params string with our login ID and the project ID.
	 * @param {string} projectId The current project ID.
	 * @returns {string} The generated params string.
	 */
	static generateAuthAndProjectIDParams(loginId, projectId) {
			return `LoginID=${loginId}&project_id=${projectId}`;
	}
}

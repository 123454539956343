import { string, object, number, } from 'yup';
import phone from 'phone';
import { customFieldTypes } from '../models/customFieldsTypes';
import { IS_PROD } from '../models/globals';

const phoneBase = (label, canBeNull) =>
	string().test(
		`${label} test`,
		`Please enter a valid ${label} number`,
		(val) =>
			val ? phone(val, { validateMobilePrefix: IS_PROD }).isValid : canBeNull,
	);
const customFieldBaseSchema = {
	[customFieldTypes.Phone]: phoneBase('phone', true).nullable(),
	[customFieldTypes.String]: string().nullable(),
};

export const contactValidation = (customFields) =>
	object().shape(
		{
			firstName: string()
				.max(124, 'Must be 124 characters or less')
				.required('Please supply a first name'),
			lastName: string()
				.max(124, 'Must be 124 characters or less')
				.required('Please supply a last name'),
			source: string().nullable().max(40, 'Must be 40 characters or less'),
			phone: string().when('cell', {
				is: (cell) => !cell || cell.length === 0 || cell === '',
				then: () => string().matches(/^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/, 'Please enter a valid phone number').required(),
				otherwise: () => string().matches(/^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/, 'Please enter a valid phone number').nullable()
			}),
			cell: string().when('phone', {
				is: (phone) => !phone || phone.length === 0 || phone === '',
				then: () => string().matches(/^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/, 'Please enter a valid cell number').required(),
				otherwise: () => string().matches(/^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/, 'Please enter a valid cell number').nullable()
			}),
			address1: string().nullable().max(50, 'Must be 50 characters or less'),
			address2: string().nullable().max(50, 'Must be 50 characters or less'),
			city: string().nullable(),
			zip: string()
				.nullable()
				.matches(/^[0-9]{5}(?:-[0-9]{4})?$/, {
					message: 'Please enter a valid zip code',
					excludeEmptyString: true,
				}),
			email: string().nullable().email('Please enter a valid email address'),
			customFields: object(
				(customFields ?? []).reduce(
					(schema, currentField) =>
						Object.assign(schema, {
							[currentField.name]:
								currentField.type === customFieldTypes.String &&
									currentField.isPoc
									? customFieldBaseSchema[customFieldTypes.String].email(
										'Please specify a valid email',
									)
									: customFieldBaseSchema[currentField.type],
						}),
					{},
				),
			),
		},
		[['phone', 'cell']],
	);

const segmentValidation = object({
	name: string()
		.max(50, 'Segment name must be 20 characters or less')
		.required('Please supply a segment name'),
});

const cxOneValidation = object({
	businessUnit: string()
		.matches(/^\d+$/, 'Must only contain numbers')
		.max(9, 'Must be at most 9 digits long')
		.min(7, 'Must be at least 7 digits long')
		.required('Business unit is required'),
	accessKey: string()
		.max(80, 'Must be 80 characters or less')
		.required('Access key is required'),
	appSecret: string()
		.max(80, 'Must be 80 characters or less')
		.required('Application secret is required'),
	authTokenUrl: string()
		.max(80, 'Must be 80 characters or less')
		.url('Must be a valid URL')
});

export const webexCCValidation = object({
	region: string().required('Region is required'),
	organizationId: string()
		.max(80, 'Must be 80 characters or less')
		.required('Organization ID is required'),
	clientId: string()
		.max(80, 'Must be 80 characters or less')
		.required('Client ID is required'),
	clientSecret: string()
		.max(80, 'Must be 80 characters or less')
		.required('Client secret is required'),
	inboundDialNumber: string().required('Inbound dial number is required'),
	callAbandonWrapUpCode: string().required('Call abandon wrap up code is required'),
});

export const webexConnectValidation = object({
	region: string().required('Region is required'),
	serviceKey: string().required('Service key is required'),
});

const inContactValidation = object({
	icUserName: string()
		.nullable()
		.max(50, 'Must be 20 characters or less')
		.required('Please supply an inContact User Name'),
	icPassword: string()
		.nullable()
		.max(48, 'Must be 48 characters or less')
		.required('Please supply an inContact Password'),
	icBU: string()
		.nullable()
		.max(7, 'The Business Unit Number is 7 Characters Long')
		.min(7, 'The Business Unit Number is 7 Characters Long')
		.required('Please supply a business unit number')
		.matches(/^\d{7}$/, 'Please supply a valid business unit number'),
	icApp: string()
		.nullable()
		.max(20, 'Please Make your application name less than 20 characters')
		.required('Please supply an application name'),
	icVendor: string()
		.nullable()
		.max(50, 'Please Make your application name less than 20 characters')
		.required('Please supply a vendor name'),
	icGetAuthTokenURL: string()
		.nullable()
		.required('This field is required')
		.url('Please enter a valid authentication token URL'),
});

// eslint-disable-next-line
export { segmentValidation, cxOneValidation, inContactValidation };

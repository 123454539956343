import { Grid, FormControlLabel, Switch, Checkbox, ListItemText, Card, CardHeader, CardContent, List, ListItem, ListItemIcon, Button, CircularProgress, Typography } from '@mui/material';
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { generateClient } from "aws-amplify/api";
import { listDncLists } from "src/graphql/queries";

export default memo(function DNCLists(props) {
		const client = generateClient();
    const { lists, onChange, enabled, errors, touched } = props;
    const [loading, setLoading] = useState(false);
    const [availableLists, setAvailableLists] = useState([]);
    const rawListsMap = useRef({});

    const getData = useCallback(async () => {
        setLoading(true);
				const dynamoLists = await client.graphql({
					query: listDncLists
				});
        const updatedLists = dynamoLists?.data?.listDNCLists?.items?.map(x => Object.assign(x, { selected: false })) ?? [];
        setAvailableLists(updatedLists.filter(x => !lists.includes(x.id)));
        rawListsMap.current = updatedLists.reduce((acc, cur) => Object.assign(acc, { [cur.id]: cur }), {});
        setLoading(false);
    }, [lists]);

		useEffect(() => {
			getData();
		}, [])

    const handleAdd = useCallback(() => {
        setAvailableLists(availableLists => {
            const selected = []
            const unselected = [];

            for (const list of availableLists) {
                if (list.selected) {
                    selected.push(list.id);
                    list.selected = false;
                } else {
                    unselected.push(list);
                }
            }

            onChange({
                target: {
                    name: 'dncListSuppressions',
                    value: [...lists, ...selected]
                }
            });

            return unselected;
        });

    }, [lists]);

    const handleRemove = useCallback(() => {
        setAvailableLists(availableLists => {
            const selected = []
            const unselected = [];

            for (const listId of lists) {
                if (rawListsMap.current[listId].selected) {
                    selected.push(rawListsMap.current[listId]);
                    rawListsMap.current[listId].selected = false;
                } else {
                    unselected.push(listId);
                }
            }

            onChange({
                target: {
                    name: 'dncListSuppressions',
                    value: [...unselected]
                }
            });

            return [...availableLists, ...selected];
        });

    }, [lists]);

    const handleAvailableToggle = useCallback(index => {
        setAvailableLists(lists => {
            lists[index].selected = !lists[index].selected;
            return [...lists];
        });
    }, []);

    const handleSelectedToggle = useCallback(id => {
        rawListsMap.current[id].selected = !rawListsMap.current[id].selected;
        setAvailableLists(lists => [...lists]);
    }, []);

    const handleAddAll = useCallback(() => {
        setAvailableLists(availableLists => {
            for (const list of availableLists) list.selected = false;

            onChange({
                target: {
                    name: 'dncListSuppressions',
                    value: [...lists, ...availableLists.map(x => x.id)]
                }
            });

            return [];
        });

    }, [lists]);

    const handleRemoveAll = useCallback(() => {
        setAvailableLists(availableLists => {
            onChange({
                target: {
                    name: 'dncListSuppressions',
                    value: []
                }
            });

            return [...availableLists, ...lists.map(x => {
                rawListsMap.current[x].selected = false;
                return rawListsMap.current[x];
            })];
        })

    }, [lists]);

    return (
        <Grid container spacing={2} direction='column'>
            <Grid item>
                <FormControlLabel
                    label='Enabled'
                    control={<Switch
                        checked={enabled}
                        onChange={onChange}
                        color='primary'
                        name='dncListSuppressionEnabled' />}
                />
            </Grid>
            {errors && touched &&
                <Grid item>
                    <Typography variant='body1' color='error'>{errors}</Typography>
                </Grid>}
            {loading &&
                <Grid item>
                    <CircularProgress variant='indeterminate' color='primary' />
                </Grid>}
            {!loading &&
                <Grid item>
                    <Grid container spacing={2} alignItems='center'>
                        <Grid item>
                            <Card variant='outlined'>
                                <CardHeader title='Available Lists' />
                                <CardContent>
                                    <List dense>
                                        {availableLists.map((list, index) =>
                                            <FastListItem name={list.name} id={list.id} selected={list.selected} key={index} onClick={() => handleAvailableToggle(index)} />
                                        )}
                                    </List>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={1} direction='column' justifyContent='center'>
                                <Grid item>
                                    <Button variant="outlined" color='primary' size="small" onClick={handleAddAll}>≫</Button>
                                </Grid>
                                <Grid item>
                                    <Button variant="contained" color='primary' size="small" onClick={handleAdd}>&gt;</Button>
                                </Grid>
                                <Grid item>
                                    <Button variant="contained" color='primary' size="small" onClick={handleRemove}>&lt;</Button>
                                </Grid>
                                <Grid item>
                                    <Button variant="outlined" color='primary' size="small" onClick={handleRemoveAll}>≪</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Card variant='outlined'>
                                <CardHeader title='Selected Lists' />
                                <CardContent>
                                    <List dense component='div' role='list'>
                                        {Object.keys(rawListsMap.current).length > 0 && lists.map((listId) =>
                                            <FastListItem
                                                name={rawListsMap.current[listId].name}
                                                id={listId} key={listId}
                                                selected={rawListsMap.current[listId].selected}
                                                onClick={() => handleSelectedToggle(listId)} />
                                        )}
                                    </List>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>}
        </Grid>
    );
}, (previousProps, newProps) => {
    return previousProps.lists === newProps.lists && previousProps.enabled === newProps.enabled && previousProps.errors === newProps.errors && previousProps.touched === newProps.touched;
});

const FastListItem = memo(function FastListItem(props) {
    return (
        <ListItem button onClick={props.onClick}>
            <ListItemIcon>
                <Checkbox
                    color='primary'
                    checked={props.selected ?? false}
                />
            </ListItemIcon>
            <ListItemText primary={props.name} />
        </ListItem>
    );
}, (previousProps, newProps) => {
    return previousProps.id === newProps.id && previousProps.selected === newProps.selected;
});

import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormControlLabel, MenuItem, Checkbox, TextField, Radio, RadioGroup, Grid, InputLabel, Typography, Select } from '@mui/material';
import moment from 'moment';

const ValueEditor = ({
	operator,
	value,
	handleOnChange,
	title,
	className,
	type,
	inputType,
	values
}) => {
	function handleRelativeDateChange(e) {
		if (!e.target.checked) {
			handleOnChange(moment().format("YYYY-MM-DD[T]HH:mm:ss"));
		} else {
			handleOnChange('now-7d');
		}
	}

	function handleRelativeOperatorChange(e) {
		handleOnChange(`${value.substring(0, 3)}${e.target.value}${value.substring(4)}`);
	}

	function handleRelativeDaysChange(e) {
		handleOnChange(`${value.substring(0, 4)}${e.target.value}${value[value.length - 1]}`)
	}

	if (operator === 'null' || operator === 'notNull') {
		return null;
	}

	switch (type) {
		case 'select':
			return (
				<Grid item>
					<FormControl>
						<InputLabel>{title}</InputLabel>
						<Select
							label={title}
							sx={{ minWidth: '80px' }}
							value={value}
							onChange={(e) => handleOnChange(e.target.value)} >
							{values.map((v) => (
								<MenuItem key={v.name} value={v.name}> {v.label}</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>
			);

		case 'boolean':
			return (
				<Grid item>
					<FormControl>
						<InputLabel>{title}</InputLabel>
						<Select
							label={title}
							sx={{ minWidth: '80px' }}
							labelWidth={0}
							value={!!value}
							onChange={(e) => handleOnChange(!!e.target.value)}
						>
							<MenuItem value={true}>True</MenuItem>
							<MenuItem value={false}>False</MenuItem>
						</Select>
					</FormControl>
				</Grid>
			);

		case 'radio':
			return (
				<Grid item>
					<RadioGroup aria-label={title} onChange={(e) => handleOnChange(e.target.value)}>
						{values.map((v) => (
							<FormControlLabel variant="" value={v.name} control={<Radio />} label={v.name} />
						))}
					</RadioGroup>
				</Grid>
			);

		case 'date':
			return (
				<Grid item>
					<Grid container spacing={2} alignItems='flex-end'>
						{value && value.substring(0, 3).toLowerCase() === "now" &&
							<>
								<Grid item>
									<Typography variant='body1' color='primary'>Now</Typography>
								</Grid>
								<Grid item>
									<FormControl>
										<InputLabel>Operator</InputLabel>
										<Select
											label="Operator"
											sx={{ minWidth: '200px' }}
											value={value[3]} onChange={handleRelativeOperatorChange}>
											<MenuItem value='+'>+</MenuItem>
											<MenuItem value='-'>-</MenuItem>
										</Select>
									</FormControl>
								</Grid>
								<Grid item>
									<TextField style={{ width: '80px' }} label="Days" type="number" InputProps={{ inputProps: { min: 0 } }} value={+value.substring(4, value.length - 1)} onChange={handleRelativeDaysChange} />
								</Grid>
							</>}
						{value && value.substring(0, 3).toLowerCase() !== "now" &&
							<Grid item>
								<TextField value={value} type="datetime-local" color="primary" InputProps={{ inputProps: { step: 1 } }} onChange={e => handleOnChange(e.target.value)} />
							</Grid>}
						<Grid item>
							<FormControlLabel control={<Checkbox checked={value && value.substring(0, 3).toLowerCase() === "now"} onChange={handleRelativeDateChange} color='primary' />} label="Relative" />
						</Grid>
					</Grid>
				</Grid>
			);

		default:
			return (
				<Grid item>
					<TextField
						id={title}
						label={title}
						value={value}
						onChange={(e) => handleOnChange(e.target.value)}
					/>
				</Grid>
			);
	}
};

ValueEditor.displayName = 'ValueEditor';

ValueEditor.propTypes = {
	field: PropTypes.string,
	operator: PropTypes.string,
	value: PropTypes.any,
	handleOnChange: PropTypes.func,
	title: PropTypes.string,
	className: PropTypes.string,
	type: PropTypes.oneOf(['select', 'boolean', 'radio', 'text', 'date']),
	inputType: PropTypes.string,
	values: PropTypes.arrayOf(PropTypes.object)
};

export default ValueEditor;

/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "cdyxmaxpromptpay",
            "endpoint": "https://yncsud9fal.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "cdyxoutreach",
            "endpoint": "https://29cno3eqvj.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "cdyxpromptpay",
            "endpoint": "https://5zh78qxml4.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://vgugtxalxrdozlevytfq3cqd24.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-east-1:4508276b-c521-4496-b448-421e630c8deb",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_D1GfsvK6P",
    "aws_user_pools_web_client_id": "r7fbg3uou9hih2l8t7cd8fvo9",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "ors-20201101153733-hostingbucket-prod",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://dpb43po5v4lia.cloudfront.net",
    "aws_user_files_s3_bucket": "cdyxoutreach164611-prod",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;

/**
 * Invokes the target function every n times the wrapper function is called.
 * Designed as a function decorator even though function decorator syntax doesn't exist in ECMAScript.
 * @param {Function} target The function to decorate.
 * @param {Number} n
 * @returns {Function} The wrapped target.
 */
export function nthInvocation(target, n) {
    let currentN = 0;

    return function (...args) {
        if (++currentN >= n) {
            target(...args);
            currentN = 0;
        }
    }
}

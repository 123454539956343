import { useEffect, useContext, useState } from 'react';
import { Box, Dialog, DialogContent, DialogTitle, DialogActions, FormControl, InputLabel, Select, MenuItem, TextField, Button } from '@mui/material';
import { EditOutlined, DeleteOutlineOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import MaterialTable from '@material-table/core';
import { UserContext } from '../../contexts/UserContext';
import { generateClient } from 'aws-amplify/api';
import { enqueueSnackbar } from 'notistack';
import { createContactFieldSuppressionList, deleteContactFieldSuppressionList, } from '../../graphql/mutations';
import { listContactFieldSuppressionLists, } from '../../graphql/queries';
import { PageAppBar } from 'src/components/pageAppBar';
import { ConfirmDialog } from 'src/components/confirmDialog/confirmDialog';
import { Formik } from 'formik';
import { object, string } from 'yup';
import { actionOneButtonStyle, actionTwoButtonStyle } from 'src/theme';

export function ContactFieldSuppressionLists() {
	const client = generateClient();
	const userContext = useContext(UserContext);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const [dncLists, setDNCLists] = useState([]);
	const [toDelete, setToDelete] = useState(null);
	const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
	const [newDialogOpen, setNewDialogOpen] = useState(false);

	useEffect(() => {
		async function getData() {
			getLists();
		}
		if (userContext.tenantId) {
			getData();
		}
	}, [userContext.tenantId]);

	async function getLists() {
		setLoading(true);
		// Loop Query to make sure all the data is fetched
		const contactFieldSuppressionLists = await client.graphql({
			query: listContactFieldSuppressionLists,
		});
		console.log(contactFieldSuppressionLists);
		if (contactFieldSuppressionLists && contactFieldSuppressionLists.data) {
			setDNCLists(
				contactFieldSuppressionLists.data.listContactFieldSuppressionLists.items.sort(
					(a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt),
				),
			);
		}
		setLoading(false);
	}

	async function handleDelete() {
		setLoading(true);
		const deleteResult = await client.graphql({
			query: deleteContactFieldSuppressionList,
			variables: { input: { id: toDelete } },
		});
		console.log(deleteResult);
		// if profile is successful in deleting remove it from the current list.
		if (deleteResult.data) {
			const deletedProfile = deleteResult.data.deleteContactFieldSuppressionList;
			const curProfiles = [...dncLists];
			curProfiles.splice(
				curProfiles.findIndex((e) => e.id === deletedProfile.id),
				1,
			);
			setDNCLists([...curProfiles]);
		}

		//Reload list

		setLoading(false);
		setConfirmDeleteOpen(false);
	}

	function handleCancelDelete() {
		setToDelete(null);
		setConfirmDeleteOpen(false);
	}

	return (
		<Box>
			<PageAppBar
				title="Contact Field Suppression Lists"
				description="Create a list of contact fields to suppress from your campaigns."
				actionOneText="+ List"
				actionOneHandler={() => {
					setNewDialogOpen(true);
				}}
			/>
			<MaterialTable
				components={{
					Container: (props) => <Box {...props} elevation={0} />,
				}}
				title=""
				data={dncLists}
				columns={[{ title: 'Name', field: 'name' }]}
				isLoading={loading}
				options={{
					actionsColumnIndex: -1,
					pageSize: 10,
					searchFieldStyle: {
						marginBottom: '16px',
						marginleft: '-28px',
					},
					headerStyle: {
						borderTop: '1px solid #e0e0e0',
						padding: '16px',
					},
					searchFieldVariant: 'outlined',
					paginationType: 'stepped',
				}}
				actions={[
					{
						icon: () => <EditOutlined color="primary" />,
						tooltip: 'Edit List',
						onClick: (event, rowData) => {
							navigate(`/contact-field-suppression/${rowData.id}`);
						},
					},
					{
						icon: () => <DeleteOutlineOutlined color="primary" />,
						tooltip: 'Delete DNC List',
						onClick: (event, rowData) => {
							setToDelete(rowData.id);
							setConfirmDeleteOpen(true);
						},
					},
				]}
				onRowClick={(event, rowData) => {
					navigate(`/contact-field-suppression/${rowData.id}`);
				}}
			/>
			<ConfirmDialog
				open={confirmDeleteOpen}
				title="Delete DNC List"
				description="Are you sure you want to delete this list?"
				actionOneText="Delete"
				actionOneHandler={() => {
					handleDelete();
				}}
				actionTwoText="Cancel"
				actionTwoHandler={() => {
					handleCancelDelete();
				}}
			/>
			<Dialog open={newDialogOpen} onClose={() => setNewDialogOpen(false)} aria-labelledby="form-dialog-title">
				<DialogTitle>New Contact Field Suppression List</DialogTitle>
				<DialogContent>
					<Formik
						initialValues={{
							name: '',
							contactField: '',
							tenant: userContext.tenantId
						}}
						validationSchema={
							object().shape({
								name: string().required('Required'),
								contactField: string().required('Required')
							})
						}
						onSubmit={async (values, { setSubmitting }) => {
							setSubmitting(true);
							try {
								const result = await
									client.graphql({
										query: createContactFieldSuppressionList,
										variables: { input: values }
									});
								if (result.data.createContactFieldSuppressionList) {
									getLists();
									enqueueSnackbar('Contact Field Suppression created', {
										variant: 'success',
										autoHideDuration: 5000
									});
									setNewDialogOpen(false);
								}
							} catch (e) {
								console.log(e);
								enqueueSnackbar('Error creating Contact Field Suppression', {
									variant: 'error',
									autoHideDuration: 5000
								});
							}
							setNewDialogOpen(false);
							setSubmitting(false);
						}
						}
					>
						{props => (
							<form onSubmit={props.handleSubmit}>
								<Box display="grid" gridTemplateColumns="1fr 1fr" gap={2}>
										<TextField
											id="name"
											name="name"
											label="List Name"
											onChange={props.handleChange}
											onBlur={props.handleBlur}
											value={props.values.name}
										/>
										<FormControl>
											<InputLabel id="contactFieldLabel">Contact Field</InputLabel>
											<Select
												labelId="contactFieldLabel"
												id="contactField"
												name="contactField"
												value={props.values.contactField}
												onChange={props.handleChange}
											>
												<MenuItem value="externalId">External ID</MenuItem>
												<MenuItem value="firstName">First Name</MenuItem>
												<MenuItem value="lastName">Last Name</MenuItem>
												<MenuItem value="phone">Phone</MenuItem>
												<MenuItem value="source">Source</MenuItem>
												<MenuItem value="cell">Cell</MenuItem>
												<MenuItem value="email">Email</MenuItem>
												<MenuItem value="address1">Address1</MenuItem>
												<MenuItem value="address2">Address2</MenuItem>
												<MenuItem value="city">City</MenuItem>
												<MenuItem value="state">State</MenuItem>
												<MenuItem value="zip">Zip</MenuItem>
												<MenuItem value="outboundCallerId">Outbound Caller ID</MenuItem>
												{userContext?.customFields && userContext.customFields?.map((field, index) => (
													<MenuItem key={index} value={`custom:${field.name}`}>{field.displayName}</MenuItem>
												))}
											</Select>
										</FormControl>
									</Box>
								<DialogActions>
									<Button sx={actionTwoButtonStyle} onClick={() => setNewDialogOpen(false)} color="primary">
										Cancel
									</Button>
									<Button sx={actionOneButtonStyle} type="submit" variant="contained" color="primary">
										Save
									</Button>
								</DialogActions>
							</form>
						)}
					</Formik>
				</DialogContent>
			</Dialog>
		</Box>
	);
}

import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
	InputAdornment,
	TextField,
	Tooltip
} from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import { UserContext } from '../../contexts/UserContext';
import { post } from 'aws-amplify/api';
import { fetchAuthSession } from 'aws-amplify/auth';
import { HelpRounded } from '@mui/icons-material';

import './niceDialog.css';
import { Cache } from 'aws-amplify/utils';

const EXPIRATION_TIME_SECONDS = 300;

/**
 * @typedef {Object} Props
 * @property {Function} onError The function to call when an error occurs.
 * @property {Function} onAgentConnected The function to call when the agent is successfully connected.
 */

/**
 * Displays a dialog to verify the user's account for NICE.
 *
 * @param {Props} props The props for the component.
 */
export default function NiceDialog(props) {
	const userContext = useContext(UserContext);

	/** Whether something is currently being loaded in the page. */
	const [loading, setLoading] = useState(false);

	/** Whether the interface is being used within CXone Agent. If false, the interface is being used within MAX. */
	const [isCxOneAgent, setIsCxOneAgent] = useState(false);

	/** String representing the dialog to show, if any. Can be agentId, verificationCode, or an empty string. */
	const [dialogToShow, setDialogToShow] = useState('agentId');

	/** The ID of the agent entered by the user. */
	const [agentId, setAgentId] = useState('');

	/** The skill IDs for the agent. */
	const [agentSkillIds, setAgentSkillIds] = useState([]);

	/** The verification code entered by the user. */
	const [verificationCode, setVerificationCode] = useState('');

	/** The amount of time remaining before the verification code expires. */
	const [timeToCodeExpiration, setTimeToCodeExpiration] = useState('5:00');

	/** The number of seconds remaining before the verification code expires. */
	const [secondsToCodeExpiration, setSecondsToCodeExpiration] = useState(EXPIRATION_TIME_SECONDS);

	// TODO: Is there a better way to do this?
	/** The ref to the time to code expiration. Used as a workaround because states are not available within the setInterval callback. */
	const timeToCodeExpirationRef = useRef('5:00');

	/** The ref to the expiration interval. Used to stop the interval when the timer reaches 0:00. */
	const expirationIntervalRef = useRef();
	/**
	 * Determines whether the client is CXone Agent or MAX.
	 */
	useEffect(() => {
		checkAuthorization();

		// Get the client URL param.
		const urlParams = new URLSearchParams(window.location.search);
		const client = urlParams.get('client');
		if (client === 'agent') {
			setIsCxOneAgent(true);
		}
	}, []);

	// TODO: Is there a better way to do this?
	/**
	 * Updates the time to code expiration ref when the time to code expiration changes. Used as
	 * a workaround because states are not available within the setInterval callback.
	 */
	useEffect(() => {
		timeToCodeExpirationRef.current = timeToCodeExpiration;
	}, [timeToCodeExpiration]);

	/**
	 * Checks if the user is already logged in to NICE.
	 */
	async function checkAuthorization() {
		const isAuthorized = await Cache.getItem('niceAuthorized');
		if (isAuthorized) {
			console.debug('User has already been authorized with NICE.');
			props.onAgentConnected();
			setLoading(false);
		}
	}

	/**
	 * Calls an API to generate a verification code and sends it to the agent (provided the agent ID is valid). Proceeds to the next
	 * dialog if successful.
	 */
	async function sendVerificationCode() {
		setLoading(true);
		const session = await fetchAuthSession();

		try {
			const response = await post({
				apiName: 'cdyxoutreach',
				path: '/approver/c2c-generate',
				options: {
					headers: {
						Authorization: `Bearer ${session.tokens.idToken}`,
						'x-api-key': userContext.apiKey,
					},
					body: {
						agentId
					}
				}
			}).response;

			const generateResponse = await response.body.json();

			if (!generateResponse.skills) {
				console.error('No skill IDs returned from API');
			}
			const skillIds = generateResponse.skills.map((skill) => String(skill));
			if (skillIds.length === 0) {
				setLoading(false);
				props.onError(
					'No Skills',
					'You don\'t have any skills for your account. Please contact your administrator to assign you a skill.',
					true
				);
				return;
			}
			setAgentSkillIds(skillIds);
		} catch (error) {
			setLoading(false);
			props.onError(
				'Invalid Agent ID',
				'The agent ID you entered appears to be invalid. Please check the agent ID and try again.'
			);
			return;
		}

		setLoading(false);
		setDialogToShow('verificationCode');

		expirationIntervalRef.current = setInterval(() => {
			setSecondsToCodeExpiration((prevSeconds) => prevSeconds - 1);
			const [minutes, seconds] = timeToCodeExpirationRef.current.split(':');
			if (minutes === '0' && seconds === '00') {
				clearInterval(expirationIntervalRef.current);
				return;
			}
			const newMinutes = seconds === '00' ? minutes - 1 : minutes;
			const newSeconds = seconds === '00' ? 59 : seconds - 1;
			const newTimeToCodeExpiration = `${newMinutes}:${newSeconds < 10 ? '0' + newSeconds : newSeconds}`;
			setTimeToCodeExpiration(newTimeToCodeExpiration);
		}, 1000);
	}

	/**
	 * Calls an API to verify the verification code entered by the user. Proceeds to the approver interface if successful.
	 */
	async function verifyCode() {
		setLoading(true);

		let session;
		try {
			session = await fetchAuthSession();
		} catch (error) {
			console.error('Error getting current session', error);
			return;
		}

		try {
			await post({
				apiName: 'cdyxoutreach',
				path: '/approver/c2c-verify',
				options: {
					headers: {
						Authorization: `Bearer ${session.tokens.idToken}`,
						'x-api-key': userContext.apiKey,
					},
					body: {
						verificationCode,
					}
				}
			}).response;
		} catch (error) {
			setLoading(false);
			props.onError(
				'Invalid Verification Code',
				'The verification code you entered is invalid. Please check the verification code and try again.'
			);
			return;
		}

		await Cache.setItem('niceAuthorized', true, {
			expires: Date.now + 60 * 60 * 1000, // 1 hour
		});

		setLoading(false);
		props.onAgentConnected(agentId, agentSkillIds);
	}

	return (
		<>
			{/* Agent ID Dialog */}
			<Dialog open={dialogToShow === 'agentId' && !loading} fullWidth>
				<DialogTitle>Agent ID</DialogTitle>
				<DialogContent>
					<DialogContentText gutterBottom>Verify your account by entering your agent ID.</DialogContentText>
					<br></br>
					{/* <DialogContentText gutterBottom>To find this value within MAX, open the sidebar and select <em>More</em> at the bottom. Then select <em>Information</em>, and copy the value listed as <em>Agent ID</em>.</DialogContentText> */}
					<TextField
						margin='dense'
						name='agentId'
						label='Agent ID'
						placeholder='Enter your agent ID'
						type='text'
						autoCapitalize='off'
						autoCorrect='off'
						fullWidth
						value={agentId}
						onChange={(event) => setAgentId(event.target.value)}
						InputProps={{
							endAdornment: (
								<InputAdornment position='end'>
									<Tooltip title='Where do I find this?'>
										<IconButton onClick={() => setDialogToShow('agentIdHelp')}>
											<HelpRounded color='primary' />
										</IconButton>
									</Tooltip>
								</InputAdornment>
							)
						}}
						required
					/>
				</DialogContent>
				<DialogActions>
					<Button color='primary' onClick={sendVerificationCode} disabled={agentId.length < 1}>Next</Button>
				</DialogActions>
			</Dialog>

			{/* Agent ID Help Dialog */}
			<Dialog open={dialogToShow === 'agentIdHelp' && !loading} fullWidth>
				<DialogTitle>Agent ID Help - {isCxOneAgent ? 'CXone Agent' : 'MAX'}</DialogTitle>
				<DialogContent>
					{isCxOneAgent &&
						<>
							<DialogContentText gutterBottom>Open the <em>Settings</em> tab from the tab bar at the left side of the window.</DialogContentText>
							<img
								src="/click2call-help/agent-settings.png"
								alt="Screenshot of the CXone Agent interface with settings highlighted"
								className="help-image"
							/>
							<DialogContentText gutterBottom>Select the <em>Information</em> from the tabs at the top of the content.</DialogContentText>
							<img
								src="/click2call-help/agent-information.png"
								alt="Screenshot of the CXone Agent interface with the Information tab highlighted"
								className="help-image"
							/>
							<DialogContentText>Copy the value listed as <em>Agent ID</em>.</DialogContentText>
							<img
								src="/click2call-help/agent-agent-id.png"
								alt="Screenshot of the CXone Agent interface with the Agent ID highlighted"
								className="help-image"
							/>
						</>
					}
					{!isCxOneAgent &&
						<>
							<DialogContentText gutterBottom>Open the sidebar and select <em>More</em> at the bottom.</DialogContentText>
							<img
								src="/click2call-help/max-more.png"
								alt="Screen shot of MAX sidebar with the More option highlighted"
								className="help-image"
							/>
							<DialogContentText gutterBottom>Select <em>Information</em> from the options.</DialogContentText>
							<img
								src="/click2call-help/max-information.png"
								alt="Screenshot of MAX sidebar with the More Tools menu opened and the Information option highlighted"
								width={298}
								className="help-image"
							/>
							<DialogContentText>Copy the value listed as <em>Agent ID</em>.</DialogContentText>
							<img
								src="/click2call-help/max-agent-id.png"
								alt="Screenshot of the Information tab in MAX with the Agent ID highlighted"
								width={299}
								className="help-image"
							/>
						</>
					}
				</DialogContent>
				<DialogActions>
					<Button color='primary' onClick={() => setDialogToShow('agentId')}>OK</Button>
				</DialogActions>
			</Dialog>

			{/* Verification Code Dialog */}
			<Dialog open={dialogToShow === 'verificationCode' && !loading} maxWidth='xs'>
				<DialogTitle>Verification Code</DialogTitle>
				<DialogContent>
					<DialogContentText gutterBottom>A verification code has been sent to you as a message in {isCxOneAgent ? 'CXone Agent' : 'MAX'}.</DialogContentText>
					<DialogContentText gutterBottom>Enter the code below to verify your account.</DialogContentText>
					<br></br>
					<TextField
						margin='dense'
						name='code'
						label='Verification Code'
						placeholder='Enter your verification code'
						helperText={
							timeToCodeExpiration === '0:00' ?
								'Your code has expired. Please reload and try again.' :
								`Your code will expire in ${timeToCodeExpiration}`
						}
						type='text'
						inputProps={{ inputMode: 'numeric', maxLength: 6, pattern: '^[0-9]{6,}$' }}
						autoCorrect='off'
						autoComplete='off'
						value={verificationCode}
						onChange={(event) => setVerificationCode(event.target.value)}
						disabled={timeToCodeExpiration === '0:00'}
						InputProps={{
							startAdornment: (
								<CircularProgress size={20} variant='determinate' value={secondsToCodeExpiration / EXPIRATION_TIME_SECONDS * 100} color='primary' thickness={6} sx={{ marginRight: '10px' }}></CircularProgress>
							)
						}}
						fullWidth
						required
					/>
				</DialogContent>
				<DialogActions>
					<Button color='primary' onClick={verifyCode} disabled={verificationCode.length < 6 || timeToCodeExpiration === '0:00'}>Verify</Button>
				</DialogActions>
			</Dialog>

			{/* Loading */}
			{loading &&
				<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
					<CircularProgress variant='indeterminate' color='primary'></CircularProgress>
				</div>
			}
		</>
	);
}

import {
	Button,
	Heading,
	Image,
	View,
	useAuthenticator,
	useTheme,
} from '@aws-amplify/ui-react';

export const formFields = {
	signIn: {
		username: {
			placeholder: 'Enter your email',
		},
		password: {
			placeholder: 'Enter your password',
		},
	},
	forgotPassword: {
		username: {
			placeholder: 'Enter your email',
		},
	},
};

export const components = {
	Header() {
		const { tokens } = useTheme();

		return (
			<View textAlign="center" padding={tokens.space.large}>
				<Image
					alt="Customer Dynamics logo"
					src="https://telecomprehensive.com/mt-content/uploads/2020/05/customer-dynamics.png"
				/>
			</View>
		);
	},

	SignIn: {
		Header() {
			const { tokens } = useTheme();

			return (
				<Heading
					padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
					level={3}
				>
					Sign In
				</Heading>
			);
		},
		Footer() {
			const { toForgotPassword } = useAuthenticator();

			return (
				<View textAlign="center">
					<Button
						fontWeight="normal"
						onClick={toForgotPassword}
						size="small"
						variation="link"
						marginBottom={30}
					>
						Reset Password
					</Button>
				</View>
			);
		},
	},
};

import { TextField, Grid, Card, CardHeader, CardContent, Box } from '@mui/material';
import axios from 'axios';
import { useEffect, useRef, useState } from 'react';

export default function Styling(props) {
	const { formikProps } = props;

	const [siteLogo, setSiteLogo] = useState("");
	const [validLogoUrl, setValidLogoUrl] = useState(false);
	const firstTimeImageLoad = useRef(false);

	useEffect(() => {
		if (!firstTimeImageLoad.current && formikProps.values.siteLogo) {
			testUrl();
		}
	}, [formikProps.values.siteLogo]);

	async function testUrl() {
		firstTimeImageLoad.current = true;
		try {
			await axios.get(formikProps.values.siteLogo);

			setValidLogoUrl(true);
			setSiteLogo(formikProps.values.siteLogo);
		} catch (err) {
			setValidLogoUrl(false);
		}
	}

	return (
		<Card variant='outlined'>
			<CardHeader title='C2A Landing Page Styling' titleTypographyProps={{
				variant: "body2",
				color: "textSecondary",
			}} />
			<CardContent>
				<Box display="grid" gridTemplateColumns="1fr" gap={2}>
					<Box display="grid" gridTemplateColumns="1fr 1fr" gap={2} alignItems="center">
						<TextField
							margin="dense"
							name="siteLogo"
							label="Site Logo"
							type="text"
							value={formikProps.values.siteLogo}
							onChange={formikProps.handleChange}
							onBlur={e => { formikProps.handleBlur(e); testUrl(); }}
							error={(formikProps.errors.siteLogo && formikProps.touched.siteLogo)}
							helperText={formikProps.touched.siteLogo ? formikProps.errors.siteLogo : ""}
						/>
						<Box>
							{validLogoUrl &&
								<img src={siteLogo} alt='site logo' style={{ maxWidth: '300px' }} />}
							{!validLogoUrl &&
								<div />}
						</Box>
					</Box>
					<TextField
						margin="dense"
						name="header1"
						label="Header 1"
						type="text"
						value={formikProps.values.header1}
						onChange={formikProps.handleChange}
						onBlur={formikProps.handleBlur}
						error={formikProps.errors.header1 && formikProps.touched.header1}
						helperText={formikProps.touched.header1 ? formikProps.errors.header1 : ""}
					/>
					<TextField
						margin="dense"
						name="header2"
						label="Header 2"
						type="text"
						value={formikProps.values.header2}
						onChange={formikProps.handleChange}
						onBlur={formikProps.handleBlur}
						error={formikProps.errors.header2 && formikProps.touched.header2}
						helperText={formikProps.touched.header2 ? formikProps.errors.header2 : ""}
					/>

					<TextField
						margin="dense"
						name="sitePrimary"
						label="Site Primary"
						type="color"
						value={formikProps.values.sitePrimary}
						onChange={formikProps.handleChange}
						onBlur={formikProps.handleBlur}
						error={formikProps.errors.sitePrimary && formikProps.touched.sitePrimary}
						helperText={formikProps.touched.sitePrimary ? formikProps.errors.sitePrimary : ""}
					/>

					<TextField
						margin="dense"
						name="siteSecondary"
						label="Site Secondary"
						type="color"
						value={formikProps.values.siteSecondary}
						onChange={formikProps.handleChange}
						onBlur={formikProps.handleBlur}
						error={formikProps.errors.siteSecondary && formikProps.touched.siteSecondary}
						helperText={formikProps.touched.siteSecondary ? formikProps.errors.siteSecondary : ""}
					/>

				</Box>
			</CardContent>
		</Card >
	);
}

import { useEffect, useContext, useState, useRef } from 'react';
import {
	TextField,
	Checkbox,
	Select,
	MenuItem,
	Box,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	FormControlLabel,
	FormControl,
	InputLabel,
} from '@mui/material';
import { CheckCircleOutline, DeleteOutline } from '@mui/icons-material';
import MaterialTable, { MTableAction } from '@material-table/core';
import { generateClient, post } from 'aws-amplify/api';
import { fetchAuthSession } from 'aws-amplify/auth';
import { camelCase } from 'lodash';
import { getTenantSettings, tenantField } from '../../graphql/queries';
import { updateTenantSettings } from '../../graphql/mutations';
import { UserContext } from '../../contexts/UserContext';
import { customFieldTypes } from '../../models/customFieldsTypes';
import { PageAppBar } from '../../components/pageAppBar';
import { ConfirmDialog } from '../../components/confirmDialog/confirmDialog';
import {
	actionOneButtonStyle,
	actionTwoButtonStyle,
} from '../../theme';
import { enqueueSnackbar } from 'notistack';

const startOfWarning =
	'You will receive an email once any affected sensitive point of contact data has been';
const baseMessage = 'Custom field';
const snackbars = {
	Deleted: `${baseMessage} deleted.`,
	Added: `${baseMessage} added.`,
	UpdatedWithWarning: '',
	DeletedWithWarning: '',
};
snackbars.DeletedWithWarning = `${snackbars.Deleted} ${startOfWarning} deleted.`;

export function CustomFields() {
	const client = generateClient();
	const userContext = useContext(UserContext);
	const [customFields, setCustomFields] = useState([]);
	const [loading, setLoading] = useState(false);
	const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
	const [toDelete, setToDelete] = useState(null);
	const reservedKeywords = new Set([
		'id',
		'tenant',
		'externalid',
		'account',
		'firstname',
		'lastname',
		'source',
		'phone',
		'cell',
		'email',
		'status',
		'address',
		'address1',
		'address2',
		'city',
		'state',
		'zip',
		'timezone',
		'updatedby',
		'customfields',
	]);
	const materialTableRef = useRef(null);

	const [createFieldOpen, setCreateFieldOpen] = useState(false);
	const [newField, setNewField] = useState({
		displayName: '',
		type: 'String',
		name: '',
		isPoc: false,
	});

	const handleClose = () => {
		setCreateFieldOpen(false);
	};

	useEffect(() => {
		const getData = async () => {
			setLoading(true);
			// Get Tenant Settings
			const settings = await client.graphql({
				query: getTenantSettings,
				variables: { id: userContext.tenantId },
			});
			if (settings.data.getTenantSettings.customFields) {
				setCustomFields(settings.data.getTenantSettings.customFields);
			}
			setLoading(false);
		};
		if (userContext.tenantId) {
			getData();
		}
	}, [userContext.tenantId]);

	async function existsMetadataForField(fieldName) {
		return (
			(await client.graphql({
				query: tenantField,
				variables: {
					tenantId: userContext.tenantId,
					contactField: {
						eq: fieldName,
					},
					limit: 1,
				},
			})?.data?.tenantField?.items?.length) > 0
		);
	}

	async function updateMetadata(isDelete, oldFieldName, newFieldName) {
		await post(
			'cdyxoutreach',
			`/metadata/cleanup/${isDelete ? 'delete' : 'retain'}`,
			{
				headers: {
					Authorization: `Bearer ${(await fetchAuthSession()).tokens.idToken}`,
					'x-api-key': userContext.apiKey,
				},
				body: {
					tenantId: userContext.tenantId,
					oldFieldName,
					newFieldName,
				},
			},
		);
	}

	async function addField() {
		const customFieldsCopy = [newField, ...customFields];
		for (const customField of customFieldsCopy) {
			// Remove TypeName from the custom field
			delete customField.__typename;
			console.log(customField);
		}
		await client.graphql({
			query: updateTenantSettings,
			variables: {
				input: {
					id: userContext.tenantId,
					customFields: customFieldsCopy,
				},
			},
		});
		setCustomFields(customFieldsCopy);
		setCreateFieldOpen(false);
		setNewField({
			displayName: '',
			type: 'String',
			name: '',
			isPoc: false,
		});

		enqueueSnackbar('Custom field created.', { variant: 'success' });
	}

	async function handleDelete() {
		console.log(toDelete);
		const checkForMetaData = await existsMetadataForField(toDelete.name);
		if (checkForMetaData) {
			await updateMetadata(true, toDelete.name, '');
		}
		const customFieldsCopy = [...customFields];
		customFieldsCopy.splice(toDelete.tableData.id, 1);
		for (const customField of customFieldsCopy) {
			// Remove TypeName from the custom field
			delete customField.__typename;
			console.log(customField);
		}
		await client.graphql({
			query: updateTenantSettings,
			variables: {
				input: {
					id: userContext.tenantId,
					customFields: customFieldsCopy,
				},
			},
		});
		setCustomFields(customFieldsCopy);
		setToDelete(null);
		setDeleteConfirmOpen(false);
	}

	function handleCancelDelete() {
		setToDelete(null);
		setDeleteConfirmOpen(false);
	}

	function handleSearchChange(value) {
		window.sessionStorage.setItem('CustomFieldsSearch', value);
	}

	return (
		<Box>
			<PageAppBar
				title="Custom Fields"
				description="Define custom fields to store additional information about your contacts"
				actionOneText="+ Custom Field"
				actionOneHandler={() => {
					setCreateFieldOpen(true);
				}}
			/>
			<MaterialTable
				components={{
					Container: (props) => <Box {...props} elevation={0} />,
					Action: (props) => {
						if (props.action.tooltip !== 'Add')
							return <MTableAction {...props} />;
						else return null;
					},
				}}
				title=""
				tableRef={materialTableRef}
				isLoading={loading}
				columns={[
					{
						title: 'Display Label',
						field: 'displayName',
						initialEditValue: '',
						editComponent: ({ onRowDataChange, rowData, ...p }) => (
							<TextField
								{...p}
								size="small"
								fullWidth
								onChange={(e) => {
									const newRowData = { ...rowData };
									newRowData.name = camelCase(e.target.value);
									newRowData.displayName = e.target.value;
									onRowDataChange(newRowData);
								}}
							/>
						),
						validate: (rowData) => {
							if (reservedKeywords.has(rowData.name)) {
								return {
									isValid: false,
									helperText: 'Field name is a reserved keyword.',
								};
							} else if (
								customFields.filter(
									(x) =>
										x.displayName === rowData.displayName ||
										x.displayName === rowData.name ||
										x.name === rowData.displayName ||
										x.name === rowData.name,
								).length >
								(materialTableRef.current.state.lastEditingRow ? 1 : 0)
							) {
								return {
									isValid: false,
									helperText: 'Field name is already in use.',
								};
							} else if (rowData.displayName?.trim()?.length < 3) {
								return {
									isValid: false,
									helperText: 'Field name must contain at least 3 characters.',
								};
							} else {
								return true;
							}
						},
					},
					{
						title: 'Type',
						field: 'type',
						lookup: customFieldTypes,
						initialEditValue: 'String',
						editComponent: ({ onRowDataChange, rowData }) => (
							<Select
								value={rowData.type}
								margin="dense"
								onChange={(e) => {
									const newRowData = { ...rowData };
									newRowData.type = e.target.value;
									newRowData.isPoc =
										(newRowData.isPoc &&
											(e.target.value === customFieldTypes.String ||
												e.target.value === customFieldTypes.Phone)) ||
										e.target.value === customFieldTypes.Phone;
									onRowDataChange(newRowData);
								}}
							>
								{Object.keys(customFieldTypes).map((type, index) => (
									<MenuItem key={index} value={type}>
										{type}
									</MenuItem>
								))}
							</Select>
						),
					},
					{
						title: 'Field Name',
						field: 'name',
						editable: 'never',
						initialEditValue: '',
					},
					{
						title: 'Is Point of Contact?',
						field: 'isPoc',
						initialEditValue: false,
						render: (rowData) =>
							rowData.isPoc ? <CheckCircleOutline color="primary" /> : null,
						editComponent: ({ onRowDataChange, rowData }) => (
							<Checkbox
								color="primary"
								disabled={rowData.type !== customFieldTypes.String}
								checked={rowData.isPoc}
								onChange={(e) => {
									const newRowData = { ...rowData };
									newRowData.isPoc =
										e.target.checked &&
										(rowData.type === customFieldTypes.String ||
											rowData.type === customFieldTypes.Phone);
									onRowDataChange(newRowData);
								}}
							/>
						),
					},
				]}
				data={JSON.parse(JSON.stringify(customFields))}
				options={{
					maxBodyHeight: 'calc(100vh - 350px)',
					actionsColumnIndex: -1,
					pageSize: 10,
					searchFieldStyle: {
						marginBottom: '16px',
						marginleft: '-28px',
					},

					addRowPosition: 'first',
					searchText: window.sessionStorage.getItem('CustomFieldsSearch'),
					headerStyle: {
						borderTop: '1px solid #e0e0e0',
						padding: '16px',
					},
					searchFieldVariant: 'outlined',
					paginationType: 'stepped',
				}}
				onSearchChange={handleSearchChange}
				actions={[
					{
						icon: () => <DeleteOutline color="primary" />,
						tooltip: 'Delete',
						onClick: (event, rowData) => {
							setToDelete(rowData);
							setDeleteConfirmOpen(true);
						},
					},
				]}
			/>
			<Dialog
				open={createFieldOpen}
				maxWidth="lg"
				onClose={handleClose}
				sx={{
					'& .MuiDialog-paper': {
						width: '100%',
						maxWidth: '500px',
					},
				}}
			>
				<DialogTitle>Create Custom Field</DialogTitle>
				<DialogContent>
					<Box
						display="grid" gridTemplateColumns="1fr 1fr" gap={2}
					>
						<TextField
							label="Display Label"
							fullWidth
							margin="normal"
							variant="outlined"
							value={newField.displayName}
							onChange={(e) => {
								setNewField({
									...newField,
									displayName: e.target.value,
									name: camelCase(e.target.value),
								});
							}}
						/>
						<TextField
							label="Field Name"
							fullWidth
							disabled
							margin="normal"
							variant="outlined"
							value={newField.name}
						/>
						<FormControl>
							<InputLabel id="type">Data Type</InputLabel>
							<Select
								label="Data Type"
								labelId="type"
								value={newField.type}
								onChange={(e) => {
									setNewField({
										...newField,
										type: e.target.value,
										isPoc:
											(e.target.value === customFieldTypes.String ||
												e.target.value === customFieldTypes.Phone) &&
											newField.isPoc,
									});
								}}
							>
								{Object.keys(customFieldTypes).map((type, index) => (
									<MenuItem key={index} value={type}>
										{type}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<FormControlLabel
							control={
								<Checkbox
									color="primary"
									checked={newField.isPoc}
									disabled={
										newField.type !== customFieldTypes.String &&
										newField.type !== customFieldTypes.Phone
									}
									onChange={(e) => {
										setNewField({
											...newField,
											isPoc: e.target.checked,
										});
									}}
								/>
							}
							label="Is Point of Contact?"
						/>
					</Box>
				</DialogContent>
				<DialogActions>
					<Button sx={actionTwoButtonStyle} onClick={handleClose}>
						Cancel
					</Button>
					<Button sx={actionOneButtonStyle} onClick={addField}>
						Create
					</Button>
				</DialogActions>
			</Dialog>
			<ConfirmDialog
				open={deleteConfirmOpen}
				title="Delete Custom Field"
				description="Are you sure you want to delete this custom field? If this field has been used in any sensitive point of contact data, the data will be deleted."
				actionOneText="Delete"
				actionOneHandler={() => {
					handleDelete();
				}}
				actionTwoText="Cancel"
				actionTwoHandler={() => {
					handleCancelDelete();
				}}
			/>
		</Box >
	);
}

import { forwardRef } from "react";
import { FormControl, InputLabel, Select, Chip, MenuItem, Input } from '@mui/material';

const availableTypes = [
    "Note",
    "Dial",
    "SMS",
    "SMSApprove",
    "SMSSuppress",
    "SMSRecycle",
    "SMSUndeliverable",
    "System",
    "C3",
    "DialApprove",
    "DialSuppress",
    "DialRecycle",
    "DNCScrubInvocation",
    "DNCReassignmentInvocation",
    "DNCLitigatorInvocation"
];

export default forwardRef((props, ref) => {
    const { onChange, value } = props;
    return (
        <FormControl ref={ref} sx={{
						minWidth: '200px',
						marginTop: '8px'
				}}>
            <InputLabel>Activity Type Filter</InputLabel>
            <Select
                multiple
                value={value}
                onChange={onChange}
                input={<Input />}
                renderValue={selected =>
                    <div>
                        {selected.map(type =>
                            <Chip color="primary" size="small" key={type} label={type} />)}
                    </div>}
            >
                {availableTypes.map(type =>
                    <MenuItem key={type} value={type}>{type}</MenuItem>)}
            </Select>
        </FormControl>
    );
})

import { useEffect, useRef, forwardRef } from 'react';


const PostmanTextField = forwardRef((props, ref) => {
    // const classes = useStyles();
    const divRef = useRef();

    useEffect(() => {
        if (divRef.current && props.value !== "") {
            if (document.activeElement === divRef.current) {
                const oldSelection = window.getSelection();
                const oldCursorPosition = findOldCursorPosition({ nativeEvent: { target: divRef.current } }, oldSelection);
                divRef.current.textContent = props.value;
                highlightPlaceholders({ nativeEvent: { target: divRef.current } });
                const newSelection = document.createRange();

                const [newNode, newOffset] = findNewCursorPosition({ nativeEvent: { target: divRef.current } }, oldCursorPosition);

                newSelection.setStart(newNode, newOffset);
                newSelection.setEnd(newNode, newOffset);
                oldSelection.removeAllRanges();
                oldSelection.addRange(newSelection);
            } else {
                divRef.current.textContent = props.value;
                highlightPlaceholders({ nativeEvent: { target: divRef.current } });
            }
        }
    }, [props.value]);

    function highlightPlaceholders(event) {
        event.nativeEvent.target.innerHTML = event.nativeEvent.target.textContent.replace(/{{.*?}}/g, match => `<span class="${classes.placeholderBase}">${match}</span>`);
    }

    function findOldCursorPosition(e, oldSelection) {
        let oldCursorPosition = 0;
        for (let i = 0; i < e.nativeEvent.target.childNodes.length; ++i) {
            for (let j = 0; j <= e.nativeEvent.target.childNodes[i].textContent.length; ++j) {
                if (e.nativeEvent.target.childNodes[i].childNodes?.length > 0) {
                    if (oldSelection.focusNode === e.nativeEvent.target.childNodes[i].childNodes[0] && j === oldSelection.focusOffset) {
                        return oldCursorPosition;
                    }
                } else {
                    if (oldSelection.focusNode === e.nativeEvent.target.childNodes[i] && j === oldSelection.focusOffset) {
                        return oldCursorPosition;
                    }
                }

                ++oldCursorPosition;
            }

            --oldCursorPosition;
        }

        return oldCursorPosition;
    }

    function findNewCursorPosition(e, oldPosition) {
        for (let i = 0; i < e.nativeEvent.target.childNodes.length; ++i) {
            for (let j = 0; j < e.nativeEvent.target.childNodes[i].textContent.length; ++j) {
                if (!oldPosition) {
                    if (e.nativeEvent.target.childNodes[i].childNodes?.length > 0) {
                        return [e.nativeEvent.target.childNodes[i].childNodes[0], j];
                    }
                    return [e.nativeEvent.target.childNodes[i], j];
                }

                --oldPosition;
            }
        }

        if (e.nativeEvent.target.childNodes[e.nativeEvent.target.childNodes.length - 1].childNodes?.length > 0) {
            return [e.nativeEvent.target.childNodes[e.nativeEvent.target.childNodes.length - 1].childNodes[0], e.nativeEvent.target.childNodes[e.nativeEvent.target.childNodes.length - 1].textContent.length];
        }
        return [e.nativeEvent.target.childNodes[e.nativeEvent.target.childNodes.length - 1], e.nativeEvent.target.childNodes[e.nativeEvent.target.childNodes.length - 1].textContent.length];
    }

    return (
        <div
            ref={elementRef => { divRef.current = elementRef; ref = elementRef; }}
            contentEditable
            className={`${props.className} ${classes.postmanTextFieldRoot}`}
            onInput={e => props.onChange({ target: { name: props.name, value: e.nativeEvent.target.textContent } })}
            placeholder={props.placeholder}
            onBlur={() => props.onBlur({ target: { name: props.name } })}
        />
    );
});

export default PostmanTextField;

import { useRef } from "react";
import { Grid, FormControlLabel, Typography, Switch } from '@mui/material';
import SuppressionBuilder from "./suppressionBuilder";
import _ from 'lodash';

const lookupFields = {
    "poc.cell": {
        label: "Is cellphone",
        type: "boolean"
    },
    "poc.consent": {
        label: "Has consent",
        type: "boolean"
    },
    "poc.writtenConsent": {
        label: "Date of written consent",
        type: "date",
    },
    "poc.optOut": {
        label: "Has opted out",
        type: "boolean"
    },
    "poc.preferred": {
        label: "Is preferred",
        type: "boolean"
    },
    "poc.thirdParty": {
        label: "Is third party",
        type: "boolean"
    }
}
const condensedFields = Object.keys(lookupFields).map(x => { return { name: x, label: lookupFields[x].label }});

export default function PocSuppression(props) {
    const { formikProps, tenant } = props;
    const queryInitialTouch = useRef(false);

    function processFilterArray(items, combinator, isBase = true) {
        const ruleArray = [];
        const ruleGroup = { [combinator]: ruleArray };
        for (const item of items) {
            ruleArray.push(processFilterItem(item, items.combinator));
        }

        if (isBase) {
            return { and: [{ tenant: { eq: tenant } }, { contact: { eq: "CONTACTIDPLACEHOLDER" } }, { field: { eq: "FIELDPLACEHOLDER" } }, ruleGroup] };
        } else {
            return ruleGroup;
        }
    }

    function processFilterItem(item) {
        const cpyItem = { ...item };
        if (item.field) {
            if (cpyItem.value === true || cpyItem.value === false || cpyItem.value === 'true' || cpyItem === 'false') {
                if ( _.isString(cpyItem.value)) {
                    cpyItem.value = cpyItem.value === 'true';
                } else {
                    cpyItem.value = cpyItem.value === true;
                }
            } else if (!isNaN(cpyItem.value)) {
                cpyItem.value = +cpyItem.value;
            } else if (!!Date.parse(cpyItem.value)) {
                cpyItem.value = Date.parse(cpyItem.value);
            }

            return { [`${cpyItem.field}`]: { [cpyItem.operator]: cpyItem.value } };
        }
        return processFilterArray(cpyItem.rules, cpyItem.combinator, false);
    }

    function handleQueryChange(query, formikProps) {
        if (!queryInitialTouch.current) {
            queryInitialTouch.current = true;
        } else {
            formikProps.setFieldTouched(`pocQuerySuppression.rawQuery`, true);
        }

        formikProps.setFieldValue(`pocQuerySuppression.rawQuery`, query);
        const formattedQuery = processFilterArray(query.rules, query.combinator ?? 'AND');
        formikProps.setFieldValue(`pocQuerySuppression.query`, formattedQuery);
    }

    function getType(field, operator) {
        return lookupFields[field].type;
    }

    function getValues(field, operator) {
        return lookupFields[field].values;
    }

    function getOperators(field) {
        switch (lookupFields[field].type) {
            case "boolean":
            default:
                return [
                    { name: 'eq', label: '=' },
                    { name: 'ne', label: '!=' }
                ];

            case "date":
                return [
                    { name: 'lt', label: '<' },
                    { name: 'gt', label: '>' },
                    { name: 'lte', label: '<=' },
                    { name: 'gte', label: '>=' },
                ];
        }
    }

    return (
        <Grid container spacing={2} direction='column'>
            <Grid item>
                <FormControlLabel
                    label='Enabled'
                    control={<Switch
                        checked={formikProps.values.pocQuerySuppressionEnabled}
                        onChange={formikProps.handleChange}
                        color='primary'
                        name='pocQuerySuppressionEnabled' />}
                />
            </Grid>
            {formikProps.touched?.pocQuerySuppression?.rawQuery && formikProps.errors?.pocQuerySuppression?.rawQuery &&
            <Grid item>
                <Typography color='error' variant='body1'>{formikProps.errors?.pocQuerySuppression?.rawQuery}</Typography>
            </Grid>}
            <Grid item>
                <FormControlLabel
                    label="Suppress if it is a weekend and the point of contact has been marked not to be dialed during weekends"
                    control={<Switch
                        checked={formikProps.values.pocQuerySuppression?.suppressIfWeekendAndCantCallOnWeekends}
                        onChange={formikProps.handleChange}
                        color='primary'
                        name='pocQuerySuppression.suppressIfWeekendAndCantCallOnWeekends' />
                    }
                />
            </Grid>
            <Grid item>
                <SuppressionBuilder
                    query={formikProps.values.pocQuerySuppression.rawQuery}
                    onQueryChange={query => handleQueryChange(query, formikProps, 'pocQuerySuppression')}
                    showCombinatorsBetweenRules
                    fields={condensedFields}
                    getValueEditorType={getType}
                    getInputType={getType}
                    getValues={getValues}
                    getOperators={getOperators} />
            </Grid>
        </Grid>
    );
}

import React, { useEffect, useState, } from 'react';
import { Box, FormControl, TableRow, TableBody,  FormControlLabel, Grid, TextField, Select, InputLabel, Typography, Checkbox, MenuItem, Tooltip, FormHelperText, IconButton, Card, CardHeader, CardContent, Table, TableHead, TableCell } from "@mui/material";
import _ from 'lodash';
import { AddCircleOutlineOutlined, DeleteOutline, HelpOutline } from '@mui/icons-material';



export default function C2APayment(props) {
	const { formikProps, contactFields } = props;
	const minPayTypes = ['None', 'Percentage', 'Static'];
	const emptyPendingField = { replacementField: '', contactField: '' };
	const [pendingField, setPendingField] = useState(emptyPendingField);
	const [selectedFields, setSelectedFields] = useState([])

	const paymentFields = [
		{
			name: 'Balance',
			value: 'balanceReplacement',
		},
		{
			name: 'Aging Info',
			value: 'agingReplacement',
		},
		{
			name: 'Invoice Id',
			value: 'invoiceReplacement',
		},
	]

	useEffect(() => {
		const fields = [];
		for (let key in formikProps.values.c2a.payment) {
			for (let field of paymentFields) {
				if (field.value === key && formikProps.values.c2a.payment[key]) {
					fields.push({
						contactField: formikProps.values.c2a.payment[key],
						replacementField: key,
						display: field.name
					})
					break;
				}
			}
		}
		setSelectedFields(fields);
	}, [props])

	const clearSegments = formikProps => {
		setSelectedFields([]);
		const reset = _.cloneDeep(formikProps.values.c2a);
		delete reset.payment.balanceReplacement;
		delete reset.payment.agingReplacement;
		delete reset.payment.invoiceReplacement;
		formikProps.setFieldValue('c2a', reset);
	}
	const deleteSegment = (segment, formikProps, index) => {
		selectedFields.splice(index, 1);
		const reset = _.cloneDeep(formikProps.values.c2a);
		delete reset.payment[segment.replacementField];
		formikProps.setFieldValue('c2a', reset);
	}
	const addSegment = formikProps => {
		setSelectedFields([...selectedFields, pendingField]);
		const added = { ...formikProps.values.c2a };
		added.payment[pendingField.replacementField] = pendingField.contactField;
		formikProps.setFieldValue('c2a', added);
		setPendingField(emptyPendingField);
	}

	const handleReplacementField = (event) => {
		let display = '';
		for (let field of paymentFields) {
			if (field.value === event.target.value) {
				display = field.name;
				break;
			}
		}
		setPendingField({
			...pendingField,
			replacementField: event.target.value,
			display: display
		})
	}

	const handleContactField = (event) => {
		setPendingField({
			...pendingField,
			contactField: event.target.value
		})
	}

	return (
		<Grid item container direction="column" spacing={2}>
			<Grid item>
				<Grid container spacing={2} alignContent='center' alignItems='center'>
					{(formikProps.values.c2a?.paymentEnabled ?? false) &&
						<Grid item>
							<FormControlLabel
								control={<Checkbox checked={formikProps.values.c2a?.payment?.finalize ?? false} onChange={formikProps.handleChange} onBlur={formikProps.handleChange} name="c2a.payment.finalize" color="primary" />}
								label="Finalize contact in campaign after payment"
							/>
						</Grid>}
				</Grid>
			</Grid>
			{(formikProps.values.c2a?.paymentEnabled ?? false) &&
				<Box display="flex" flexDirection="column" gap={2}>
					<Card variant="outlined">
						<CardHeader title="Payment Details" titleTypographyProps={{
							variant: "body2",
							color: "textSecondary",
						}} />
						<CardContent>
							<Box display="grid" gridTemplateColumns="1fr 1fr 1fr 1fr" gap={2} alignItems="center">

								<TextField
									margin="dense"
									name="c2a.payment.dynamicDescriptor"
									label="Payment Descriptor"
									type="text"
									value={formikProps.values.c2a?.payment?.dynamicDescriptor ?? ''}
									onChange={formikProps.handleChange}
									onBlur={formikProps.handleBlur}
									error={formikProps.errors?.c2a?.payment?.dynamicDescriptor && formikProps.touched?.c2a?.payment?.dynamicDescriptor}
									helperText={formikProps.touched?.c2a?.payment?.dynamicDescriptor && formikProps.errors?.c2a?.payment?.dynamicDescriptor}
								/>

								<TextField
									margin="dense"
									name="c2a.payment.agingInfo"
									label="Expiration Time"
									type="number"
									value={formikProps.values.c2a?.payment?.agingInfo ?? 5}
									onChange={formikProps.handleChange}
									onBlur={formikProps.handleBlur}
									error={formikProps.errors?.c2a?.payment?.agingInfo && formikProps.touched?.c2a?.payment?.agingInfo}
									helperText={formikProps.touched?.c2a?.payment?.agingInfo && formikProps.errors?.c2a?.payment?.agingInfo}
								/>

								<FormControl>
									<InputLabel error={formikProps.errors?.c2a?.payment?.minType && formikProps.touched?.c2a?.payment?.minType}>Min Pay Type</InputLabel>
									<Select
										label="Min Pay Type"
										labelId="min-pay-types-label"
										name="c2a.payment.minType"
										value={formikProps.values.c2a?.payment?.minType ?? 'none'}
										onBlur={formikProps.handleBlur}
										onChange={formikProps.handleChange}
										error={formikProps.errors?.c2a?.payment?.minType && formikProps.touched?.c2a?.payment?.minType}
									>
										{minPayTypes.map(type =>
											<MenuItem key={type.toLowerCase()} value={type.toLowerCase()}>{type}</MenuItem>)}
									</Select>
									{formikProps.errors?.c2a?.payment?.minType && formikProps.touched?.c2a?.payment?.minType &&
										<FormHelperText error>{formikProps.errors?.c2a?.payment?.minType}</FormHelperText>}
								</FormControl>

								<TextField
									margin="dense"
									name="c2a.payment.minRate"
									label="Min Rate"
									type="number"
									value={formikProps.values.c2a?.payment?.minRate ?? 1}
									onChange={formikProps.handleChange}
									onBlur={formikProps.handleBlur}
									error={formikProps.values.c2a?.payment?.minType !== 'none' && formikProps.errors?.c2a?.payment?.minRate && formikProps.touched?.c2a?.payment?.minRate}
									helperText={formikProps.values.c2a?.payment?.minType !== 'none' && formikProps.touched?.c2a?.payment?.minRate && formikProps.errors?.c2a?.payment?.minRate}
								/>

							</Box>
						</CardContent>
					</Card>
					<Card variant="outlined">
						<CardHeader title={
							<Grid container spacing={2} alignItems="center">
								<Grid item>
									<Typography>Payment Fields</Typography>
								</Grid>
								<Grid item>
									<Tooltip title="Map contact fields for C2A Payment text replacements">
										<HelpOutline fontSize="small" color="primary" />
									</Tooltip>
								</Grid>
							</Grid>
						} titleTypographyProps={{
							variant: "body2",
							color: "textSecondary",
						}} />
						<CardContent>
							<Box display="grid" gridTemplateColumns="5fr 5fr 1fr" gap={2} alignItems="center">
								<FormControl>
									<InputLabel color="primary" id="campaign-label">Replacement Field</InputLabel>
									<Select
										color="primary"
										name="segmentId"
										label="Segment"
										value={pendingField.replacementField}
										onChange={handleReplacementField}
										onBlur={formikProps.handleBlur}
										error={
											formikProps.errors?.c2a?.payment?.balanceReplacement &&
											formikProps.touched?.c2a?.payment?.balanceReplacement
										}>
										{paymentFields.map(field =>
											!selectedFields.some(item => item.replacementField === field.value) &&
											<MenuItem key={field.value} value={field.value}>{field.name}</MenuItem>
										)}
									</Select>
									{formikProps.errors?.c2a?.payment?.balanceReplacement && formikProps.touched?.c2a?.payment?.balanceReplacement && (
										<FormHelperText error >
											{
												formikProps.errors?.c2a?.payment?.balanceReplacement
											}
										</FormHelperText>
									)}
								</FormControl>
								<FormControl>
									<InputLabel color="primary" id="campaign-label">Contact Field</InputLabel>
									<Select
										color="primary"
										name="segmentId"
										label="Segment"
										value={pendingField.contactField}
										onBlur={formikProps.handleBlur}
										error={
											formikProps.errors?.c2a?.payment?.balanceReplacement &&
											formikProps.touched?.c2a?.payment?.balanceReplacement
										}
										onChange={handleContactField} >
										{contactFields.map((field, index) =>
											<MenuItem key={index} value={field.value}>{field.label}</MenuItem>)}
									</Select>
									{formikProps.errors?.c2a?.payment?.balanceReplacement && formikProps.touched?.c2a?.payment?.balanceReplacement && (
										<FormHelperText error >
											{
												formikProps.errors?.c2a?.payment?.balanceReplacement
											}
										</FormHelperText>
									)}
								</FormControl>
								<Tooltip title={pendingField.replacementField === "" ? 'Select a field' : pendingField.contactField === "" ? "Select the replacing field" : "Add Field"}>
									<div>
										<IconButton aria-label="add" onClick={() => addSegment(formikProps)} disabled={pendingField.replacementField === "" || pendingField.contactField === ""}>
											<AddCircleOutlineOutlined color={pendingField.replacementField === "" || pendingField.contactField === "" ? "disabled" : "primary"} fontSize="small" />
										</IconButton>
									</div>
								</Tooltip>
							</Box>
							{selectedFields.length > 0 &&  <Table>
									<TableHead>
										<TableRow>
											<TableCell>Replacement Field</TableCell>
											<TableCell>Contact Field</TableCell>
											<TableCell>Delete</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{selectedFields.map((selection, index) => {
											return <TableRow>
												<TableCell>{selection.display}</TableCell>
												<TableCell>{selection.contactField}</TableCell>
												<TableCell>
													<IconButton onClick={() => deleteSegment(selection, formikProps, index)}>
														<DeleteOutline color="primary" fontSize="small" />
													</IconButton>
												</TableCell>
											</TableRow>
										}
										)}
									</TableBody>
								</Table>}
						</CardContent>
					</Card>
				</Box>}
		</Grid >
	);
}

const themeColors = {
	primary: {
		main: '#1559b3',
		light: '#b9cde8',
		dark: '#0c3e9a',
	},
	secondary: {
		main: '#fd8941',
		light: '#fedcc6',
		dark: '#fc6c2a',
	},
	warning: {
		main: '#ff9800',
	},
	error: {
		main: '#f44336',
	},
	success: {
		main: '#4caf50',
	},
	white: {
		main: '#ffffff',
	},
	black: {
		main: '#000000',
	},
};

// ====================================== App Bar ======================================
export const appBarStyle = {
	backgroundColor: themeColors.primary.light,
	color: themeColors.black.main,
	borderRadius: '7px',
	marginBottom: '20px',
};

// ====================================== List Items ======================================

export const listItemButtonStyle = {
	borderRadius: '7px',
	margin: '0px 0px 2px 0px',
	'&:hover': {
		backgroundColor: themeColors.primary.light,
		color: themeColors.primary.main,
	},
};

export const listItemIconStyle = {
	color: themeColors.primary.main,
	minWidth: '30px',
	'&:hover': {
		color: themeColors.primary.main,
	},
};

export const subListItemButtonStyle = {
	borderRadius: '7px',
	'&:hover': {
		backgroundColor: themeColors.white.main,
		color: themeColors.primary.main,
	},
};

// Selected Styles

export const listItemSelectedStyle = {
	borderRadius: '7px',
	backgroundColor: themeColors.primary.main,
	color: themeColors.white.main,
	margin: '0px 0px 2px 0px',
	'&:hover': {
		borderRadius: '7px',
		backgroundColor: themeColors.primary.main,
		color: themeColors.white.main,
	},
};

export const listItemIconSelectedStyle = {
	color: themeColors.white.main,
	minWidth: '30px',
	'&:hover': {
		color: themeColors.white.main,
	},
};

export const subListItemSelectedStyle = {
	borderRadius: '7px',
	backgroundColor: themeColors.white.main,
	color: themeColors.primary.main,
	'&:hover': {
		borderRadius: '7px',
		backgroundColor: themeColors.white.main,
		color: themeColors.primary.main,
	},
};

// ====================================== Buttons ======================================

export const button = {
	textTransform: 'none',
	whiteSpace: 'nowrap',
	borderRadius: '7px',
	padding: '6px 16px',
	margin: '5px',
};

export const actionOneButtonStyle = {
	...button,
	backgroundColor: themeColors.primary.main,
	color: themeColors.white.main,
	'&:hover': {
		backgroundColor: themeColors.primary.dark,
		color: themeColors.white.main,
	},
};

export const actionTwoButtonStyle = {
	...button,
	border: `1px solid ${themeColors.primary.main}`,
	color: themeColors.primary.main,
	'&:hover': {
		backgroundColor: themeColors.primary.dark,
		color: themeColors.white.main,
	},
};

export const destructiveButtonStyle = {
	...button,
	backgroundColor: '#FA896B',
	color: themeColors.white.main,
	'&:hover': {
		backgroundColor: '#ac2a06',
		color: themeColors.white.main,
	},
};

// ====================================== Form Fields ======================================
export const formFieldStyle = {
	margin: '24px 0px 0px 24px',
	maxWidth: '65%',
};

// ====================================== Select Input ======================================
export const selectInputStyle = {
	width: '200px',
};


// ====================================== Cards ======================================
export const cardStyle = {
	border: '1px solid #e0e0e0',
	borderRadius: '7px'
};

// ====================================== Tabs ======================================
export const tabStyle = {
	fontSize: '1.2rem',
	fontWeight: 'bold',
	textTransform: 'none',
};

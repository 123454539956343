import { ChevronLeft } from '@mui/icons-material';
import { AppBar, Box, Button, Toolbar, Typography } from '@mui/material';
import {
	actionOneButtonStyle,
	actionTwoButtonStyle,
	appBarStyle,
} from 'src/theme';

export function PageAppBar({
	title,
	description,
	actionOneText,
	actionOneHandler,
	actionOneDisabled,
	actionTwoText,
	actionTwoHandler,
	actionTwoDisabled
}) {
	return (
		<Box sx={{ flexGrow: 1 }}>
			{/* <Button size='small' sx={{ fontSize: '.75rem' }} onClick={() => {
				// If there is history, go back
				if (window.history.length > 1) {
					window.history.back();
				} else {
					// Otherwise, go to the message templates page
					window.location.href = '/message-templates';
				}
			}}>
				<ChevronLeft />
				Back to Message Templates
			</Button> */}
			<AppBar sx={appBarStyle} elevation={0} position="static">
				<Toolbar>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'flex-start',
							width: '100%',
							padding: '12px',
						}}
					>
						<Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
							{title}
						</Typography>
						<Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
							{description}
						</Typography>
					</Box>
					{actionTwoHandler && actionTwoText && (
						<Button sx={actionTwoButtonStyle} onClick={actionTwoHandler} disabled={actionTwoDisabled}>
							{actionTwoText}
						</Button>
					)}
					{actionOneHandler && actionOneText && (
						<Button sx={actionOneButtonStyle} onClick={actionOneHandler} disabled={actionOneDisabled}>
							{actionOneText}
						</Button>
					)}
				</Toolbar>
			</AppBar>
		</Box>
	);
}

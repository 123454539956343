import { Box, Checkbox, Chip, Divider, FormControl, IconButton, InputLabel, ListItemText, MenuItem, Select, Tooltip } from '@mui/material';
import { DeleteForeverOutlined, Info, ReplayOutlined } from '@mui/icons-material';
import React, { useEffect, useRef, useState } from 'react';
import { CAMPAIGN_COLORS, CAMPAIGN_COLOR_4, CAMPAIGN_COLOR_6 } from '../../models/campaignColors';
import { Cache, I18n } from 'aws-amplify/utils';


/**
 * The minimum number of contacts that can be displayed to the agent. This will try to be respected, the actual
 * displayed contacts can be lower if a campaign does not have enough contacts.
 */
const MIN_CONTACTS_TO_DISPLAY = 3;

/** The maximum number of contacts that can be displayed to the agent. */
const MAX_CONTACTS_TO_DISPLAY = 6;

const STRINGS = {
	'en-US': { // English (United States)
		campaignLabel: 'Campaigns',
		numberOfContactsLabel: 'Number of Contacts',
		recycleTooltip: 'Recycle Contact (r)',
		suppressTooltip: 'Suppress Contact (s)',
		campaignInfoShowTooltip: 'Show Campaign Info',
		campaignInfoHideTooltip: 'Hide Campaign Info',
	},
	'es-419': { // Spanish (Latin America)
		campaignLabel: 'Campañas',
		numberOfContactsLabel: 'Número de Contactos',
		recycleTooltip: 'Reciclar Contacto (r)',
		suppressTooltip: 'Suprimir Contacto (s)',
		campaignInfoShowTooltip: 'Mostrar Información de la Campaña',
		campaignInfoHideTooltip: 'Ocultar Información de la Campaña',
	},
	'pt-BR': { // Portuguese (Brazil)
		campaignLabel: 'Campanhas',
		numberOfContactsLabel: 'Número de Contatos',
		recycleTooltip: 'Reciclar Contato (r)',
		suppressTooltip: 'Suprimir Contato (s)',
		campaignInfoShowTooltip: 'Mostrar Informações da Campanha',
		campaignInfoHideTooltip: 'Ocultar Informações da Campanha',
	}
};
I18n.putVocabularies(STRINGS);

/**
 *
 * @param {{
 *   disabled: boolean,
 *   click2Call: boolean,
 *   maxSelectableCampaigns: number,
 *   allCampaigns: object[],
 *   gettingCampaigns: boolean,
 *   campaignToDeselect: object,
 *   onCampaignSelectionChange: (campaigns: object[]) => void,
 *   onChannelSelectionChange: (channel: string) => void,
 *   onNumberOfContactsChange: (numberOfContacts: number) => void,
 *   onClickActionChange: (clickAction: string) => void,
 *   onCampaignListRefreshRequested: () => void
 * }} props
 * @returns
 */
export function ApproverControls(props) {
	const [hasRehydratedSelectedCampaigns, setHasRehydratedSelectedCampaigns] = useState(false);
	const [hasRehydratedNumberOfContacts, setHasRehydratedNumberOfContacts] = useState(false);

	/** All of the selected campaigns. */
	const [selectedCampaigns, setSelectedCampaigns] = useState([]);

	const listenerRef = useRef(null);

	useEffect(() => {
		rehydrateNumberOfContacts();
		listenerRef.current = window.addEventListener('keydown', (event) => {
			let clickAction = null;
			if (event.key === 'r') {
				clickAction = 'recycle';
			} else if (event.key === 's') {
				clickAction = 'suppress';
			} else if (event.key === 'a') {
				clickAction = 'approve';
			}
			if (clickAction) {
				toggleClickAction(clickAction);
			}
		});

		return () => {
			window.removeEventListener('keydown', listenerRef.current);
		};
	}, []);

	useEffect(() => {
		if (props.allCampaigns.length > 0 && !hasRehydratedSelectedCampaigns) {
			rehydrateSelectedCampaigns();
		}
	}, [props.allCampaigns]);

	useEffect(() => {
		props.onCampaignSelectionChange(selectedCampaigns);
		if (hasRehydratedSelectedCampaigns) { // Ignore the initial state.
			Cache.setItem('selectedCampaigns', selectedCampaigns);
		}
	}, [selectedCampaigns]);


	/** The selected channel type to use (non-Click2Call). */
	const [selectedChannel, setSelectedChannel] = useState('voice');
	useEffect(() => {
		if (!props.click2Call) {
			props.onChannelSelectionChange(selectedChannel);
		}
	}, [selectedChannel]);

	/**
	 * The number of contacts to display to the user in the game at a single time (for Click2Call).
	 * It's possible that the actual displayed contacts will be lower than this if a campaign does not have enough contacts.
	 */
	const [numberOfContactsToDisplay, setNumberOfContactsToDisplay] = useState(5);
	useEffect(() => {
		if (props.click2Call) {
			props.onNumberOfContactsChange(numberOfContactsToDisplay);
			if (hasRehydratedNumberOfContacts) { // Ignore the initial state.
				Cache.setItem('numberOfContactsToDisplay', numberOfContactsToDisplay);
			}
		}
	}, [numberOfContactsToDisplay]);

	/** The state of the game that determines what happens when a contact is clicked (approve, recycle, or suppress). */
	const [clickAction, setClickAction] = useState('approve');
	useEffect(() => {
		props.onClickActionChange(clickAction);
	}, [clickAction]);

	/** Removes a specified campaign from the selected list. */
	useEffect(() => {
		if (props.campaignToDeselect !== null) {
			const campaignToDeselect = props.campaignToDeselect;
			setSelectedCampaigns(selectedCampaigns.filter(campaign => campaign.id !== campaignToDeselect.id));
		}
	}, [props.campaignToDeselect]);

	/**
	 * Re-hydrates the selected campaigns from the cache.
	 */
	async function rehydrateSelectedCampaigns() {
		const cachedSelectedCampaigns = await Cache.getItem('selectedCampaigns');
		if (cachedSelectedCampaigns) {
			// Remove any campaigns that are no longer in the list of all campaigns.
			const validCampaignIds = props.allCampaigns.map((campaign) => campaign.id);
			const filteredCampaigns = cachedSelectedCampaigns.filter((campaign) => validCampaignIds.includes(campaign.id));
			setSelectedCampaigns(filteredCampaigns);
		}
		setHasRehydratedSelectedCampaigns(true);
	}

	/**
	 * Re-hydrates the number of contacts to display from the cache.
	 */
	async function rehydrateNumberOfContacts() {
		const cachedNumberOfContacts = await Cache.getItem('numberOfContactsToDisplay');
		if (cachedNumberOfContacts) {
			setNumberOfContactsToDisplay(cachedNumberOfContacts);
		}
		setHasRehydratedNumberOfContacts(true);
	}

	/**
	 * Generates an element containing chips for each campaign.
	 *
	 * @param {string[]} campaignIds The list of selected campaign IDs to generate chips for.
	 * @returns An element containing chips for each campaign.
	 */
	function getCampaignChips(campaignIds) {
		const campaigns = props.allCampaigns.filter((campaign) => campaignIds.includes(campaign.id));
		return (
			<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
				{campaigns.map((campaign, i) => (
					<Tooltip key={campaign.id} title={campaign.name} arrow={true} placement='bottom'>
						<Chip key={campaign.id} label={campaign.name} style={{
							maxWidth: '150px',
							height: '23px',
							backgroundColor: CAMPAIGN_COLORS[i],
							color: CAMPAIGN_COLORS[i] === CAMPAIGN_COLOR_4 || CAMPAIGN_COLORS[i] === CAMPAIGN_COLOR_6 ? 'black' : 'white',
						}} />
					</Tooltip>
				))}
			</Box>
		);
	}

	/**
	 * Sets the selected campaigns and clears out the contacts.
	 *
	 * @param {import('react').SyntheticEvent} event The select changed event.
	 */
	const handleCampaignChange = (event) => {
		const campaignIds = event.target.value;
		const selectedCampaigns = props.allCampaigns.filter(campaign => campaignIds.includes(campaign.id));
		setSelectedCampaigns(selectedCampaigns);
		document.activeElement.blur();
	};

	/**
	 * Generates a list of elements containing menu items for the number of contacts options to display (based on the min/max).
	 *
	 * @returns A number of MenuItem elements.
	 */
	function getNumberOfContactsMenuItems() {
		const menuItems = [];
		for (let i = MIN_CONTACTS_TO_DISPLAY; i <= MAX_CONTACTS_TO_DISPLAY; i++) {
			menuItems.push(
				<MenuItem key={i} value={i}>
					{i}
				</MenuItem>
			);
		}
		return menuItems;
	}

	/**
	 * Determines whether the campaign option should be disabled. Only 3 campaigns can be selected at a time, so
	 * each additional campaign option should be disabled.
	 *
	 * @param {string} campaignId The ID of the campaign to check.
	 * @returns {boolean} Whether the campaign option should be disabled.
	 */
	function isCampaignOptionDisabled(campaignId) {
		return selectedCampaigns.length >= props.maxSelectableCampaigns && !selectedCampaigns.map(campaign => campaign.id).includes(campaignId);
	}

	/**
	 * Toggles the state for the click action when recycle or suppress is clicked.
	 * @param {string} actionClicked The action that was clicked.
	 */
	function toggleClickAction(actionClicked) {
		const action = clickAction === actionClicked ? 'approve' : actionClicked;
		setClickAction(action);
		props.onClickActionChange(action);
	}

	return (
		<Box display="grid" gridTemplateColumns="1fr 1fr auto" gap={2} padding="10px">
			{/* Campaigns Dropdown */}
			<FormControl color='primary' variant='outlined' disabled={props.gettingCampaigns || props.disabled} onClick={() => props.onCampaignListRefreshRequested()}>
				<InputLabel id='campaigns-label' htmlFor="campaigns">{I18n.get('campaignLabel', STRINGS['en-US'].campaignLabel)}</InputLabel>
				<Select
					multiple
					inputProps={{ id: 'campaigns' }}
					color='primary'
					label={I18n.get('campaignLabel', STRINGS['en-US'].campaignLabel)}
					onChange={handleCampaignChange}
					value={selectedCampaigns.map(campaign => campaign.id)}
					renderValue={(selected) => getCampaignChips(selected)}
					disabled={props.allCampaigns.length === 0 || props.disabled}
				>
					{props.allCampaigns.map((campaign =>
						<MenuItem key={campaign.id} value={campaign.id} disabled={isCampaignOptionDisabled(campaign.id)}>
							<Checkbox name={campaign.id} color='primary' checked={selectedCampaigns.some(selectedCampaign => selectedCampaign.id === campaign.id)} />
							<ListItemText primary={campaign.name} />
						</MenuItem>
					))}
				</Select>
			</FormControl>

			{/* Channel Dropdown */}
			<FormControl color='primary' variant='outlined' disabled={props.disabled}>
				{!props.click2Call &&
					<>
						<InputLabel id='channel-label' htmlFor="channel">Channel</InputLabel>
						<Select
							color='primary'
							inputProps={{ id: 'channel' }}
							label='Channel'
							onChange={event => setSelectedChannel(event.target.value)}
							value={selectedChannel}
						>
							<MenuItem value='voice'>Voice</MenuItem>
							<MenuItem value='sms'>SMS</MenuItem>
						</Select>
					</>
				}

				{/* Number of Contacts Dropdown */}
				{props.click2Call &&
					<>
						<InputLabel id='number-of-contacts-label' htmlFor="number-of-contacts">{I18n.get('numberOfContactsLabel', STRINGS['en-US'].numberOfContactsLabel)}</InputLabel>
						<Select
							color='primary'
							inputProps={{ id: 'number-of-contacts' }}
							label={I18n.get('numberOfContactsLabel', STRINGS['en-US'].numberOfContactsLabel)}
							onChange={(selected) => setNumberOfContactsToDisplay(selected.target.value)}
							value={numberOfContactsToDisplay}
						>
							{getNumberOfContactsMenuItems().map((menuItem) => {
								return menuItem;
							})}
						</Select>
					</>
				}
			</FormControl>

			{/* Approver Actions */}
			<Box display='flex' gap={1} alignItems="center">
				<Tooltip title={I18n.get('recycleTooltip', STRINGS['en-US'].recycleTooltip)} arrow={true}>
					<span>
						<IconButton onClick={() => toggleClickAction('recycle')} disabled={props.disabled} size='small'>
							<ReplayOutlined color={clickAction === 'recycle' ? 'primary' : 'action'} />
						</IconButton>
					</span>
				</Tooltip>
				<Tooltip title={I18n.get('suppressTooltip', STRINGS['en-US'].suppressTooltip)} arrow={true}>
					<span>
						<IconButton onClick={() => toggleClickAction('suppress')} disabled={props.disabled} size='small'>
							<DeleteForeverOutlined color={clickAction === 'suppress' ? 'primary' : 'action'} />
						</IconButton>
					</span>
				</Tooltip>
				<Divider orientation='vertical'></Divider>
				<Tooltip
					title={
						props.showStatusDrawer ?
							I18n.get('campaignInfoHideTooltip', STRINGS['en-US'].campaignInfoHideTooltip) :
							I18n.get('campaignInfoShowTooltip', STRINGS['en-US'].campaignInfoShowTooltip)}
					arrow={true}>
					<span>
						<IconButton onClick={() => props.onToggleDrawer()} disabled={props.disabled} size='small'>
							<Info color={props.showStatusDrawer ? 'primary' : 'action'} />
						</IconButton>
					</span>
				</Tooltip>
			</Box>

			{/* <Grid item xs={12} sm={3} container>
				<Grid item container direction='column' xs justifyContent='center'>
					<Grid item>
						<Typography variant='caption'>APPROVER ACTIONS</Typography>
					</Grid>
					<Grid item>
						<Grid container direction='row'>
							<Grid item xs={6}>

							</Grid>
							<Grid item xs={6}>

							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid> */}


		</Box>
	);
}

import React from 'react';
import PropTypes from 'prop-types';

import { Button, Grid, IconButton } from '@mui/material';
import { actionOneButtonStyle } from 'src/theme';

const ActionElement = ({ className, handleOnClick, label, title }) => (
  <Grid item>
    {className === 'iconBtn' ?
      <IconButton size="small" onClick={(e) => handleOnClick(e)}>{label}</IconButton>
      :
      <Button sx={actionOneButtonStyle} className={className} title={title} onClick={(e) => handleOnClick(e)}>
        {label}
      </Button>
    }
  </Grid>
);

ActionElement.displayName = 'ActionElement';

ActionElement.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  className: PropTypes.string,
  handleOnClick: PropTypes.func,
  title: PropTypes.string
};

export default ActionElement;

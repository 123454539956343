import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Typography,
} from '@mui/material';
import { actionOneButtonStyle, actionTwoButtonStyle } from 'src/theme';

export function ConfirmDialog({
	open,
	title,
	description,
	actionOneText,
	actionOneHandler,
	actionTwoText,
	actionTwoHandler,
}) {
	return (
		<Dialog open={open} onClose={actionTwoHandler}>
			<DialogTitle>
				<Typography
					variant="h5"
					sx={{ flexGrow: 1, textAlign: 'center' }}
				>
					{/* Typography complains about having an invalid child so we are wrapping the title in a fragment */}
					<>
						{title}
					</>
				</Typography>
			</DialogTitle>
			<DialogContent>
				<Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
					<>
						{description}
					</>
				</Typography>
			</DialogContent>
			<DialogActions>
				{actionTwoHandler && actionTwoText && (
					<Button sx={actionTwoButtonStyle} onClick={actionTwoHandler}>
						<>
							{actionTwoText}
						</>
					</Button>
				)}
				{actionOneHandler && actionOneText && (
					<Button sx={actionOneButtonStyle} onClick={actionOneHandler}>
						<>
							{actionOneText}
						</>
					</Button>
				)}
			</DialogActions>
		</Dialog>
	);
}

import { useEffect, useRef, useState } from 'react';
import { Box, Button, CircularProgress, Typography, useTheme } from '@mui/material';
import { I18n } from 'aws-amplify/utils';

const STRINGS = {
	'en-US': { // English (United States)
		InitiatingCall: 'Initiating Call...',
		Dialing: 'Dialing...',
		CallEnded: 'Call Ended',
		AwaitingWrapUp: 'Call Ended - Awaiting Wrap Up',
		OnCall: 'On Call',
		NowConnecting: 'Now Connecting',
		NoAnswer: 'No Answer',
		Busy: 'Call Busy',
		Error: 'Error',
		AgentNotAvailable: 'Agent Not Available',
		NotAllowedToDial: 'Not Allowed To Dial',
		dismiss: 'Dismiss'
	},
	'es-419': { // Spanish (Latin America)
		InitiatingCall: 'Iniciando Llamada...',
		Dialing: 'Marcando...',
		CallEnded: 'Llamada Finalizada',
		AwaitingWrapUp: 'Llamada Finalizada - Esperando Finalización',
		OnCall: 'En Llamada',
		NowConnecting: 'Conectando Ahora',
		NoAnswer: 'Sin Respuesta',
		Busy: 'Llamada Ocupada',
		Error: 'Error',
		agentNotAvailable: 'Agente No Disponible',
		NotAllowedToDial: 'No Permitido Marcar',
		dismiss: 'Descartar'
	},
	'pt-BR': { // Portuguese (Brazil)
		InitiatingCall: 'Iniciando Chamada...',
		Dialing: 'Discando...',
		CallEnded: 'Chamada Encerrada',
		AwaitingWrapUp: 'Chamada Encerrada - Aguardando Encerramento',
		OnCall: 'Em Chamada',
		NowConnecting: 'Conectando Agora',
		NoAnswer: 'Sem Resposta',
		Busy: 'Chamada Ocupada',
		Error: 'Erro',
		AgentNotAvailable: 'Agente Não Disponível',
		NotAllowedToDial: 'Não Permitido Discar',
		dismiss: 'Dispensar'
	}
};
I18n.putVocabularies(STRINGS);

/** The amount of time in milliseconds before a call is errored out. */
const NO_CALL_RESPONSE_TIMEOUT = 60000;

/** The various statuses of a call. */
const CALL_STATUS = {
	InitiatingCall: 'Initiating Call...',
	Dialing: 'Dialing...',
	CallEnded: 'Call Ended',
	AwaitingWrapUp: 'Call Ended - Awaiting Wrap Up',
	OnCall: 'On Call',
	NowConnecting: 'Now Connecting',
	NoAnswer: 'No Answer',
	Busy: 'Call Busy',
	Error: 'Error',
	AgentNotAvailable: 'Agent Not Available',
	NotAllowedToDial: 'Not Allowed To Dial',
};

/** The various sentiments for a call status. */
const CALL_STATUS_SENTIMENT = {
	Neutral: 'neutral',
	Positive: 'positive',
	Negative: 'negative',
};

const containerStyles = {
	height: '100%',
	borderRadius: '4px',
	boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
	animation: 'fadeIn 0.25s',
	'@keyframes fadeIn': {
		'0%': {
			opacity: 0,
		},
		'100%': {
			opacity: 1,
		},
	},
};

const backgroundCommonStyles = {
	color: '#fff',
	'-webkit-transition': 'all 0.25s linear',
	'-moz-transition': 'all 0.25s linear',
	'-o-transition': 'all 0.25s linear',
	transition: 'all 0.25s linear',
};

const positiveBackgroundStyles = {
	backgroundColor: '#4caf50',
	...backgroundCommonStyles
};

const negativeBackgroundStyles = {
	backgroundColor: '#f44336',
	...backgroundCommonStyles
};

export function CallStatusPane(props) {
	const theme = useTheme();

	/** The status of the call. */
	const [callStatus, setCallStatus] = useState(CALL_STATUS.InitiatingCall);

	// TODO: Figure out if there's a better way to do this?
	/** Call status ref to the state. Used to allow retrieving this value in a callback. */
	let callStatusRef = useRef(callStatus);
	useEffect(() => {
		callStatusRef.current = callStatus;
	}, [callStatus]);

	/** The sentiment for the call status (neutral, positive, or negative). Used to change the color of the status pane. */
	const [statusSentiment, setStatusSentiment] = useState(
		CALL_STATUS_SENTIMENT.Neutral,
	);

	/** Whether to show the progress indicator for the status. */
	const [showProgress, setShowProgress] = useState(true);

	useEffect(() => {
		setTimeout(() => {
			// If the call is still "initiating" or "dialing" after the timeout, then we can assume there was an error.
			if (callStatusRef.current === CALL_STATUS.InitiatingCall || callStatusRef.current === CALL_STATUS.Dialing) {
				setCallStatus(CALL_STATUS.Error);
			}
		}, NO_CALL_RESPONSE_TIMEOUT);
	}, []);

	/**
	 * Determine whether to show the progress indicator and set the status sentiment for background color.
	 */
	useEffect(() => {
		setShowProgress(
			callStatus === CALL_STATUS.InitiatingCall ||
			callStatus === CALL_STATUS.Dialing ||
			callStatus === CALL_STATUS.NowConnecting ||
			callStatus === CALL_STATUS.AwaitingWrapUp);
		let sentiment = CALL_STATUS_SENTIMENT.Negative;
		if (callStatus === CALL_STATUS.OnCall) {
			sentiment = CALL_STATUS_SENTIMENT.Positive;
		} else if (
			callStatus === CALL_STATUS.InitiatingCall ||
			callStatus === CALL_STATUS.Dialing ||
			callStatus === CALL_STATUS.NowConnecting
		) {
			sentiment = CALL_STATUS_SENTIMENT.Neutral;
		}
		setStatusSentiment(sentiment);
	}, [callStatus]);

	/**
	 * Update the call status when the contact changes.
	 */
	useEffect(() => {
		if (props.contact.callStatus) {
			setCallStatus(props.contact.callStatus);
		}
	}, [props.contact.callStatus]);

	/**
	 * Formats a phone number to be in the format +# (###) ###-####.
	 *
	 * @param {string} phoneNumber The phone number to format.
	 * @returns A formatted phone number string.
	 */
	function getFormattedPhoneNumber(phoneNumber) {
		try {
			const cleaned = ('' + phoneNumber).replace(/\D/g, '');
			const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
			const intlCode = match[1] ? '+1 ' : '';
			return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
		} catch (error) {
			return phoneNumber;
		}
	}

	/**
	 * Gets the styles for the container.
	 *
	 * @returns The styles.
	 */
	function getContainerStyles() {
		if (statusSentiment === CALL_STATUS_SENTIMENT.Neutral) {
			return {
				...containerStyles,
				color: theme.palette.mode === 'dark' ? '#dddddd' : '#444444',
				backgroundColor: theme.palette.mode === 'dark' ? '#333333' : '#eeeeee'
			};
		}
		const sentimentStyles = statusSentiment === CALL_STATUS_SENTIMENT.Positive
			? positiveBackgroundStyles
			: negativeBackgroundStyles;
		return { ...containerStyles, ...sentimentStyles };
	}

	function getKeyForCallStatus(callStatus) {
		return Object.keys(CALL_STATUS).find(key => CALL_STATUS[key] === callStatus);
	}

	return (
		<Box
			display="flex"
			flexDirection="column"
			alignItems="center"
			justifyContent="center"
			height="100%"
			width="100%"
			sx={getContainerStyles()}
		>
			{/* Name */}
			<Typography variant="h3" gutterBottom>
				{props.contact.firstName} {props.contact.lastName}
			</Typography>

			{/* Phone */}
			<Typography variant="h5" gutterBottom textAlign="center">
				{getFormattedPhoneNumber(props.contact.phone)}
			</Typography>

			{/* Status and Loader */}
			<Box display="flex" gap="12px">
				{showProgress && (
					<CircularProgress variant="indeterminate" color={statusSentiment === CALL_STATUS_SENTIMENT.Neutral ? 'primary' : 'white'} size={25} />
				)}
				<Typography variant="h5">{I18n.get(getKeyForCallStatus(callStatus), STRINGS['en-US'][getKeyForCallStatus(callStatus)])}</Typography>
			</Box>
			<br></br>

			{/* Dismiss Button */}
			{statusSentiment === CALL_STATUS_SENTIMENT.Negative && callStatus !== CALL_STATUS.AwaitingWrapUp && (
				<Button
					color="white"
					variant="outlined"
					onClick={() => props.onDismiss(props.contact)}
				>
					{I18n.get('dismiss', STRINGS['en-US'].dismiss)}
				</Button>
			)}
		</Box>
	);
}

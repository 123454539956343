import { useContext, useEffect, useState } from 'react';
import {
	Button,
	Switch,
	Dialog,
	DialogTitle,
	DialogContent,
	TextField,
	Box,
	Typography,
	DialogActions,
	Tabs,
	Tab,
	FormControlLabel,
} from '@mui/material';
import SimpleBar from 'simplebar-react';
// import { allCountries } from 'country-region-data';
import { Formik } from 'formik';
import { UserContext } from '../../contexts/UserContext';
import { contactValidation } from '../yupValidations';
import { customFieldTypes } from '../../models/customFieldsTypes';
import { phone } from 'phone';
import { IS_PROD } from '../../models/globals';
import { generateClient } from 'aws-amplify/api';
import { createContact } from 'src/graphql/mutations';
import {
	actionOneButtonStyle,
	actionTwoButtonStyle,
	tabStyle,
} from 'src/theme';
import { FormControl } from '@mui/base';
import { fetchUserAttributes } from 'aws-amplify/auth';

/**
 * The dialog for creating and editing a contact.
 * @component
 */
export function ContactDialog(props) {
	const userContext = useContext(UserContext);
	const client = generateClient();
	const vanillaContact = {
		firstName: '',
		lastName: '',
		source: '',
		phone: '',
		cell: '',
		email: '',
		address1: '',
		address2: '',
		city: '',
		state: '',
		zip: '',
		customFields: {},
	};
	const [currentTab, setCurrentTab] = useState(0);

	const [contact, setContact] = useState(vanillaContact);

	useEffect(() => {
		if (props.contact) {
			setContact(props.contact);
		} else {
			setContact(vanillaContact);
		}
	}, [props.contact]);


	useEffect(() => {
		// Set States
		// setUsStates(allCountries.find((country) => country[1] === 'US')[2]);
	}, []);

	return (
		<UserContext.Consumer>
			{({ tenant, customFields }) => (
				<div>
					<Dialog
						open={props.contactDialogOpen}
						maxWidth="lg"
						sx={{
							'& .MuiDialog-paper': {
								width: '100%',
								maxWidth: '40vw',
							},
						}}
					>
						<DialogTitle>
							<Typography
								sx={{
									fontSize: '1.5rem',
									fontWeight: 'bold',
								}}
							>
								New Contact
							</Typography>
						</DialogTitle>

						<DialogContent>
							<Formik
								initialValues={contact}
								validationSchema={contactValidation(customFields)}
								enableReinitialize
								onSubmit={async (values) => {
									//dynamodb doesn't like empty strings turn empty into null
									const contact = { ...values };
									for (const key in contact) {
										contact[key] = contact[key] === '' ? null : contact[key];
									}

									contact.tenant = tenant;
									contact.updatedBy = (await fetchUserAttributes()).name;
									delete contact.createdAt;
									delete contact.updatedAt;
									if (contact.customFields) {
										for (const key in contact.customFields) {
											const tenantCustomField = customFields.find(
												(field) => field.name === key,
											);
											if (tenantCustomField) {
												if (tenantCustomField.type === 'Number') {
													contact.customFields[key] =
														+contact.customFields[key];
												} else if (tenantCustomField.type === 'DateTime') {
													const dateField = contact.customFields[key];
													if (dateField && Number(dateField)) {
														contact.customFields[key] = dateField;
													} else {
														contact.customFields[key] = new Date(
															dateField,
														).getTime();
													}
												} else if (tenantCustomField.type === 'Boolean') {
													contact.customFields[key] = Boolean(
														contact.customFields[key],
													);
												} else if (
													tenantCustomField.type === customFieldTypes.Phone
												) {
													contact.customFields[key] = phone(
														contact.customFields[key],
														{ validateMobilePrefix: IS_PROD },
													).phoneNumber;
												}
											}
										}
										contact.customFields = JSON.stringify(contact.customFields);
									}

									contact.phone = phone(contact.phone, {
										validateMobilePrefix: IS_PROD,
									}).phoneNumber;
									if (contact.cell)
										contact.cell = phone(contact.cell, {
											validateMobilePrefix: IS_PROD,
										}).phoneNumber;

									try {
										contact.tenant = userContext.tenantId;
										await client.graphql({
											query: createContact,
											variables: { input: contact },
										});
										props.setContactDialogOpen(false);
										window.location.reload(false);
									} catch (err) {
										console.error(err);
									}
								}}
							>
								{(formikProps) => (
									<form onSubmit={formikProps.handleSubmit}>
										<SimpleBar style={{ maxHeight: '70vh' }}>
											<Box display="flex" flexDirection="column" gap="20px">
												<Tabs
													value={currentTab}
													indicatorColor="primary"
													textColor="primary"
													onChange={(e, newValue) => setCurrentTab(newValue)}
												>
													<Tab sx={tabStyle} label="Contact Information" />
													<Tab sx={tabStyle} label="Custom Fields" />
												</Tabs>
												{currentTab === 0 && (
													<>
														<TextField
															label="External ID"
															value={formikProps.values.externalId}
															onChange={formikProps.handleChange}
															error={
																formikProps.touched.externalId &&
																Boolean(formikProps.errors.externalId)
															}
															helperText={
																formikProps.touched.externalId &&
																formikProps.errors.externalId
															}
															name="externalId"
															fullWidth
														/>
														<Box display="grid" gridTemplateColumns="1fr 1fr" gap="20px">
															<TextField
																label="First Name"
																value={formikProps.values.firstName}
																onChange={formikProps.handleChange}
																error={
																	formikProps.touched.firstName &&
																	Boolean(formikProps.errors.firstName)
																}
																helperText={
																	formikProps.touched.firstName &&
																	formikProps.errors.firstName
																}
																name="firstName"
																fullWidth
															/>
															<TextField
																label="Last Name"
																value={formikProps.values.lastName}
																onChange={formikProps.handleChange}
																error={
																	formikProps.touched.lastName &&
																	Boolean(formikProps.errors.lastName)
																}
																helperText={
																	formikProps.touched.lastName && formikProps.errors.lastName
																}
																name="lastName"
																fullWidth
															/>
															<TextField
																label="Phone"
																value={formikProps.values.phone}
																onChange={formikProps.handleChange}
																error={
																	formikProps.touched.phone && Boolean(formikProps.errors.phone)
																}
																helperText={
																	formikProps.touched.phone && formikProps.errors.phone
																}
																name="phone"
																fullWidth
															/>
															<TextField
																label="Cell"
																value={formikProps.values.cell}
																onChange={formikProps.handleChange}
																error={
																	formikProps.touched.cell && Boolean(formikProps.errors.cell)
																}
																helperText={
																	formikProps.touched.cell && formikProps.errors.cell
																}
																name="cell"
																fullWidth
															/>
															<TextField
																label="Email"
																value={formikProps.values.email}
																onChange={formikProps.handleChange}
																error={
																	formikProps.touched.email && Boolean(formikProps.errors.email)
																}
																helperText={
																	formikProps.touched.email && formikProps.errors.email
																}
																name="email"
																fullWidth
															/>
															<TextField
																label="Source"
																value={formikProps.values.source}
																onChange={formikProps.handleChange}
																error={
																	formikProps.touched.source &&
																	Boolean(formikProps.errors.source)
																}
																helperText={
																	formikProps.touched.source && formikProps.errors.source
																}
																name="source"
																fullWidth
															/>
															<TextField
																label="Address 1"
																value={formikProps.values.address1}
																onChange={formikProps.handleChange}
																error={
																	formikProps.touched.address1 &&
																	Boolean(formikProps.errors.address1)
																}
																helperText={
																	formikProps.touched.address1 && formikProps.errors.address1
																}
																name="address1"
																fullWidth
															/>
															<TextField
																label="Address 2"
																value={formikProps.values.address2}
																onChange={formikProps.handleChange}
																error={
																	formikProps.touched.address2 &&
																	Boolean(formikProps.errors.address2)
																}
																helperText={
																	formikProps.touched.address2 && formikProps.errors.address2
																}
																name="address2"
																fullWidth
															/>
															<TextField
																label="City"
																value={formikProps.values.city}
																onChange={formikProps.handleChange}
																error={
																	formikProps.touched.city && Boolean(formikProps.errors.city)
																}
																helperText={
																	formikProps.touched.city && formikProps.errors.city
																}
																name="city"
																fullWidth
															/>
															<TextField
																label="State"
																value={formikProps.values.state}
																onChange={formikProps.handleChange}
																error={
																	formikProps.touched.state && Boolean(formikProps.errors.state)
																}
																helperText={
																	formikProps.touched.state && formikProps.errors.state
																}
																name="state"
																fullWidth
															/>
															<TextField
																label="Zip"
																value={formikProps.values.zip}
																onChange={formikProps.handleChange}
																error={
																	formikProps.touched.zip && Boolean(formikProps.errors.zip)
																}
																helperText={
																	formikProps.touched.zip && formikProps.errors.zip
																}
																name="zip"
																fullWidth
															/>
														</Box>
													</>
												)}
												{currentTab === 1 && (
													<Box display="grid" gridTemplateColumns="1fr 1fr" gap="20px">
														{customFields &&
															customFields.map((field) =>
																(function () {
																	switch (field.type) {
																		case 'Number':
																		case 'String':
																		case 'Phone':
																			return (
																				<TextField
																					key={field.name}
																					label={field.displayName}
																					value={
																						formikProps.values.customFields[field.name]
																					}
																					onChange={formikProps.handleChange}
																					name={`customFields.${field.name}`}
																					fullWidth
																				/>
																			);
																		case 'Boolean':
																			return (
																				<FormControl key={field.name}>
																					<FormControlLabel
																						control={
																							<Switch
																								checked={
																									formikProps.values.customFields[
																									field.name
																									]
																								}
																								onChange={formikProps.handleChange}
																								name={`customFields.${field.name}`}
																							/>
																						}
																						label={field.displayName}
																					/>
																				</FormControl>
																			)
																		case 'DateTime':
																			return (
																				<TextField
																					key={field.name}
																					label={field.displayName}
																					value={
																						formikProps.values.customFields[field.name]
																					}
																					onChange={formikProps.handleChange}
																					name={`customFields.${field.name}`}
																					type="date"
																					fullWidth
																				/>
																			)

																		default:
																			return null;
																	}
																}
																)(),
															)}
													</Box>
												)}
											</Box>
										</SimpleBar>
										<DialogActions>
											<Button
												onClick={() => props.setContactDialogOpen(false)}
												sx={actionTwoButtonStyle}
											>
												Cancel
											</Button>
											<Button sx={actionOneButtonStyle} type="submit">
												Save
											</Button>
										</DialogActions>
									</form>
								)}
							</Formik>
						</DialogContent>
					</Dialog>
				</div>
			)}
		</UserContext.Consumer>
	);
}

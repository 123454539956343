import { useEffect, useContext, useState } from 'react';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button } from '@mui/material';
import { EditOutlined, DeleteOutlineOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import MaterialTable from '@material-table/core';
import { UserContext } from '../../contexts/UserContext';
import { generateClient } from 'aws-amplify/api';
import { enqueueSnackbar } from 'notistack';
import { createDncList, deleteDncList, } from '../../graphql/mutations';
import { listDncLists } from '../../graphql/queries';
import { PageAppBar } from 'src/components/pageAppBar';
import { ConfirmDialog } from 'src/components/confirmDialog/confirmDialog';
import { actionOneButtonStyle, actionTwoButtonStyle } from 'src/theme';

export function DNCLists() {
	const client = generateClient();
	const userContext = useContext(UserContext);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const [dncLists, setDNCLists] = useState([]);
	const [toDelete, setToDelete] = useState(null);
	const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
	const [newDialogOpen, setNewDialogOpen] = useState(false);
	const [newDNCListName, setNewDNCListName] = useState('');

	useEffect(() => {
		async function getData() {
			setLoading(true);
			// Loop Query to make sure all the data is fetched

			const savedDNCLists = await client.graphql({
				query: listDncLists,
			});
			console.log(savedDNCLists);
			if (savedDNCLists && savedDNCLists.data) {
				setDNCLists(
					savedDNCLists.data.listDNCLists.items.sort(
						(a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt),
					),
				);
			}
			setLoading(false);
		}
		if (userContext.tenantId) {
			getData();
		}
	}, [userContext.tenantId]);

	async function handleDelete() {
		setLoading(true);
		const deleteResult = await client.graphql({
			query: deleteDncList,
			variables: { input: { id: toDelete } },
		});
		console.log(deleteResult);
		//if profile is successful in deleting remove it from the current list.
		// if (deleteResult.data) {
		// 	const deletedProfile = deleteResult.data.deleteProfile;
		// 	const curProfiles = [...profiles];
		// 	curProfiles.splice(
		// 		curProfiles.findIndex((e) => e.id === deletedProfile.id),
		// 		1,
		// 	);
		// 	setProfiles([...curProfiles]);
		// }

		//Reload list

		setLoading(false);
	}

	function handleCancelDelete() {
		setToDelete(null);
		setConfirmDeleteOpen(false);
	}

	function createNewDNCList() {
		try {
			client.graphql({
				query: createDncList,
				variables: { input: { tenant: userContext.tenantId, name: newDNCListName } },
			});
			enqueueSnackbar('DNC List created successfully', { variant: 'success' });

		} catch (error) {
			console.error(error);
			enqueueSnackbar('Error creating DNC List', { variant: 'error' });
		}

		setNewDialogOpen(false);
	}

	return (
		<Box>
			<PageAppBar
				title="DNC Lists"
				description="Create and manage lists of phone numbers that should not be contacted."
				actionOneText="+ DNC List"
				actionOneHandler={() => setNewDialogOpen(true)}
			/>
			<MaterialTable
				components={{
					Container: (props) => <Box {...props} elevation={0} />,
				}}
				title=""
				data={dncLists}
				columns={[{ title: 'Name', field: 'name' }]}
				isLoading={loading}
				options={{
					actionsColumnIndex: -1,
					pageSize: 10,
					searchFieldStyle: {
						marginBottom: '16px',
						marginleft: '-28px',
					},
					headerStyle: {
						borderTop: '1px solid #e0e0e0',
						padding: '16px',
					},
					searchFieldVariant: 'outlined',
					paginationType: 'stepped',
				}}
				actions={[
					{
						icon: () => <EditOutlined color="primary" />,
						tooltip: 'Edit List',
						onClick: (event, rowData) => {
							navigate(`/dnc-lists/${rowData.id}`);
						},
					},
					{
						icon: () => <DeleteOutlineOutlined color="primary" />,
						tooltip: 'Delete DNC List',
						onClick: (event, rowData) => {
							setToDelete(rowData.id);
							setConfirmDeleteOpen(true);
						},
					},
				]}
				onRowClick={(event, rowData) => {
					navigate(`/dnc-lists/${rowData.id}`);
				}}
			/>
			<ConfirmDialog
				open={confirmDeleteOpen}
				title="Delete DNC List"
				description="Are you sure you want to delete this list?"
				actionOneText="Delete"
				actionOneHandler={() => {
					handleDelete();
				}}
				actionTwoText="Cancel"
				actionTwoHandler={() => {
					handleCancelDelete();
				}}
			/>
			<Dialog open={newDialogOpen}>
				<DialogTitle>Create New DNC List</DialogTitle>
				<DialogContent>
					<TextField
						label="Name"
						variant="outlined"
						fullWidth
						value={newDNCListName}
						onChange={(e) => setNewDNCListName(e.target.value)}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => {
						setNewDialogOpen(false)
						setNewDNCListName('')
					}}
					sx={actionTwoButtonStyle}
					>Cancel</Button>
					<Button onClick={createNewDNCList} sx={actionOneButtonStyle}>
						Create
					</Button>
				</DialogActions>
			</Dialog>

		</Box>
	);
}

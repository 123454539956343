import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { Formik } from "formik";
import { object, string } from "yup";

import './imageDialog.css';
import { actionOneButtonStyle, actionTwoButtonStyle } from "src/theme";

/**
 * @typedef {object} ImageDialogProps
 *
 * @property {boolean} open Whether or not the dialog is open.
 * @property {(imageDetails) => {}} onClose The function to call when the dialog
 */

/**
 *
 * @param {ImageDialogProps} props The props for the ImageDialog component.
 */
export function ImageDialog({
	open,
	onClose,
}) {

	return (
		<Dialog open={open} onClose={onClose} fullWidth>
			<DialogTitle>Insert Image</DialogTitle>


			<Formik
				initialValues={{
					url: 'https://',
					description: '',
				}}
				enableReinitialize={true}
				validationSchema={object(
					{
						url: string().url('Must be a valid URL').required('URL is required'),
						description: string(),
					}
				)}
				onSubmit={(values) => {
					console.log(values);
					onClose(values);
				}}
			>
				{formikProps => (
					<>
						<DialogContent>

							{/* Image URL */}
							<TextField
								color="primary"
								name="url"
								label="Image URL"
								type="url"
								autoComplete="off"
								placeholder="https://example.com/image.jpg"
								onBlur={formikProps.handleBlur}
								onChange={formikProps.handleChange}
								value={formikProps.values.url}
								error={formikProps.errors.url && formikProps.touched.url}
								helperText={
									formikProps.errors.url ? formikProps.errors.url : 'The URL of the image to insert'
								}
								className="text-field"
								fullWidth
							/>


							{/* Image description */}
							<TextField
								color="primary"
								name="description"
								label="Image Description"
								type="text"
								rows={4}
								multiline
								autoComplete="off"
								placeholder="Yellow Labrador Retriever sitting on a grassy field, looking up playfully."
								onBlur={formikProps.handleBlur}
								onChange={formikProps.handleChange}
								value={formikProps.values.description}
								error={formikProps.errors.description && formikProps.touched.description}
								helperText={'A description of the image (alt text)'}
								className="text-field"
								fullWidth
							/>
						</DialogContent>

						<DialogActions>
							<Button
								sx={actionTwoButtonStyle}
								onClick={onClose}>Cancel</Button>
							<Button
								sx={actionOneButtonStyle}
								type="submit"
								disabled={!formikProps.isValid || !formikProps.dirty}
								onClick={formikProps.handleSubmit}>Insert</Button>
						</DialogActions>

					</>
				)}

			</Formik>
		</Dialog>
	);
}

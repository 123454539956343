import { useEffect, useContext, useState } from 'react';
import { Box } from '@mui/material';
import { EditOutlined, DeleteOutlineOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import MaterialTable from '@material-table/core';
import { UserContext } from '../../contexts/UserContext';
import { generateClient } from 'aws-amplify/api';
// import { useSnackbar } from 'notistack';
import { deleteProfile } from '../../graphql/mutations';
import { profileByTenant, listCampaigns } from '../../graphql/queries';
import { PageAppBar } from 'src/components/pageAppBar';
import { ConfirmDialog } from 'src/components/confirmDialog/confirmDialog';

export function DialingProfiles() {
	const client = generateClient();
	// const classes = useStyles();
	const userContext = useContext(UserContext);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const [profiles, setProfiles] = useState([]);
	const [toDelete, setToDelete] = useState(null);
	const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

	useEffect(() => {
		async function getData() {
			setLoading(true);
			const savedProfiles = await client.graphql({
				query: profileByTenant,
				variables: { tenant: userContext.tenantId },
			});
			console.log(savedProfiles);
			if (savedProfiles && savedProfiles.data) {
				setProfiles(
					savedProfiles.data.profileByTenant.items.sort(
						(a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt),
					),
				);
			}
			setLoading(false);
		}
		if (userContext.tenantId) {
			getData();
		}
	}, [userContext.tenantId]);

	async function handleDelete() {
		setLoading(true);
		const campaignResult = await client.graphql({
			query: listCampaigns,
			variables: {
				filter: {
					and: [
						{ tenant: { eq: userContext.tenantId } },
						{ campaignProfileId: { eq: toDelete } },
					],
				},
			},
		});
		if (campaignResult.data.listCampaigns.items.length > 0) {
			// enqueueSnackbar('Cannot delete the profile. Campaigns are using it.', {
			//     variant: 'error',
			//     autoHideDuration: 5000
			// });
			setLoading(false);
			setToDelete(null);
			setConfirmDeleteOpen(false);
			return;
		}
		const deleteResult = await client.graphql({
			query: deleteProfile,
			variables: { input: { id: toDelete } },
		});
		console.log(deleteResult);
		//if profile is successful in deleting remove it from the current list.
		if (deleteResult.data) {
			const deletedProfile = deleteResult.data.deleteProfile;
			const curProfiles = [...profiles];
			curProfiles.splice(
				curProfiles.findIndex((e) => e.id === deletedProfile.id),
				1,
			);
			setProfiles([...curProfiles]);
		}

		setLoading(false);
	}

	function handleCancelDelete() {
		setToDelete(null);
		setConfirmDeleteOpen(false);
	}

	function handleSearchChange(value) {
		window.sessionStorage.setItem('ProfilesSearch', value);
	}

	return (
		<Box>
			<PageAppBar
				title="Communication Profiles"
				description="Set up Call Windows, Cadence, Attempts, and More"
				actionOneText="+ Profile"
				actionOneHandler={() => {
					navigate('/profiles/new');
				}}
			/>
			<MaterialTable
				components={{
					Container: (props) => <Box {...props} elevation={0} />,
				}}
				title=""
				data={profiles}
				columns={[
					{ title: 'Name', field: 'name' },
					{ title: 'Channels', render: (rowData) => {
						const includedChannels = []
						if(rowData.channels.find((channel) => channel.type === 'Voice')) {
							includedChannels.push('Voice');
						}
						if(rowData.channels.find((channel) => channel.type === 'SMS')) {
							includedChannels.push('SMS');
						}
						if(rowData.channels.find((channel) => channel.type === 'Email')) {
							includedChannels.push('Email');
						}

						return includedChannels.join(', ');
					}
				},
				{ 'title': 'Pacing', render: (rowData) => {
					if(rowData.channels.find((channel) => channel.type === 'Voice') && rowData.clickToCall){
						return 'Click to Call';
					} else if(rowData.channels.find((channel) => channel.type === 'Voice') && rowData.overrideDialToAgent){
						return 'Static Dialing Ratio';
					} else if (rowData.channels.find((channel) => channel.type === 'Voice')){
						return 'Dynamic Dialing Ratio';
					} else {
						return '-';
					}
				} }
				]}
				isLoading={loading}
				options={{
					actionsColumnIndex: -1,
					pageSize: 10,
					searchText: window.sessionStorage.getItem('ProfilesSearch'),
					searchFieldStyle: {
						marginBottom: '16px',
						marginleft: '-28px',
					},
					headerStyle: {
						borderTop: '1px solid #e0e0e0',
						padding: '16px',
					},
					searchFieldVariant: 'outlined',
					paginationType: 'stepped',
				}}
				onSearchChange={handleSearchChange}
				actions={[
					{
						icon: () => <EditOutlined color="primary" />,
						tooltip: 'Edit Profile',
						onClick: (event, rowData) => {
							navigate(`/profiles/${rowData.id}`);
						},
					},
					{
						icon: () => <DeleteOutlineOutlined color="primary" />,
						tooltip: 'Delete Profile',
						onClick: (event, rowData) => {
							setToDelete(rowData.id);
							setConfirmDeleteOpen(true);
						},
					},
				]}
				onRowClick={(event, rowData) => {
					navigate(`/profiles/${rowData.id}`);
				}}
			/>
			<ConfirmDialog
				open={confirmDeleteOpen}
				title="Delete Profile"
				description="Are you sure you want to delete this profile?"
				actionOneText="Delete"
				actionOneHandler={() => {
					handleDelete();
				}}
				actionTwoText="Cancel"
				actionTwoHandler={() => {
					handleCancelDelete();
				}}
			/>
		</Box>
	);
}

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
	$getRoot,
	$insertNodes,
} from 'lexical';
import { useEffect } from 'react';
import { $generateNodesFromDOM } from '@lexical/html';

/**
 * @typedef {object} ToolbarPluginProps
 *
 * @property {string} [initialValue] The initial HTML value for the editor.
 */

/**
 * Reusable HTML plugin for the editor.
 */
export function HTMLPlugin({
	initialValue
}) {
	const [editor] = useLexicalComposerContext();

	/**
	 * Imports the HTML content when the editor is initialized.
	 */
	useEffect(() => {
		if (!editor || !initialValue) {
			return;
		}

		editor.update(() => {
			const parser = new DOMParser();
			const dom = parser.parseFromString(initialValue, 'text/html');
			const nodes = $generateNodesFromDOM(editor, dom);

			// Clear any existing content.
			const root = $getRoot();
			root.clear();

			$getRoot().select();
			$insertNodes(nodes);
		});
	}, [editor, initialValue]);
}

/** The color of the bubbles for the first campaign. */
export const CAMPAIGN_COLOR_1 = '#2175e5'; // Blue

/** The color of the bubbles for the second campaign. */
export const CAMPAIGN_COLOR_2 = '#ffa731'; // Orange

/** The color of the bubbles for the third campaign. */
export const CAMPAIGN_COLOR_3 = '#3bc469'; // Green

/** The color of the bubbles for the third campaign. */
export const CAMPAIGN_COLOR_4 = '#e8ce4e'; // Yellow

/** The color of the bubbles for the third campaign. */
export const CAMPAIGN_COLOR_5 = '#b407e9'; // Purple

/** The color of the bubbles for the sixth campaign. */
export const CAMPAIGN_COLOR_6 = '#D1D1D1'; // White

export const CAMPAIGN_COLORS = [
	CAMPAIGN_COLOR_1,
	CAMPAIGN_COLOR_2,
	CAMPAIGN_COLOR_3,
	CAMPAIGN_COLOR_4,
	CAMPAIGN_COLOR_5,
	CAMPAIGN_COLOR_6,
];

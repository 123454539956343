import { useEffect, useContext, useState } from 'react';
import MaterialTable from '@material-table/core';
import { listPaymentTemplates } from 'src/graphql/queries';
import { deletePaymentTemplate } from 'src/graphql/mutations';
import { generateClient } from 'aws-amplify/api';
import { UserContext } from 'src/contexts/UserContext';
import { DeleteOutlineOutlined } from '@mui/icons-material';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { PageAppBar } from 'src/components/pageAppBar';
import { ConfirmDialog } from 'src/components/confirmDialog/confirmDialog';
import { enqueueSnackbar } from 'notistack';

export function MessageTemplates() {
	const client = generateClient();
	const userContext = useContext(UserContext);
	const navigate = useNavigate();

	const [templates, setTemplates] = useState([]);
	const [selectedTemplate, setSelectedTemplate] = useState(); // For deletion

	const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
	const [loading, setLoading] = useState(false);

	document.title = 'Message Templates';

	/**
	 * Gets the templates.
	 */
	useEffect(() => {
		async function getData() {
			setLoading(true);

			const savedTemplates = await client.graphql({
				query: listPaymentTemplates
			});
			if (savedTemplates && savedTemplates.data) {
				setTemplates(savedTemplates.data.listPaymentTemplates.items.sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt)));
			}
			setLoading(false);
		}

		if (userContext.tenantId) {
			getData();
		}
	}, [userContext.tenantId]);

	/**
	 * Shows the delete confirmation dialog when a template is selected.
	 */
	useEffect(() => {
		setShowDeleteConfirmation(!!selectedTemplate);
	}, [selectedTemplate]);

	async function deleteMessageTemplate(templateId) {
		try {
			const deleteResult = await client.graphql({
				query: deletePaymentTemplate,
				variables: {
					input: { id: templateId },
				}
			});

			if (deleteResult.data) {
				const deletedTemplate = deleteResult.data['deletePaymentTemplate'];
				const currentTemplates = [...templates].filter((template) => template.id !== deletedTemplate.id);
				setTemplates([...currentTemplates]);
			}
			enqueueSnackbar("Template deleted.", {
				variant: "success",
				autoHideDuration: 5000
			});
		} catch (error) {
			console.error("Error deleting template: ", error);
			enqueueSnackbar("Error deleting template.", {
				variant: "error",
				autoHideDuration: 5000
			});
		}
	}

	function stripHtmlTags(input) {
		return input.replace(/<\/?[^>]+(>|$)/g, "");
	}

	return (
		<div style={{ margin: 'auto', maxWidth: '1800px' }}>
			<PageAppBar
				title="Message Templates"
				description="View and manage your templates used for messaging"
				actionOneText="Add Template"
				actionOneHandler={() => {
					navigate('/message-templates/new');
				}}
			/>

			{/* Table */}
			<MaterialTable
				isLoading={loading}
				title="Templates"
				actions={[
					{
						icon: () => <DeleteOutlineOutlined fontSize="medium" color="primary" />,
						tooltip: "Delete Template",
						onClick: (_, rowData) => {
							setSelectedTemplate(rowData);
						},
					},
				]}
				columns={[
					{ title: "Name", field: "name", width: "20%" },
					{
						title: "Content",
						field: "textBody",
						width: "80%",
						render: (rowData) => (
							<div
								style={{
									maxHeight: "26px",
									overflow: "hidden",
								}}
							>
								{stripHtmlTags(rowData.textBody)}
							</div>
						),
					},
				]}
				data={templates}
				onRowClick={(_, rowData) => {
					navigate(`/message-templates/${rowData.id}`);
				}}
				options={{
					actionsColumnIndex: -1,
					pageSize: 20,
					emptyRowsWhenPaging: false,
					searchAutoFocus: true,
					headerStyle: {
						padding: '16px',
					},
					paginationType: 'stepped',
				}}
				localization={{
					body: {
						emptyDataSourceMessage: 'No templates found',
					},
				}}
				components={{
					Container: (props) => <Box {...props} elevation={0} />,
				}}
			/>

			{/* Confirmation */}
			<ConfirmDialog
				open={showDeleteConfirmation}
				title="Delete Message Template"
				description="Are you sure you want to delete this message template? This action cannot be undone."
				actionOneText="Delete"
				actionOneHandler={() => {
					deleteMessageTemplate(selectedTemplate.id);
					setSelectedTemplate();
				}}
				actionTwoText="Cancel"
				actionTwoHandler={() => {
					setSelectedTemplate();
				}}
			/>

		</div>
	);
}

import { Grid, FormControlLabel, Typography, TextField, Switch, Tooltip } from "@mui/material";
import { InfoOutlined, HelpOutlineOutlined } from "@mui/icons-material";
import SuppressionBuilder from "./suppressionBuilder";
import _ from 'lodash';
import { useRef } from "react";

const lookupFields = {
    "ActivityLog_Heading": {
        label: "Disposition Values",
        type: "heading"
    },
    "metaData.DispositionClassificationId": {
        label: "Classification ID",
        type: "text"
    },
    disposition: {
        label: "Disposition",
        type: "text"
    },
    type: {
        label: "Activity",
        type: "select",
        values: [
            { name: "Note", label: "Note" },
            { name: "Dial", label: "Dial" },
            { name: "SMS", label: "SMS" },
            { name: "Email", label: "Email" },
            { name: "SMSApprove", label: "SMSApprove" },
            { name: "SMSSuppress", label: "SMSSuppress" },
            { name: "SMSRecycle", label: "SMSRecycle" },
            { name: "SMSUndeliverable", label: "SMSUndeliverable" },
            { name: "System", label: "System" },
            { name: "C3", label: "C3" },
            { name: "DialApprove", label: "DialApprove" },
            { name: "DialSuppress", label: "DialSuppress" },
            { name: "DialRecycle", label: "DialRecycle" }
        ]
    },
    timestamp: {
        label: "Timestamp",
        type: "date"
    },
    "POC_Fields": {
        label: "POC Fields",
        type: "heading"
    },
    "metaData.metaFieldData.poc.cell": {
        label: "Is cellphone",
        type: "boolean"
    },
    "metaData.metaFieldData.poc.consent": {
        label: "Has consent",
        type: "boolean"
    },
    "metaData.metaFieldData.poc.writtenConsent": {
        label: "Date of written consent",
        type: "date",
    },
    "metaData.metaFieldData.poc.optOut": {
        label: "Has opted out",
        type: "boolean"
    },
    "metaData.metaFieldData.poc.preferred": {
        label: "Is preferred",
        type: "boolean"
    },
    "metaData.metaFieldData.poc.thirdParty": {
        label: "Is third party",
        type: "boolean"
    }
}

const condensedFields = Object.keys(lookupFields).map(x => { return { name: x, label: lookupFields[x].label, type: lookupFields[x].type }});

export default function ActivityLogSuppression(props) {
    const { formikProps, classes, tenant } = props;
    const queryInitialTouch = useRef(false);

    function processFilterArray(items, combinator, isBase = true) {
        const ruleArray = [];
        const ruleGroup = { [combinator]: ruleArray };
        for (const item of items) {
            ruleArray.push(processFilterItem(item, items.combinator));
        }

        if (isBase) {
            return { and: [{ tenant: { eq: tenant } }, { activityLogContactId: { eq: "CONTACTIDPLACEHOLDER" } }, ruleGroup] };
        } else {
            return ruleGroup;
        }
    }

    function processFilterItem(item) {
        const cpyItem = { ...item };
        if (item.field) {
            if (cpyItem.operator === 'wildcard') {
                cpyItem.value = `*${cpyItem.value.toLowerCase()}*`;
            } else if (cpyItem.operator === 'wildcard*') {
                cpyItem.value = `${cpyItem.value.toLowerCase()}*`;
                cpyItem.operator = 'wildcard';
            } else if (cpyItem.operator === '*wildcard') {
                cpyItem.value = `*${cpyItem.value.toLowerCase()}`;
                cpyItem.operator = 'wildcard';
            } else {
                if (cpyItem.value === true || cpyItem.value === false || cpyItem.value === 'true' || cpyItem === 'false') {
                    if ( _.isString(cpyItem.value)) {
                        cpyItem.value = cpyItem.value === 'true';
                    } else {
                        cpyItem.value = cpyItem.value === true;
                    }
                } else if (!isNaN(cpyItem.value)) {
                    cpyItem.value = +cpyItem.value;
                } else if (!!Date.parse(cpyItem.value)) {
                    cpyItem.value = Date.parse(cpyItem.value);
                }
            }
            return { [`${cpyItem.field}`]: { [cpyItem.operator]: cpyItem.value } };
        }
        return processFilterArray(cpyItem.rules, cpyItem.combinator, false);
    }

    function handleQueryChange(query, formikProps) {
        if (!queryInitialTouch.current) {
            queryInitialTouch.current = true;
        } else {
            formikProps.setFieldTouched(`activityLogQuerySuppression.rawQuery`, true);
        }

        formikProps.setFieldValue(`activityLogQuerySuppression.rawQuery`, query);
        const formattedQuery = processFilterArray(query.rules, query.combinator ?? 'AND');
        formikProps.setFieldValue(`activityLogQuerySuppression.query`, formattedQuery);
    }

    function getType(field, operator) {
        return lookupFields[field].type;
    }

    function getValues(field, operator) {
        return lookupFields[field].values;
    }

    function getOperators(field) {
        switch (lookupFields[field].type) {
            case "boolean":
            default:
                return [
                    { name: 'eq', label: '=' },
                    { name: 'ne', label: '!=' }
                ];

            case "date":
                return [
                    { name: 'lt', label: '<' },
                    { name: 'gt', label: '>' },
                    { name: 'lte', label: '<=' },
                    { name: 'gte', label: '>=' },
                ];
        }
    }

    function navigateToIds(){
        window.open('https://help.incontact.com/Content/ACD/Dispositions/SystemDispositionValues.htm?Highlight=system%20dispositions');
    }

    return (
        <Grid container spacing={2} direction='column'>
            <Grid item>
                <FormControlLabel
                    label='Enabled'
                    control={<Switch
                        checked={formikProps.values.activityLogQuerySuppressionEnabled}
                        onChange={formikProps.handleChange}
                        color='primary'
                        name='activityLogQuerySuppressionEnabled' />}
                />
            </Grid>
            {formikProps.touched?.activityLogQuerySuppression?.rawQuery && formikProps.errors?.activityLogQuerySuppression?.rawQuery &&
            <Grid item>
                <Typography color='error' variant='body1'>{formikProps.errors?.activityLogQuerySuppression?.rawQuery}</Typography>
            </Grid>}
            <Grid item>
                <Grid item container spacing={2} alignItems='flex-start' alignContent='flex-start'>
                    <Grid sx={{margin: '1em'}}>
                        <SuppressionBuilder
                        query={formikProps.values.activityLogQuerySuppression.rawQuery}
                        onQueryChange={query => handleQueryChange(query, formikProps)}
                        showCombinatorsBetweenRules
                        fields={condensedFields}
                        getValueEditorType={getType}
                        getInputType={getType}
                        getValues={getValues}
                        getOperators={getOperators} />
                    </Grid>
                    <Grid item>
                        <Tooltip onClick={navigateToIds} title={`Click here to lookup classification ids`}><HelpOutlineOutlined color="primary" /></Tooltip>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container spacing={1} alignItems='center' alignContent='center'>
                    <Grid item>
                        <TextField
                            required
                            label="Threshold"
                            type="number"
                            value={formikProps.values.activityLogQuerySuppression.threshold}
                            name="activityLogQuerySuppression.threshold"
                            onChange={e => { formikProps.handleChange(e); }}
                            onBlur={formikProps.handleBlur}
                            error={formikProps.errors?.activityLogQuerySuppression?.threshold && formikProps.touched?.activityLogQuerySuppression?.threshold}
                            helperText={formikProps.touched?.activityLogQuerySuppression?.threshold && formikProps.errors?.activityLogQuerySuppression?.threshold}
                            InputProps={{
                                inputProps: {
                                    min: 0
                                }
                            }} />
                    </Grid>
                    <Grid item>
                        <Tooltip title={`A contact will be suppressed if the activity log count matching the above query is at least ${formikProps.values.activityLogQuerySuppression.threshold}.`}>
                            <InfoOutlined color='primary' />
                        </Tooltip>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

import { FormControlLabel, Grid, Switch, Box, Checkbox, MenuItem, Select, InputLabel, TextField, FormControl, FormHelperText } from "@mui/material";;

export default function C2ACallMe(props) {

	const { formikProps, outboundSkills, ivrScripts } = props;

	return (
		<Grid container direction="column" spacing={2}>
			<Grid item>
				<Grid container spacing={2} alignContent='center' alignItems='center'>
					<Grid item>
						{(formikProps.values.c2a?.callMeNowEnabled ?? false) &&
							<FormControlLabel
								control={<Checkbox checked={formikProps.values.c2a?.callMeNow?.finalize ?? false} onChange={formikProps.handleChange} onBlur={formikProps.handleBlur} name="c2a.callMeNow.finalize" color="primary" />}
								label="Finalize Call Me C2A"
							/>}
					</Grid>
				</Grid>
			</Grid>
			{(formikProps.values.c2a?.callMeNowEnabled ?? false) &&
				<Box display="grid" gridTemplateColumns="1fr" gap={2}>
					<Box display="grid" gridTemplateColumns="1fr 1fr" gap={2}>
						<FormControl>
							<InputLabel error={formikProps.errors.c2a?.callMeNow?.skillId && formikProps.touched?.c2a?.callMeNow?.skillId}>Outbound Skill</InputLabel>
							<Select
								label="Outbound Skill"
								name="c2a.callMeNow.skillId"
								value={formikProps.values.c2a?.callMeNow?.skillId ?? ''}
								onBlur={formikProps.handleBlur}
								onChange={formikProps.handleChange}
								error={formikProps.errors.c2a?.callMeNow?.skillId && formikProps.touched?.c2a?.callMeNow?.skillId}
							>
								{outboundSkills.map(skill =>
									<MenuItem key={skill.skillId} value={skill.skillId}>{skill.skillName}</MenuItem>)}
							</Select>
							{formikProps.touched.c2a?.callMeNow?.skillId && formikProps.errors.c2a?.callMeNow?.skillId &&
								<FormHelperText error>{formikProps.errors.c2a?.callMeNow?.skillId}</FormHelperText>}
						</FormControl>
						<FormControl>
							<InputLabel error={formikProps.errors.c2a?.callMeNow?.ivrScript && formikProps.touched?.c2a?.callMeNow?.ivrScript}>IVR Script</InputLabel>
							<Select
								label="IVR Script"
								name="c2a.callMeNow.ivrScript"
								value={formikProps.values.c2a?.callMeNow?.ivrScript ?? ''}
								onBlur={formikProps.handleBlur}
								onChange={formikProps.handleChange}
								error={formikProps.errors.c2a?.callMeNow?.ivrScript && formikProps.touched?.c2a?.callMeNow?.ivrScript}
							>
								{ivrScripts.map(script =>
									<MenuItem key={script.scriptId} value={script.scriptName}>{script.scriptName}</MenuItem>)}
							</Select>
							{formikProps.touched.c2a?.callMeNow?.ivrScript && formikProps.errors.c2a?.callMeNow?.ivrScript &&
								<FormHelperText error>{formikProps.errors.c2a?.callMeNow?.ivrScript}</FormHelperText>}
						</FormControl>
					</Box>
						<TextField
							margin="dense"
							label="Message"
							name="c2a.callMeNow.text"
							type="text"
							variant="outlined"
							multiline
							rows={12}
							value={formikProps.values.c2a?.callMeNow?.text ?? ''}
							onChange={formikProps.handleChange}
							onBlur={formikProps.handleBlur}
							error={formikProps.errors.c2a?.callMeNow?.text && formikProps.touched?.c2a?.callMeNow?.text}
							helperText={formikProps.touched.c2a?.callMeNow?.text && formikProps.errors.c2a?.callMeNow?.text}
						/>
				</Box>}
		</Grid>
	);
}

import { useEffect, useState, useContext } from 'react';
import { TextField, Button, CircularProgress, Card, CardHeader, CardActions, Box, CardContent, useMediaQuery } from '@mui/material';
import { UserContext } from '../../contexts/UserContext';
import { enqueueSnackbar } from 'notistack';
import { Formik } from 'formik';
import { cxOneValidation } from '../yupValidations';
import { updateTenantSettings } from '../../graphql/mutations';
import { getTenantSettings } from '../../graphql/queries';
import { generateClient, post } from 'aws-amplify/api';
import { fetchAuthSession } from 'aws-amplify/auth';
import { cardStyle } from 'src/theme';

export function NICE() {
	const client = generateClient();

	const useSingleColumn = useMediaQuery('(max-width:845px)');

	const [settings, setSettings] = useState({
		businessUnit: '',
		accessKey: '',
		appSecret: '',
		authTokenUrl: '',
	});
	const userContext = useContext(UserContext);
	const [testing, setTesting] = useState(false);
	const [saving, setSaving] = useState(false);
	const [connectionSucceeded, setConnectionSucceeded] = useState(false);


	useEffect(() => {
		async function getData() {
			try {
				const graphqlResponse = await client.graphql({
					query: getTenantSettings,
					variables: { id: userContext.tenantId },
				});
				const tenantSettings = graphqlResponse.data.getTenantSettings;
				if (tenantSettings.telephoneProviderInfo && tenantSettings.telephoneProviderInfo.incontact) {
					setSettings({
						businessUnit: tenantSettings.telephoneProviderInfo.incontact.icBU,
						accessKey: tenantSettings.telephoneProviderInfo.incontact.icAccessKey,
						appSecret: tenantSettings.telephoneProviderInfo.incontact.icAppSecret,
						authTokenUrl: tenantSettings.telephoneProviderInfo.incontact.icGetAuthTokenURL,
					});
				}
			} catch (err) {
				console.error(err);
			}
		}
		if (userContext.tenantId) {
			getData();
		}
	}, [userContext.tenantId]);

	const testCXOneConnection = (values) => async () => {
		const tenantSettings = {
			id: userContext.tenantId, // This really shouldn't be needed...
			icBU: values.businessUnit,
			icAccessKey: values.accessKey,
			icAppSecret: values.appSecret,
			icGetAuthTokenURL: values.authTokenUrl,
		};
		try {
			setTesting(true);
			setConnectionSucceeded(false);
			await post({
				apiName: 'cdyxoutreach',
				path: '/cci/incontact/niclogin/test',
				options: {
					body: {
						tenantSettings,
					},
					headers: {
						Authorization: `Bearer ${(await fetchAuthSession()).tokens.idToken
							}`,
						'x-api-key': userContext.apiKey,
					},
				},
			}).response;

			enqueueSnackbar('Successfully authenticated with NICE CXone. Please save your configuration values.', {
				variant: 'success',
				autoHideDuration: 5000,
			});
			setConnectionSucceeded(true);
			setTesting(false);
		} catch (err) {
			enqueueSnackbar(
				'Unable to authenticate with NICE CXone. Please check your connection settings.',
				{
					variant: 'error',
					autoHideDuration: 5000,
				},
			);
			setTesting(false);
			setConnectionSucceeded(false);
		}
	};

	return (
		<UserContext.Consumer>
			{({ tenant }) => (
				<Formik
					enableReinitialize
					validateOnMount={true}
					initialValues={settings}
					validationSchema={cxOneValidation}
					onSubmit={async (values) => {
						setSaving(true);
						try {
							const newSettings = { ...values };
							setSettings(newSettings);
							const nicConfig = {
								id: tenant,
								telephonyProvider: 'NiC',
								telephoneProviderInfo: {
									nice: {
										businessUnit: newSettings.businessUnit,
										authTokenUrl: newSettings.authTokenUrl,
										accessKey: newSettings.accessKey,
										appSecret: newSettings.appSecret,
									},
								},
							};
							for (const key in nicConfig) {
								nicConfig[key] = nicConfig[key] || null;
							}
							console.log(nicConfig);
							delete nicConfig.createdAt;
							delete nicConfig.updatedAt;

							// Remove any other set providers.
							delete nicConfig.telephoneProviderInfo.webex;
							await client.graphql({
								query: updateTenantSettings,
								variables: { input: nicConfig },
							});
							setSaving(false);
							enqueueSnackbar('Your NICE CXone settings have been saved.', {
								variant: 'success',
								autoHideDuration: 5000,
							});
						} catch (err) {
							setSaving(false);
							enqueueSnackbar('An unexpected error occurred while saving your NICE CXone settings. Please make a note of your values and try again later. ', {
								variant: 'error',
								autoHideDuration: 5000,
							});
						}
					}}
				>
					{(formikProps) => (
						<Card
							style={cardStyle}
							elevation={0}
						>
							<CardHeader title="NICE CXone" />
							<CardContent>
								<form key="cxOne" autoComplete="off" onSubmit={formikProps.handleSubmit}>
									<Box
										display="grid"
										gridTemplateColumns={useSingleColumn ? "1fr" : "1fr 1fr"}
										gap="20px"
									>

										{/* Business Unit */}
										<TextField
											disabled={testing}
											color="primary"
											name="businessUnit"
											placeholder="1234567"
											label="Business Unit"
											type="number"
											onChange={formikProps.handleChange}
											onBlur={formikProps.handleBlur}
											value={formikProps.values.businessUnit}
											error={
												formikProps.errors.businessUnit &&
												formikProps.touched.businessUnit
											}
											helperText={
												formikProps.errors.businessUnit &&
													formikProps.touched.businessUnit ? formikProps.errors.businessUnit :
													'The unit number for your CXone business unit'
											}
										/>

										{/* Access Key */}
										<TextField
											disabled={testing}
											color="primary"
											name="accessKey"
											label="Access Key"
											type="text"
											onChange={formikProps.handleChange}
											onBlur={formikProps.handleBlur}
											value={formikProps.values.accessKey}
											error={
												formikProps.errors.accessKey &&
												formikProps.touched.accessKey
											}
											helperText={
												formikProps.errors.accessKey &&
													formikProps.touched.accessKey ? formikProps.errors.accessKey :
													'The access key for your CXone account'
											}
										/>

										{/* Application Secret */}
										<TextField
											disabled={testing}
											color="primary"
											name="appSecret"
											label="Application Secret"
											type="password"
											autoComplete="off"
											onChange={formikProps.handleChange}
											onBlur={formikProps.handleBlur}
											value={formikProps.values.appSecret}
											error={
												formikProps.errors.appSecret &&
												formikProps.touched.appSecret
											}
											helperText={
												formikProps.errors.appSecret &&
													formikProps.touched.appSecret ? formikProps.errors.appSecret :
													'The application secret for your CXone account'
											}
										/>

										{/* Auth Token URL */}
										<TextField
											disabled={testing}
											color="primary"
											name="authTokenUrl"
											label="Auth Token URL"
											placeholder="https://example.com/auth/token"
											type="text"
											onChange={formikProps.handleChange}
											onBlur={formikProps.handleBlur}
											value={formikProps.values.authTokenUrl}
											error={
												formikProps.errors.authTokenUrl &&
												formikProps.touched.authTokenUrl
											}
											helperText={
												formikProps.errors.authTokenUrl &&
													formikProps.touched.authTokenUrl ? formikProps.errors.authTokenUrl :
													'Optional. Supply a URL to override the default CXone auth token URL'
											}
										/>
									</Box>
								</form>
							</CardContent>
							<CardActions>
								<Box
									width="100%"
									display="flex"
									flexDirection="row"
									justifyContent="end"
									alignItems="center"
								>
									{(testing || saving) && (
										<CircularProgress
											color="primary"
											size={24}
										/>
									)}
									<Button
										color="primary"
										disabled={!formikProps.isValid || testing || saving}
										onClick={testCXOneConnection(formikProps.values)}
									>
										{testing ? 'Testing Connection...' : 'Test Connection'}
									</Button>
									<Button
										disabled={!formikProps.isValid || !connectionSucceeded || testing || saving}
										type="submit"
										color="primary"
									>
										{saving ? 'Saving...' : 'Save'}
									</Button>
								</Box>
							</CardActions>
						</Card>
					)}
				</Formik>
			)}
		</UserContext.Consumer>
	);
}

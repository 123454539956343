import { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';

/**
 * @typedef {Object} Props
 * @property {Function} onError The function to call when an error occurs.
 * @property {Function} onAgentConnected The function to call when the agent is successfully connected.
 */

/**
 * Displays a dialog to verify the user's account for Amazon Connect.
 *
 * @param {Props} props The props for the component.
 */
export function AmazonConnectDialog(props) {

	/** Whether something is currently being loaded in the page. */
	const [loading, setLoading] = useState(false);

	/**
	 * Attempts to authorize the user with Amazon Connect.
	 */
	useEffect(() => {
		authorizeAmazonConnect();
	}, []);

	/**
	 * Checks if the user is already logged in to Amazon Connect.
	 */
	async function authorizeAmazonConnect() {
		setLoading(true);
		setTimeout(() => {
			props.onAgentConnected();
			setLoading(false);
		}, 1000);
	}

	return (
		<>
			{/* Loading */}
			{loading &&
				<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
					<CircularProgress variant='indeterminate' color='primary'></CircularProgress>
				</div>
			}
		</>
	);
}

import { useEffect, useContext, useState } from 'react';
import { Box } from '@mui/material';
import { EditOutlined, DeleteOutlineOutlined, DoneOutline } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import MaterialTable from '@material-table/core';
import { UserContext } from '../../contexts/UserContext';
import { generateClient } from 'aws-amplify/api';
// import { useSnackbar } from 'notistack';
import { deletePaymentProfile, deleteProfile, deleteSuppression } from '../../graphql/mutations';
import { profileByTenant, listCampaigns, listPaymentProfiles, listSuppressions } from '../../graphql/queries';
import { PageAppBar } from 'src/components/pageAppBar';
import { ConfirmDialog } from 'src/components/confirmDialog/confirmDialog';
import { SuppressionDefaults } from 'src/models/suppressionResults';

export function SuppressionProfiles() {
	const client = generateClient();
	// const classes = useStyles();
	const userContext = useContext(UserContext);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const [profiles, setProfiles] = useState([]);
	const [toDelete, setToDelete] = useState(null);
	const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

	useEffect(() => {
		async function getData() {
			setLoading(true);
			const savedProfiles = await client.graphql({
				query: listSuppressions,
				variables: { tenant: userContext.tenantId },
			});
			console.log(savedProfiles);
			if (savedProfiles && savedProfiles.data) {
				setProfiles(
					savedProfiles.data.listSuppressions.items.sort(
						(a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt),
					),
				);
			}
			setLoading(false);
		}
		if (userContext.tenantId) {
			getData();
		}
	}, [userContext.tenantId]);

	async function handleDelete() {
		setLoading(true);
		const deleteResult = await client.graphql({
			query: deleteSuppression,
			variables: { input: { id: toDelete } },
		});
		console.log(deleteResult);
		//if profile is successful in deleting remove it from the current list.
		if (deleteResult.data) {
			const deletedProfile = deleteResult.data.deleteSuppression;
			const curProfiles = [...profiles];
			curProfiles.splice(
				curProfiles.findIndex((e) => e.id === deletedProfile.id),
				1,
			);
			setProfiles([...curProfiles]);
		}

		setLoading(false);
	}

	function handleCancelDelete() {
		setToDelete(null);
		setConfirmDeleteOpen(false);
	}

	return (
		<Box>
			<PageAppBar
				title="Suppression Profiles"
				description="Set up suppression profiles"
				actionOneText="+ Profile"
				actionOneHandler={() => {
					navigate('/suppression/new');
				}}
			/>
			<MaterialTable
				components={{
					Container: (props) => <Box {...props} elevation={0} />,
				}}
				title=""
				data={profiles}
				columns={[
					{ title: "Name", field: "name" },
					{
							title: "Activity",
							field: "activityLogQuerySuppressionEnabled",
							render: rowData => rowData.activityLogQuerySuppressionEnabled ?
							<DoneOutline fontSize="small" color="primary"/> : null
					},
					{
							title: "Point of Contact",
							field: "pocQuerySuppressionEnabled",
							render: rowData => rowData.pocQuerySuppressionEnabled ? <DoneOutline fontSize="small" color="primary"/> : null
					},
					{
							title: "REST",
							field: "restSuppressionEnabled",
							render: rowData => rowData.restSuppressionEnabled ? <DoneOutline fontSize="small" color="primary"/> : null
					},
					{
							title: "DNC Lists",
							field: "dncListSuppressionEnabled",
							render: rowData => rowData.dncListSuppressionEnabled ? <DoneOutline fontSize="small" color="primary"/> : null
					},
					{
							title: "Contact Field Suppression",
							field: "contactFieldSuppressionEnabled",
							render: rowData => rowData.contactFieldSuppressionEnabled ? <DoneOutline fontSize="small" color="primary"/> : null
					},
					{
							title: "DNC.com",
							field: "dncDotComEnabled",
							render: rowData => rowData.dncDotComEnabled ? <DoneOutline fontSize="small" color="primary"/> : null
					},
					{ title: "Result", field: "result", lookup: SuppressionDefaults.reduce((acc, cur) => { acc[cur.dynamo] = cur.display; return acc; }, {})}
			]}
				isLoading={loading}
				options={{
					actionsColumnIndex: -1,
					pageSize: 10,
					searchFieldStyle: {
						marginBottom: '16px',
						marginleft: '-28px',
					},
					headerStyle: {
						borderTop: '1px solid #e0e0e0',
						padding: '16px',
					},
					searchFieldVariant: 'outlined',
					paginationType: 'stepped',
				}}
				actions={[
					{
						icon: () => <EditOutlined color="primary" />,
						tooltip: 'Edit Profile',
						onClick: (event, rowData) => {
							navigate(`/suppression/${rowData.id}`);
						},
					},
					{
						icon: () => <DeleteOutlineOutlined color="primary" />,
						tooltip: 'Delete Profile',
						onClick: (event, rowData) => {
							setToDelete(rowData.id);
							setConfirmDeleteOpen(true);
						},
					},
				]}
				onRowClick={(event, rowData) => {
					navigate(`/suppression/${rowData.id}`);
				}}
			/>
			<ConfirmDialog
				open={confirmDeleteOpen}
				title="Delete Profile"
				description="Are you sure you want to delete this profile?"
				actionOneText="Delete"
				actionOneHandler={() => {
					handleDelete();
				}}
				actionTwoText="Cancel"
				actionTwoHandler={() => {
					handleCancelDelete();
				}}
			/>
		</Box>
	);
}

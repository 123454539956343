/**
 * Splits a simple 24-hour time string into the corresponding hour and minute parts.
 * @param {String} time The 24-hour time string.
 * @returns {{ hour: Number, minute: Number }} An object with the hour and minute parts of the input time.
 */
export function timeToCallWindow(time) {
	const units = time.split(':');
	return {
		hour: Number(units[0]),
		minute: Number(units[1]),
	};
}

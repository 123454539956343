import { useEffect, useContext, useState } from 'react';
import { Box } from '@mui/material';
import { EditOutlined, DeleteOutlineOutlined, ControlPointDuplicateOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import MaterialTable from '@material-table/core';
import { UserContext } from '../../contexts/UserContext';
import { generateClient } from 'aws-amplify/api';
// import { useSnackbar } from 'notistack';
import { deleteProfile, deleteSegment } from '../../graphql/mutations';
import {  listCampaigns, segmentByTenant } from '../../graphql/queries';
import { PageAppBar } from 'src/components/pageAppBar';
import { ConfirmDialog } from 'src/components/confirmDialog/confirmDialog';

export function Segments() {
	const client = generateClient();
	// const classes = useStyles();
	const userContext = useContext(UserContext);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const [segments, setSegments] = useState([]);
	const [toDelete, setToDelete] = useState(null);
	const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

	useEffect(() => {
		async function getData() {
			setLoading(true);
			const savedSegments = await client.graphql({
				query: segmentByTenant,
				variables: { tenant: userContext.tenantId },
			});
			console.log(savedSegments);
			if (savedSegments && savedSegments.data) {
				setSegments(
					savedSegments.data.segmentByTenant.items.sort(
						(a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt),
					),
				);
			}
			setLoading(false);
		}
		if (userContext.tenantId) {
			getData();
		}
	}, [userContext.tenantId]);

	async function handleDelete() {
		setLoading(true);
		const deleteResult = await client.graphql({
			query: deleteSegment,
			variables: { input: { id: toDelete } },
		});
		console.log(deleteResult);
		//if segment is successful in deleting remove it from the current list.
		if (deleteResult.data) {
			const deletedSegment = deleteResult.data.deleteSegment;
			const curSegments= [...segments];
			curSegments.splice(
				curSegments.findIndex((e) => e.id === deletedSegment.id),
				1,
			);
			setSegments([...curSegments]);
		}

		setLoading(false);
		setToDelete(null);
		setConfirmDeleteOpen(false);
	}

	function handleCancelDelete() {
		setToDelete(null);
		setConfirmDeleteOpen(false);
	}

	function handleSearchChange(value) {
		window.sessionStorage.setItem('SegmentsSearch', value);
	}

	return (
		<Box>
			<PageAppBar
				title="Segments"
				description="Build segments to target specific groups of contacts."
				actionOneText="+ Segment"
				actionOneHandler={() => {
					navigate('/segments/new');
				}}
			/>
			<MaterialTable
				components={{
					Container: (props) => <Box {...props} elevation={0} />,
				}}
				title=""
				data={segments}
				columns={[
					{ title: 'Name', field: 'name' },
					{ title: 'Description', field: 'description' },
					{ title: "Suppression Profile", field: "suppression.name" }
			]}
				isLoading={loading}
				options={{
					actionsColumnIndex: -1,
					pageSize: 10,
					searchText: window.sessionStorage.getItem('SegmentsSearch'),
					searchFieldStyle: {
						marginBottom: '16px',
						marginleft: '-28px',
					},
					headerStyle: {
						borderTop: '1px solid #e0e0e0',
						padding: '16px',
					},
					searchFieldVariant: 'outlined',
					paginationType: 'stepped',
				}}
				onSearchChange={handleSearchChange}
				actions={[
					{
						icon: () => <EditOutlined color="primary" />,
						tooltip: 'Edit Segment',
						onClick: (event, rowData) => {
							navigate(`/segments/${rowData.id}?op=edit`);
						},
					},
					{
						icon: () => <ControlPointDuplicateOutlined color="primary" />,
						tooltip: 'Clone Segment',
						onClick: (event, rowData) => {
							navigate(`/segments/${rowData.id}?op=clone`);
						},
					},
					{
						icon: () => <DeleteOutlineOutlined color="primary" />,
						tooltip: 'Delete Segment',
						onClick: (event, rowData) => {
							setToDelete(rowData.id);
							setConfirmDeleteOpen(true);
						},
					},
				]}
				onRowClick={(event, rowData) => {
					navigate(`/segments/${rowData.id}?op=edit`);
				}}
			/>
			<ConfirmDialog
				open={confirmDeleteOpen}
				title="Delete Segment"
				description="Are you sure you want to delete this segment?"
				actionOneText="Delete"
				actionOneHandler={() => {
					handleDelete();
				}}
				actionTwoText="Cancel"
				actionTwoHandler={() => {
					handleCancelDelete();
				}}
			/>
		</Box>
	);
}

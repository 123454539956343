import React, { useRef, useEffect } from 'react';
// import { makeStyles } from '@mui/material';
import { CAMPAIGN_COLOR_1, CAMPAIGN_COLOR_2 } from '../../models/campaignColors';

/** The number of times per second that the game is updated under the hood. */
const GAME_UPDATE_FPS = 60;

const FONT_SIZE = 14;

const MINIMUM_BUBBLE_RADIUS = 20;

const MAXIMUM_BUBBLE_RADIUS = 60;

const BUBBLE_MOVEMENT_SPEED = .5;

/** The rate at which bubbles grow from their smallest state. */
const BUBBLE_GROWTH_SPEED = .33;

/** The rate at which bubbles fade in from their spawn state. */
const BUBBLE_FADE_IN_SPEED = .01;

/** The maximum number of bubbles that can be displayed at any given moment. */
const MAXIMUM_BUBBLES = 1500;

/** The rate at which popped bubbles fade out. */
const POPPED_BUBBLE_FADE_OUT_SPEED = 0.02;

/** The maximum length of the popped bubble lines. */
const POPPED_BUBBLE_LINE_LENGTH_MAX = 20;

/** The speed at which the popped bubble lines move. */
const POPPED_BUBBLE_LINE_SPEED = 3;

/** The speed at which the popped bubble lines grow or shrink. */
const POPPED_BUBBLE_LINE_GROWTH_SPEED = 1;

/** The width of the popped bubble lines. */
const POPPED_BUBBLE_LINE_WIDTH = 4;

/** The number of pixels to adjust the diagonal lines by in order to create a more circular shape. */
const DIAGONAL_ADJUSTMENT = 1.5;

/** The eight cardinal directions (n, ne, e, etc.). */
const DIRECTIONS = ['n', 'ne', 'e', 'se', 's', 'sw', 'w', 'nw'];

// const useStyles = makeStyles(theme => ({
//     canvas: {
//         // background: 'url(\'/bblbg.png\')',
//         background:
//             theme.palette.type === 'dark' ?
//                 'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,.4) 100%)' :
//                 'linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(200,200,200,.4) 100%)',
//         backgroundSize: '100% 100%',
//         width: '100%',
//         borderRadius: theme.shape.borderRadius,
//         animation: '$fadeIn 0.5s',
//     },
//     '@keyframes fadeIn': {
//         '0%': {
//             opacity: 0,
//         },
//         '100%': {
//             opacity: 1,
//         },
//     },
// }));

/**
 * Represents all info about a popped bubble.
 */
class PoppedBubble {
    /** The x coordinate of where the bubble was popped. */
    x = 0;

    /** The y coordinate of where the bubble was popped. */
    y = 0;

    /** The color of the bubble. */
    color = '';

    /** The opacity of the bubble. */
    opacity = 1;

    /** The length of the popped bubble lines. */
    length = 1;

    /** Whether the popped bubble lines are growing or shrinking. */
    growing = true;

    /**
     * The popped bubble lines for the animation.
     * @type {Array<{ startX: number, startY: number, endX: number, endY: number, direction: string }>}
     */
    lines = [];

    /** Whether the popped bubble is still visible. */
    get isVisible() {
        return this.opacity > 0 && this.length > 0;
    }

    constructor(bubble) {
        this.x = bubble.x;
        this.y = bubble.y;
        this.color = bubble.color;
        for (const direction of DIRECTIONS) {
            this.lines.push({
                startX: bubble.x,
                startY: bubble.y,
                endX: bubble.x,
                endY: bubble.y,
                direction,
            });
        }
    }

    /**
     * Advances the pop animation by one frame. Moves lines further from the center and reduces opacity.
     */
    updatePop() {
        this.opacity -= POPPED_BUBBLE_FADE_OUT_SPEED;
        this.growing = this.growing && this.length < POPPED_BUBBLE_LINE_LENGTH_MAX;
        this.length += this.growing ? POPPED_BUBBLE_LINE_GROWTH_SPEED : -POPPED_BUBBLE_LINE_GROWTH_SPEED;

        // Prevent negative values and stop updating if invisible.
        if (!this.isVisible) {
            this.opacity = 0;
            this.length = 0;
            return;
        }

        // Update each line, moving away from the center.
        this.lines.forEach((line) => {
            const isDiagonal = line.direction.length === 2;
            const adjustedLineLength = isDiagonal ? this.length / DIAGONAL_ADJUSTMENT : this.length;
            const adjustedLineSpeed = isDiagonal ? POPPED_BUBBLE_LINE_SPEED / DIAGONAL_ADJUSTMENT : POPPED_BUBBLE_LINE_SPEED;

            if (line.direction.includes('n')) {
                line.startY -= adjustedLineSpeed;
                line.endY = line.startY - adjustedLineLength;
            } else if (line.direction.includes('s')) {
                line.startY += adjustedLineSpeed;
                line.endY = line.startY + adjustedLineLength;
            }
            if (line.direction.includes('e')) {
                line.startX += adjustedLineSpeed;
                line.endX = line.startX + adjustedLineLength;
            } else if (line.direction.includes('w')) {
                line.startX -= adjustedLineSpeed;
                line.endX = line.startX - adjustedLineLength;
            }
        });
    }
}

/**
 *
 * @param {{
 *   parentElement: React.RefObject<any>,
 *   selectedCampaigns: Campaign[],
 *   selectedChannel: string,
 *   contactsPerCampaign: Map<string, Contact[]>,
 *   onContactClicked: (contact: Contact) => void,
 * }} props
 */
export function BubbleGame(props) {
    const classes = useStyles();

    /** Reference to the canvas element. */
    const canvasRef = useRef();

    /** The list of selected campaigns. */
    const selectedCampaigns = useRef(props.selectedCampaigns);

    /** The list of all bubbles to be drawn. */
    const bubbles = useRef([]);

    /** The list of all popped bubbles to be drawn. */
    const poppedBubbles = useRef([]);

    /** The list of contacts to display in the game. */
    const contactsToDisplay = useRef([]);

    /** The last position of the mouse. Used for the mouse hover state. */
    const lastMousePosition = useRef({ x: 0, y: 0 });

    /** The current width of the canvas. */
    let canvasWidth = 0;

    /** The current height of the canvas. */
    let canvasHeight = 0;

    /** Interval that runs to populate the grid. */
    let loopInterval;

    /** The list of contacts that should not be spawned because they have been clicked. */
    const doNotSpawn = useRef([]);

    /**
     * Sets up the game on initial load.
     */
    useEffect(() => {
        setUpGame();
    }, []);

    /**
     * Destroys the game on unmount.
     */
    useEffect(() => () => {
        clearInterval(loopInterval);
        window.removeEventListener('resize', setCanvasSize);
    }, []);

    /**
     * Recalculates the colors for the bubbles anytime the selected campaigns change.
     */
    useEffect(() => {
        selectedCampaigns.current = props.selectedCampaigns;
        recalculateColors();
    }, [props.selectedCampaigns]);

    /**
     * Recalculates the contacts to display anytime the contacts change.
     */
    useEffect(() => {
        calculateContactsToDisplay(props.contactsPerCampaign);
    }, [props.contactsPerCampaign]);

    /**
     * Sets the game to its initial state.
     */
    function setUpGame() {
        setCanvasSize();
        canvasRef.current.addEventListener('click', handleClick);
        canvasRef.current.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('resize', setCanvasSize);

        // Draw and loop the game
        loopInterval = setInterval(() => {
            runGameLogic();
        }, 1000 / GAME_UPDATE_FPS);
    }

    /**
     * Sets the canvas size based on the parent element size. Also accounts for high DPI screens.
     *
     * NOTE: This this will run on every resize event, so don't do anything too expensive here!
     */
    function setCanvasSize() {
        canvasWidth = props.parentElement.current.clientWidth;
        canvasHeight = props.parentElement.current.clientHeight;
        const devicePixelRatio = window.devicePixelRatio || 1;
        canvasRef.current.width = canvasWidth * devicePixelRatio;
        canvasRef.current.height = canvasHeight * devicePixelRatio;
        const canvasContext = canvasRef.current.getContext('2d');
        canvasContext.scale(devicePixelRatio, devicePixelRatio);
        window.requestAnimationFrame(drawBubbles);
    }

    /**
     * Calculates the list of contacts to display in the game based on the selected campaigns.
     */
    function calculateContactsToDisplay(contactsPerCampaign) {
        const numberOfSelectedCampaigns = selectedCampaigns.current.length;
        const newContactsToDisplay = [];

        // No need to calculate contacts if there are no selected campaigns
        if (numberOfSelectedCampaigns === 0) {
            contactsToDisplay.current = [];
            return;
        }

        // Round robin through the selected campaigns to get the contacts to display
        const campaignIdsToSkip = [];
        for (let i = 0; i < MAXIMUM_BUBBLES; i++) {
            const campaignIndex = i % numberOfSelectedCampaigns;
            const campaignId = selectedCampaigns.current[campaignIndex].id;
            const contactIndex = Math.floor(i / numberOfSelectedCampaigns);

            // If a campaign doesn't have any contacts left to display, skip contacts from this campaign and retry the current index.
            if (contactsPerCampaign.get(campaignId).length <= contactIndex && !campaignIdsToSkip.includes(campaignId)) {
                campaignIdsToSkip.push(campaignId);
                i--;
                continue;
            }
            const contactToPush = contactsPerCampaign.get(campaignId)[contactIndex];
            if (contactToPush) {
                newContactsToDisplay.push(contactToPush);
            }
        }
        contactsToDisplay.current = newContactsToDisplay;
    }

    /**
     * Updates the game state and attempts to draw the bubbles.
     */
    function runGameLogic() {
        removeInvisiblePoppedBubbles();
        removeBubblesOutOfView();
        removeOldBubbles();
        spawnNewBubbles();
        updateBubbles();

        // Attempt to draw after each update (60 FPS). This allows us to update the bubbles consistently at a different rate than the display.
        window.requestAnimationFrame(drawBubbles);
    }

    /**
     * Removes any popped bubbles that are no longer visible so that we don't update them.
     */
    function removeInvisiblePoppedBubbles() {
        poppedBubbles.current = poppedBubbles.current.filter(poppedBubble => poppedBubble.isVisible);
    }

    /**
     * Checks if any bubbles need to be re-spawned because they have gone out of view.
     */
    function removeBubblesOutOfView() {
        const bubblesOutOfView = bubbles.current.filter((bubble) => {
            return bubble.y - bubble.radius > canvasHeight ||
                bubble.y + bubble.radius < 0 ||
                bubble.x - bubble.radius > canvasWidth ||
                bubble.x + bubble.radius < 0;
        });

        bubblesOutOfView.forEach((bubble) => {
            // Remove these bubbles from the list, and add them to spawn.
            const indexOfBubble = bubbles.current.indexOf(bubble);
            contactsToDisplay.current.unshift(bubbles.current.splice(indexOfBubble, 1)[0].contact);
        });
    }

    /**
     * Removes any old bubbles that don't exist in the contacts to display list. This can happen because they were either
     * clicked or the campaign was changed.
     */
    function removeOldBubbles() {
        bubbles.current = bubbles.current.filter((bubble) => {
            return contactsToDisplay.current.some((contact) => contact.id === bubble.contact.id);
        });
    }

    /**
     * Spawns new bubbles if there are available contacts and the maximum number of bubbles has not been reached.
     */
    function spawnNewBubbles() {
        for (const contactToSpawn of contactsToDisplay.current) {
            // Remove and do not spawn if already spawned or in do not spawn.
            const isAlreadySpawned = bubbles.current.some(bubble => bubble.contact.id === contactToSpawn.id);
            const isInDoNotSpawn = doNotSpawn.current.some(contact => contact.id === contactToSpawn.id);
            if (isAlreadySpawned || isInDoNotSpawn) {
                continue; // Ignore this contact.
            }

            // Don't spawn if we have too many bubbles.
            if (bubbles.current.length >= MAXIMUM_BUBBLES) {
                return;
            }

            // Spawn a new bubble
            const newBubble = {
                radius: MINIMUM_BUBBLE_RADIUS,
                contact: contactToSpawn,
                x: getRandomInteger(MINIMUM_BUBBLE_RADIUS, canvasWidth, - MINIMUM_BUBBLE_RADIUS),
                y: getRandomInteger(MINIMUM_BUBBLE_RADIUS, canvasHeight - MINIMUM_BUBBLE_RADIUS),
                direction: getRandomDirection(),
                color: getCampaignColor(contactToSpawn.campaignId),
                growing: true,
                opacity: 0,
            };
            bubbles.current.push(newBubble);
        }
    }

    /**
     * Updates the bubble movement and size.
     */
    function updateBubbles() {
        bubbles.current.forEach(bubble => {
            // Update bubble fade in.
            if (bubble.opacity < 1) {
                bubble.opacity += BUBBLE_FADE_IN_SPEED;
            } else {
                bubble.opacity = 1;
            }

            // Move the bubble.
            if (bubble.direction.includes('n')) {
                bubble.y -= BUBBLE_MOVEMENT_SPEED;
            } else if (bubble.direction.includes('s')) {
                bubble.y += BUBBLE_MOVEMENT_SPEED;
            }
            if (bubble.direction.includes('e')) {
                bubble.x += BUBBLE_MOVEMENT_SPEED;
            } else if (bubble.direction.includes('w')) {
                bubble.x -= BUBBLE_MOVEMENT_SPEED;
            }

            // Grow or shrink the bubble.
            if (bubble.radius <= MINIMUM_BUBBLE_RADIUS) {
                bubble.growing = true;
            } else if (bubble.radius >= MAXIMUM_BUBBLE_RADIUS) {
                bubble.growing = false;
            }
            if (bubble.growing) {
                bubble.radius += BUBBLE_GROWTH_SPEED;
            } else {
                bubble.radius -= BUBBLE_GROWTH_SPEED;
            }
        });

        poppedBubbles.current.forEach(poppedBubble => {
            poppedBubble.updatePop();
        });
    }

    /**
     * Clears the canvas and draws all of the bubbles.
     */
    function drawBubbles() {
        if (!canvasRef.current) {
            return;
        }
        canvasRef.current.getContext('2d').clearRect(0, 0, canvasWidth, canvasHeight);

        bubbles.current.forEach((bubble) => {
            drawBubble(bubble);
        });

        poppedBubbles.current.forEach((poppedBubble) => {
            drawPoppedBubble(poppedBubble);
        });
    }

    /**
     * Draws a single bubble on the canvas.
     *
     * @param {Bubble} bubble The bubble to draw.
    */
    function drawBubble(bubble) {
        /** @type {CanvasRenderingContext2D} */
        const canvasContext = canvasRef.current.getContext('2d');
        const isDarkTheme = localStorage.getItem('previouslySelectedTheme').includes('dark');

        // Handle hover effect
        const mousePosition = lastMousePosition.current;
        const hoveredBubble = getBubbleAtCoordinates(mousePosition.x, mousePosition.y);
        canvasRef.current.style.cursor = hoveredBubble ? 'pointer' : 'default';
        if (hoveredBubble && hoveredBubble.contact.id === bubble.contact.id) {
            bubble.radius = MAXIMUM_BUBBLE_RADIUS;
            bubble.opacity = 1;
        }

        // Draw bubble
        canvasContext.globalAlpha = bubble.opacity;
        canvasContext.beginPath();
        canvasContext.moveTo(bubble.x, bubble.y);
        canvasContext.arc(bubble.x, bubble.y, bubble.radius, 0, 2 * Math.PI);
        const gradient = canvasContext.createRadialGradient(bubble.x - 10, bubble.y - 10, bubble.radius - 15, bubble.x, bubble.y, bubble.radius);
        gradient.addColorStop(0, isDarkTheme ? 'transparent' : 'white');
        gradient.addColorStop(1, bubble.color);
        canvasContext.fillStyle = gradient;
        canvasContext.fill();

        // Draw bubble text
        canvasContext.font = `bold ${FONT_SIZE}px Arial`;
        canvasContext.fillStyle = isDarkTheme ? 'white' : 'black';
        canvasContext.textAlign = 'center';
        canvasContext.fillText(`${bubble.contact.firstName} ${bubble.contact.lastName}`, bubble.x, bubble.y, bubble.radius * 1.75);
        canvasContext.font = `${FONT_SIZE}px Arial`;
        const contactPhoneNumber = props.selectedChannel === 'sms' && !!bubble.contact.cell ? bubble.contact.cell : bubble.contact.phone;
        canvasContext.fillText(`${getFormattedPhoneNumber(contactPhoneNumber)}`, bubble.x, bubble.y + FONT_SIZE, bubble.radius * 1.75);
    }

    /**
     * Draws a single popped bubble animation on the canvas.
     *
     * @param {PoppedBubble} poppedBubble The popped bubble to draw.
     */
    function drawPoppedBubble(poppedBubble) {
        /** @type {CanvasRenderingContext2D} */
        const canvasContext = canvasRef.current.getContext('2d');

        poppedBubble.lines.forEach(line => {
            canvasContext.globalAlpha = poppedBubble.opacity;
            canvasContext.beginPath();
            canvasContext.lineWidth = POPPED_BUBBLE_LINE_WIDTH;
            canvasContext.strokeStyle = poppedBubble.color;
            canvasContext.moveTo(line.startX, line.startY);
            canvasContext.lineTo(line.endX, line.endY);
            canvasContext.stroke();
        });
    }

    /**
     * Recalculates the colors for the bubbles based on the campaigns.
     */
    function recalculateColors() {
        bubbles.current.forEach((bubble) => {
            bubble.color = getCampaignColor(bubble.contact.campaignId);
        });
    }

    /**
     * Gets a random integer between the given min and max values.
     *
     * @param {number} min The minimum value.
     * @param {number} max The maximum value.
     * @returns {number} A random integer.
     */
    function getRandomInteger(min, max) {
        return Math.floor(Math.random() * (max - min) + min);
    }

    /**
     * Gets a random cardinal direction represented as a string.
     *
     * @returns {string} One of eight cardinal directions.
     */
    function getRandomDirection() {
        return DIRECTIONS[Math.floor(Math.random() * DIRECTIONS.length)];
    }

    /**
     * Handles a click on the canvas and determines if a call needs to be made.
     *
     * @param {MouseEvent} event The mouse event for the click.
     */
    function handleClick(event) {
        canvasRef.current.style.cursor = 'default';
        const bubbleIndex = getIndexAtCoordinates(event.offsetX, event.offsetY);

        // No bubble was clicked.
        if (bubbleIndex === -1) {
            return;
        }

        doNotSpawn.current.push(bubbles.current[bubbleIndex].contact);
        props.onContactClicked(bubbles.current[bubbleIndex].contact);
        poppedBubbles.current.unshift(new PoppedBubble(bubbles.current[bubbleIndex]));
        bubbles.current.splice(bubbleIndex, 1);
    }

    /**
     * Handles a mouse move event to show a hover effect.
     *
     * NOTE: This event runs on every mouse move within the canvas, so don't do anything too expensive here!
     *
     * @param {MouseEvent} event The mouse event for the move.
     */
    function handleMouseMove(event) {
        lastMousePosition.current = { x: event.offsetX, y: event.offsetY };
    }

    /**
     * Gets the top bubble at the given coordinates.
     *
     * @param {number} x The x coordinate.
     * @param {number} y The y coordinate.
     * @returns {Square} The top bubble at the given coordinates, if one exists.
     */
    function getBubbleAtCoordinates(x, y) {
        return bubbles.current.findLast((bubble) => {
            const xMin = bubble.x - bubble.radius;
            const xMax = bubble.x + bubble.radius;
            const yMin = bubble.y - bubble.radius;
            const yMax = bubble.y + bubble.radius;
            return (x >= xMin) && (x <= xMax) && (y >= yMin) && (y <= yMax);
        });
    }

    /**
     * Gets the index of the bubble at the given coordinates.
     *
     * @param {number} x The x coordinate.
     * @param {number} y The y coordinate.
     * @returns {number} The index of the bubble.
     */
    function getIndexAtCoordinates(x, y) {
        return bubbles.current.findLastIndex((bubble) => {
            const xMin = bubble.x - bubble.radius;
            const xMax = bubble.x + bubble.radius;
            const yMin = bubble.y - bubble.radius;
            const yMax = bubble.y + bubble.radius;
            return (x >= xMin) && (x <= xMax) && (y >= yMin) && (y <= yMax);
        });
    }

    /**
     * Gets the campaign color for the given campaign ID.
     *
     * @param {string} campaignId The campaign ID.
     * @returns {string} The hex color as a string.
     */
    function getCampaignColor(campaignId) {
        const colors = [CAMPAIGN_COLOR_1, CAMPAIGN_COLOR_2, CAMPAIGN_COLOR_3, CAMPAIGN_COLOR_4, CAMPAIGN_COLOR_5, CAMPAIGN_COLOR_6];
        const colorIndex = selectedCampaigns.current.map((campaign) => campaign.id).indexOf(campaignId);
        return colors[colorIndex] ?? 'transparent';
    }

    /**
     * Formats a phone number to be in the format +# (###) ###-####.
     *
     * @param {string} phoneNumber The phone number to format.
     * @returns {string} A formatted phone number string.
     */
    function getFormattedPhoneNumber(phoneNumber) {
        try {
            const cleaned = ('' + phoneNumber).replace(/\D/g, '');
            const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
            const intlCode = (match[1] ? '+1 ' : '');
            return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
        } catch (error) {
            return phoneNumber;
        }
    }

    return (
        <canvas ref={canvasRef} className={classes.canvas} />
    );
}

import { useEffect, useContext, useState } from 'react';
import { Box } from '@mui/material';
import { EditOutlined, DeleteOutlineOutlined, DoneOutline } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import MaterialTable from '@material-table/core';
import { UserContext } from '../../contexts/UserContext';
import { generateClient } from 'aws-amplify/api';
// import { useSnackbar } from 'notistack';
import { deletePaymentProfile, deleteProfile } from '../../graphql/mutations';
import { profileByTenant, listCampaigns, listPaymentProfiles } from '../../graphql/queries';
import { PageAppBar } from 'src/components/pageAppBar';
import { ConfirmDialog } from 'src/components/confirmDialog/confirmDialog';

export function C2AProfiles() {
	const client = generateClient();
	// const classes = useStyles();
	const userContext = useContext(UserContext);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const [profiles, setProfiles] = useState([]);
	const [toDelete, setToDelete] = useState(null);
	const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

	useEffect(() => {
		async function getData() {
			setLoading(true);
			const savedProfiles = await client.graphql({
				query: listPaymentProfiles,
				variables: { tenant: userContext.tenantId },
			});
			console.log(savedProfiles);
			if (savedProfiles && savedProfiles.data) {
				setProfiles(
					savedProfiles.data.listPaymentProfiles.items.sort(
						(a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt),
					),
				);
			}
			setLoading(false);
		}
		if (userContext.tenantId) {
			getData();
		}
	}, [userContext.tenantId]);

	async function handleDelete() {
		setLoading(true);
		const deleteResult = await client.graphql({
			query: deletePaymentProfile,
			variables: { input: { id: toDelete } },
		});
		console.log(deleteResult);
		//if profile is successful in deleting remove it from the current list.
		if (deleteResult.data) {
			const deletedProfile = deleteResult.data.deletePaymentProfile;
			const curProfiles = [...profiles];
			curProfiles.splice(
				curProfiles.findIndex((e) => e.id === deletedProfile.id),
				1,
			);
			setProfiles([...curProfiles]);
		}

		setLoading(false);
	}

	function handleCancelDelete() {
		setToDelete(null);
		setConfirmDeleteOpen(false);
	}

	function handleSearchChange(value) {
		window.sessionStorage.setItem('ProfilesSearch', value);
	}

	return (
		<Box>
			<PageAppBar
				title="Digital Profiles"
				description="Set up Omni Channel Messaging and C2A Actions"
				actionOneText="+ Profile"
				actionOneHandler={() => {
					navigate('/digital-profiles/new');
				}}
			/>
			<MaterialTable
				components={{
					Container: (props) => <Box {...props} elevation={0} />,
				}}
				title=""
				data={profiles}
				columns={[{ title: 'Name', field: 'name' },
				{
					title: 'SMS', render: (rowData) => {
						console.log(rowData)
						if (JSON.parse(rowData.channels).smsEnabled) {
							return <DoneOutline color="primary" />
						}
					}
				},
				{
					title: 'Email', render: (rowData) => {
						console.log(rowData)
						if (JSON.parse(rowData.channels).emailEnabled) {
							return <DoneOutline color="primary" />
						}
					}
				},

				]}
				isLoading={loading}
				options={{
					actionsColumnIndex: -1,
					pageSize: 10,
					searchFieldStyle: {
						marginBottom: '16px',
						marginleft: '-28px',
					},
					headerStyle: {
						borderTop: '1px solid #e0e0e0',
						padding: '16px',
					},
					searchFieldVariant: 'outlined',
					paginationType: 'stepped',
				}}
				onSearchChange={handleSearchChange}
				actions={[
					{
						icon: () => <EditOutlined color="primary" />,
						tooltip: 'Edit Profile',
						onClick: (event, rowData) => {
							navigate(`/digital-profiles/${rowData.id}`);
						},
					},
					{
						icon: () => <DeleteOutlineOutlined color="primary" />,
						tooltip: 'Delete Profile',
						onClick: (event, rowData) => {
							setToDelete(rowData.id);
							setConfirmDeleteOpen(true);
						},
					},
				]}
				onRowClick={(event, rowData) => {
					navigate(`/digital-profiles/${rowData.id}`);
				}}
			/>
			<ConfirmDialog
				open={confirmDeleteOpen}
				title="Delete Profile"
				description="Are you sure you want to delete this profile?"
				actionOneText="Delete"
				actionOneHandler={() => {
					handleDelete();
				}}
				actionTwoText="Cancel"
				actionTwoHandler={() => {
					handleCancelDelete();
				}}
			/>
		</Box>
	);
}

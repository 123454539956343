import { useState, useContext, useEffect } from "react";
import {
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	FormHelperText,
	Box,
	Button,
	DialogContent,
	DialogTitle,
	Dialog,
	DialogActions,
	Typography,
	useMediaQuery,
	Tooltip,
} from "@mui/material";
import {
	DeleteOutlineOutlined,
} from '@mui/icons-material';
import { Formik } from "formik";
import { enqueueSnackbar } from "notistack";
import MaterialTable from '@material-table/core';
import { generateClient } from "aws-amplify/api";
import { tenantCustomDispos } from "src/graphql/queries";
import { createCustomDispoMapping, deleteCustomDispoMapping } from "src/graphql/mutations";
import { fetchAuthSession } from "aws-amplify/auth";
import { mixed, object } from "yup";
import { UserContext } from "src/contexts/UserContext";
import { InContact } from "src/contexts/InContact";
import { Webex } from "src/contexts/Webex";
import { PageAppBar } from "src/components/pageAppBar";
import { ConfirmDialog } from "src/components/confirmDialog/confirmDialog";
import { actionOneButtonStyle, actionTwoButtonStyle } from "src/theme";

export function DispositionMappings() {
	const client = generateClient();
	const userContext = useContext(UserContext);
	const displayAddDispositionsVertical = useMediaQuery('(min-width:700px)');


	// Skills or queues
	const [skills, setSkills] = useState([]);
	const [selectedSkill, setSelectedSkill] = useState();

	const [filteredDispositions, setFilteredDispositions] = useState([]); // Dispositions not already mapped

	const [mappings, setMappings] = useState([]);
	const [filteredMappings, setFilteredMappings] = useState([]); // Filtered by selected skill
	const [selectedMapping, setSelectedMapping] = useState(); // For deletion

	const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
	const [loading, setLoading] = useState(false);

	const [showDispositionMappingDialog, setShowDispositionMappingDialog] = useState(false);

	const [dispoOutcomes, setDispoOutcomes] = useState([]);

	const initialValues = {
		skillId: "",
		customDispo: "",
		outcome: "",
	};

	const customDispoMapping = {
		tenant: "",
		custDispId: 0,
		custDispName: "",
		dispClassId: 0,
		dispCode: 0,
		dispDescription: "",
		dispClassName: "",
		skill: [],
	};

	const outcomes = [
		{
			dispClassId: 1,
			dispCode: 10,
			name: "Positive w/Amount",
			description: "Agent entered a positive amount",
		},
		{
			dispClassId: 2,
			dispCode: 10,
			name: "Positive no Amount",
			description: "Agent does not enter a positive amount",
		},
		{
			dispClassId: 3,
			dispCode: 99,
			name: "Negative — DNC — Skill",
			description: "Contact request to be added to skills DNC",
		},
		{
			dispClassId: 4,
			dispCode: 98,
			name: "Negative — DNC — BU",
			description: "Contact requests to be added to BU DNC",
		},
		{
			dispClassId: 5,
			dispCode: 10,
			name: "Negative",
			description: "Agent received a negative response",
		},
		{
			dispClassId: 6,
			dispCode: 10,
			name: "Other",
			description: "No further effort required- Bad Number responses.",
		},
		{
			dispClassId: 12,
			dispCode: 0,
			name: "Final — Answering Machine",
			description: "Answering machine reached - message left",
		},
		{
			dispClassId: 16,
			dispCode: 6,
			name: "Error",
			description: "Can not reach number, Network Error",
		},
		{
			dispClassId: 18,
			dispCode: 20,
			name: "Invalid Number",
			description: "The number the dialer called was invalid",
		},
		{
			dispClassId: 21,
			dispCode: 22,
			name: "Number Changed",
			description: "The called party number is not longer assigned",
		},
		{
			dispClassId: 22,
			dispCode: 21,
			name: "Disconnect",
			description: "The called party has a disconnected number",
		},
		{
			dispClassId: 13,
			dispCode: 3,
			name: "Fax Machine",
			description: "Agent Determined Number is for a fax machine",
		},
		{
			dispClassId: 14,
			dispCode: 33,
			name: "Answering Machine Left Message",
			description: "Reached Answering Machine - Left Message",
		},
		{
			dispClassId: 11,
			dispCode: 5,
			name: "Retry - Answering Machine",
			description: "Retry record later",
		},
		{
			dispClassId: 10,
			dispCode: 89,
			name: "Retry - Not Available",
			description: "The requested person was unavailable.",
		},
		{
			dispClassId: 32,
			dispCode: 31,
			name: "Called Party Hang Up",
			description: "The contact hung up before being connected to an agent.",
		}
	];

	document.title = 'Disposition Mappings';

	/**
	 * Gets the skills and dispositions
	 */
	useEffect(() => {
		async function getData() {
			let availableSkills = [];
			try {
				switch (userContext.telephonyProvider) {
					case "NiC":
						availableSkills = await InContact.getActivePhoneSkills();
						break;
					case 'Webex':
						outcomes.push({
							dispClassId: 9,
							dispCode: 9,
							name: "Scheduled Callback",
							description: "The contact requested a callback.",
						});
						const bearerToken = (await fetchAuthSession()).tokens.idToken;
						const webexQueues = await Webex.getEntryPoints(bearerToken, userContext.apiKey);
						const webexTranslatedQueues = webexQueues.map((queue) => {
							return {
								skillId: queue.id,
								skillName: queue.name
							};
						});
						availableSkills = webexTranslatedQueues;
						break;
					default:
						console.error(`Unsupported telephony provider: ${userContext.telephonyProvider}`);
						break;
				}
				setSkills(availableSkills.concat().sort(sortBy('skillName')));
			} catch (error) {
				console.error(error);
				enqueueSnackbar("Error loading skills.", {
					variant: "error",
					autoHideDuration: 5000,
				});
			}
			await loadDispositionMappings(availableSkills);
			setDispoOutcomes(outcomes);
		}

		if (userContext.tenantId && userContext.telephonyProvider) {
			getData();
		}
	}, [userContext.tenantId, userContext.telephonyProvider]);

	/**
	 * Filter mappings and dispositions when mappings are loaded.
	 */
	useEffect(() => {
		if (mappings && selectedSkill && filteredDispositions) {
			filterMappings(selectedSkill);
			filterDispositions(selectedSkill);
		}
	}, [mappings]);

	async function loadDispositionMappings(availableSkills) {
		setLoading(true);
		try {
			let allMappings = [];
			let nextToken;
			do {
				const options = {
					tenant: userContext.tenantId
				};
				if (nextToken) {
					options.nextToken = nextToken;
				}
				const listCustomDispositions = await client.graphql({
					query: tenantCustomDispos,
					variables: options
				});
				allMappings = [...allMappings, ...listCustomDispositions.data.tenantCustomDispos.items];
				nextToken = listCustomDispositions.data.tenantCustomDispos.nextToken;
			} while (nextToken);

			for (const mapping of allMappings) {
				mapping.skillName = availableSkills.find(skill => skill.skillId == mapping.skill)?.skillName ?? 'Deleted Skill';
			}
			setMappings(allMappings);
			setLoading(false);
		} catch (error) {
			console.log('Error loading disposition mappings: ', error);
			enqueueSnackbar('Error loading disposition mappings.', {
				variant: 'error',
				autoHideDuration: 5000,
			});
			setLoading(false);
		}
	}

	/**
	 * Filters the mappings that are associated with the selected skill.
	 *
	 * @param {string} skillId The ID of the selected skill.
	 */
	function filterMappings(skillId) {
		// `==` comparison is used here because skillId is a number whereas mapping.skill is a string.
		const filteredMappings = mappings.filter((mapping) => mapping.skill == skillId);
		setFilteredMappings(filteredMappings);
	}

	/**
	 * Filters the dispositions that are not already mapped to the selected skill.
	 *
	 * @param {string} skillId The ID of the selected skill.
	 */
	async function filterDispositions(skillId) {
		let allDispositions = [];
		if (userContext.telephonyProvider === 'NiC') {
			allDispositions = await InContact.getCustomDispositions(skillId) ?? [];
		} else if (userContext.telephonyProvider === 'Webex') {
			const token = (await fetchAuthSession()).tokens.idToken;
			const allDispositionsResponse = await Webex.getWrapUpCodes(token, userContext.apiKey);
			allDispositions = await allDispositionsResponse.body.json();
		}
		const filteredDispositions = allDispositions.filter((disposition) =>
			!mappings.find((mapping) => (
				mapping.custDispId === (disposition.dispositionId ?? disposition.dispositionCode ?? disposition.id) &&
				mapping.skill == skillId
			))
		);
		setFilteredDispositions(filteredDispositions);
	}

	/**
	 * Changes the selected skill and filters the mappings and dispositions.
	 *
	 * @param {*} event The event that triggered the change.
	 */
	async function handleSkillChange(event) {
		setSelectedSkill(event.target.value);
		filterMappings(event.target.value);
		filterDispositions(event.target.value);
	}

	/**
	 * Deletes the selected disposition mapping.
	 *
	 * @param {*} mappingId The ID of the mapping to delete.
	 */
	async function deleteDispositionMapping(mappingId) {
		try {
			client.graphql({
				query: deleteCustomDispoMapping,
				variables: {
					input: { id: mappingId },
				}
			});

			enqueueSnackbar("Mapping deleted.", {
				variant: "success",
				autoHideDuration: 5000
			});
			await loadDispositionMappings(skills);
		} catch (error) {
			console.error("Error deleting mapping: ", error);
			enqueueSnackbar("Error deleting mapping.", {
				variant: "error",
				autoHideDuration: 5000
			});
		}
	}

	async function handleSubmit(values, formikBag) {
		try {
			customDispoMapping.tenant = userContext.tenantId;
			// Get all the skillInfo from the skillId and the disposition info from outcome, fill in the rest of the values of customDispoMapping
			const outcomeInfo = [...dispoOutcomes].find(
				(outcome) => outcome.dispClassId === Number(values.outcome)
			);
			customDispoMapping.skill = values.skillId;
			customDispoMapping.custDispId = values.customDispo.dispositionId ?? values.customDispo.dispositionCode ?? values.customDispo.id;
			customDispoMapping.custDispName = getDispositionName(values.customDispo);
			customDispoMapping.dispClassId = outcomeInfo.dispClassId;
			customDispoMapping.dispCode = outcomeInfo.dispCode;
			customDispoMapping.dispDescription = outcomeInfo.description;
			customDispoMapping.dispClassName = outcomeInfo.name;

			await client.graphql({
				query: createCustomDispoMapping,
				variables: { input: customDispoMapping }
			});

			formikBag.setSubmitting(false);
			formikBag.resetForm();
			formikBag.setFieldValue("skillId", selectedSkill);
			await loadDispositionMappings(skills);
			enqueueSnackbar("Mapping Created", { variant: "success" });
		} catch (err) {
			console.error(err);
			enqueueSnackbar("Error creating custom mapping", { variant: "error" });
		}
	}

	function getDispositionName(disposition) {
		switch (userContext.telephonyProvider) {
			case 'Webex':
				return disposition.name;
			default:
				return disposition.dispositionName ?? disposition.description ?? disposition.name;
		}
	}

	const sortBy = (key) => {
		return (a, b) => (a[key] > b[key]) ? 1 : ((b[key] > a[key]) ? -1 : 0);
	};

	return (
		<div style={{ margin: 'auto', maxWidth: '1800px' }}>
			<PageAppBar
				title="Disposition Mappings"
				description="Configure how your dispositions map to outcomes"
			/>

			{userContext.telephonyProvider && (
				<>
					<Formik
						initialValues={initialValues}
						enableReinitialize={true}
						validationSchema={object({
							skillId: mixed(),
							customDispo: mixed().required("A disposition is required"),
							outcome: mixed().required(
								"Please select an outcome to map the disposition"
							),
						})}
						onSubmit={handleSubmit}
					>
						{(formikProps) => (
							<form onSubmit={formikProps.handleSubmit}>

								{/* Skill / Queue */}
								<FormControl fullWidth>
									<InputLabel id="skill-id-label" htmlFor="skill-id" color="primary">{userContext.skillLabel}</InputLabel>
									<Select
										value={formikProps.values.skillId}
										inputProps={{ id: 'skill-id' }}
										name="skillId"
										label={userContext.skillLabel}
										labelId="skill-id-label"
										color="primary"
										onChange={(event) => {
											handleSkillChange(event);
											formikProps.handleChange(event);
										}}
										onBlur={formikProps.handleBlur}
										error={
											formikProps.touched.skillId && formikProps.errors.skillId
										}
									>
										{skills.map((skill) => (
											<MenuItem key={skill.skillId} value={skill.skillId}>
												{skill.skillName}
											</MenuItem>
										))}
									</Select>
									<FormHelperText>
										The {userContext.skillLabel.toLowerCase()} for which to display mappings
									</FormHelperText>
								</FormControl>

								<Tooltip title={formikProps.values.skillId && filteredDispositions.length === 0 ? `All dispositions for this ${userContext.skillLabel.toLowerCase()} are already mapped` : ''}>
									<span>
										<Button
											disabled={!formikProps.values.skillId || filteredDispositions.length === 0}
											onClick={() => setShowDispositionMappingDialog(true)}
											sx={actionOneButtonStyle}
										>Add Disposition</Button>
									</span>
								</Tooltip>

								<Dialog open={showDispositionMappingDialog} fullWidth>
									<DialogTitle>Add Disposition Mapping</DialogTitle>
									<DialogContent>
										<Typography variant="subtitle">For the {userContext.skillLabel.toLowerCase()} "{skills.find((skill) => skill.id = formikProps.values.skillId)?.skillName}",</Typography>
										<br></br><br></br>
										<Box display="flex" flexDirection={displayAddDispositionsVertical ? 'row' : 'column'} alignItems="center" gap="10px">
											{/* Disposition */}
											<FormControl fullWidth>
												<InputLabel id="disposition-label" htmlFor="disposition" color="primary">
													{userContext.dispositionLabel}
												</InputLabel>
												<Select
													value={formikProps.values.customDispo}
													inputProps={{ id: 'disposition' }}
													name="customDispo"
													label={userContext.dispositionLabel}
													labelId="disposition-label"
													disabled={filteredDispositions.length === 0}
													color="primary"
													onChange={formikProps.handleChange}
													onBlur={formikProps.handleBlur}
													error={
														formikProps.touched.customDispo &&
														formikProps.errors.customDispo
													}
												>
													{filteredDispositions.map((disposition) => (
														<MenuItem key={disposition.dispositionId ?? disposition.dispositionCode ?? disposition.id} value={disposition}>
															{getDispositionName(disposition)}
														</MenuItem>
													))}
												</Select>
												<FormHelperText>
													The {userContext.dispositionLabel.toLowerCase()} in {userContext.telephonyProvider === 'Webex' ? 'Webex' : 'CXone'}
												</FormHelperText>
											</FormControl>

											<div style={{ paddingBottom: '24px', minWidth: '58px' }}>maps to</div>

											{/* Outcome */}
											<FormControl fullWidth>
												<InputLabel id="outcome-label" htmlFor="outcome" color="primary">Outcome</InputLabel>
												<Select
													value={formikProps.values.outcome}
													inputProps={{ id: 'outcome' }}
													name="outcome"
													label="Outcome"
													labelId="outcome-label"
													disabled={filteredDispositions.length === 0}
													color="primary"
													onChange={formikProps.handleChange}
													onBlur={formikProps.handleBlur}
													error={
														formikProps.touched.outcome &&
														formikProps.errors.outcome
													}
												>
													{dispoOutcomes.map((outcome) => (
														<MenuItem
															key={outcome.dispClassId}
															value={outcome.dispClassId}
														>
															{outcome.name}
														</MenuItem>
													))}
												</Select>
												<FormHelperText>
													The outcome in Velocity
												</FormHelperText>
											</FormControl>

											{/* Submit button */}
											{/* <IconButton color="primary" type="submit" disabled={filteredDispositions.length === 0}>
											<AddCircle />
										</IconButton> */}

										</Box>

									</DialogContent>
									<DialogActions>
										<Button onClick={() => setShowDispositionMappingDialog(false)} sx={actionTwoButtonStyle}>Cancel</Button>
										<Button onClick={() => {
											formikProps.handleSubmit();
											setShowDispositionMappingDialog(false);
										}} sx={actionOneButtonStyle}
											disabled={formikProps.errors.outcome || formikProps.errors.disposition}>Add</Button>
									</DialogActions>
								</Dialog>

							</form >
						)}
					</Formik >

					{/* Table */}
					<MaterialTable
						isLoading={loading}
						title="Mappings"
						columns={[
							{ title: userContext.skillLabel, field: "skillName" },
							{ title: userContext.dispositionLabel, field: "custDispName" },
							{ title: "Outcome", field: "dispClassName" },
						]}
						data={filteredMappings}
						actions={[
							{
								icon: () => <DeleteOutlineOutlined fontSize="medium" color="error" style={{ margin: 'auto' }} />,
								tooltip: "Delete Mapping",
								onClick: (_, mapping) => {
									setSelectedMapping(mapping);
									setShowDeleteConfirmation(true);
								},
							},
						]}
						options={{
							minBodyHeight: 'calc(100vh - 450px)',
							actionsColumnIndex: -1,
							pageSize: 20,
							emptyRowsWhenPaging: false,
							searchAutoFocus: true,
							headerStyle: {
								padding: '16px',
							},
							paginationType: 'stepped',
						}}
						style={{ marginTop: '15px' }}
						localization={{
							body: {
								emptyDataSourceMessage: !selectedSkill ?
									`Select a ${userContext.skillLabel.toLowerCase()} to view mappings` :
									`No mappings found`,
							},
						}}
						components={{
							Container: (props) => <Box {...props} elevation={0} />,
						}}
					/>

				</>
			)}

			{/* Confirmation */}
			<ConfirmDialog
				open={showDeleteConfirmation}
				title="Delete Disposition Mapping"
				description="Are you sure you want to delete this disposition mapping? This action cannot be undone."
				actionOneText="Delete"
				actionOneHandler={() => {
					deleteDispositionMapping(selectedMapping.id);
					setShowDeleteConfirmation(false);
				}}
				actionTwoText="Cancel"
				actionTwoHandler={() => {
					setShowDeleteConfirmation(false);
				}}
			/>
		</div >
	);
}

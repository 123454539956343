import { StorageManager } from '@aws-amplify/ui-react-storage';
import { useState, useContext, useEffect, useRef } from 'react';
import { generateClient, post } from 'aws-amplify/api';
import { getUrl } from 'aws-amplify/storage';
import { fetchAuthSession } from 'aws-amplify/auth';
import {
	Card,
	Select,
	MenuItem,
	Button,
	Typography,
	Drawer,
	Tabs,
	Tab,
	Box,
	FormControl,
	InputLabel,
	IconButton,
	Tooltip,
	CardContent,
} from '@mui/material';
import { UserContext } from '../../contexts/UserContext';
import {
	tenantMappings,
	listContactMetadataFieldMappingss,
	listContactCallWindowsFieldMappingss,
} from '../../graphql/queries';
import { NavLink } from 'react-router-dom';
import { FieldMappingPreview } from './fieldMappingPreview';
import { enqueueSnackbar } from 'notistack';
import { VisibilityOutlined } from '@mui/icons-material';
import { cardStyle } from 'src/theme';


/**
 * This page component handles uploading CSV files to an S3 bucket so we can then process them with
 * a lambda function and insert the rows in the database.
 *
 * The UI contains the ability to specify how fields from their files can be mapped to our existing fields.
 * @category Pages
 * @component
 * @param {any} props React props object
 */
export function FileImport() {
	const client = generateClient();
	const userContext = useContext(UserContext);
	const importStates = {
		WaitingMapping: 0,
		CanBrowse: 1,
		CanUpload: 2,
		IsUploading: 3,
		CanImport: 4,
		IsImporting: 5,
		IsFinished: 6,
	};
	const [importState, setImportState] = useState(importStates.WaitingMapping);
	const [fieldMapping, setFieldMapping] = useState({});
	const [fieldMappings, setFieldMappings] = useState([]);
	const [loading, setLoading] = useState(false);
	const [noMappings, setNoMappings] = useState(false);
	const [currentTab, setCurrentTab] = useState(0);
	const [uploadHelper, setUploadHelper] = useState({});
	const [mappingPreview, setMappingPreview] = useState(false);
	const [dataType, setDataType] = useState(0);

	const drawerWidth = 450;

	useEffect(() => {
		async function getData() {
			setLoading(true);
			const fieldMappingsResponse = await client.graphql({
				query: uploadHelper.mappingsQuery.query,
				variables: { tenant: userContext.tenantId },
			});
			if (
				fieldMappingsResponse?.data[uploadHelper.mappingsQuery.retrievalString]
					?.items?.length === 0
			) {
				setNoMappings(true);
			} else {
				setFieldMappings(
					fieldMappingsResponse.data[uploadHelper.mappingsQuery.retrievalString]
						.items,
				);
				setNoMappings(false);
			}
			setLoading(false);
		}
		if (userContext.tenantId && Object.keys(uploadHelper).length > 0) {
			getData();
		}
	}, [userContext.tenantId, uploadHelper]);

	useEffect(() => {
		setFieldMapping({});
		setImportState(importStates.WaitingMapping);
		switch (dataType) {
			default:
			case 0:
				setUploadHelper({
					noMappingsRedirectPath: '/config/field-mappings/contact/new',
					s3KeyPrefix: 'contacts',
					importRoutePath: '/import/contacts',
					mappingsQuery: {
						query: tenantMappings,
						retrievalString: 'tenantMappings',
					},
				});
				break;

			case 1:
				setUploadHelper({
					noMappingsRedirectPath: '/config/field-mappings/metadata/new',
					s3KeyPrefix: 'contact-metadata',
					importRoutePath: '/import/metadata',
					mappingsQuery: {
						query: listContactMetadataFieldMappingss,
						retrievalString: 'listContactMetadataFieldMappingss',
					},
				});
				break;

			case 2:
				setUploadHelper({
					noMappingsRedirectPath: '/config/field-mappings/call-windows/new',
					s3KeyPrefix: 'call-windows',
					importRoutePath: '/import/call-windows',
					mappingsQuery: {
						query: listContactCallWindowsFieldMappingss,
						retrievalString: 'listContactCallWindowsFieldMappingss',
					},
				});
				break;
		}
	}, [dataType]);



	/**
	 * The click handler to kick of the process of importing. If there is mapping setup
	 * it will also send that data in.
	 * @param {any} event The click event object.
	 */
	async function importData(path) {
		const urlObject = await getUrl({
			path,
			options: {
				level: 'private',
			},
		});

		const url = urlObject.url.toString();


		setImportState(importStates.IsImporting);
		try {
			const session = await fetchAuthSession();

			await post({
				apiName: 'cdyxoutreach',
				path: `${uploadHelper.importRoutePath}`,
				options: {
					body: {
						url,
						jwtToken: session.tokens.accessToken.toString(),
						tenantId: userContext.tenantId,
						fieldMapping: fieldMapping.mappings,
						apiKey: userContext.apiKey,
					},
					headers: {
						Authorization: `Bearer ${session.tokens.idToken}`,
						'x-api-key': userContext.apiKey,
					},

				},
			}).response;

			setImportState(importStates.IsFinished);
			enqueueSnackbar(
				'The import has started. You will receive an email upon completion.',
				{ variant: 'success' },
			);
		} catch (err) {
			console.error(err);
			enqueueSnackbar(err.message, { variant: 'error' });
		}
	};

	/**
	 * Handle {@link Import~MappingRule} change event.
	 * @param {Import~MappingRow} row The row data.
	 */
	const mappingChanged = (event) => {
		setImportState(importStates.CanBrowse);
		setFieldMapping(fieldMappings.find((x) => x.id === event.target.value));
	};

	return (
		<Box>
			<Card
				style={cardStyle}
				elevation={0}
			>
				<CardContent>
					<Typography variant="overline">Import Options</Typography>
					<Box>
						{noMappings && (
							<Box>
								<Typography>No mappings created yet... </Typography>
								<Button
									variant="outlined"
									color="primary"
									component={NavLink}
									to={
										uploadHelper?.noMappingsRedirectPath ?? '/config/field-mappings'
									}
								>
									Create a mapping
								</Button>
							</Box>
						)}
						{!noMappings && (
							<Box display="grid"
								gridTemplateColumns={"1fr"}
								gap="20px">
								<Box display="grid"
									gridTemplateColumns={"1fr 1fr"}
									gap="20px"
								>
									<Box display='flex' alignItems='center'>
										<FormControl sx={{ width: '100%' }}>
											<InputLabel id="fieldMappingId">Data Type</InputLabel>
											<Select
												labelId="fieldMappingId"
												label="Data Type"
												onChange={(e) => setDataType(e.target.value)}
												disabled={loading}
												value={dataType}
											>
												<MenuItem value={0}>Contact</MenuItem>
												<MenuItem value={1}>Contact Metadata</MenuItem>
												<MenuItem value={2}>Contact Call Windows</MenuItem>
											</Select>
										</FormControl>
									</Box>
									<Box display='flex' alignItems='center'>
										<FormControl sx={{ width: '100%' }}>
											<InputLabel id="fieldMappingId">Field Mapping</InputLabel>
											<Select
												labelId="fieldMappingId"
												label="Field Mapping"
												onChange={mappingChanged}
												disabled={loading}
												value={fieldMapping.id ?? ''}
											>
												{fieldMappings.map((mapping, index) => (
													<MenuItem key={index} value={mapping.id}>{mapping.name}</MenuItem>
												))}
											</Select>
										</FormControl>
										{fieldMapping?.mappings?.length >= 1 && (
											<Tooltip title="Preview field mapping">
												<IconButton onClick={() => setMappingPreview(true)} color='primary' ><VisibilityOutlined /></IconButton>
											</Tooltip>
										)}
									</Box>
								</Box>
								{fieldMapping?.mappings?.length >= 1 && (<Box>
									<StorageManager
										displayText={{
											dropFilesText: 'Drop file here or'
										}}
										acceptedFileTypes={['.csv']}
										path={({ identityId }) => `private/${identityId}/`}
										maxFileCount={1}
										autoUpload={false}
										onUploadSuccess={(file) => {
											importData(file.key);
										}

										}
									/>
								</Box>)}
							</Box>
						)}
					</Box>
				</CardContent>
			</Card>
			{/* Drawer */}
			<Box
				sx={{
					width: { sm: drawerWidth },
					flexShrink: { sm: 0 },
				}}
			>
				<Drawer
					variant="temporary"
					open={mappingPreview}
					anchor="right"
					onClose={() => setMappingPreview(false)}
					sx={{
						'& .MuiDrawer-paper': {
							boxSizing: 'border-box',
							width: drawerWidth,
							padding: '15px',
						},
					}}
				>
					<FieldMappingPreview mappings={fieldMapping.mappings} />
				</Drawer>
			</Box>
		</Box>
	);
}

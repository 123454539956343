import { useEffect, useRef, useState } from 'react';

import { EditorRefPlugin } from '@lexical/react/LexicalEditorRefPlugin';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { PlainTextPlugin } from '@lexical/react/LexicalPlainTextPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { TabIndentationPlugin } from '@lexical/react/LexicalTabIndentationPlugin';
import { ListNode, ListItemNode } from '@lexical/list';
import { LinkNode, $toggleLink } from '@lexical/link';

import { ImageNode } from './nodes/imageNode';

import { TreeViewPlugin } from './plugins/treeViewPlugin';
import { ToolbarPlugin } from './plugins/toolbarPlugin';
import { HTMLPlugin } from './plugins/htmlPlugin';
import { ImagePlugin } from './plugins/imagePlugin';
import { theme } from './theme';

import './editor.css';
import { $createParagraphNode, $createTextNode, $getRoot, $getSelection, LexicalEditor } from 'lexical';
import { FloatingLinkEditorPlugin } from './plugins/floatingLinkEditorPlugin';
import { Tooltip, Typography } from '@mui/material';
import { Warning } from '@mui/icons-material';
import { $generateHtmlFromNodes } from '@lexical/html';

const CHARS_PER_SMS = 160;

const editorConfig = {
	namespace: 'Editor',
	nodes: [
		ListNode,
		ListItemNode,
		LinkNode,
		ImageNode,
	],
	onError(error) {
		throw error;
	},
	theme: theme,
};

/**
 * @typedef {object} EditorProps
 *
 * @property {string} [initialValue] The initial value for the editor.
 * @property {boolean} [disabled] Whether or not the editor is disabled.
 * @property {boolean} [richText] Whether or not the editor is in rich text mode.
 * @property {string} [placeholderToInsert] The placeholder text to insert.
 * @property {string} [placeholder] The placeholder text for the editor.
 * @property {boolean} [showToolbar] Whether or not to show the toolbar.
 * @property {boolean} [showTreeView] Whether or not to show the tree view.
 * @property {(content: string) => {}} [onContentChange] The function to call when the content changes.
 * @property {() => {}} [onPlaceholderButtonClick] The function to call when the placeholder button is clicked.
 */


/**
 * Reusable component for WYSIWYG editor.
 *
 * @param {EditorProps} props The props for the editor.
 */
export function Editor({
	initialValue,
	disabled,
	richText,
	placeholderToInsert,
	placeholder,
	showToolbar,
	showTreeView,
	onContentChange,
	onPlaceholderButtonClick,
}) {

	/**
	 * @type {React.MutableRefObject<LexicalEditor>}
	 */
	const editorRef = useRef(null);

	const [characterCount, setCharacterCount] = useState(0);
	const [messageSizeKb, setMessageSizeKb] = useState(0);
	const [floatingAnchorElem, setFloatingAnchorElem] = useState(null);
	const [isLinkEditMode, setIsLinkEditMode] = useState(false);

	const onRef = (_floatingAnchorElem) => {
		if (_floatingAnchorElem !== null) {
			setFloatingAnchorElem(_floatingAnchorElem);
		}
	};

	/**
	 * Handles the editor disabled state.
	 */
	useEffect(() => {
		if (!editorRef.current) {
			return;
		}
		editorRef.current.update(() => {
			editorRef.current.setEditable(!disabled);
		});
	}, [disabled]);

	/**
	 * Handles changes for the initial value.
	 */
	useEffect(() => {
		if (!initialValue) {
			return;
		}
		editorRef.current.update(() => {
			if (!richText) {
				const root = $getRoot();
				root.clear();
				const paragraphNode = $createParagraphNode();
				const textNode = $createTextNode(initialValue);
				paragraphNode.append(textNode);
				root.append(paragraphNode);
			}
		});
	}, [initialValue]);

	/**
	 * Handles changes for the rich text mode.
	 */
	useEffect(() => {
		editorRef.current.update(() => {
			if (!richText) {
				const root = $getRoot();
				const plainText = root.getTextContent();
				root.clear();
				const paragraphNode = $createParagraphNode();
				const textNode = $createTextNode(plainText);
				paragraphNode.append(textNode);
				root.append(paragraphNode);
				setCharacterCount(plainText.length);
			} else {
				const htmlString = $generateHtmlFromNodes(editorRef.current);
				setCharacterCount(htmlString.length);
			}
		});
	}, [richText]);

	/**
	 * Inserts the placeholder into the editor when one is received.
	 */
	useEffect(() => {
		if (!placeholderToInsert) {
			return;
		}
		insertPlaceholder(placeholderToInsert);
	}, [placeholderToInsert]);

	function updateCharacterCount() {
		const text = $getRoot().getTextContent();
		setCharacterCount(text.length);
	}

	function updateMessageSizeKb() {

	}

	/**
	 * Inserts a placeholder into the editor.
	 *
	 * @param {string} placeholderText The placeholder text to insert.
	 */
	function insertPlaceholder(placeholderText) {
		editorRef.current.update(() => {
			const selection = $getSelection();
			if (!selection) {
				// Nothing selected.
				return;
			}

			if (placeholderText === '~PayLink~' && richText) {
				const { anchor, focus } = selection;

				// Insert just the link text at the current selection.
				selection.insertText(placeholderText);

				// Select the inserted text.
				anchor.offset -= placeholderText.length;
				focus.offset = anchor.offset + placeholderText.length;

				// Transforming the selection into a link.
				$toggleLink('~PayLink~', {
					title: '~PayLink~'
				});

				// Reset the selection.
				anchor.offset += placeholderText.length;
				focus.offset = anchor.offset;
			} else {
				selection.insertText(placeholderText);
			}
		});
	}

	return (
		<LexicalComposer initialConfig={editorConfig}>
			<div className="editor-container">

				{/* Toolbar */}
				{showToolbar &&
					<ToolbarPlugin
						richText={richText}
						onPlaceholderButtonClick={onPlaceholderButtonClick} />
				}

				{/* Inner Editor */}
				<div className="editor-inner">

					{/* Plain Text */}
					{!richText &&
						<PlainTextPlugin
							contentEditable={
								<div style={{ height: "100%" }} ref={onRef}>
									<ContentEditable
										className="editor-input"
										aria-placeholder={placeholder}
										placeholder={
											<div className="editor-placeholder">{placeholder}</div>
										}
									/>
								</div>
							}
							ErrorBoundary={LexicalErrorBoundary}

						/>
					}

					{/* Rich Text */}
					{richText &&
						<RichTextPlugin
							contentEditable={
								<div style={{ height: "100%" }} ref={onRef}>
									<ContentEditable
										className="editor-input"
										aria-placeholder={placeholder}
										placeholder={
											<div className="editor-placeholder">{placeholder}</div>
										}
									/>
								</div>
							}
							ErrorBoundary={LexicalErrorBoundary}
						/>
					}

					{/* Plugins */}
					<EditorRefPlugin editorRef={editorRef} />
					<OnChangePlugin ignoreSelectionChange onChange={() => {
						editorRef.current._editorState.read(() => {
							if (richText) {
								const htmlString = $generateHtmlFromNodes(editorRef.current);
								setCharacterCount(htmlString.length);
								onContentChange(htmlString);
							} else {
								const text = $getRoot().getTextContent();
								setCharacterCount(text.length);
								onContentChange(text);
							}
						});
					}} />

					<HistoryPlugin />
					<AutoFocusPlugin />
					<ClearEditorPlugin />
					{richText &&
						<>
							<HTMLPlugin initialValue={initialValue} />
							<ListPlugin />
							<TabIndentationPlugin />
							<ImagePlugin />
							<LinkPlugin />
							{floatingAnchorElem &&
								<FloatingLinkEditorPlugin
									anchorElem={floatingAnchorElem}
									isLinkEditMode={isLinkEditMode}
									setIsLinkEditMode={setIsLinkEditMode}
								/>
							}
						</>
					}

					{/* Tree View (Debug) */}
					{showTreeView &&
						<TreeViewPlugin />
					}
				</div>

				<div className="editor-footer">
					<div className="character-count">
						<Typography variant='caption'>
							<Tooltip
								placement='top'
								title={!richText && characterCount > CHARS_PER_SMS ? 'If sent via SMS, your message may be split into several texts' : ''}
								arrow
							>
								<div>
									{!richText && characterCount > CHARS_PER_SMS &&
										<Warning style={{ color: '#dbc200', verticalAlign: 'middle', fontSize: '.75rem' }} />
									}
									~{characterCount} characters
								</div>
							</Tooltip>
						</Typography>
					</div>

				</div>
			</div>
		</LexicalComposer>
	);
}

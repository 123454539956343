import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { I18n } from 'aws-amplify/utils';
import React from 'react';

const STRINGS = {
	'en-US': { // English (United States)
		gettingContacts: 'Getting contacts...',
		gettingCampaigns: 'Getting campaigns...',
		noActiveCampaigns: 'No active campaigns',
		selectUpTo: 'Select up to 3 campaigns to get started',
		noContactsNeedApproval: 'No contacts need approval',
		youAreNotAnApprover: 'You are not an approver',
		pleaseContactAdmin: 'Please contact your administrator to have your user enabled as an approver.',
		refreshCampaigns: 'Refresh Campaigns'
	},
	'es-419': { // Spanish (Latin America)
		gettingContacts: 'Obteniendo contactos...',
		gettingCampaigns: 'Obteniendo campañas...',
		noActiveCampaigns: 'No hay campañas activas',
		selectUpTo: 'Seleccione hasta 3 campañas para comenzar',
		noContactsNeedApproval: 'No hay contactos que necesiten aprobación',
		youAreNotAnApprover: 'No eres un aprobador',
		pleaseContactAdmin: 'Por favor, contacta a tu administrador para que habilite tu usuario como aprobador.',
		refreshCampaigns: 'Actualizar Campañas'
	},
	'pt-BR': { // Portuguese (Brazil)
		gettingContacts: 'Obtendo contatos...',
		gettingCampaigns: 'Obtendo campanhas...',
		noActiveCampaigns: 'Sem campanhas ativas',
		selectUpTo: 'Selecione até 3 campanhas para começar',
		noContactsNeedApproval: 'Nenhum contato precisa de aprovação',
		youAreNotAnApprover: 'Você não é um aprovador',
		pleaseContactAdmin: 'Entre em contato com seu administrador para habilitar seu usuário como aprovador.',
		refreshCampaigns: 'Atualizar Campanhas'
	}
};
I18n.putVocabularies(STRINGS);

/**
 *
 * @param {{
 *  isApprover: boolean
 *  contactsNeedingApproval: any[]
 *  maxSelectableCampaigns: number
 *  numberOfCampaigns: number
 *  numberOfSelectedCampaigns: number
 *  gettingCampaigns: boolean
 *  gettingContacts: boolean
 *  onCampaignListRefreshRequested: () => void
 * }} props
 * @returns
 */
export const InactiveGameMessage = (props) => {

	/**
	 * Gets the message to be displayed to the user.
	 */
	function getMessage() {
		if (props.gettingContacts) {
			return I18n.get('gettingContacts', STRINGS['en-US'].gettingContacts);
		} else if (props.gettingCampaigns) {
			return I18n.get('gettingCampaigns', STRINGS['en-US'].gettingCampaigns);
		} else if (props.numberOfCampaigns === 0) {
			return I18n.get('noActiveCampaigns', STRINGS['en-US'].noActiveCampaigns);
		} else if (props.numberOfSelectedCampaigns === 0) {
			return I18n.get('selectUpTo', STRINGS['en-US'].selectUpTo);
		} else if (props.contactsNeedingApproval.length === 0) {
			return I18n.get('noContactsNeedApproval', STRINGS['en-US'].noContactsNeedApproval);
		}
		return '';
	}

	return (
		<Box textAlign="center">

			{/* Regular messages */}
			{props.isApprover &&
				<>
					{getMessage().includes('...') &&
						<CircularProgress />
					}
					<Typography variant='overline'>
						<br></br>
						{getMessage()}
						<br></br>
						{getMessage() === 'No active campaigns' &&
							<Button variant='text' color='primary'
								onClick={() => props.onCampaignListRefreshRequested()}
							>{I18n.get('refreshCampaigns', STRINGS['en-US'].refreshCampaigns)}</Button>
						}
					</Typography>
				</>
			}

			{/* Non approver message */}
			{!props.isApprover &&
				<>
					<Typography variant='h4'>
						{I18n.get('youAreNotAnApprover', STRINGS['en-US'].youAreNotAnApprover)}
					</Typography>
					<Typography variant='body1'>
						{I18n.get('pleaseContactAdmin', STRINGS['en-US'].pleaseContactAdmin)}
					</Typography>
				</>
			}
		</Box>
	);
};

import {
	Box,
	Card,
	CardContent,
	CardHeader,
	Button,
	Grid,
	IconButton,
	Menu,
	MenuItem,
	TableCell,
	Table,
	TableBody,
	TableHead,
	TableRow,
	TableContainer,
	Tooltip,
} from '@mui/material';
import {
	DeleteOutlineOutlined,
	DragHandle,
	EditOutlined,
	HelpOutlined,
	HelpOutlineOutlined,
} from '@mui/icons-material';
import { useState, useContext } from 'react';
import { ChannelDialog } from './channelDialog';
import { UserContext } from '../../contexts/UserContext';
import { actionOneButtonStyle, actionTwoButtonStyle } from 'src/theme';

export function ProfileChannels(props) {
	const { channels, setFieldValue } = props;
	const [typesAnchor, setTypesAnchor] = useState(null);
	const [channelDialogOpen, setChannelDialogOpen] = useState(false);
	const [channelToUpdate, setChannelToUpdate] = useState();
	const userContext = useContext(UserContext);

	// REMOVE "VOICE" OPTION IF NO TELEPHONY PROVIDER IS SELECTED
	let types = ['Voice', 'SMS', 'Email'];
	if (userContext.telephonyProvider === 'None') {
		types.splice(0, 1);
	}

	function handleTypeSelectClose() {
		setTypesAnchor(null);
	}

	function handleNewChannelTypeSelect(e) {
		setChannelToUpdate({
			maxAttempts: 1,
			maxContacts: 1,
			retryDelay: 120,
			custRetry: '[]',
			cadence: '[]',
			paymentProfileId: '',
			paymentProfileName: '',
			order: channels.length + 1,
			dispositions: [],
			type: e.target.innerText,
		});
		setChannelDialogOpen(true);
		setTypesAnchor(null);
	}

	function onEditChannel(channel) {
		setChannelToUpdate(channel);
		setChannelDialogOpen(true);
	}

	function onUpdateChannel(channel) {
		const editingChannelIndex = channels.findIndex(
			(x) => x.order === channelToUpdate.order,
		);

		if (editingChannelIndex === -1) {
			setFieldValue('channels', [...channels, channel]);
		} else {
			setFieldValue(`channels[${editingChannelIndex}]`, channel);
		}
	}

	function onDeleteChannel(deleted) {
		const filtered = channels.filter((x) => x.order !== deleted.order);
		for (const channel of filtered) {
			if (channel.order > deleted.order) {
				--channel.order;
			}
		}

		setFieldValue('channels', filtered);
	}

	return (
		<Box>
			<Card variant="outlined" style={{ padding: '10px' }}>
				<CardHeader
					title={
						<Grid
							container
							justifyContent="space-between"
							alignItems="center"
						>
							<Grid item>Channels</Grid>
							<Grid item>
								<Button
									sx={actionOneButtonStyle}
									onClick={(e) => setTypesAnchor(e.currentTarget)}
								>
									+ New Channel
								</Button>
							</Grid>
						</Grid>
					}
					titleTypographyProps={{
						variant: "body2",
						color: "textSecondary",
					}}
				/>
				<CardContent>
					<TableContainer>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>Order</TableCell>
									<TableCell align='center' >Media Type</TableCell>
									<TableCell align='center'>Custom Definitions</TableCell>
									<TableCell align='center'>Attempts</TableCell>
									<TableCell align='center'><div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
										<div style={{ paddingRight: '5px' }}>Retry Delay (Minutes)</div>
										<Tooltip title="When running agentless campaigns - retries aren't activated till ttl has been met">
											<HelpOutlineOutlined fontSize="small" color="primary" />
										</Tooltip>
									</div></TableCell>
									<TableCell align='center'>Digital Profile</TableCell>
									{userContext.telephonyProvider !== 'None' && (
										<TableCell align='center'>Dialing Script</TableCell>
									)}
									<TableCell align='center'>Actions</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{channels?.map((channel, index) => (
									<TableRow key={index}>
										<TableCell>{channel.order}</TableCell>
										<TableCell align='center'>{channel.type}</TableCell>
										<TableCell align='center'>
											{channel.type === 'Voice'
												? channel.cadence
													? 'Cadence'
													: 'Custom Retries'
												: 'N/A'}
										</TableCell>
										<TableCell align='center'>{channel.maxAttempts}</TableCell>
										<TableCell align='center'>{channel.retryDelay}</TableCell>
										<TableCell align='center'>{channel.paymentProfileName || 'N/A'}</TableCell>
										{userContext.telephonyProvider !== 'None' && (
											<TableCell align='center'>{channel.dialingScript || 'N/A'}</TableCell>
										)}
										<TableCell align='center'>
											<Grid container justifyContent='center' alignItems="center">
												<Grid item>
													<IconButton
														aria-label="edit"
														onClick={() => onEditChannel(channel)}
													>
														<EditOutlined color="primary" fontSize="small" />
													</IconButton>
												</Grid>
												<Grid item>
													<IconButton
														aria-label="delete"
														onClick={() => onDeleteChannel(channel)}
													>
														<DeleteOutlineOutlined color="primary" fontSize="small" />
													</IconButton>
												</Grid>
												<Grid
													item
													style={{ marginTop: '5px', marginLeft: '10px' }}
												>
													<DragHandle
														className="drag-handle"
														color="primary"
														style={{ cursor: 'grab' }}
													/>
												</Grid>
											</Grid>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</CardContent>
			</Card>

				<Menu
					id="simple-menu"
					anchorEl={typesAnchor}
					keepMounted
					open={Boolean(typesAnchor)}
					onClose={handleTypeSelectClose}
				>
					{types.map((type) => (
						<MenuItem
							key={type}
							value={type}
							onClick={handleNewChannelTypeSelect}
						>
							{type}
						</MenuItem>
					))}
				</Menu>
				<ChannelDialog
					open={channelDialogOpen}
					onClose={() => setChannelDialogOpen(false)}
					onChange={onUpdateChannel}
					channelData={channelToUpdate}
				/>
		</Box>
	);
}

import { useState, useEffect, useRef, Fragment } from 'react';
import {
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	ListItemIcon,
	Backdrop,
	CircularProgress,
	Tooltip,
	IconButton,
	Paper,
	Typography,
	ListItem,
	ListItemText,
	Button,
	Box,
	Card,
	CardHeader,
	CardContent
} from '@mui/material';

import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

import { useParams, useNavigate } from 'react-router-dom';
// import PopUpNote from '../Components/PopUpNote';
// import PopUpContact from '../Components/PopUpContact.js';
// import BalancesTable from '../Components/BalancesTable';
// import ManualPayment from '../Components/ManualPayment';
import { screenPop, onActivityLogCreate } from '../../graphql/custom-queries';
import { pocMeta, esQuery } from '../../graphql/queries';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';
import awsconfig from '../../aws-exports';
import { print } from 'graphql';
import { gql } from 'graphql-tag';
// import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';
import { AppSyncClient } from '@aws-sdk/client-appsync';
import { useSnackbar } from 'notistack';
import _ from 'lodash';
// import C3Info from './C3Info';
import DOMPurify from 'dompurify';
import ActivityLogTypeFilter from '../../components/contacts/activityLogTypeFilter';
import { NoteOutlined, Clear, PhoneDisabledOutlined, PhoneOutlined, SmsOutlined, CompareArrowsOutlined, GetAppOutlined, OpenInBrowserOutlined, CancelScheduleSendOutlined, MonetizationOnOutlined, SettingsSystemDaydreamOutlined } from '@mui/icons-material';
import { InContact } from '../../contexts/InContact';
import { generateClient, post } from 'aws-amplify/api';

/**
 * A form that presents a contacts informtion in a Read Only Optimized format
 * All these states need to be moved into a react context for global variable management.
 * The use case matches exactly here: https://reactjs.org/docs/context.html
 */
const ContactRO = function (props) {
	const client = generateClient();
	const { id, altContactId } = useParams();
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
	const [tenant, setTenant] = useState();
	const [skill, setSkill] = useState();
	const [agentId, setAgentId] = useState();
	const [contactId, setContactId] = useState();
	const [authToken, setAuthToken] = useState();
	const [apiKey, setApiKey] = useState();
	const [editContactOpen, setEditContactOpen] = useState(false);
	const [createNoteOpen, setCreateNoteOpen] = useState(false);
	const [openBalances, setOpenBalances] = useState(false);
	const [openManual, setOpenManual] = useState(false);
	const [openRefund, setOpenRefund] = useState(false);
	const [activities, setActivities] = useState([]);
	const [activityCount, setActivityCount] = useState(10);
	const activityLogNextToken = useRef('');
	const [customFields, setCustomFields] = useState([]);
	const [loading, setLoading] = useState(false);
	const [sessionId, setSessionId] = useState('');
	const [icContactId, setIcContactId] = useState('');
	const [gettingDTMFStuffs, setGettingDTMFStuffs] = useState(false);
	const [contact, setContact] = useState({
		firstName: '',
		lastName: '',
		email: '',
		contactAccountId: '',
		account: {},
		source: '',
		phone: '',
		cell: '',
		address1: '',
		address2: '',
		city: '',
		state: '',
		zip: '',
		dnc: false,
		customFields: {},
		/* expireDt: undefined, */
		outboundCallerId: '',
		balances: []
	});
	const [contactMeta, setContactMeta] = useState();
	const [appSyncClient, setClient] = useState();
	const [logTypeFilter, setLogTypeFilter] = useState(['Dial', 'C3']);
	const [loadingActivities, setLoadingActivities] = useState(false);
	const activityLogSubscription = useRef();

	useEffect(() => {
		//verify token
		async function getData() {
			try {
				setLoading(true);

				const passport = await post({
					apiName: 'cdyxoutreach',
					path: '/screenpop/verify',
					options: {
						body: {
							id
						}
					}
				}).response;

				const responseData = await passport.body.json();

				const token = responseData.token;

				const tokenInfo = jwtDecode(token);

				if (altContactId) {
					tokenInfo.contactId = altContactId;
				}

				//Get the tenant

				const screenPopResponse = await axios.post(awsconfig.aws_appsync_graphqlEndpoint, {
					query: print(gql`${screenPop}`),
					variables: {
						tenant: tokenInfo.tenant,
						contactId: tokenInfo.contactId
					}
				}, {
					headers: {
						Authorization: tokenInfo.cognitoToken
					}
				});

				InContact.icSettings = screenPopResponse.data.data.getTenantSettings;
				const tenantCustomFields = screenPopResponse.data.data.getTenantSettings.customFields;
				setApiKey(screenPopResponse.data.data.getTenantSettings.apiKey);
				setCustomFields(tenantCustomFields);
				const contact = screenPopResponse.data.data.getContact;
				if (contact.customFields) {
					contact.customFields = JSON.parse(contact.customFields);
				} else {
					contact.customFields = {};
				}
				setContact(contact);

				const pocResponse = await axios.post(awsconfig.aws_appsync_graphqlEndpoint, {
					query: print(gql`${pocMeta}`),
					variables: {
						tenant: tokenInfo.tenant,
						contactField: {
							eq: {
								contact: contact.id
							}
						}
					}
				}, {
					headers: {
						Authorization: tokenInfo.cognitoToken
					}
				});

				setContactMeta(pocResponse.data.data.PocMeta.items);

				activityLogSubscription.current = client.graphql({
					query: gql(onActivityLogCreate),
				}).subscribe({
					next: data => updateScreenPop(data.data.onTenantCreateActivityLog, tokenInfo.contactId),
					error: error => console.warn(error)
				});

				// activityLogSubscription.current = client.subscribe({ query: gql(onActivityLogCreate), variables: { tenant: tokenInfo.tenant } }).subscribe({
				// 	next: data => updateScreenPop(data.data.onTenantCreateActivityLog, tokenInfo.contactId),
				// 	error: error => console.warn(error)
				// });

				setContactId(tokenInfo.contactId);
				setTenant(tokenInfo.tenant);
				setAuthToken(tokenInfo.cognitoToken);
				setSkill(tokenInfo.skillId);
				setAgentId(tokenInfo.agentId);

			} catch (err) {
				console.error(err);
				navigate('/');
			}

			setLoading(false);
		}

		getData();

		return () => {
			if (activityLogSubscription.current?.unsubscribe) {
				activityLogSubscription.current.unsubscribe();
			}
		};
	}, []);

	useEffect(() => {
		if (tenant && contactId && authToken) {
			getActivities();
		}
	}, [logTypeFilter, tenant, contactId, authToken]);

	useEffect(() => {
		async function getDTMFStuffs() {
			setGettingDTMFStuffs(true);

			const [sessionId, icContactId] = await Promise.all([InContact.getAgentSession(agentId, authToken), InContact.getContactIdForAgent(agentId, authToken)]);
			setSessionId(sessionId);
			setIcContactId(icContactId);

			setGettingDTMFStuffs(false);
		}

		if (agentId) {
			getDTMFStuffs();
		}
	}, [agentId]);

	async function updateScreenPop(data, id) {
		if (data.activityLogContactId === id) {
			setActivities(currentLogs => {
				return [data, ...currentLogs];
			});
			if (data.title === 'C3 Opened Event') {
				enqueueSnackbar('Payment link has been opened', {
					variant: 'info'
				});
			} else if (data.title === 'C3 Attempted Event') {
				const metaData = JSON.parse(data.metaData);
				if (metaData.transaction) {
					enqueueSnackbar('Payment Successful', {
						variant: 'success'
					});
				} else if (metaData.transactionMeta) {
					enqueueSnackbar('Payment Declined', {
						variant: 'error'
					});
				}
			}
		}
	}

	const openEditContact = () => {
		setEditContactOpen(true);
	};

	const openCreateNote = () => {
		setCreateNoteOpen(true);
	};

	const handleOpenBalances = () => {
		setOpenBalances(true);
	};

	const handleOpenManual = () => {
		setOpenManual(true);
	};

	const handleCloseManual = () => {
		setOpenManual(false);
	};

	const handleOpenRefund = () => {
		setOpenRefund(true);
	};

	const handleCloseBalances = () => {
		setOpenBalances(false);
	};

	const handleCloseRefunds = () => {
		setOpenRefund(false);
	};

	const cancelCreateNote = () => {
		setCreateNoteOpen(false);
	};

	const handleUpdatedContact = (contact) => {
		const updatedContact = { ...contact };
		updatedContact.customFields = JSON.parse(updatedContact.customFields);
		setContact(updatedContact);
		setEditContactOpen(false);
		//window.location.reload(false);
	};

	const handleNoteCreated = () => {
		setCreateNoteOpen(false);
	};

	const getActivities = async (loadMore = false) => {
		setLoadingActivities(!loadMore);

		const options = {
			model: 'activitylog',
			action: '_search',
			query: {
				"size": 10,
				"sort": {
					"timestamp": {
						"order": "desc"
					}
				},
				"query": {
					"bool": {
						"must": [
							{
								"term": {
									"tenant.keyword": tenant
								}
							},
							{
								"term": {
									"activityLogContactId.keyword": contactId
								}
							}
						]
					}
				}
			}
		};

		if (loadMore && activityLogNextToken.current) {
			options.query.search_after = [activityLogNextToken.current];
		}

		if (logTypeFilter.length > 0) {
			options.query.query.bool.must.push({
				bool: {
					should: logTypeFilter.map(x => {
						return {
							term: {
								"type.keyword": x
							}
						};
					})
				}
			});
		}

		options.query = JSON.stringify(options.query);

		const result = await axios.post(awsconfig.aws_appsync_graphqlEndpoint, {
			query: print(gql`${esQuery}`),
			variables: options
		}, {
			headers: {
				Authorization: authToken
			}
		});

		const parsed = result.data.data.esQuery;
		setActivityCount(parsed?.hits?.total ?? 0);
		setActivities([...(loadMore ? activities : []), ...(parsed?.hits?.hits?.map(x => x._source) ?? [])]);
		activityLogNextToken.current = parsed?.hits?.hits?.[parsed?.hits?.hits?.length - 1]?.sort[0];

		setLoadingActivities(false);
	};

	return (
		<>
			<Backdrop open={loading}>
				<CircularProgress variant='indeterminate' color='secondary' size={96} />
			</Backdrop>
			<Box>
				<Card variant='outlined' style={{ padding: '1rem' }}>
					<CardHeader title="Contact Details" />
					<CardContent>
						<Box gap={2}>
							<Typography variant="h6" color="primary" gutterBottom>{`${contact.firstName} ${contact.lastName}`}</Typography>
							<Typography variant='body1'>{contact.externalId}</Typography>
							<Typography variant='body1'>{contact.source}</Typography>
							<Box display="grid" gridTemplateColumns="1fr 1fr" gap={2}>
								{(contact.address1 || contact.address2) &&
									<Grid item>
										{contact.address1 && `${contact.address1}`} {contact.address2 && `${contact.address2}`}
									</Grid>}
								{(contact.city || contact.state || contact.zip) &&
									<Grid item>
										{contact.city && `${contact.city},`}{contact.state && ` ${contact.state}`}{contact.zip && ` ${contact.zip}`}
									</Grid>}
								{(contact.phone || contact.cell || contact.email) &&
									<Grid item>
										{contact.phone && <span><strong>Phone</strong>: {contact.phone}</span>} {contact.cell && <span>| <strong>Cell</strong>: {contact.cell}</span>} {contact.email && <span>| <strong>Email</strong>: {contact.email}</span>}
									</Grid>}
								{customFields.map(field => {
									if (field.name in contact.customFields) {
										return (
											<Grid item key={field.name}>
												{function () {
													if (field.type === 'DateTime') {
														return <Typography><strong>{`${field.displayName}`}</strong>{`: ${new Date(contact.customFields[field.name]).toDateString()}`}</Typography>;
													} else {
														return <Typography><strong>{`${field.displayName}`}</strong>{`: ${contact.customFields[field.name]}`}</Typography>;
													}
												}()}
											</Grid>
										);
									} else {
										return null;
									}
								})}
							</Box>
						</Box>
					</CardContent>
				</Card>
			</Box>
			<Grid container direction="column" spacing={2}>
				<Grid item>
					<Paper style={{ borderLeft: '3px solid #0277bd' }}>
						<Grid container direction="column" alignItems="center" spacing={1}>
							<Grid item>
								<Typography variant="h4" color="primary" gutterBottom>{`${contact.firstName} ${contact.lastName}`}</Typography>
							</Grid>

							<Grid item>
								<Grid container spacing={1} justifyContent='center'>
									{customFields.map(field => {
										if (field.name in contact.customFields) {
											return (
												<Grid item key={field.name}>
													{function () {
														if (field.type === 'DateTime') {
															return <Typography><strong>{`${field.displayName}`}</strong>{`: ${new Date(contact.customFields[field.name]).toDateString()}`}</Typography>;
														} else {
															return <Typography><strong>{`${field.displayName}`}</strong>{`: ${contact.customFields[field.name]}`}</Typography>;
														}
													}()}
												</Grid>
											);
										} else {
											return null;
										}
									}
									)}
								</Grid>
							</Grid>
							<Grid item style={{ width: '100%' }}>
								<Grid container justifyContent="flex-end" spacing={2}>
									<Grid item hidden={!contact.balances}>
										<Button onClick={handleOpenBalances} color="primary" variant="contained">View Balances</Button>
										<Dialog open={openBalances} onBackdropClick={handleCloseBalances} maxWidth='lg'>
											<DialogTitle id="balances-dialog-title">Balances</DialogTitle>
											<DialogContent>
												{/* <BalancesTable
													tenant={tenant}
													contact={contact}
													token={authToken}
													apiKey={apiKey}
													agentId={agentId}
													skill={skill}
													awsClient={appSyncClient}
												/> */}
											</DialogContent>
										</Dialog>
									</Grid>
									<Grid item>
										<Button color="primary" variant="contained" onClick={handleOpenManual}>New Payment</Button>
										<Dialog open={openManual} maxWidth='lg'>
											<DialogTitle id='manual-payment-title'>Manual Payment</DialogTitle>
											<DialogContent>
												{/* <ManualPayment
													tenant={tenant}
													contact={_.cloneDeep(contact)}
													token={authToken}
													apiKey={apiKey}
													agentId={agentId}
													contactId={contactId}
													skill={skill}
													awsClient={appSyncClient}
													handleClose={handleCloseManual}
													sessionId={sessionId}
													icContactId={icContactId}
													gettingDTMFStuffs={gettingDTMFStuffs}
												/> */}
											</DialogContent>
										</Dialog>
									</Grid>
									<Grid item>
										<Button color="primary" variant="contained" onClick={handleOpenRefund}>Refund</Button>
										<Dialog open={openRefund} maxWidth='lg' onBackdropClick={handleCloseRefunds}>
											<DialogTitle id='manual-payment-title'>
												<Grid container justifyContent='space-between'>
													<Grid item>
														Refund
													</Grid>
													<Grid item>
														<Button variant='outlined' color='primary' onClick={handleCloseRefunds}>Close</Button>
													</Grid>
												</Grid>
											</DialogTitle>
											<DialogContent>
												{/* <C3Info
													tenant={tenant}
													contact={contact}
													agentId={agentId}
													vendorId={tenant}
												/> */}
											</DialogContent>
										</Dialog>
									</Grid>
									{/* <Grid item>
                                        <Button onClick={openEditContact} color="primary" variant="contained">Edit Contact</Button>
                                        <PopUpContact
                                            tenant={tenant}
                                            contact={contact}
                                            token={authToken}
                                            client={appSyncClient}
                                            customFields={customFields}
                                            metaData={contactMeta}
                                            open={editContactOpen}
                                            handleClose={setEditContactOpen}
                                            updateContact={handleUpdatedContact}
                                            agentId={agentId} />
                                    </Grid> */}
									{/* <Grid item>
                                        <Button onClick={openCreateNote} color="primary" variant="outlined">+ Note</Button>
                                        <PopUpNote
                                            tenant={tenant}
                                            contactId={contactId}
                                            token={authToken}
                                            open={createNoteOpen}
                                            onCreate={handleNoteCreated}
                                            onCancel={cancelCreateNote} />
                                    </Grid> */}
								</Grid>
							</Grid>
						</Grid>
					</Paper>
				</Grid>
				<Grid item>
					<Paper>
						<Grid containerdirection='column'>
							<Grid item>
								<Grid container spacing={4} alignItems='center'>
									<Grid item>
										<Typography variant="h6" color="primary" gutterBottom>Activities</Typography>
									</Grid>
									<Grid item>
										<Grid container spacing={2} alignItems='center'>
											<Grid item>
												<ActivityLogTypeFilter value={logTypeFilter} onChange={e => setLogTypeFilter(e.target.value)} />
											</Grid>
											<Grid item>
												<Tooltip title='Clear filter'>
													<IconButton onClick={() => setLogTypeFilter([])}>
														<Clear color='primary' />
													</IconButton>
												</Tooltip>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
							<Grid item>
								{loadingActivities &&
									<CircularProgress variant='indeterminate' color='primary' />}
								{!loadingActivities &&
									<SimpleBar>
										{activities.map((activity, index) => {
											let metaData = activity.metaData;
											if (typeof activity.metaData === 'string') {
												metaData = JSON.parse(activity.metaData);
											}
											return (
												<ListItem key={index} style={{ borderBottom: 'rgb(86 86 86) solid 1px' }}>
													<ListItemIcon>
														{function () {
															switch (activity.type) {
																case 'Note':
																	return <NoteOutlined color="primary" />;
																case 'Dial':
																case 'DialApprove':
																case 'DialSuppress':
																case 'DialRecycle':
																	return <PhoneOutlined color="primary" />;
																case 'SMS':
																case 'SMSSuppress':
																case 'SMSApprove':
																case 'SMSRecycle':
																case 'SMSUndeliverable':
																	return <SmsOutlined color='primary' />;
																case 'System':
																	return <SettingsSystemDaydreamOutlined color="primary" />;
																case 'C3':
																case 'PromptPay':
																	if (activity.title === 'URL redirect was clicked') return <CompareArrowsOutlined color="primary" />;
																	if (activity.title === 'File download link was clicked') return <GetAppOutlined color="primary" />;
																	if (activity.title === 'C3 Opened Event') return <OpenInBrowserOutlined color="primary" />;
																	if (activity.title === 'C3 Expired Event') return <CancelScheduleSendOutlined color="primary" />;
																	if (!metaData.transaction && !metaData.transactionMeta) return <MonetizationOnOutlined color="primary" />;
																	if (metaData.transaction) return <MonetizationOnOutlined />;
																	if (metaData.transactionMeta) return <MonetizationOnOutlined />;
																	return null;
																case 'DNCScrubInvocation':
																case 'DNCReassignmentInvocation':
																case 'DNCLitigatorInvocation':
																	return <PhoneDisabledOutlined color='primary' />;

																default:
																	return null;
															}
														}()}
													</ListItemIcon>
													<ListItemText
														primary={activity.title}
														secondary={
															<Fragment>
																<Typography
																	component="span"
																	variant="body2"
																	color="textPrimary"
																>
																	{new Date(activity.timestamp).toDateString()}
																</Typography>
																<span>{activity.agent && `Agent: ${activity.agent}`}</span>
																<span>{activity.disposition && `Disposition: ${activity.disposition}`}</span>
																<span>{activity.type === 'Note' && <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(activity.body) }} />}</span>
																<span >{activity.type === 'C3' && metaData.transaction && `Paid $${metaData.transaction.transactionAmount}`}</span>
																<span >{activity.type === 'C3' && metaData.transaction && `Card Ends in: ${metaData.transaction.cardNumber}`}</span>
																<span >{activity.type === 'C3' && metaData.transactionMeta && `Payment Declined`}</span>
																<span >{activity.type === 'C3' && metaData.channel && `Sent via ${metaData.channel}`}</span>
																<span >{activity.type === 'C3' && metaData.fileName && `${metaData.fileName}`}</span>

															</Fragment>
														}
													/>
												</ListItem>
											);
										})}
									</SimpleBar>}
							</Grid>
						</Grid>
					</Paper>
				</Grid>
			</Grid>
		</>
	);
};

export default ContactRO;

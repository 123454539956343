import { Grid, Slider, Typography } from '@mui/material';

export function RetrySlider(props) {
	return (
		<Grid container spacing={2} alignContent="center" alignItems="center">
			<Grid item>
				<Typography>{props.label}</Typography>
			</Grid>
			<Grid item style={{ flexGrow: 1 }}>
				<Slider
					style={{ marginTop: '6px' }}
					{...props}
					onChange={(_, newValue) =>
						props.onChange({ target: { value: newValue, name: props.name } })
					}
				/>
			</Grid>
			<Grid item>
				<Typography variant="body2">{`Use ${props.value} retries in a batch of 100 contacts.`}</Typography>
			</Grid>
		</Grid>
	);
}

/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createTenantSettings = /* GraphQL */ `
  mutation CreateTenantSettings(
    $input: CreateTenantSettingsInput!
    $condition: ModelTenantSettingsConditionInput
  ) {
    createTenantSettings(input: $input, condition: $condition) {
      id
      icApp
      icVendor
      icBU
      icUserName
      icPassword
      icGetAuthTokenURL
      icAccessKey
      icAppSecret
      icDiscoveryDomain
      icScriptName
      ftpImport
      ftpExport
      dashBoardCharts
      e2DashCharts
      ppDashCharts
      e2ClientId
      e2ClientSecret
      e2DynamTenantId
      onPremAccessUrl
      onPremVersion
      onPremAuthUrl
      apiKey
      licenses
      customFields {
        name
        displayName
        type
        isPoc
      }
      dynamicsConnection
      dynamicsDispoFilter
      email
      dispositionScoring {
        noAnswer
        answer
        noAgentAvailableToDial
        noAgentOnAnswer
        overApproving
      }
      telephonyProvider
      telephoneProviderInfo {
        amazonConnect {
          accessKey
          secretKey
          instanceId
          region
          queueId
          transferFlowId
        }
        incontact {
          icApp
          icVendor
          icBU
          icUserName
          icPassword
          icGetAuthTokenURL
          icAccessKey
          icAppSecret
          icDiscoveryDomain
          icScriptName
        }
        nobelbiz {
          nobelbizTenantId
          token
          instanceId
          username
          password
          encodedAuth
        }
        genesys {
          clientId
          clientSecret
          cloudRegion
          queueId
          wrapUps
          timerFlow
          chime {
            region
            outboundDialNumber
            sipMediaApplicationId
          }
        }
        webex {
          contactCenter {
            region
            organizationId
            clientId
            clientSecret
          }
          connect {
            region
            serviceKey
          }
          chime {
            region
            outboundDialNumber
            sipMediaApplicationId
          }
        }
      }
      dncDotComSettings {
        orgId
        projectId
        loginId
      }
      ipAuthentication
      ipRanges
      allowAutoMode
      overrideDialToAgentRatio
      dialToAgentRatio
      createdAt
      updatedAt
    }
  }
`;
export const updateTenantSettings = /* GraphQL */ `
  mutation UpdateTenantSettings(
    $input: UpdateTenantSettingsInput!
    $condition: ModelTenantSettingsConditionInput
  ) {
    updateTenantSettings(input: $input, condition: $condition) {
      id
      icApp
      icVendor
      icBU
      icUserName
      icPassword
      icGetAuthTokenURL
      icAccessKey
      icAppSecret
      icDiscoveryDomain
      icScriptName
      ftpImport
      ftpExport
      dashBoardCharts
      e2DashCharts
      ppDashCharts
      e2ClientId
      e2ClientSecret
      e2DynamTenantId
      onPremAccessUrl
      onPremVersion
      onPremAuthUrl
      apiKey
      licenses
      customFields {
        name
        displayName
        type
        isPoc
      }
      dynamicsConnection
      dynamicsDispoFilter
      email
      dispositionScoring {
        noAnswer
        answer
        noAgentAvailableToDial
        noAgentOnAnswer
        overApproving
      }
      telephonyProvider
      telephoneProviderInfo {
        amazonConnect {
          accessKey
          secretKey
          instanceId
          region
          queueId
          transferFlowId
        }
        incontact {
          icApp
          icVendor
          icBU
          icUserName
          icPassword
          icGetAuthTokenURL
          icAccessKey
          icAppSecret
          icDiscoveryDomain
          icScriptName
        }
        nobelbiz {
          nobelbizTenantId
          token
          instanceId
          username
          password
          encodedAuth
        }
        genesys {
          clientId
          clientSecret
          cloudRegion
          queueId
          wrapUps
          timerFlow
          chime {
            region
            outboundDialNumber
            sipMediaApplicationId
          }
        }
        webex {
          contactCenter {
            region
            organizationId
            clientId
            clientSecret
          }
          connect {
            region
            serviceKey
          }
          chime {
            region
            outboundDialNumber
            sipMediaApplicationId
          }
        }
      }
      dncDotComSettings {
        orgId
        projectId
        loginId
      }
      ipAuthentication
      ipRanges
      allowAutoMode
      overrideDialToAgentRatio
      dialToAgentRatio
      createdAt
      updatedAt
    }
  }
`;
export const deleteTenantSettings = /* GraphQL */ `
  mutation DeleteTenantSettings(
    $input: DeleteTenantSettingsInput!
    $condition: ModelTenantSettingsConditionInput
  ) {
    deleteTenantSettings(input: $input, condition: $condition) {
      id
      icApp
      icVendor
      icBU
      icUserName
      icPassword
      icGetAuthTokenURL
      icAccessKey
      icAppSecret
      icDiscoveryDomain
      icScriptName
      ftpImport
      ftpExport
      dashBoardCharts
      e2DashCharts
      ppDashCharts
      e2ClientId
      e2ClientSecret
      e2DynamTenantId
      onPremAccessUrl
      onPremVersion
      onPremAuthUrl
      apiKey
      licenses
      customFields {
        name
        displayName
        type
        isPoc
      }
      dynamicsConnection
      dynamicsDispoFilter
      email
      dispositionScoring {
        noAnswer
        answer
        noAgentAvailableToDial
        noAgentOnAnswer
        overApproving
      }
      telephonyProvider
      telephoneProviderInfo {
        amazonConnect {
          accessKey
          secretKey
          instanceId
          region
          queueId
          transferFlowId
        }
        incontact {
          icApp
          icVendor
          icBU
          icUserName
          icPassword
          icGetAuthTokenURL
          icAccessKey
          icAppSecret
          icDiscoveryDomain
          icScriptName
        }
        nobelbiz {
          nobelbizTenantId
          token
          instanceId
          username
          password
          encodedAuth
        }
        genesys {
          clientId
          clientSecret
          cloudRegion
          queueId
          wrapUps
          timerFlow
          chime {
            region
            outboundDialNumber
            sipMediaApplicationId
          }
        }
        webex {
          contactCenter {
            region
            organizationId
            clientId
            clientSecret
          }
          connect {
            region
            serviceKey
          }
          chime {
            region
            outboundDialNumber
            sipMediaApplicationId
          }
        }
      }
      dncDotComSettings {
        orgId
        projectId
        loginId
      }
      ipAuthentication
      ipRanges
      allowAutoMode
      overrideDialToAgentRatio
      dialToAgentRatio
      createdAt
      updatedAt
    }
  }
`;
export const createFtpImport = /* GraphQL */ `
  mutation CreateFtpImport(
    $input: CreateFtpImportInput!
    $condition: ModelFtpImportConditionInput
  ) {
    createFtpImport(input: $input, condition: $condition) {
      id
      tenant
      name
      host
      username
      password
      path
      privateKey
      port
      fieldMappingId
      lastCheck
      fileNameToSource
      algorithms {
        cipher
      }
      readyTimeout
      reportEmail
      delimiter
      createdAt
      updatedAt
    }
  }
`;
export const updateFtpImport = /* GraphQL */ `
  mutation UpdateFtpImport(
    $input: UpdateFtpImportInput!
    $condition: ModelFtpImportConditionInput
  ) {
    updateFtpImport(input: $input, condition: $condition) {
      id
      tenant
      name
      host
      username
      password
      path
      privateKey
      port
      fieldMappingId
      lastCheck
      fileNameToSource
      algorithms {
        cipher
      }
      readyTimeout
      reportEmail
      delimiter
      createdAt
      updatedAt
    }
  }
`;
export const deleteFtpImport = /* GraphQL */ `
  mutation DeleteFtpImport(
    $input: DeleteFtpImportInput!
    $condition: ModelFtpImportConditionInput
  ) {
    deleteFtpImport(input: $input, condition: $condition) {
      id
      tenant
      name
      host
      username
      password
      path
      privateKey
      port
      fieldMappingId
      lastCheck
      fileNameToSource
      algorithms {
        cipher
      }
      readyTimeout
      reportEmail
      delimiter
      createdAt
      updatedAt
    }
  }
`;
export const createFieldsMapping = /* GraphQL */ `
  mutation CreateFieldsMapping(
    $input: CreateFieldsMappingInput!
    $condition: ModelFieldsMappingConditionInput
  ) {
    createFieldsMapping(input: $input, condition: $condition) {
      id
      tenant
      name
      mappings {
        fromField
        toField
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateFieldsMapping = /* GraphQL */ `
  mutation UpdateFieldsMapping(
    $input: UpdateFieldsMappingInput!
    $condition: ModelFieldsMappingConditionInput
  ) {
    updateFieldsMapping(input: $input, condition: $condition) {
      id
      tenant
      name
      mappings {
        fromField
        toField
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteFieldsMapping = /* GraphQL */ `
  mutation DeleteFieldsMapping(
    $input: DeleteFieldsMappingInput!
    $condition: ModelFieldsMappingConditionInput
  ) {
    deleteFieldsMapping(input: $input, condition: $condition) {
      id
      tenant
      name
      mappings {
        fromField
        toField
      }
      createdAt
      updatedAt
    }
  }
`;
export const createContactMetadataFieldMappings = /* GraphQL */ `
  mutation CreateContactMetadataFieldMappings(
    $input: CreateContactMetadataFieldMappingsInput!
    $condition: ModelContactMetadataFieldMappingsConditionInput
  ) {
    createContactMetadataFieldMappings(input: $input, condition: $condition) {
      id
      tenant
      name
      mappings {
        fromField
        toField
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateContactMetadataFieldMappings = /* GraphQL */ `
  mutation UpdateContactMetadataFieldMappings(
    $input: UpdateContactMetadataFieldMappingsInput!
    $condition: ModelContactMetadataFieldMappingsConditionInput
  ) {
    updateContactMetadataFieldMappings(input: $input, condition: $condition) {
      id
      tenant
      name
      mappings {
        fromField
        toField
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteContactMetadataFieldMappings = /* GraphQL */ `
  mutation DeleteContactMetadataFieldMappings(
    $input: DeleteContactMetadataFieldMappingsInput!
    $condition: ModelContactMetadataFieldMappingsConditionInput
  ) {
    deleteContactMetadataFieldMappings(input: $input, condition: $condition) {
      id
      tenant
      name
      mappings {
        fromField
        toField
      }
      createdAt
      updatedAt
    }
  }
`;
export const createContactCallWindowsFieldMappings = /* GraphQL */ `
  mutation CreateContactCallWindowsFieldMappings(
    $input: CreateContactCallWindowsFieldMappingsInput!
    $condition: ModelContactCallWindowsFieldMappingsConditionInput
  ) {
    createContactCallWindowsFieldMappings(
      input: $input
      condition: $condition
    ) {
      id
      tenant
      name
      mappings {
        fromField
        toField
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateContactCallWindowsFieldMappings = /* GraphQL */ `
  mutation UpdateContactCallWindowsFieldMappings(
    $input: UpdateContactCallWindowsFieldMappingsInput!
    $condition: ModelContactCallWindowsFieldMappingsConditionInput
  ) {
    updateContactCallWindowsFieldMappings(
      input: $input
      condition: $condition
    ) {
      id
      tenant
      name
      mappings {
        fromField
        toField
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteContactCallWindowsFieldMappings = /* GraphQL */ `
  mutation DeleteContactCallWindowsFieldMappings(
    $input: DeleteContactCallWindowsFieldMappingsInput!
    $condition: ModelContactCallWindowsFieldMappingsConditionInput
  ) {
    deleteContactCallWindowsFieldMappings(
      input: $input
      condition: $condition
    ) {
      id
      tenant
      name
      mappings {
        fromField
        toField
      }
      createdAt
      updatedAt
    }
  }
`;
export const createCampaign = /* GraphQL */ `
  mutation CreateCampaign(
    $input: CreateCampaignInput!
    $condition: ModelCampaignConditionInput
  ) {
    createCampaign(input: $input, condition: $condition) {
      id
      tenant
      name
      segments
      segmentSchedule
      campaignSchedule
      static
      sort
      skillId
      skillName
      skillSource
      campaignProfileId
      profile {
        id
        tenant
        name
        useAi
        flowRatio
        scrubber {
          cell
          natDnc
          stateDnc
          orgDnc
          phoneDup
          contactDup
        }
        retry {
          minDuration
          maxAttempts
          maxContacts
          custRetry
          ratio
        }
        ai {
          org
          system
        }
        pushContacts
        startTime
        endTime
        buffer
        channels {
          type
          order
          retryDelay
          maxAttempts
          maxContacts
          custRetry
          paymentProfileId
          paymentProfileName
          dispositions
          cadence
          dialingScript
        }
        ratio
        dontCallOnWeekends
        callWindow {
          days
          tmz {
            offset
            dst
          }
          beginTime {
            hour
            minute
          }
          endTime {
            hour
            minute
          }
          callWindowQuery {
            field
            operator
            value
          }
          default
          order
        }
        clickToCall
        callWindows {
          days
          tmz {
            offset
            dst
          }
          beginTime {
            hour
            minute
          }
          endTime {
            hour
            minute
          }
          callWindowQuery {
            field
            operator
            value
          }
          default
          order
        }
        dispositionScoring {
          noAnswer
          answer
          noAgentAvailableToDial
          noAgentOnAnswer
          overApproving
        }
        overrideDispositionScoring
        overrideDialToAgentRatio
        dialToAgentRatio
        freshLIFO
        retryLIFO
        createdAt
        updatedAt
      }
      status
      expireDate
      stopOnCompletion
      cci
      autoMode
      cciMetaData
      createdAt
      updatedAt
    }
  }
`;
export const updateCampaign = /* GraphQL */ `
  mutation UpdateCampaign(
    $input: UpdateCampaignInput!
    $condition: ModelCampaignConditionInput
  ) {
    updateCampaign(input: $input, condition: $condition) {
      id
      tenant
      name
      segments
      segmentSchedule
      campaignSchedule
      static
      sort
      skillId
      skillName
      skillSource
      campaignProfileId
      profile {
        id
        tenant
        name
        useAi
        flowRatio
        scrubber {
          cell
          natDnc
          stateDnc
          orgDnc
          phoneDup
          contactDup
        }
        retry {
          minDuration
          maxAttempts
          maxContacts
          custRetry
          ratio
        }
        ai {
          org
          system
        }
        pushContacts
        startTime
        endTime
        buffer
        channels {
          type
          order
          retryDelay
          maxAttempts
          maxContacts
          custRetry
          paymentProfileId
          paymentProfileName
          dispositions
          cadence
          dialingScript
        }
        ratio
        dontCallOnWeekends
        callWindow {
          days
          tmz {
            offset
            dst
          }
          beginTime {
            hour
            minute
          }
          endTime {
            hour
            minute
          }
          callWindowQuery {
            field
            operator
            value
          }
          default
          order
        }
        clickToCall
        callWindows {
          days
          tmz {
            offset
            dst
          }
          beginTime {
            hour
            minute
          }
          endTime {
            hour
            minute
          }
          callWindowQuery {
            field
            operator
            value
          }
          default
          order
        }
        dispositionScoring {
          noAnswer
          answer
          noAgentAvailableToDial
          noAgentOnAnswer
          overApproving
        }
        overrideDispositionScoring
        overrideDialToAgentRatio
        dialToAgentRatio
        freshLIFO
        retryLIFO
        createdAt
        updatedAt
      }
      status
      expireDate
      stopOnCompletion
      cci
      autoMode
      cciMetaData
      createdAt
      updatedAt
    }
  }
`;
export const deleteCampaign = /* GraphQL */ `
  mutation DeleteCampaign(
    $input: DeleteCampaignInput!
    $condition: ModelCampaignConditionInput
  ) {
    deleteCampaign(input: $input, condition: $condition) {
      id
      tenant
      name
      segments
      segmentSchedule
      campaignSchedule
      static
      sort
      skillId
      skillName
      skillSource
      campaignProfileId
      profile {
        id
        tenant
        name
        useAi
        flowRatio
        scrubber {
          cell
          natDnc
          stateDnc
          orgDnc
          phoneDup
          contactDup
        }
        retry {
          minDuration
          maxAttempts
          maxContacts
          custRetry
          ratio
        }
        ai {
          org
          system
        }
        pushContacts
        startTime
        endTime
        buffer
        channels {
          type
          order
          retryDelay
          maxAttempts
          maxContacts
          custRetry
          paymentProfileId
          paymentProfileName
          dispositions
          cadence
          dialingScript
        }
        ratio
        dontCallOnWeekends
        callWindow {
          days
          tmz {
            offset
            dst
          }
          beginTime {
            hour
            minute
          }
          endTime {
            hour
            minute
          }
          callWindowQuery {
            field
            operator
            value
          }
          default
          order
        }
        clickToCall
        callWindows {
          days
          tmz {
            offset
            dst
          }
          beginTime {
            hour
            minute
          }
          endTime {
            hour
            minute
          }
          callWindowQuery {
            field
            operator
            value
          }
          default
          order
        }
        dispositionScoring {
          noAnswer
          answer
          noAgentAvailableToDial
          noAgentOnAnswer
          overApproving
        }
        overrideDispositionScoring
        overrideDialToAgentRatio
        dialToAgentRatio
        freshLIFO
        retryLIFO
        createdAt
        updatedAt
      }
      status
      expireDate
      stopOnCompletion
      cci
      autoMode
      cciMetaData
      createdAt
      updatedAt
    }
  }
`;
export const createProfile = /* GraphQL */ `
  mutation CreateProfile(
    $input: CreateProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    createProfile(input: $input, condition: $condition) {
      id
      tenant
      name
      useAi
      flowRatio
      scrubber {
        cell
        natDnc
        stateDnc
        orgDnc
        phoneDup
        contactDup
      }
      retry {
        minDuration
        maxAttempts
        maxContacts
        custRetry
        ratio
      }
      ai {
        org
        system
      }
      pushContacts
      startTime
      endTime
      buffer
      channels {
        type
        order
        retryDelay
        maxAttempts
        maxContacts
        custRetry
        paymentProfileId
        paymentProfileName
        dispositions
        cadence
        dialingScript
      }
      ratio
      dontCallOnWeekends
      callWindow {
        days
        tmz {
          offset
          dst
        }
        beginTime {
          hour
          minute
        }
        endTime {
          hour
          minute
        }
        callWindowQuery {
          field
          operator
          value
        }
        default
        order
      }
      clickToCall
      callWindows {
        days
        tmz {
          offset
          dst
        }
        beginTime {
          hour
          minute
        }
        endTime {
          hour
          minute
        }
        callWindowQuery {
          field
          operator
          value
        }
        default
        order
      }
      dispositionScoring {
        noAnswer
        answer
        noAgentAvailableToDial
        noAgentOnAnswer
        overApproving
      }
      overrideDispositionScoring
      overrideDialToAgentRatio
      dialToAgentRatio
      freshLIFO
      retryLIFO
      createdAt
      updatedAt
    }
  }
`;
export const updateProfile = /* GraphQL */ `
  mutation UpdateProfile(
    $input: UpdateProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    updateProfile(input: $input, condition: $condition) {
      id
      tenant
      name
      useAi
      flowRatio
      scrubber {
        cell
        natDnc
        stateDnc
        orgDnc
        phoneDup
        contactDup
      }
      retry {
        minDuration
        maxAttempts
        maxContacts
        custRetry
        ratio
      }
      ai {
        org
        system
      }
      pushContacts
      startTime
      endTime
      buffer
      channels {
        type
        order
        retryDelay
        maxAttempts
        maxContacts
        custRetry
        paymentProfileId
        paymentProfileName
        dispositions
        cadence
        dialingScript
      }
      ratio
      dontCallOnWeekends
      callWindow {
        days
        tmz {
          offset
          dst
        }
        beginTime {
          hour
          minute
        }
        endTime {
          hour
          minute
        }
        callWindowQuery {
          field
          operator
          value
        }
        default
        order
      }
      clickToCall
      callWindows {
        days
        tmz {
          offset
          dst
        }
        beginTime {
          hour
          minute
        }
        endTime {
          hour
          minute
        }
        callWindowQuery {
          field
          operator
          value
        }
        default
        order
      }
      dispositionScoring {
        noAnswer
        answer
        noAgentAvailableToDial
        noAgentOnAnswer
        overApproving
      }
      overrideDispositionScoring
      overrideDialToAgentRatio
      dialToAgentRatio
      freshLIFO
      retryLIFO
      createdAt
      updatedAt
    }
  }
`;
export const deleteProfile = /* GraphQL */ `
  mutation DeleteProfile(
    $input: DeleteProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    deleteProfile(input: $input, condition: $condition) {
      id
      tenant
      name
      useAi
      flowRatio
      scrubber {
        cell
        natDnc
        stateDnc
        orgDnc
        phoneDup
        contactDup
      }
      retry {
        minDuration
        maxAttempts
        maxContacts
        custRetry
        ratio
      }
      ai {
        org
        system
      }
      pushContacts
      startTime
      endTime
      buffer
      channels {
        type
        order
        retryDelay
        maxAttempts
        maxContacts
        custRetry
        paymentProfileId
        paymentProfileName
        dispositions
        cadence
        dialingScript
      }
      ratio
      dontCallOnWeekends
      callWindow {
        days
        tmz {
          offset
          dst
        }
        beginTime {
          hour
          minute
        }
        endTime {
          hour
          minute
        }
        callWindowQuery {
          field
          operator
          value
        }
        default
        order
      }
      clickToCall
      callWindows {
        days
        tmz {
          offset
          dst
        }
        beginTime {
          hour
          minute
        }
        endTime {
          hour
          minute
        }
        callWindowQuery {
          field
          operator
          value
        }
        default
        order
      }
      dispositionScoring {
        noAnswer
        answer
        noAgentAvailableToDial
        noAgentOnAnswer
        overApproving
      }
      overrideDispositionScoring
      overrideDialToAgentRatio
      dialToAgentRatio
      freshLIFO
      retryLIFO
      createdAt
      updatedAt
    }
  }
`;
export const createManualProfile = /* GraphQL */ `
  mutation CreateManualProfile(
    $input: CreateManualProfileInput!
    $condition: ModelManualProfileConditionInput
  ) {
    createManualProfile(input: $input, condition: $condition) {
      id
      tenant
      name
      dialingProfile
      suppressionProfile
      skills
      createdAt
      updatedAt
    }
  }
`;
export const updateManualProfile = /* GraphQL */ `
  mutation UpdateManualProfile(
    $input: UpdateManualProfileInput!
    $condition: ModelManualProfileConditionInput
  ) {
    updateManualProfile(input: $input, condition: $condition) {
      id
      tenant
      name
      dialingProfile
      suppressionProfile
      skills
      createdAt
      updatedAt
    }
  }
`;
export const deleteManualProfile = /* GraphQL */ `
  mutation DeleteManualProfile(
    $input: DeleteManualProfileInput!
    $condition: ModelManualProfileConditionInput
  ) {
    deleteManualProfile(input: $input, condition: $condition) {
      id
      tenant
      name
      dialingProfile
      suppressionProfile
      skills
      createdAt
      updatedAt
    }
  }
`;
export const createSegment = /* GraphQL */ `
  mutation CreateSegment(
    $input: CreateSegmentInput!
    $condition: ModelSegmentConditionInput
  ) {
    createSegment(input: $input, condition: $condition) {
      id
      tenant
      externalId
      name
      description
      query {
        qbQuery
        searchFilter
        sort {
          field
          direction
        }
      }
      filter {
        qbQuery
        searchFilter
        sort {
          field
          direction
        }
      }
      fieldsMappingId
      suppressionID
      suppression {
        id
        tenant
        name
        result
        activityLogQuerySuppressionEnabled
        activityLogQuerySuppression {
          query
          rawQuery
          threshold
        }
        pocQuerySuppressionEnabled
        pocQuerySuppression {
          query
          rawQuery
          suppressIfWeekendAndCantCallOnWeekends
        }
        restSuppressionEnabled
        restSuppression {
          endpoint
          method
          params {
            key
            value
            description
          }
          headers {
            key
            value
            description
          }
          auth {
            type
            info
          }
          response {
            path
            type
            value
            options
          }
          suppressOnError
          dispositionOnError
        }
        dncDotComEnabled
        dncDotCom {
          scrub
          state
          numberReassignment
          knownLitigator
          reassignmentSince
        }
        dncListSuppressionEnabled
        dncListSuppressions
        contactFieldSuppressionEnabled
        contactFieldSuppressions
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateSegment = /* GraphQL */ `
  mutation UpdateSegment(
    $input: UpdateSegmentInput!
    $condition: ModelSegmentConditionInput
  ) {
    updateSegment(input: $input, condition: $condition) {
      id
      tenant
      externalId
      name
      description
      query {
        qbQuery
        searchFilter
        sort {
          field
          direction
        }
      }
      filter {
        qbQuery
        searchFilter
        sort {
          field
          direction
        }
      }
      fieldsMappingId
      suppressionID
      suppression {
        id
        tenant
        name
        result
        activityLogQuerySuppressionEnabled
        activityLogQuerySuppression {
          query
          rawQuery
          threshold
        }
        pocQuerySuppressionEnabled
        pocQuerySuppression {
          query
          rawQuery
          suppressIfWeekendAndCantCallOnWeekends
        }
        restSuppressionEnabled
        restSuppression {
          endpoint
          method
          params {
            key
            value
            description
          }
          headers {
            key
            value
            description
          }
          auth {
            type
            info
          }
          response {
            path
            type
            value
            options
          }
          suppressOnError
          dispositionOnError
        }
        dncDotComEnabled
        dncDotCom {
          scrub
          state
          numberReassignment
          knownLitigator
          reassignmentSince
        }
        dncListSuppressionEnabled
        dncListSuppressions
        contactFieldSuppressionEnabled
        contactFieldSuppressions
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteSegment = /* GraphQL */ `
  mutation DeleteSegment(
    $input: DeleteSegmentInput!
    $condition: ModelSegmentConditionInput
  ) {
    deleteSegment(input: $input, condition: $condition) {
      id
      tenant
      externalId
      name
      description
      query {
        qbQuery
        searchFilter
        sort {
          field
          direction
        }
      }
      filter {
        qbQuery
        searchFilter
        sort {
          field
          direction
        }
      }
      fieldsMappingId
      suppressionID
      suppression {
        id
        tenant
        name
        result
        activityLogQuerySuppressionEnabled
        activityLogQuerySuppression {
          query
          rawQuery
          threshold
        }
        pocQuerySuppressionEnabled
        pocQuerySuppression {
          query
          rawQuery
          suppressIfWeekendAndCantCallOnWeekends
        }
        restSuppressionEnabled
        restSuppression {
          endpoint
          method
          params {
            key
            value
            description
          }
          headers {
            key
            value
            description
          }
          auth {
            type
            info
          }
          response {
            path
            type
            value
            options
          }
          suppressOnError
          dispositionOnError
        }
        dncDotComEnabled
        dncDotCom {
          scrub
          state
          numberReassignment
          knownLitigator
          reassignmentSince
        }
        dncListSuppressionEnabled
        dncListSuppressions
        contactFieldSuppressionEnabled
        contactFieldSuppressions
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createCampaignLog = /* GraphQL */ `
  mutation CreateCampaignLog(
    $input: CreateCampaignLogInput!
    $condition: ModelCampaignLogConditionInput
  ) {
    createCampaignLog(input: $input, condition: $condition) {
      id
      tenant
      campaign
      type
      metaData
      createdAt
      updatedAt
    }
  }
`;
export const updateCampaignLog = /* GraphQL */ `
  mutation UpdateCampaignLog(
    $input: UpdateCampaignLogInput!
    $condition: ModelCampaignLogConditionInput
  ) {
    updateCampaignLog(input: $input, condition: $condition) {
      id
      tenant
      campaign
      type
      metaData
      createdAt
      updatedAt
    }
  }
`;
export const deleteCampaignLog = /* GraphQL */ `
  mutation DeleteCampaignLog(
    $input: DeleteCampaignLogInput!
    $condition: ModelCampaignLogConditionInput
  ) {
    deleteCampaignLog(input: $input, condition: $condition) {
      id
      tenant
      campaign
      type
      metaData
      createdAt
      updatedAt
    }
  }
`;
export const createConfigProfile = /* GraphQL */ `
  mutation CreateConfigProfile(
    $input: CreateConfigProfileInput!
    $condition: ModelConfigProfileConditionInput
  ) {
    createConfigProfile(input: $input, condition: $condition) {
      id
      tenant
      name
      direction
      mediaType
      crmEntity
      org {
        name
        id
        url
        version
      }
      connection {
        dynamicsTenant
        appSecret
        clientId
        altUrl
        authUrl
        onPremVersion
      }
      skillIds
      multiSearch
      search
      searchAction
      activityEntityMap
      logging
      postLogging
      loggingAction
      createdAt
      updatedAt
    }
  }
`;
export const updateConfigProfile = /* GraphQL */ `
  mutation UpdateConfigProfile(
    $input: UpdateConfigProfileInput!
    $condition: ModelConfigProfileConditionInput
  ) {
    updateConfigProfile(input: $input, condition: $condition) {
      id
      tenant
      name
      direction
      mediaType
      crmEntity
      org {
        name
        id
        url
        version
      }
      connection {
        dynamicsTenant
        appSecret
        clientId
        altUrl
        authUrl
        onPremVersion
      }
      skillIds
      multiSearch
      search
      searchAction
      activityEntityMap
      logging
      postLogging
      loggingAction
      createdAt
      updatedAt
    }
  }
`;
export const deleteConfigProfile = /* GraphQL */ `
  mutation DeleteConfigProfile(
    $input: DeleteConfigProfileInput!
    $condition: ModelConfigProfileConditionInput
  ) {
    deleteConfigProfile(input: $input, condition: $condition) {
      id
      tenant
      name
      direction
      mediaType
      crmEntity
      org {
        name
        id
        url
        version
      }
      connection {
        dynamicsTenant
        appSecret
        clientId
        altUrl
        authUrl
        onPremVersion
      }
      skillIds
      multiSearch
      search
      searchAction
      activityEntityMap
      logging
      postLogging
      loggingAction
      createdAt
      updatedAt
    }
  }
`;
export const createCurfewXCodes = /* GraphQL */ `
  mutation CreateCurfewXCodes(
    $input: CreateCurfewXCodesInput!
    $condition: ModelCurfewXCodesConditionInput
  ) {
    createCurfewXCodes(input: $input, condition: $condition) {
      id
      minOffSet
      maxOffSet
      dls
      createdAt
      updatedAt
    }
  }
`;
export const updateCurfewXCodes = /* GraphQL */ `
  mutation UpdateCurfewXCodes(
    $input: UpdateCurfewXCodesInput!
    $condition: ModelCurfewXCodesConditionInput
  ) {
    updateCurfewXCodes(input: $input, condition: $condition) {
      id
      minOffSet
      maxOffSet
      dls
      createdAt
      updatedAt
    }
  }
`;
export const deleteCurfewXCodes = /* GraphQL */ `
  mutation DeleteCurfewXCodes(
    $input: DeleteCurfewXCodesInput!
    $condition: ModelCurfewXCodesConditionInput
  ) {
    deleteCurfewXCodes(input: $input, condition: $condition) {
      id
      minOffSet
      maxOffSet
      dls
      createdAt
      updatedAt
    }
  }
`;
export const createPaymentProfile = /* GraphQL */ `
  mutation CreatePaymentProfile(
    $input: CreatePaymentProfileInput!
    $condition: ModelPaymentProfileConditionInput
  ) {
    createPaymentProfile(input: $input, condition: $condition) {
      id
      tenant
      name
      vendorId
      apiKey
      descriptor
      ttl
      siteLogo
      header1
      header2
      sitePrimary
      siteSecondary
      minType
      minRate
      supportEmail
      supportPhone
      promptPaySkills
      channels
      c2a
      subject
      sendingEmail
      createdAt
      updatedAt
    }
  }
`;
export const updatePaymentProfile = /* GraphQL */ `
  mutation UpdatePaymentProfile(
    $input: UpdatePaymentProfileInput!
    $condition: ModelPaymentProfileConditionInput
  ) {
    updatePaymentProfile(input: $input, condition: $condition) {
      id
      tenant
      name
      vendorId
      apiKey
      descriptor
      ttl
      siteLogo
      header1
      header2
      sitePrimary
      siteSecondary
      minType
      minRate
      supportEmail
      supportPhone
      promptPaySkills
      channels
      c2a
      subject
      sendingEmail
      createdAt
      updatedAt
    }
  }
`;
export const deletePaymentProfile = /* GraphQL */ `
  mutation DeletePaymentProfile(
    $input: DeletePaymentProfileInput!
    $condition: ModelPaymentProfileConditionInput
  ) {
    deletePaymentProfile(input: $input, condition: $condition) {
      id
      tenant
      name
      vendorId
      apiKey
      descriptor
      ttl
      siteLogo
      header1
      header2
      sitePrimary
      siteSecondary
      minType
      minRate
      supportEmail
      supportPhone
      promptPaySkills
      channels
      c2a
      subject
      sendingEmail
      createdAt
      updatedAt
    }
  }
`;
export const createPaymentTemplate = /* GraphQL */ `
  mutation CreatePaymentTemplate(
    $input: CreatePaymentTemplateInput!
    $condition: ModelPaymentTemplateConditionInput
  ) {
    createPaymentTemplate(input: $input, condition: $condition) {
      id
      tenant
      name
      textBody
      type
      createdAt
      updatedAt
    }
  }
`;
export const updatePaymentTemplate = /* GraphQL */ `
  mutation UpdatePaymentTemplate(
    $input: UpdatePaymentTemplateInput!
    $condition: ModelPaymentTemplateConditionInput
  ) {
    updatePaymentTemplate(input: $input, condition: $condition) {
      id
      tenant
      name
      textBody
      type
      createdAt
      updatedAt
    }
  }
`;
export const deletePaymentTemplate = /* GraphQL */ `
  mutation DeletePaymentTemplate(
    $input: DeletePaymentTemplateInput!
    $condition: ModelPaymentTemplateConditionInput
  ) {
    deletePaymentTemplate(input: $input, condition: $condition) {
      id
      tenant
      name
      textBody
      type
      createdAt
      updatedAt
    }
  }
`;
export const createCustomDispoMapping = /* GraphQL */ `
  mutation CreateCustomDispoMapping(
    $input: CreateCustomDispoMappingInput!
    $condition: ModelCustomDispoMappingConditionInput
  ) {
    createCustomDispoMapping(input: $input, condition: $condition) {
      id
      tenant
      skill
      custDispId
      custDispName
      dispClassId
      dispCode
      dispDescription
      dispClassName
      createdAt
      updatedAt
    }
  }
`;
export const updateCustomDispoMapping = /* GraphQL */ `
  mutation UpdateCustomDispoMapping(
    $input: UpdateCustomDispoMappingInput!
    $condition: ModelCustomDispoMappingConditionInput
  ) {
    updateCustomDispoMapping(input: $input, condition: $condition) {
      id
      tenant
      skill
      custDispId
      custDispName
      dispClassId
      dispCode
      dispDescription
      dispClassName
      createdAt
      updatedAt
    }
  }
`;
export const deleteCustomDispoMapping = /* GraphQL */ `
  mutation DeleteCustomDispoMapping(
    $input: DeleteCustomDispoMappingInput!
    $condition: ModelCustomDispoMappingConditionInput
  ) {
    deleteCustomDispoMapping(input: $input, condition: $condition) {
      id
      tenant
      skill
      custDispId
      custDispName
      dispClassId
      dispCode
      dispDescription
      dispClassName
      createdAt
      updatedAt
    }
  }
`;
export const createCustomDispositions = /* GraphQL */ `
  mutation CreateCustomDispositions(
    $input: CreateCustomDispositionsInput!
    $condition: ModelCustomDispositionsConditionInput
  ) {
    createCustomDispositions(input: $input, condition: $condition) {
      id
      tenant
      name
      description
      classification
      previewDispo
      noteRequired
      amountRequired
      rescheduleDateRequired
      createdAt
      updatedAt
    }
  }
`;
export const updateCustomDispositions = /* GraphQL */ `
  mutation UpdateCustomDispositions(
    $input: UpdateCustomDispositionsInput!
    $condition: ModelCustomDispositionsConditionInput
  ) {
    updateCustomDispositions(input: $input, condition: $condition) {
      id
      tenant
      name
      description
      classification
      previewDispo
      noteRequired
      amountRequired
      rescheduleDateRequired
      createdAt
      updatedAt
    }
  }
`;
export const deleteCustomDispositions = /* GraphQL */ `
  mutation DeleteCustomDispositions(
    $input: DeleteCustomDispositionsInput!
    $condition: ModelCustomDispositionsConditionInput
  ) {
    deleteCustomDispositions(input: $input, condition: $condition) {
      id
      tenant
      name
      description
      classification
      previewDispo
      noteRequired
      amountRequired
      rescheduleDateRequired
      createdAt
      updatedAt
    }
  }
`;
export const createDncList = /* GraphQL */ `
  mutation CreateDncList(
    $input: CreateDNCListInput!
    $condition: ModelDNCListConditionInput
  ) {
    createDNCList(input: $input, condition: $condition) {
      id
      tenant
      name
      numbers {
        items {
          id
          tenant
          phoneNumber
          dncList
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateDncList = /* GraphQL */ `
  mutation UpdateDncList(
    $input: UpdateDNCListInput!
    $condition: ModelDNCListConditionInput
  ) {
    updateDNCList(input: $input, condition: $condition) {
      id
      tenant
      name
      numbers {
        items {
          id
          tenant
          phoneNumber
          dncList
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteDncList = /* GraphQL */ `
  mutation DeleteDncList(
    $input: DeleteDNCListInput!
    $condition: ModelDNCListConditionInput
  ) {
    deleteDNCList(input: $input, condition: $condition) {
      id
      tenant
      name
      numbers {
        items {
          id
          tenant
          phoneNumber
          dncList
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createDnc = /* GraphQL */ `
  mutation CreateDnc(
    $input: CreateDNCInput!
    $condition: ModelDNCConditionInput
  ) {
    createDNC(input: $input, condition: $condition) {
      id
      tenant
      phoneNumber
      dncList
      createdAt
      updatedAt
    }
  }
`;
export const updateDnc = /* GraphQL */ `
  mutation UpdateDnc(
    $input: UpdateDNCInput!
    $condition: ModelDNCConditionInput
  ) {
    updateDNC(input: $input, condition: $condition) {
      id
      tenant
      phoneNumber
      dncList
      createdAt
      updatedAt
    }
  }
`;
export const deleteDnc = /* GraphQL */ `
  mutation DeleteDnc(
    $input: DeleteDNCInput!
    $condition: ModelDNCConditionInput
  ) {
    deleteDNC(input: $input, condition: $condition) {
      id
      tenant
      phoneNumber
      dncList
      createdAt
      updatedAt
    }
  }
`;
export const createContactFieldSuppressionList = /* GraphQL */ `
  mutation CreateContactFieldSuppressionList(
    $input: CreateContactFieldSuppressionListInput!
    $condition: ModelContactFieldSuppressionListConditionInput
  ) {
    createContactFieldSuppressionList(input: $input, condition: $condition) {
      id
      tenant
      name
      contactField
      fields {
        items {
          id
          tenant
          contactFieldValue
          contactFieldSuppressionList
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateContactFieldSuppressionList = /* GraphQL */ `
  mutation UpdateContactFieldSuppressionList(
    $input: UpdateContactFieldSuppressionListInput!
    $condition: ModelContactFieldSuppressionListConditionInput
  ) {
    updateContactFieldSuppressionList(input: $input, condition: $condition) {
      id
      tenant
      name
      contactField
      fields {
        items {
          id
          tenant
          contactFieldValue
          contactFieldSuppressionList
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteContactFieldSuppressionList = /* GraphQL */ `
  mutation DeleteContactFieldSuppressionList(
    $input: DeleteContactFieldSuppressionListInput!
    $condition: ModelContactFieldSuppressionListConditionInput
  ) {
    deleteContactFieldSuppressionList(input: $input, condition: $condition) {
      id
      tenant
      name
      contactField
      fields {
        items {
          id
          tenant
          contactFieldValue
          contactFieldSuppressionList
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createContactFieldSuppression = /* GraphQL */ `
  mutation CreateContactFieldSuppression(
    $input: CreateContactFieldSuppressionInput!
    $condition: ModelContactFieldSuppressionConditionInput
  ) {
    createContactFieldSuppression(input: $input, condition: $condition) {
      id
      tenant
      contactFieldValue
      contactFieldSuppressionList
      createdAt
      updatedAt
    }
  }
`;
export const updateContactFieldSuppression = /* GraphQL */ `
  mutation UpdateContactFieldSuppression(
    $input: UpdateContactFieldSuppressionInput!
    $condition: ModelContactFieldSuppressionConditionInput
  ) {
    updateContactFieldSuppression(input: $input, condition: $condition) {
      id
      tenant
      contactFieldValue
      contactFieldSuppressionList
      createdAt
      updatedAt
    }
  }
`;
export const deleteContactFieldSuppression = /* GraphQL */ `
  mutation DeleteContactFieldSuppression(
    $input: DeleteContactFieldSuppressionInput!
    $condition: ModelContactFieldSuppressionConditionInput
  ) {
    deleteContactFieldSuppression(input: $input, condition: $condition) {
      id
      tenant
      contactFieldValue
      contactFieldSuppressionList
      createdAt
      updatedAt
    }
  }
`;
export const createSuppression = /* GraphQL */ `
  mutation CreateSuppression(
    $input: CreateSuppressionInput!
    $condition: ModelSuppressionConditionInput
  ) {
    createSuppression(input: $input, condition: $condition) {
      id
      tenant
      name
      result
      activityLogQuerySuppressionEnabled
      activityLogQuerySuppression {
        query
        rawQuery
        threshold
      }
      pocQuerySuppressionEnabled
      pocQuerySuppression {
        query
        rawQuery
        suppressIfWeekendAndCantCallOnWeekends
      }
      restSuppressionEnabled
      restSuppression {
        endpoint
        method
        params {
          key
          value
          description
        }
        headers {
          key
          value
          description
        }
        auth {
          type
          info
        }
        response {
          path
          type
          value
          options
        }
        suppressOnError
        dispositionOnError
      }
      dncDotComEnabled
      dncDotCom {
        scrub
        state
        numberReassignment
        knownLitigator
        reassignmentSince
      }
      dncListSuppressionEnabled
      dncListSuppressions
      contactFieldSuppressionEnabled
      contactFieldSuppressions
      createdAt
      updatedAt
    }
  }
`;
export const updateSuppression = /* GraphQL */ `
  mutation UpdateSuppression(
    $input: UpdateSuppressionInput!
    $condition: ModelSuppressionConditionInput
  ) {
    updateSuppression(input: $input, condition: $condition) {
      id
      tenant
      name
      result
      activityLogQuerySuppressionEnabled
      activityLogQuerySuppression {
        query
        rawQuery
        threshold
      }
      pocQuerySuppressionEnabled
      pocQuerySuppression {
        query
        rawQuery
        suppressIfWeekendAndCantCallOnWeekends
      }
      restSuppressionEnabled
      restSuppression {
        endpoint
        method
        params {
          key
          value
          description
        }
        headers {
          key
          value
          description
        }
        auth {
          type
          info
        }
        response {
          path
          type
          value
          options
        }
        suppressOnError
        dispositionOnError
      }
      dncDotComEnabled
      dncDotCom {
        scrub
        state
        numberReassignment
        knownLitigator
        reassignmentSince
      }
      dncListSuppressionEnabled
      dncListSuppressions
      contactFieldSuppressionEnabled
      contactFieldSuppressions
      createdAt
      updatedAt
    }
  }
`;
export const deleteSuppression = /* GraphQL */ `
  mutation DeleteSuppression(
    $input: DeleteSuppressionInput!
    $condition: ModelSuppressionConditionInput
  ) {
    deleteSuppression(input: $input, condition: $condition) {
      id
      tenant
      name
      result
      activityLogQuerySuppressionEnabled
      activityLogQuerySuppression {
        query
        rawQuery
        threshold
      }
      pocQuerySuppressionEnabled
      pocQuerySuppression {
        query
        rawQuery
        suppressIfWeekendAndCantCallOnWeekends
      }
      restSuppressionEnabled
      restSuppression {
        endpoint
        method
        params {
          key
          value
          description
        }
        headers {
          key
          value
          description
        }
        auth {
          type
          info
        }
        response {
          path
          type
          value
          options
        }
        suppressOnError
        dispositionOnError
      }
      dncDotComEnabled
      dncDotCom {
        scrub
        state
        numberReassignment
        knownLitigator
        reassignmentSince
      }
      dncListSuppressionEnabled
      dncListSuppressions
      contactFieldSuppressionEnabled
      contactFieldSuppressions
      createdAt
      updatedAt
    }
  }
`;
export const createOAuthLookUp = /* GraphQL */ `
  mutation CreateOAuthLookUp(
    $input: CreateOAuthLookUpInput!
    $condition: ModelOAuthLookUpConditionInput
  ) {
    createOAuthLookUp(input: $input, condition: $condition) {
      id
      clientSecret
      cognitoUserName
      cognitoPassword
      createdAt
      updatedAt
    }
  }
`;
export const updateOAuthLookUp = /* GraphQL */ `
  mutation UpdateOAuthLookUp(
    $input: UpdateOAuthLookUpInput!
    $condition: ModelOAuthLookUpConditionInput
  ) {
    updateOAuthLookUp(input: $input, condition: $condition) {
      id
      clientSecret
      cognitoUserName
      cognitoPassword
      createdAt
      updatedAt
    }
  }
`;
export const deleteOAuthLookUp = /* GraphQL */ `
  mutation DeleteOAuthLookUp(
    $input: DeleteOAuthLookUpInput!
    $condition: ModelOAuthLookUpConditionInput
  ) {
    deleteOAuthLookUp(input: $input, condition: $condition) {
      id
      clientSecret
      cognitoUserName
      cognitoPassword
      createdAt
      updatedAt
    }
  }
`;
export const createContact = /* GraphQL */ `
  mutation CreateContact(
    $input: CreateContactInput!
    $condition: ModelContactConditionInput
  ) {
    createContact(input: $input, condition: $condition) {
      id
      tenant
      externalId
      firstName
      lastName
      source
      phone
      cell
      email
      dateCreated
      address1
      address2
      city
      state
      zip
      dnc
      updatedBy
      customFields
      expireDt
      outboundCallerId
      balances {
        id
        type
        amount
        age
      }
      timeZoneBounds {
        min {
          offset
          dst
        }
        max {
          offset
          dst
        }
      }
      type
      optOuts {
        voice
        sms
        email
      }
      optIns {
        voice
        sms
        email
      }
      attempts
      createdAt
      updatedAt
    }
  }
`;
export const updateContact = /* GraphQL */ `
  mutation UpdateContact(
    $input: UpdateContactInput!
    $condition: ModelContactConditionInput
  ) {
    updateContact(input: $input, condition: $condition) {
      id
      tenant
      externalId
      firstName
      lastName
      source
      phone
      cell
      email
      dateCreated
      address1
      address2
      city
      state
      zip
      dnc
      updatedBy
      customFields
      expireDt
      outboundCallerId
      balances {
        id
        type
        amount
        age
      }
      timeZoneBounds {
        min {
          offset
          dst
        }
        max {
          offset
          dst
        }
      }
      type
      optOuts {
        voice
        sms
        email
      }
      optIns {
        voice
        sms
        email
      }
      attempts
      createdAt
      updatedAt
    }
  }
`;
export const deleteContact = /* GraphQL */ `
  mutation DeleteContact(
    $input: DeleteContactInput!
    $condition: ModelContactConditionInput
  ) {
    deleteContact(input: $input, condition: $condition) {
      id
      tenant
      externalId
      firstName
      lastName
      source
      phone
      cell
      email
      dateCreated
      address1
      address2
      city
      state
      zip
      dnc
      updatedBy
      customFields
      expireDt
      outboundCallerId
      balances {
        id
        type
        amount
        age
      }
      timeZoneBounds {
        min {
          offset
          dst
        }
        max {
          offset
          dst
        }
      }
      type
      optOuts {
        voice
        sms
        email
      }
      optIns {
        voice
        sms
        email
      }
      attempts
      createdAt
      updatedAt
    }
  }
`;
export const createActivityLog = /* GraphQL */ `
  mutation CreateActivityLog(
    $input: CreateActivityLogInput!
    $condition: ModelActivityLogConditionInput
  ) {
    createActivityLog(input: $input, condition: $condition) {
      id
      tenant
      type
      disposition
      title
      body
      activityLogContactId
      segment {
        id
        tenant
        externalId
        name
        description
        query {
          qbQuery
          searchFilter
          sort {
            field
            direction
          }
        }
        filter {
          qbQuery
          searchFilter
          sort {
            field
            direction
          }
        }
        fieldsMappingId
        suppressionID
        suppression {
          id
          tenant
          name
          result
          activityLogQuerySuppressionEnabled
          activityLogQuerySuppression {
            query
            rawQuery
            threshold
          }
          pocQuerySuppressionEnabled
          pocQuerySuppression {
            query
            rawQuery
            suppressIfWeekendAndCantCallOnWeekends
          }
          restSuppressionEnabled
          restSuppression {
            endpoint
            method
            params {
              key
              value
              description
            }
            headers {
              key
              value
              description
            }
            auth {
              type
              info
            }
            response {
              path
              type
              value
              options
            }
            suppressOnError
            dispositionOnError
          }
          dncDotComEnabled
          dncDotCom {
            scrub
            state
            numberReassignment
            knownLitigator
            reassignmentSince
          }
          dncListSuppressionEnabled
          dncListSuppressions
          contactFieldSuppressionEnabled
          contactFieldSuppressions
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      campaign {
        id
        tenant
        name
        segments
        segmentSchedule
        campaignSchedule
        static
        sort
        skillId
        skillName
        skillSource
        campaignProfileId
        profile {
          id
          tenant
          name
          useAi
          flowRatio
          scrubber {
            cell
            natDnc
            stateDnc
            orgDnc
            phoneDup
            contactDup
          }
          retry {
            minDuration
            maxAttempts
            maxContacts
            custRetry
            ratio
          }
          ai {
            org
            system
          }
          pushContacts
          startTime
          endTime
          buffer
          channels {
            type
            order
            retryDelay
            maxAttempts
            maxContacts
            custRetry
            paymentProfileId
            paymentProfileName
            dispositions
            cadence
            dialingScript
          }
          ratio
          dontCallOnWeekends
          callWindow {
            days
            tmz {
              offset
              dst
            }
            beginTime {
              hour
              minute
            }
            endTime {
              hour
              minute
            }
            callWindowQuery {
              field
              operator
              value
            }
            default
            order
          }
          clickToCall
          callWindows {
            days
            tmz {
              offset
              dst
            }
            beginTime {
              hour
              minute
            }
            endTime {
              hour
              minute
            }
            callWindowQuery {
              field
              operator
              value
            }
            default
            order
          }
          dispositionScoring {
            noAnswer
            answer
            noAgentAvailableToDial
            noAgentOnAnswer
            overApproving
          }
          overrideDispositionScoring
          overrideDialToAgentRatio
          dialToAgentRatio
          freshLIFO
          retryLIFO
          createdAt
          updatedAt
        }
        status
        expireDate
        stopOnCompletion
        cci
        autoMode
        cciMetaData
        createdAt
        updatedAt
      }
      agent
      contactStatus
      timestamp
      metaData
      provider
      c2aId
      pocActual
      createdAt
      updatedAt
      contact {
        id
        tenant
        externalId
        firstName
        lastName
        source
        phone
        cell
        email
        dateCreated
        address1
        address2
        city
        state
        zip
        dnc
        updatedBy
        customFields
        expireDt
        outboundCallerId
        balances {
          id
          type
          amount
          age
        }
        timeZoneBounds {
          min {
            offset
            dst
          }
          max {
            offset
            dst
          }
        }
        type
        optOuts {
          voice
          sms
          email
        }
        optIns {
          voice
          sms
          email
        }
        attempts
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateActivityLog = /* GraphQL */ `
  mutation UpdateActivityLog(
    $input: UpdateActivityLogInput!
    $condition: ModelActivityLogConditionInput
  ) {
    updateActivityLog(input: $input, condition: $condition) {
      id
      tenant
      type
      disposition
      title
      body
      activityLogContactId
      segment {
        id
        tenant
        externalId
        name
        description
        query {
          qbQuery
          searchFilter
          sort {
            field
            direction
          }
        }
        filter {
          qbQuery
          searchFilter
          sort {
            field
            direction
          }
        }
        fieldsMappingId
        suppressionID
        suppression {
          id
          tenant
          name
          result
          activityLogQuerySuppressionEnabled
          activityLogQuerySuppression {
            query
            rawQuery
            threshold
          }
          pocQuerySuppressionEnabled
          pocQuerySuppression {
            query
            rawQuery
            suppressIfWeekendAndCantCallOnWeekends
          }
          restSuppressionEnabled
          restSuppression {
            endpoint
            method
            params {
              key
              value
              description
            }
            headers {
              key
              value
              description
            }
            auth {
              type
              info
            }
            response {
              path
              type
              value
              options
            }
            suppressOnError
            dispositionOnError
          }
          dncDotComEnabled
          dncDotCom {
            scrub
            state
            numberReassignment
            knownLitigator
            reassignmentSince
          }
          dncListSuppressionEnabled
          dncListSuppressions
          contactFieldSuppressionEnabled
          contactFieldSuppressions
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      campaign {
        id
        tenant
        name
        segments
        segmentSchedule
        campaignSchedule
        static
        sort
        skillId
        skillName
        skillSource
        campaignProfileId
        profile {
          id
          tenant
          name
          useAi
          flowRatio
          scrubber {
            cell
            natDnc
            stateDnc
            orgDnc
            phoneDup
            contactDup
          }
          retry {
            minDuration
            maxAttempts
            maxContacts
            custRetry
            ratio
          }
          ai {
            org
            system
          }
          pushContacts
          startTime
          endTime
          buffer
          channels {
            type
            order
            retryDelay
            maxAttempts
            maxContacts
            custRetry
            paymentProfileId
            paymentProfileName
            dispositions
            cadence
            dialingScript
          }
          ratio
          dontCallOnWeekends
          callWindow {
            days
            tmz {
              offset
              dst
            }
            beginTime {
              hour
              minute
            }
            endTime {
              hour
              minute
            }
            callWindowQuery {
              field
              operator
              value
            }
            default
            order
          }
          clickToCall
          callWindows {
            days
            tmz {
              offset
              dst
            }
            beginTime {
              hour
              minute
            }
            endTime {
              hour
              minute
            }
            callWindowQuery {
              field
              operator
              value
            }
            default
            order
          }
          dispositionScoring {
            noAnswer
            answer
            noAgentAvailableToDial
            noAgentOnAnswer
            overApproving
          }
          overrideDispositionScoring
          overrideDialToAgentRatio
          dialToAgentRatio
          freshLIFO
          retryLIFO
          createdAt
          updatedAt
        }
        status
        expireDate
        stopOnCompletion
        cci
        autoMode
        cciMetaData
        createdAt
        updatedAt
      }
      agent
      contactStatus
      timestamp
      metaData
      provider
      c2aId
      pocActual
      createdAt
      updatedAt
      contact {
        id
        tenant
        externalId
        firstName
        lastName
        source
        phone
        cell
        email
        dateCreated
        address1
        address2
        city
        state
        zip
        dnc
        updatedBy
        customFields
        expireDt
        outboundCallerId
        balances {
          id
          type
          amount
          age
        }
        timeZoneBounds {
          min {
            offset
            dst
          }
          max {
            offset
            dst
          }
        }
        type
        optOuts {
          voice
          sms
          email
        }
        optIns {
          voice
          sms
          email
        }
        attempts
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteActivityLog = /* GraphQL */ `
  mutation DeleteActivityLog(
    $input: DeleteActivityLogInput!
    $condition: ModelActivityLogConditionInput
  ) {
    deleteActivityLog(input: $input, condition: $condition) {
      id
      tenant
      type
      disposition
      title
      body
      activityLogContactId
      segment {
        id
        tenant
        externalId
        name
        description
        query {
          qbQuery
          searchFilter
          sort {
            field
            direction
          }
        }
        filter {
          qbQuery
          searchFilter
          sort {
            field
            direction
          }
        }
        fieldsMappingId
        suppressionID
        suppression {
          id
          tenant
          name
          result
          activityLogQuerySuppressionEnabled
          activityLogQuerySuppression {
            query
            rawQuery
            threshold
          }
          pocQuerySuppressionEnabled
          pocQuerySuppression {
            query
            rawQuery
            suppressIfWeekendAndCantCallOnWeekends
          }
          restSuppressionEnabled
          restSuppression {
            endpoint
            method
            params {
              key
              value
              description
            }
            headers {
              key
              value
              description
            }
            auth {
              type
              info
            }
            response {
              path
              type
              value
              options
            }
            suppressOnError
            dispositionOnError
          }
          dncDotComEnabled
          dncDotCom {
            scrub
            state
            numberReassignment
            knownLitigator
            reassignmentSince
          }
          dncListSuppressionEnabled
          dncListSuppressions
          contactFieldSuppressionEnabled
          contactFieldSuppressions
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      campaign {
        id
        tenant
        name
        segments
        segmentSchedule
        campaignSchedule
        static
        sort
        skillId
        skillName
        skillSource
        campaignProfileId
        profile {
          id
          tenant
          name
          useAi
          flowRatio
          scrubber {
            cell
            natDnc
            stateDnc
            orgDnc
            phoneDup
            contactDup
          }
          retry {
            minDuration
            maxAttempts
            maxContacts
            custRetry
            ratio
          }
          ai {
            org
            system
          }
          pushContacts
          startTime
          endTime
          buffer
          channels {
            type
            order
            retryDelay
            maxAttempts
            maxContacts
            custRetry
            paymentProfileId
            paymentProfileName
            dispositions
            cadence
            dialingScript
          }
          ratio
          dontCallOnWeekends
          callWindow {
            days
            tmz {
              offset
              dst
            }
            beginTime {
              hour
              minute
            }
            endTime {
              hour
              minute
            }
            callWindowQuery {
              field
              operator
              value
            }
            default
            order
          }
          clickToCall
          callWindows {
            days
            tmz {
              offset
              dst
            }
            beginTime {
              hour
              minute
            }
            endTime {
              hour
              minute
            }
            callWindowQuery {
              field
              operator
              value
            }
            default
            order
          }
          dispositionScoring {
            noAnswer
            answer
            noAgentAvailableToDial
            noAgentOnAnswer
            overApproving
          }
          overrideDispositionScoring
          overrideDialToAgentRatio
          dialToAgentRatio
          freshLIFO
          retryLIFO
          createdAt
          updatedAt
        }
        status
        expireDate
        stopOnCompletion
        cci
        autoMode
        cciMetaData
        createdAt
        updatedAt
      }
      agent
      contactStatus
      timestamp
      metaData
      provider
      c2aId
      pocActual
      createdAt
      updatedAt
      contact {
        id
        tenant
        externalId
        firstName
        lastName
        source
        phone
        cell
        email
        dateCreated
        address1
        address2
        city
        state
        zip
        dnc
        updatedBy
        customFields
        expireDt
        outboundCallerId
        balances {
          id
          type
          amount
          age
        }
        timeZoneBounds {
          min {
            offset
            dst
          }
          max {
            offset
            dst
          }
        }
        type
        optOuts {
          voice
          sms
          email
        }
        optIns {
          voice
          sms
          email
        }
        attempts
        createdAt
        updatedAt
      }
    }
  }
`;
export const createAgentScript = /* GraphQL */ `
  mutation CreateAgentScript(
    $input: CreateAgentScriptInput!
    $condition: ModelAgentScriptConditionInput
  ) {
    createAgentScript(input: $input, condition: $condition) {
      id
      tenant
      name
      level
      type
      textBody
      isRoot
      createdAt
      updatedAt
      children {
        items {
          id
          parentId
          childId
          createdAt
          updatedAt
          parent {
            id
            tenant
            name
            level
            type
            textBody
            isRoot
            createdAt
            updatedAt
            children {
              items {
                id
                parentId
                childId
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          child {
            id
            tenant
            name
            level
            type
            textBody
            isRoot
            createdAt
            updatedAt
            children {
              items {
                id
                parentId
                childId
                createdAt
                updatedAt
              }
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const updateAgentScript = /* GraphQL */ `
  mutation UpdateAgentScript(
    $input: UpdateAgentScriptInput!
    $condition: ModelAgentScriptConditionInput
  ) {
    updateAgentScript(input: $input, condition: $condition) {
      id
      tenant
      name
      level
      type
      textBody
      isRoot
      createdAt
      updatedAt
      children {
        items {
          id
          parentId
          childId
          createdAt
          updatedAt
          parent {
            id
            tenant
            name
            level
            type
            textBody
            isRoot
            createdAt
            updatedAt
            children {
              items {
                id
                parentId
                childId
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          child {
            id
            tenant
            name
            level
            type
            textBody
            isRoot
            createdAt
            updatedAt
            children {
              items {
                id
                parentId
                childId
                createdAt
                updatedAt
              }
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteAgentScript = /* GraphQL */ `
  mutation DeleteAgentScript(
    $input: DeleteAgentScriptInput!
    $condition: ModelAgentScriptConditionInput
  ) {
    deleteAgentScript(input: $input, condition: $condition) {
      id
      tenant
      name
      level
      type
      textBody
      isRoot
      createdAt
      updatedAt
      children {
        items {
          id
          parentId
          childId
          createdAt
          updatedAt
          parent {
            id
            tenant
            name
            level
            type
            textBody
            isRoot
            createdAt
            updatedAt
            children {
              items {
                id
                parentId
                childId
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          child {
            id
            tenant
            name
            level
            type
            textBody
            isRoot
            createdAt
            updatedAt
            children {
              items {
                id
                parentId
                childId
                createdAt
                updatedAt
              }
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const createAgentScriptConnector = /* GraphQL */ `
  mutation CreateAgentScriptConnector(
    $input: CreateAgentScriptConnectorInput!
    $condition: ModelAgentScriptConnectorConditionInput
  ) {
    createAgentScriptConnector(input: $input, condition: $condition) {
      id
      parentId
      childId
      createdAt
      updatedAt
      parent {
        id
        tenant
        name
        level
        type
        textBody
        isRoot
        createdAt
        updatedAt
        children {
          items {
            id
            parentId
            childId
            createdAt
            updatedAt
            parent {
              id
              tenant
              name
              level
              type
              textBody
              isRoot
              createdAt
              updatedAt
              children {
                nextToken
              }
            }
            child {
              id
              tenant
              name
              level
              type
              textBody
              isRoot
              createdAt
              updatedAt
              children {
                nextToken
              }
            }
          }
          nextToken
        }
      }
      child {
        id
        tenant
        name
        level
        type
        textBody
        isRoot
        createdAt
        updatedAt
        children {
          items {
            id
            parentId
            childId
            createdAt
            updatedAt
            parent {
              id
              tenant
              name
              level
              type
              textBody
              isRoot
              createdAt
              updatedAt
              children {
                nextToken
              }
            }
            child {
              id
              tenant
              name
              level
              type
              textBody
              isRoot
              createdAt
              updatedAt
              children {
                nextToken
              }
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const updateAgentScriptConnector = /* GraphQL */ `
  mutation UpdateAgentScriptConnector(
    $input: UpdateAgentScriptConnectorInput!
    $condition: ModelAgentScriptConnectorConditionInput
  ) {
    updateAgentScriptConnector(input: $input, condition: $condition) {
      id
      parentId
      childId
      createdAt
      updatedAt
      parent {
        id
        tenant
        name
        level
        type
        textBody
        isRoot
        createdAt
        updatedAt
        children {
          items {
            id
            parentId
            childId
            createdAt
            updatedAt
            parent {
              id
              tenant
              name
              level
              type
              textBody
              isRoot
              createdAt
              updatedAt
              children {
                nextToken
              }
            }
            child {
              id
              tenant
              name
              level
              type
              textBody
              isRoot
              createdAt
              updatedAt
              children {
                nextToken
              }
            }
          }
          nextToken
        }
      }
      child {
        id
        tenant
        name
        level
        type
        textBody
        isRoot
        createdAt
        updatedAt
        children {
          items {
            id
            parentId
            childId
            createdAt
            updatedAt
            parent {
              id
              tenant
              name
              level
              type
              textBody
              isRoot
              createdAt
              updatedAt
              children {
                nextToken
              }
            }
            child {
              id
              tenant
              name
              level
              type
              textBody
              isRoot
              createdAt
              updatedAt
              children {
                nextToken
              }
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteAgentScriptConnector = /* GraphQL */ `
  mutation DeleteAgentScriptConnector(
    $input: DeleteAgentScriptConnectorInput!
    $condition: ModelAgentScriptConnectorConditionInput
  ) {
    deleteAgentScriptConnector(input: $input, condition: $condition) {
      id
      parentId
      childId
      createdAt
      updatedAt
      parent {
        id
        tenant
        name
        level
        type
        textBody
        isRoot
        createdAt
        updatedAt
        children {
          items {
            id
            parentId
            childId
            createdAt
            updatedAt
            parent {
              id
              tenant
              name
              level
              type
              textBody
              isRoot
              createdAt
              updatedAt
              children {
                nextToken
              }
            }
            child {
              id
              tenant
              name
              level
              type
              textBody
              isRoot
              createdAt
              updatedAt
              children {
                nextToken
              }
            }
          }
          nextToken
        }
      }
      child {
        id
        tenant
        name
        level
        type
        textBody
        isRoot
        createdAt
        updatedAt
        children {
          items {
            id
            parentId
            childId
            createdAt
            updatedAt
            parent {
              id
              tenant
              name
              level
              type
              textBody
              isRoot
              createdAt
              updatedAt
              children {
                nextToken
              }
            }
            child {
              id
              tenant
              name
              level
              type
              textBody
              isRoot
              createdAt
              updatedAt
              children {
                nextToken
              }
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const createCryptoPassport = /* GraphQL */ `
  mutation CreateCryptoPassport(
    $input: CreateCryptoPassportInput!
    $condition: ModelCryptoPassportConditionInput
  ) {
    createCryptoPassport(input: $input, condition: $condition) {
      id
      token
      ttl
      createdAt
      updatedAt
    }
  }
`;
export const updateCryptoPassport = /* GraphQL */ `
  mutation UpdateCryptoPassport(
    $input: UpdateCryptoPassportInput!
    $condition: ModelCryptoPassportConditionInput
  ) {
    updateCryptoPassport(input: $input, condition: $condition) {
      id
      token
      ttl
      createdAt
      updatedAt
    }
  }
`;
export const deleteCryptoPassport = /* GraphQL */ `
  mutation DeleteCryptoPassport(
    $input: DeleteCryptoPassportInput!
    $condition: ModelCryptoPassportConditionInput
  ) {
    deleteCryptoPassport(input: $input, condition: $condition) {
      id
      token
      ttl
      createdAt
      updatedAt
    }
  }
`;
export const createContactFieldMeta = /* GraphQL */ `
  mutation CreateContactFieldMeta(
    $input: CreateContactFieldMetaInput!
    $condition: ModelContactFieldMetaConditionInput
  ) {
    createContactFieldMeta(input: $input, condition: $condition) {
      id
      tenant
      contact
      field
      type
      poc {
        cell
        consent
        writtenConsent
        optOut
        preferred
        thirdParty
        dontCallOnWeekends
        callWindows {
          days
          tmz {
            offset
            dst
          }
          beginTime {
            hour
            minute
          }
          endTime {
            hour
            minute
          }
          callWindowQuery {
            field
            operator
            value
          }
          default
          order
        }
      }
      updatedBy
      createdAt
      updatedAt
      contactModel {
        id
        tenant
        externalId
        firstName
        lastName
        source
        phone
        cell
        email
        dateCreated
        address1
        address2
        city
        state
        zip
        dnc
        updatedBy
        customFields
        expireDt
        outboundCallerId
        balances {
          id
          type
          amount
          age
        }
        timeZoneBounds {
          min {
            offset
            dst
          }
          max {
            offset
            dst
          }
        }
        type
        optOuts {
          voice
          sms
          email
        }
        optIns {
          voice
          sms
          email
        }
        attempts
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateContactFieldMeta = /* GraphQL */ `
  mutation UpdateContactFieldMeta(
    $input: UpdateContactFieldMetaInput!
    $condition: ModelContactFieldMetaConditionInput
  ) {
    updateContactFieldMeta(input: $input, condition: $condition) {
      id
      tenant
      contact
      field
      type
      poc {
        cell
        consent
        writtenConsent
        optOut
        preferred
        thirdParty
        dontCallOnWeekends
        callWindows {
          days
          tmz {
            offset
            dst
          }
          beginTime {
            hour
            minute
          }
          endTime {
            hour
            minute
          }
          callWindowQuery {
            field
            operator
            value
          }
          default
          order
        }
      }
      updatedBy
      createdAt
      updatedAt
      contactModel {
        id
        tenant
        externalId
        firstName
        lastName
        source
        phone
        cell
        email
        dateCreated
        address1
        address2
        city
        state
        zip
        dnc
        updatedBy
        customFields
        expireDt
        outboundCallerId
        balances {
          id
          type
          amount
          age
        }
        timeZoneBounds {
          min {
            offset
            dst
          }
          max {
            offset
            dst
          }
        }
        type
        optOuts {
          voice
          sms
          email
        }
        optIns {
          voice
          sms
          email
        }
        attempts
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteContactFieldMeta = /* GraphQL */ `
  mutation DeleteContactFieldMeta(
    $input: DeleteContactFieldMetaInput!
    $condition: ModelContactFieldMetaConditionInput
  ) {
    deleteContactFieldMeta(input: $input, condition: $condition) {
      id
      tenant
      contact
      field
      type
      poc {
        cell
        consent
        writtenConsent
        optOut
        preferred
        thirdParty
        dontCallOnWeekends
        callWindows {
          days
          tmz {
            offset
            dst
          }
          beginTime {
            hour
            minute
          }
          endTime {
            hour
            minute
          }
          callWindowQuery {
            field
            operator
            value
          }
          default
          order
        }
      }
      updatedBy
      createdAt
      updatedAt
      contactModel {
        id
        tenant
        externalId
        firstName
        lastName
        source
        phone
        cell
        email
        dateCreated
        address1
        address2
        city
        state
        zip
        dnc
        updatedBy
        customFields
        expireDt
        outboundCallerId
        balances {
          id
          type
          amount
          age
        }
        timeZoneBounds {
          min {
            offset
            dst
          }
          max {
            offset
            dst
          }
        }
        type
        optOuts {
          voice
          sms
          email
        }
        optIns {
          voice
          sms
          email
        }
        attempts
        createdAt
        updatedAt
      }
    }
  }
`;
export const createDailyLicenseCount = /* GraphQL */ `
  mutation CreateDailyLicenseCount(
    $input: CreateDailyLicenseCountInput!
    $condition: ModelDailyLicenseCountConditionInput
  ) {
    createDailyLicenseCount(input: $input, condition: $condition) {
      id
      ownerTenant
      tenant
      licenseType
      ncBusinessUnit
      Date
      LicenseCount
      SmsCount
      EmailCount
      createdAt
      updatedAt
    }
  }
`;
export const updateDailyLicenseCount = /* GraphQL */ `
  mutation UpdateDailyLicenseCount(
    $input: UpdateDailyLicenseCountInput!
    $condition: ModelDailyLicenseCountConditionInput
  ) {
    updateDailyLicenseCount(input: $input, condition: $condition) {
      id
      ownerTenant
      tenant
      licenseType
      ncBusinessUnit
      Date
      LicenseCount
      SmsCount
      EmailCount
      createdAt
      updatedAt
    }
  }
`;
export const deleteDailyLicenseCount = /* GraphQL */ `
  mutation DeleteDailyLicenseCount(
    $input: DeleteDailyLicenseCountInput!
    $condition: ModelDailyLicenseCountConditionInput
  ) {
    deleteDailyLicenseCount(input: $input, condition: $condition) {
      id
      ownerTenant
      tenant
      licenseType
      ncBusinessUnit
      Date
      LicenseCount
      SmsCount
      EmailCount
      createdAt
      updatedAt
    }
  }
`;

const SuppressionDefaults = [
	{ display: 'Suppression Final', dynamo: 'SuppressionFinal', code: '727' },
	{ display: 'Suppression Retry - Non Increment', dynamo: 'SuppressionRetryNonIncrement', code: '729' },
	{ display: 'Suppression Retry - Increment', dynamo: 'SuppressionRetryIncrement', code: '728' },

];

const SuppressionErrorDefaults = [
	{ display: 'Suppression Final - REST Service Call Failed', dynamo: 'SuppressionFinalRestServiceCallFailed', code: '727' },
	{ display: 'Suppression Retry - Non Increment - REST Service Call Failed', dynamo: 'SuppressionRetryNonIncrementRestServiceCallFailed', code: '729' },
	{ display: 'Suppression Retry - Increment - REST Service Call Failed', dynamo: 'SuppressionRetryIncrementRestServiceCallFailed', code: '728' }
];

export { SuppressionDefaults, SuppressionErrorDefaults };

import React from 'react';
import PropTypes from 'prop-types';
import ContactRO from './pages/screenpop/contactRO';
import { ClickToCall } from './pages/screenpop/clickToCall';
import { Callback } from './pages/screenpop/callback';
import { CreateCallback } from './pages/screenpop/createCallback';
// import ManDial from '../Pages/ManDial';

/**
 * This is the component in charge of laying out the screen pop interface and giving
 * slots for other components to sit in.
 * @category Layouts
 * @component
 * @param {any} props The React props object passed into components.
 */
function ScreenPop(props) {
	/**
	 * return function for rendering HTML
	 */
	return (
		<>
			{!props.click2call && !props.manualDial && !props.callback && !props.callbackCreate &&
				// alert('ContactRO')
				<ContactRO />
			}
			{props.click2call && !props.manualDial &&
				<ClickToCall {...props} />
			}
			{/* {props.manualDial &&
					<ManDial /> */}
			{props.callback && !props.manualDial &&
				<Callback {...props} />
			}
			{props.callbackCreate && !props.manualDial &&
				<CreateCallback {...props} />
			}
		</>
	);
}

ScreenPop.propTypes = {
	container: PropTypes.instanceOf(
		typeof Element === 'undefined' ? Object : Element,
	),
};

export default ScreenPop;

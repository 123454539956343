import { uniqBy } from 'lodash';
import axios from 'axios';
import { post } from 'aws-amplify/api';
import { fetchAuthSession } from 'aws-amplify/auth';

/**
 * PLEASE READ:
 *
 * If you add more methods to this static class, you must get the access token before making the request.
 * This is because the CENTRAL_BASE_URI variable isn't set until we have logged in for the first time.
 * If you try to make the request and inline the getting of the token, you may run into problems.
 */

export class InContact {
	/**
	 * The cors proxy lambda function endpoint.
	 */
	static PROXY_URL =
		'https://wjqomkhk14.execute-api.us-west-2.amazonaws.com/dev/proxy/';

	/**
	 * User hub base endpoint.
	 */
	static USER_HUB_BASE_URI =
		'https://api-na1.niceincontact.com/inContactAPI/services/';

	/**
	 * Central base endpoint (comes back from the login and is set there).
	 */
	static CENTRAL_BASE_URI = '';

	/**
	 * The tenant's InContact settings.
	 */
	static _icSettings;

	/**
	 * Gets the tenant's InContact settings.
	 */
	static get icSettings() {
		return this._icSettings;
	}

	/**
	 * Sets the tenant's InContact settings.
	 */
	static set icSettings(tenantSettings) {
		// Grab what we need from the tenant settings.
		this._icSettings = {
			icApp: tenantSettings.icApp,
			icVendor: tenantSettings.icVendor,
			icBU: tenantSettings.icBU,
			icUserName: tenantSettings.icUserName,
			icPassword: tenantSettings.icPassword,
			icGetAuthTokenURL: tenantSettings.icGetAuthTokenURL,
			icAccessKey: tenantSettings.icAccessKey,
			icAppSecret: tenantSettings.icAppSecret,
			icDiscoveryDomain: tenantSettings.icDiscoveryDomain,
			apiKey: tenantSettings.apiKey,
		};

		this.isCentral = Boolean(this.icSettings.icApp && this.icSettings.icVendor);
		this.isUserHub = Boolean(
			this.icSettings.icAppSecret && this.icSettings.icAccessKey,
		);

		// If the tenant settings changed, invalidate the current access token (so that we login again).
		this.currentTokenExpirationEpoch = 0;
	}

	/**
	 * True if the tenant is on central. False otherwise.
	 */
	static isCentral;

	/**
	 * True if the tenant is on UserHub. False otherwise.
	 */
	static isUserHub;

	/**
	 * The epoch of when there is no access token.
	 * Will be zero if there is no token or if the token is invalid.
	 */
	static currentTokenExpirationEpoch = 0;

	/**
	 * The holy grail. The access token for InContact API calls.
	 */
	static _accessToken;

	/**
	 * Sets the access token.
	 */
	static set accessToken(accessToken) {
		this._accessToken = accessToken;
	}

	static get accessToken() {
		return this._accessToken;
	}

	static _apiEndpoint;

	static set apiEndpoint(apiEndpoint) {
		this._apiEndpoint = apiEndpoint;
	}

	static get apiEndpoint() {
		return this._apiEndpoint;
	}

	/**
	 * Constructs a URL for making an API call to InContact.
	 * @param {string} input The route of the API call with any needed params (e.g. lists/call-lists).
	 * @param {string} version The version to place in the URL. Defaults to v20.0.
	 * @returns {string} The URL.
	 */
	static getURL(input, version = 'v26.0') {
		return `${this.apiEndpoint}services/${version}/${input}`;
	}

	/**
	 * Performs a login.
	 */
	static async login(tokenId, cdyxtoken) {
		let token;

		if (cdyxtoken) {
			token = cdyxtoken;
		} else {
			token = (await fetchAuthSession()).tokens.idToken;
		}
		try {
			const response = await post({
				apiName: 'cdyxoutreach',
				path: '/cci/incontact/niclogin',
				options: {
					body: {
						tokenId,
					},
					headers: {
						Authorization: `Bearer ${token}`,
						'x-api-key': this.icSettings.apiKey,
					},
				},
			}).response;
			const loginResult = await response.body.json();

			this.apiEndpoint = loginResult.api_endpoint;
			this.accessToken = loginResult.token;
		} catch (err) {
			console.error('Unable to login to incontact', err);
		}
	}

	static async getIbIcSkills() {
		await this.login();
		try {
			const skillsResult = await axios.get(
				this.getURL(
					'skills?mediaTypeId=1&isActive=true&fields=skillId,skillName,isOutbound',
				),
				{
					headers: {
						Authorization: `Bearer ${this.accessToken}`,
					},
				},
			);

			const tenantSkills = [];

			for (const skill of skillsResult.body.skills) {
				if (!skill.isOutbound) {
					tenantSkills.push({ ...skill, source: 'NiC' });
				}
			}

			return tenantSkills;
		} catch (err) {
			console.log(err);
			return [];
		}
	}

	static async getSSValidSkills() {
		await this.login();
		try {
			const skillsResult = await axios.get(this.getURL('skills?mediaTypeId=4&isActive=true&fields=skillId,skillName,isOutbound,outboundStrategy'),
				{

					headers: {
						Authorization: `Bearer ${this.accessToken}`,
					}
				}
			);

			const tenantSkills = [];

			for (const skill of skillsResult.data.skills) {
				if (!skill.isOutbound || skill.outboundStrategy === 'Manual') {
					tenantSkills.push({ ...skill, source: 'NiC' });
				}
			}

			return tenantSkills;
		} catch (err) {
			console.log(err);
			return [];
		}
	}


	static async getActivePhoneSkills() {
		await this.login();
		try {
			const skillsResult = await axios.get(
				this.getURL('skills?mediaTypeId=4&isActive=true&fields=skillId,skillName,isOutbound,outboundStrategy'),
				{
					headers: {
						Authorization: `Bearer ${this.accessToken}`,
					},
				},
			);

			const tenantSkills = [];

			for (const skill of skillsResult.data.skills) {
				if (!skill.isOutbound || skill.outboundStrategy === 'Manual') {
					tenantSkills.push({ ...skill, source: 'NiC' });
				}
			}

			return tenantSkills;
		} catch (err) {
			console.log(err);
			return [];
		}
	}

	static async getConfigurationIcSkills(mediaType) {
		await this.login();
		try {
			const skillsResult = await axios.get(
				this.getURL(
					`skills?mediaTypeId=${mediaType}&isActive=true&fields=skillId,skillName,isOutbound`,
				),
				{
					headers: {
						Authorization: `Bearer ${this.accessToken}`,
					},
				},
			);

			return skillsResult.body.skills.map((skill) => {
				return {
					...skill,
					source: 'NiC',
				};
			});
		} catch (err) {
			return [];
		}
	}

	static async getScripts() {
		await this.login();
		try {
			const scriptsResult = await axios.get(
				this.getURL('scripts?isActive=true', 'v17.0'),
				{
					headers: {
						Authorization: `Bearer ${this.accessToken}`,
					},
				},
			);

			console.log('scriptsResult', scriptsResult.data.resultSet.scripts);

			const scripts = scriptsResult.data.resultSet.scripts.filter(
				(script) => script.mediaTypeId == 4,
			);

			console.log('scripts', scripts);

			const result = uniqBy(scripts, 'scriptName');
			return result;
		} catch (err) {
			console.error(err);
			console.log('error in scripts');
			return [];
		}
	}

	static async getMessagingSkills(mediaType) {
		await this.login();
		try {
			const skillsResult = await axios.get(
				this.getURL(
					`skills?mediaTypeId=${mediaType}&isActive=true&fields=skillId,skillName,isOutbound`,
				),
				{
					headers: {
						Authorization: `Bearer ${this.accessToken}`,
					},
				},
			);

			return skillsResult.body.skills.map((skill) => {
				return {
					...skill,
					source: 'NiC',
				};
			});
		} catch (err) {
			return err;
		}
	}

	/**
	 * API call for getting all the custom dispositions
	 */
	static async getCustomDispositions(skillId) {
		await this.login();
		try {
			const skillsResult = await axios.get(
				this.getURL(`skills/${skillId}/dispositions`),
				{
					headers: {
						Authorization: `Bearer ${this.accessToken}`,
					},
				},
			);
			const dispositions = skillsResult.data.dispositions;

			return dispositions;
		} catch (err) {
			console.log('error in getting custom dispositions');
			console.log(err);
			return [];
		}
	}

	static async getPointsOfContact() {
		await this.login();
		const result = await axios.get(this.getURL('points-of-contact'), {
			headers: {
				Authorization: `Bearer ${this.accessToken}`,
			},
		});
		return result;
	}

	static async getScript(scriptName, cdyxtoken) {
		// null is passed for the token overide
		await this.login(null, cdyxtoken);
		try {
			const result = await axios.get(this.getURL('scripts/search'), {
				headers: {
					Authorization: `Bearer ${this.accessToken}`,
				},
				params: {
					scriptName: scriptName,
				},
			});

			const scripts = result.data;
			if (scripts && scripts.scriptSearchDetails) {
				return scripts.scriptSearchDetails[0].masterID;
			} else {
				return null;
			}
		} catch (err) {
			console.error(err);
			return null;
		}
	}

	static async sendIcSMS(skillId, scriptId, contact, c3Message, cdyxtoken) {
		// null is passed for the token overide
		await this.login(null, cdyxtoken);
		try {
			const result = await axios.post(
				this.getURL(`scripts/${scriptId}/start`),
				{
					skillId,
					parameters: `${contact.cell || contact.phone}|${c3Message}|${contact.id
						}|${contact.campaignId}|${contact.segmentId}|${c3Message.link.id
						}|${skillId}`,
				},
				{
					headers: {
						Authorization: `Bearer ${this.accessToken}`,
					},
				},
			);

			const scriptResult = result.data;
			return scriptResult.contactId;
		} catch (err) {
			console.error(err);
			return null;
		}
	}

	static skillLabel = 'Skill';

	static async sendIcEmail(
		skillId,
		scriptId,
		emailTo,
		emailBody,
		emailSubject,
		cdyxtoken,
	) {
		// null is passed for the token overide
		await this.login(null, cdyxtoken);
		try {
			const result = await axios.post(
				this.getURL(`scripts/${scriptId}/start`),
				{
					skillId: skillId,
					parameters: `${emailTo}|${emailBody}|${emailSubject}|${skillId}`,
				},
				{
					headers: {
						Authorization: `Bearer ${this.accessToken}`,
					},
				},
			);

			const scriptResult = result.data;
			return scriptResult.contactId;
		} catch (err) {
			console.error(err);
			return null;
		}
	}

	static async postCustomData(agentSession, contactId, cdyxtoken, ...params) {
		// null is passed for the token overide
		await this.login(null, cdyxtoken);
		try {
			const result = await axios.post(
				`${this.getURL(
					`agent-sessions/${agentSession}/interactions/${contactId}/custom-data`,
				)}?data=${encodeURIComponent(params.join('|'))}`,
				{},
				{
					headers: {
						Authorization: `Bearer ${this.accessToken}`,
					},
				},
			);
			return result;
		} catch (err) {
			console.error(err);
		}
	}

	static async getContactIdForAgent(agentId, cdyxtoken) {
		await this.login('', cdyxtoken);
		try {
			const agentSession = await axios.get(
				this.getURL(`agents/${agentId}/states`),
				{
					headers: {
						Authorization: `Bearer ${this.accessToken}`,
					},
				},
			);
			return agentSession.data?.agentStates?.[0]?.contactId;
		} catch (err) {
			console.error(err);
		}
	}

	static async getAgentSession(agentId, cdyxtoken) {
		await this.login('', cdyxtoken);
		try {
			const agentSession = await axios.post(
				this.getURL(`agent-sessions/join`),
				{
					asAgentId: agentId,
				},
				{
					headers: {
						Authorization: `Bearer ${this.accessToken}`,
					},
				},
			);
			console.log('join session return', agentSession.data);
			return agentSession.data.sessionId;
		} catch (err) {
			console.error(err);
		}
	}
}

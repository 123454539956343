import { useRef, useState, useEffect, useContext } from 'react';
import {
	Box,
	Typography,
	Paper,
	// makeStyles,
	TextField,
	Button,
	IconButton,
	Select,
	MenuItem,
	LinearProgress,
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableBody,
	TableCell,
	FormControl,
	FormHelperText,
	Tooltip,
	Card,
	CardHeader,
	CardContent,
	CardActions,
} from '@mui/material';
import { DeleteOutlined, AddCircleOutline, Info } from '@mui/icons-material';
import {
	useParams,
	NavLink,
	// Prompt,
	useNavigate,
} from 'react-router-dom';
import { Formik, FieldArray, getIn } from 'formik';
import { UserContext } from '../../contexts/UserContext';
import { generateClient } from 'aws-amplify/api';
import {
	createContactCallWindowsFieldMappings,
	updateContactCallWindowsFieldMappings,
	createFieldsMapping,
	updateFieldsMapping,
	createContactMetadataFieldMappings,
	updateContactMetadataFieldMappings,
} from '../../graphql/mutations';
import {
	getContactCallWindowsFieldMappings,
	getFieldsMapping,
	getContactMetadataFieldMappings,
} from '../../graphql/queries';
import { string, object, array } from 'yup';
import { enqueueSnackbar } from 'notistack';
import { MappingTypes } from '../../models/mappingTypes';
import { PageAppBar } from 'src/components/pageAppBar';
import { actionOneButtonStyle, cardStyle } from 'src/theme';
import { selectInputStyle } from '../../theme';

const requiredIdentifiers = ['*', '**'];

export function ContactFieldMapping(props) {
	const client = generateClient();
	const { mappingType } = props;
	const navigate = useNavigate();
	const userContext = useContext(UserContext);
	const selectedMappingHelper = useRef(null);
	const { id } = useParams();
	const [loading, setLoading] = useState(false);
	const [fieldsMapping, setFieldsMapping] = useState({
		name: '',
		tenant: userContext.tenantId,
		mappings: [],
	});
	const isNew = useRef(false);

	useEffect(() => {
		if (selectedMappingHelper.current && id) {
			getData();
		}
	}, [id]);

	useEffect(() => {
		switch (mappingType) {
			default:
			case MappingTypes.Contact:
				userContext.fieldMappingTab = 0;
				selectedMappingHelper.current = {
					header: 'Contact Field Mappings',
					locationString: 'contact',
					graphql: {
						getMapping: {
							query: getFieldsMapping,
							retrievalString: 'getFieldsMapping',
						},
						createMapping: {
							mutation: createFieldsMapping,
							retrievalString: 'createFieldsMapping',
						},
						updateMapping: {
							mutation: updateFieldsMapping,
							retrievalString: 'updateFieldsMapping',
						},
					},
					fields: [
						{ dynamo: 'externalId', label: 'External ID' },
						{ dynamo: 'firstName', label: 'First Name' },
						{ dynamo: 'lastName', label: 'Last Name' },
						{ dynamo: 'phone', label: 'Phone' },
						{ dynamo: 'source', label: 'Source' },
						{ dynamo: 'cell', label: 'Cell' },
						{ dynamo: 'email', label: 'Email' },
						{ dynamo: 'address1', label: 'Address1' },
						{ dynamo: 'address2', label: 'Address2' },
						{ dynamo: 'city', label: 'City' },
						{ dynamo: 'state', label: 'State' },
						{ dynamo: 'zip', label: 'Zip' },
						/* { dynamo: "expireDt", label: "Expiration Date" }, */
						{ dynamo: 'outboundCallerId', label: 'Outbound Caller ID' },
						...userContext.customFields.map((field) => {
							return {
								label: field.displayName,
								dynamo: `custom:${field.name}`,
							};
						}),
					],
					required: {
						all: null,
						some: null,
						labels: {
							externalId: 0,
							firstName: 0,
							lastName: 0,
							phone: 0,
						},
					},
				};
				setFieldsMapping({
					name: '',
					tenant: userContext.tenantId,
					mappings: [
						{
							fromField: 'ID',
							toField: 'externalId',
						},
						{
							fromField: 'FirstName',
							toField: 'firstName',
						},
						{
							fromField: 'LastName',
							toField: 'lastName',
						},
						{
							fromField: 'Phone',
							toField: 'phone',
						},
						{
							fromField: 'Cell',
							toField: 'cell',
						},
						{
							fromField: 'City',
							toField: 'city',
						},
						{
							fromField: 'State',
							toField: 'state',
						},
						{
							fromField: 'Zip',
							toField: 'zip',
						},
					],
				});
				break;

			case MappingTypes.Metadata:
				userContext.fieldMappingTab = 1;
				selectedMappingHelper.current = {
					header: 'Contact Metadata Field Mappings',
					locationString: 'metadata',
					graphql: {
						getMapping: {
							query: getContactMetadataFieldMappings,
							retrievalString: 'getContactMetadataFieldMappings',
						},
						createMapping: {
							mutation: createContactMetadataFieldMappings,
							retrievalString: 'createContactMetadataFieldMappings',
						},
						updateMapping: {
							mutation: updateContactMetadataFieldMappings,
							retrievalString: 'updateContactMetadataFieldMappings',
						},
					},
					fields: [
						{ dynamo: 'externalId', label: 'External ID' },
						{ dynamo: 'field', label: 'Field' },
						{ dynamo: 'cell', label: 'Cell' },
						{ dynamo: 'consent', label: 'Consent' },
						{ dynamo: 'writtenConsent', label: 'Written Consent' },
						{ dynamo: 'optOut', label: 'Opt Out' },
						{ dynamo: 'preferred', label: 'Preferred' },
						{ dynamo: 'thirdParty', label: 'Third Party' },
						{ dynamo: 'dontCallOnWeekends', label: "Don't Call on Weekends" },
					],
					required: {
						all: null,
						some: null,
						labels: {
							externalId: 0,
							field: 0,
							cell: 1,
							consent: 1,
							writtenConsent: 1,
							optOut: 1,
							preferred: 1,
							thirdParty: 1,
							dontCallOnWeekends: 1,
						},
					},
				};
				setFieldsMapping({
					name: '',
					tenant: userContext.tenantId,
					mappings: [
						{
							fromField: 'ID',
							toField: 'externalId',
						},
						{
							fromField: 'Field',
							toField: 'field',
						},
						{
							fromField: 'Cell Phone',
							toField: 'cell',
						},
						{
							fromField: 'Consent',
							toField: 'consent',
						},
						{
							fromField: 'Written Consent',
							toField: 'writtenConsent',
						},
						{
							fromField: 'Opt Out',
							toField: 'optOut',
						},
						{
							fromField: 'Preferred',
							toField: 'preferred',
						},
						{
							fromField: 'Third Party',
							toField: 'thirdParty',
						},
						{
							fromField: "Don't Call on Weekends",
							toField: 'dontCallOnWeekends',
						},
					],
				});
				break;

			case MappingTypes.CallWindows:
				userContext.fieldMappingTab = 2;
				selectedMappingHelper.current = {
					header: 'Contact Call Window Field Mappings',
					locationString: 'call-windows',
					graphql: {
						getMapping: {
							query: getContactCallWindowsFieldMappings,
							retrievalString: 'getContactCallWindowsFieldMappings',
						},
						createMapping: {
							mutation: createContactCallWindowsFieldMappings,
							retrievalString: 'createContactCallWindowsFieldMappings',
						},
						updateMapping: {
							mutation: updateContactCallWindowsFieldMappings,
							retrievalString: 'updateContactCallWindowsFieldMappings',
						},
					},
					fields: [
						{ dynamo: 'externalId', label: 'External ID' },
						{ dynamo: 'action', label: 'Action' },
						{ dynamo: 'field', label: 'Field' },
						{ dynamo: 'days', label: 'Days' },
						{ dynamo: 'tmzOffset', label: 'Timezone Offset' },
						{ dynamo: 'dst', label: 'Is daylight savings time' },
						{ dynamo: 'beginHr', label: 'Window hour start' },
						{ dynamo: 'beginMin', label: 'Window minute start' },
						{ dynamo: 'endHr', label: 'Window hour end' },
						{ dynamo: 'endMin', label: 'Window minute end' },
					],
					required: {
						all: null,
						some: null,
						labels: {
							externalId: 0,
							action: 0,
							field: 0,
							days: 0,
							tmzOffset: 0,
							dst: 0,
							beginHr: 0,
							beginMin: 0,
							endHr: 0,
							endMin: 0,
						},
					},
				};

				setFieldsMapping({
					name: '',
					tenant: userContext.tenantId,
					mappings: [
						{
							fromField: 'External ID',
							toField: 'externalId',
						},
						{
							fromField: 'Action',
							toField: 'action',
						},
						{
							fromField: 'Field',
							toField: 'field',
						},
						{
							fromField: 'Days',
							toField: 'days',
						},
						{
							fromField: 'Timezone Offset',
							toField: 'tmzOffset',
						},
						{
							fromField: 'DST',
							toField: 'dst',
						},
						{
							fromField: 'Hour Start',
							toField: 'beginHr',
						},
						{
							fromField: 'Minute Start',
							toField: 'beginMin',
						},
						{
							fromField: 'Hour End',
							toField: 'endHr',
						},
						{
							fromField: 'Minute End',
							toField: 'endMin',
						},
					],
				});
				break;
		}
		selectedMappingHelper.current.required.all = Object.keys(
			selectedMappingHelper.current.required.labels,
		).filter((x) => selectedMappingHelper.current.required.labels[x] === 0);
		selectedMappingHelper.current.required.some = new Set(
			Object.keys(selectedMappingHelper.current.required.labels).filter(
				(x) => selectedMappingHelper.current.required.labels[x] === 1,
			),
		);

		getData();
	}, [mappingType]);

	async function getData() {
		if (id === 'new') {
			isNew.current = true;
		} else if (!isNew.current) {
			setLoading(true);
			const fieldsMappingResponse = await client.graphql({
				query: selectedMappingHelper.current.graphql.getMapping.query,
				variables: { id: id },
			});
			if (fieldsMappingResponse.data) {
				setFieldsMapping(
					fieldsMappingResponse.data[
					selectedMappingHelper.current.graphql.getMapping.retrievalString
					],
				);
			}
			setLoading(false);
		}
	}

	const arrayFieldError = (name, errors, touched) => {
		const errorInfo = getIn(errors, name);
		const touch = getIn(touched, name);
		return touch && errorInfo ? errorInfo : null;
	};

	return (
		<Formik
			initialValues={fieldsMapping}
			validateOnChange={false}
			validateOnBlur
			validationSchema={object({
				name: string().required('A Field Mapping name is required'),
				mappings: array()
					.of(
						object({
							fromField: string().required('File header field is required'),
							toField: string().required('Contact field is required'),
						}),
					)
					.test({
						name: 'Unique column fields',
						message: 'Each field per column must be unique.',
						test: (value) => {
							for (const mapping of value) {
								if (
									value.filter(
										(x) =>
											x.toField === mapping.toField ||
											x.fromField === mapping.fromField,
									).length > 1
								) {
									return false;
								}
							}

							return true;
						},
					})
					.test({
						name: 'All required fields',
						message: 'There are required fields missing.',
						test: (value) =>
							!selectedMappingHelper.current.required.all.some(
								(x) => value.find((y) => y.toField === x) == null,
							),
					})
					.test({
						name: 'At least one required field',
						message: 'At least one optional field is required',
						test: (value) =>
							selectedMappingHelper.current.required.some.size === 0 ||
							value.some((x) =>
								selectedMappingHelper.current.required.some.has(x.toField),
							),
					}),
			})}
			onSubmit={async (values, formikBag) => {
				let mutation =
					selectedMappingHelper.current.graphql.updateMapping.mutation;
				const fieldsMapping = { ...values };
				delete fieldsMapping.createdAt;
				delete fieldsMapping.updatedAt;
				if (id === 'new') {
					mutation =
						selectedMappingHelper.current.graphql.createMapping.mutation;
					fieldsMapping.tenant = userContext.tenantId;
				}
				try {
					const fieldsMappingResult = await client.graphql({
						query: mutation,
						variables: { input: fieldsMapping },
					});
					if (id === 'new') {
						navigate(
							`/field-mappings/${selectedMappingHelper.current.locationString
							}/${fieldsMappingResult.data[
								selectedMappingHelper.current.graphql.createMapping
									.retrievalString
							].id
							}`,
						);
						values.id =
							fieldsMappingResult.data[
								selectedMappingHelper.current.graphql.createMapping.retrievalString
							].id;
					}
					formikBag.resetForm({ values });
					enqueueSnackbar('Field Mapping saved', { variant: 'success' });
				} catch (err) {
					console.error(err);
					enqueueSnackbar('Error saving Field Mapping', { variant: 'error' });
				}
			}}
			enableReinitialize
		>
			{(formikProps) => (
				<form onSubmit={formikProps.handleSubmit}>
					{/* <Prompt
						when={
							(formikProps.dirty || params.id === 'new') &&
							!formikProps.isSubmitting
						}
						message="You have unsaved changes. Are you sure you want to leave this page?"
					/> */}
					<PageAppBar
						title={selectedMappingHelper?.current?.header ?? 'Field Mappings'}
						description={`Map fields from your file to your ${selectedMappingHelper?.current?.header}`}
						actionOneText='Save'
						actionOneHandler={formikProps.handleSubmit}
						actionTwoText='Close'
						actionTwoHandler={() => navigate('/field-mappings')}
					/>
					<Card
						style={cardStyle}
						elevation={0}
					>
						<CardHeader title={
							<TextField
								color="primary"
								name="name"
								autoFocus
								label="Name"
								type="text"
								required
								onChange={formikProps.handleChange}
								onBlur={formikProps.handleBlur}
								value={formikProps.values.name}
								error={
									formikProps.touched.name && formikProps.errors.name
								}
								helperText={
									formikProps.touched.name && formikProps.errors.name
								}
							/>
						}
							subheader={
								<Box>
									<Typography variant="body2">
										All fields with * are required
									</Typography>
									<Typography variant="body2">
										At least one field with ** is required
									</Typography>
									{typeof formikProps.errors.mappings === 'string' && (
										<Box>
											<Typography variant="body1" color="error">
												{formikProps.errors.mappings}
											</Typography>
										</Box>
									)}
								</Box>

							}
						/>
						<CardContent>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell>
											<Typography color="primary">
												File Header Field
											</Typography>
										</TableCell>
										<TableCell>
											<Typography color="primary">
												Contact Field
											</Typography>
										</TableCell>
										<TableCell>
											<Typography color="primary">
												Action
											</Typography>
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{formikProps.values.mappings.map(
										(mapping, index) => (
											<TableRow key={index}>
												<TableCell>
													<TextField
														color="primary"
														name={`mappings[${index}].fromField`}
														value={mapping.fromField}
														onChange={
															formikProps.handleChange
														}
														onBlur={formikProps.handleBlur}
														error={arrayFieldError(
															`mappings[${index}].fromField`,
															formikProps.errors,
															formikProps.touched,
														)}
														helperText={arrayFieldError(
															`mappings[${index}].fromField`,
															formikProps.errors,
															formikProps.touched,
														)}
													/>
												</TableCell>
												<TableCell>
													<FormControl
														error={arrayFieldError(
															`mappings[${index}].toField`,
															formikProps.errors,
															formikProps.touched,
														)}
													>
														<Select
															sx={selectInputStyle}
															name={`mappings[${index}].toField`}
															value={mapping.toField}
															onChange={
																formikProps.handleChange
															}
															onBlur={
																formikProps.handleBlur
															}
														>
															{selectedMappingHelper.current.fields.map(
																(field, index) => (
																	<MenuItem
																		key={index}
																		value={field.dynamo}
																	>{`${field.label}${requiredIdentifiers[
																		selectedMappingHelper
																			.current.required
																			.labels[
																		field.dynamo
																		]
																	] ?? ''
																		}`}</MenuItem>
																),
															)}
														</Select>
														<FormHelperText>
															{arrayFieldError(
																`mappings[${index}].toField`,
																formikProps.errors,
																formikProps.touched,
															)}
														</FormHelperText>
													</FormControl>
												</TableCell>
												<TableCell>
													<IconButton
														size="small"
														color="primary"
														onClick={() =>{
															const newMappings = [...formikProps.values.mappings];
															newMappings.splice(index, 1);
															formikProps.setFieldValue('mappings', newMappings);
														}}
													>
														<DeleteOutlined />
													</IconButton>
												</TableCell>
												<TableCell>
													{selectedMappingHelper.current
														.info &&
														selectedMappingHelper.current
															.info[mapping.toField] && (
															<Tooltip
																title={
																	selectedMappingHelper
																		.current.info[
																	mapping.toField
																	]
																}
															>
																<Info
																	style={{
																		marginTop: '6px',
																	}}
																	color="primary"
																/>
															</Tooltip>
														)}
												</TableCell>
											</TableRow>
										),
									)}
								</TableBody>
							</Table>

						</CardContent>
						<CardActions>
							<Box width="100%"
								display="flex"
								flexDirection="row"
								justifyContent="end"
								alignItems="center">
								<Button
									sx={
										actionOneButtonStyle
									}
									onClick={() =>
										formikProps.setFieldValue('mappings', [
											...formikProps.values.mappings,
											{ fromField: '', toField: '' },
										])
									}
								>
									+ Mapping
								</Button>
							</Box>
						</CardActions>
					</Card>
				</form>
			)}
		</Formik>
	);
}

/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onTenantCreateContact = /* GraphQL */ `
  subscription OnTenantCreateContact($tenant: ID!) {
    onTenantCreateContact(tenant: $tenant) {
      id
      tenant
      externalId
      firstName
      lastName
      source
      phone
      cell
      email
      dateCreated
      address1
      address2
      city
      state
      zip
      dnc
      updatedBy
      customFields
      expireDt
      outboundCallerId
      balances {
        id
        type
        amount
        age
      }
      timeZoneBounds {
        min {
          offset
          dst
        }
        max {
          offset
          dst
        }
      }
      type
      optOuts {
        voice
        sms
        email
      }
      optIns {
        voice
        sms
        email
      }
      attempts
      createdAt
      updatedAt
    }
  }
`;
export const onTenantUpdateContact = /* GraphQL */ `
  subscription OnTenantUpdateContact($tenant: ID!) {
    onTenantUpdateContact(tenant: $tenant) {
      id
      tenant
      externalId
      firstName
      lastName
      source
      phone
      cell
      email
      dateCreated
      address1
      address2
      city
      state
      zip
      dnc
      updatedBy
      customFields
      expireDt
      outboundCallerId
      balances {
        id
        type
        amount
        age
      }
      timeZoneBounds {
        min {
          offset
          dst
        }
        max {
          offset
          dst
        }
      }
      type
      optOuts {
        voice
        sms
        email
      }
      optIns {
        voice
        sms
        email
      }
      attempts
      createdAt
      updatedAt
    }
  }
`;
export const onTenantDeleteContact = /* GraphQL */ `
  subscription OnTenantDeleteContact($tenant: ID!) {
    onTenantDeleteContact(tenant: $tenant) {
      id
      tenant
      externalId
      firstName
      lastName
      source
      phone
      cell
      email
      dateCreated
      address1
      address2
      city
      state
      zip
      dnc
      updatedBy
      customFields
      expireDt
      outboundCallerId
      balances {
        id
        type
        amount
        age
      }
      timeZoneBounds {
        min {
          offset
          dst
        }
        max {
          offset
          dst
        }
      }
      type
      optOuts {
        voice
        sms
        email
      }
      optIns {
        voice
        sms
        email
      }
      attempts
      createdAt
      updatedAt
    }
  }
`;
export const onTenantCreateSegment = /* GraphQL */ `
  subscription OnTenantCreateSegment($tenant: ID!) {
    onTenantCreateSegment(tenant: $tenant) {
      id
      tenant
      externalId
      name
      description
      query {
        qbQuery
        searchFilter
        sort {
          field
          direction
        }
      }
      filter {
        qbQuery
        searchFilter
        sort {
          field
          direction
        }
      }
      fieldsMappingId
      suppressionID
      suppression {
        id
        tenant
        name
        result
        activityLogQuerySuppressionEnabled
        activityLogQuerySuppression {
          query
          rawQuery
          threshold
        }
        pocQuerySuppressionEnabled
        pocQuerySuppression {
          query
          rawQuery
          suppressIfWeekendAndCantCallOnWeekends
        }
        restSuppressionEnabled
        restSuppression {
          endpoint
          method
          params {
            key
            value
            description
          }
          headers {
            key
            value
            description
          }
          auth {
            type
            info
          }
          response {
            path
            type
            value
            options
          }
          suppressOnError
          dispositionOnError
        }
        dncDotComEnabled
        dncDotCom {
          scrub
          state
          numberReassignment
          knownLitigator
          reassignmentSince
        }
        dncListSuppressionEnabled
        dncListSuppressions
        contactFieldSuppressionEnabled
        contactFieldSuppressions
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onTenantUpdateSegment = /* GraphQL */ `
  subscription OnTenantUpdateSegment($tenant: ID!) {
    onTenantUpdateSegment(tenant: $tenant) {
      id
      tenant
      externalId
      name
      description
      query {
        qbQuery
        searchFilter
        sort {
          field
          direction
        }
      }
      filter {
        qbQuery
        searchFilter
        sort {
          field
          direction
        }
      }
      fieldsMappingId
      suppressionID
      suppression {
        id
        tenant
        name
        result
        activityLogQuerySuppressionEnabled
        activityLogQuerySuppression {
          query
          rawQuery
          threshold
        }
        pocQuerySuppressionEnabled
        pocQuerySuppression {
          query
          rawQuery
          suppressIfWeekendAndCantCallOnWeekends
        }
        restSuppressionEnabled
        restSuppression {
          endpoint
          method
          params {
            key
            value
            description
          }
          headers {
            key
            value
            description
          }
          auth {
            type
            info
          }
          response {
            path
            type
            value
            options
          }
          suppressOnError
          dispositionOnError
        }
        dncDotComEnabled
        dncDotCom {
          scrub
          state
          numberReassignment
          knownLitigator
          reassignmentSince
        }
        dncListSuppressionEnabled
        dncListSuppressions
        contactFieldSuppressionEnabled
        contactFieldSuppressions
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onTenantDeleteSegment = /* GraphQL */ `
  subscription OnTenantDeleteSegment($tenant: ID!) {
    onTenantDeleteSegment(tenant: $tenant) {
      id
      tenant
      externalId
      name
      description
      query {
        qbQuery
        searchFilter
        sort {
          field
          direction
        }
      }
      filter {
        qbQuery
        searchFilter
        sort {
          field
          direction
        }
      }
      fieldsMappingId
      suppressionID
      suppression {
        id
        tenant
        name
        result
        activityLogQuerySuppressionEnabled
        activityLogQuerySuppression {
          query
          rawQuery
          threshold
        }
        pocQuerySuppressionEnabled
        pocQuerySuppression {
          query
          rawQuery
          suppressIfWeekendAndCantCallOnWeekends
        }
        restSuppressionEnabled
        restSuppression {
          endpoint
          method
          params {
            key
            value
            description
          }
          headers {
            key
            value
            description
          }
          auth {
            type
            info
          }
          response {
            path
            type
            value
            options
          }
          suppressOnError
          dispositionOnError
        }
        dncDotComEnabled
        dncDotCom {
          scrub
          state
          numberReassignment
          knownLitigator
          reassignmentSince
        }
        dncListSuppressionEnabled
        dncListSuppressions
        contactFieldSuppressionEnabled
        contactFieldSuppressions
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onTenantCreateActivityLog = /* GraphQL */ `
  subscription OnTenantCreateActivityLog($tenant: ID!) {
    onTenantCreateActivityLog(tenant: $tenant) {
      id
      tenant
      type
      disposition
      title
      body
      activityLogContactId
      segment {
        id
        tenant
        externalId
        name
        description
        query {
          qbQuery
          searchFilter
          sort {
            field
            direction
          }
        }
        filter {
          qbQuery
          searchFilter
          sort {
            field
            direction
          }
        }
        fieldsMappingId
        suppressionID
        suppression {
          id
          tenant
          name
          result
          activityLogQuerySuppressionEnabled
          activityLogQuerySuppression {
            query
            rawQuery
            threshold
          }
          pocQuerySuppressionEnabled
          pocQuerySuppression {
            query
            rawQuery
            suppressIfWeekendAndCantCallOnWeekends
          }
          restSuppressionEnabled
          restSuppression {
            endpoint
            method
            params {
              key
              value
              description
            }
            headers {
              key
              value
              description
            }
            auth {
              type
              info
            }
            response {
              path
              type
              value
              options
            }
            suppressOnError
            dispositionOnError
          }
          dncDotComEnabled
          dncDotCom {
            scrub
            state
            numberReassignment
            knownLitigator
            reassignmentSince
          }
          dncListSuppressionEnabled
          dncListSuppressions
          contactFieldSuppressionEnabled
          contactFieldSuppressions
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      campaign {
        id
        tenant
        name
        segments
        segmentSchedule
        campaignSchedule
        static
        sort
        skillId
        skillName
        skillSource
        campaignProfileId
        profile {
          id
          tenant
          name
          useAi
          flowRatio
          scrubber {
            cell
            natDnc
            stateDnc
            orgDnc
            phoneDup
            contactDup
          }
          retry {
            minDuration
            maxAttempts
            maxContacts
            custRetry
            ratio
          }
          ai {
            org
            system
          }
          pushContacts
          startTime
          endTime
          buffer
          channels {
            type
            order
            retryDelay
            maxAttempts
            maxContacts
            custRetry
            paymentProfileId
            paymentProfileName
            dispositions
            cadence
            dialingScript
          }
          ratio
          dontCallOnWeekends
          callWindow {
            days
            tmz {
              offset
              dst
            }
            beginTime {
              hour
              minute
            }
            endTime {
              hour
              minute
            }
            callWindowQuery {
              field
              operator
              value
            }
            default
            order
          }
          clickToCall
          callWindows {
            days
            tmz {
              offset
              dst
            }
            beginTime {
              hour
              minute
            }
            endTime {
              hour
              minute
            }
            callWindowQuery {
              field
              operator
              value
            }
            default
            order
          }
          dispositionScoring {
            noAnswer
            answer
            noAgentAvailableToDial
            noAgentOnAnswer
            overApproving
          }
          overrideDispositionScoring
          overrideDialToAgentRatio
          dialToAgentRatio
          freshLIFO
          retryLIFO
          createdAt
          updatedAt
        }
        status
        expireDate
        stopOnCompletion
        cci
        autoMode
        cciMetaData
        createdAt
        updatedAt
      }
      agent
      contactStatus
      timestamp
      metaData
      provider
      c2aId
      pocActual
      createdAt
      updatedAt
      contact {
        id
        tenant
        externalId
        firstName
        lastName
        source
        phone
        cell
        email
        dateCreated
        address1
        address2
        city
        state
        zip
        dnc
        updatedBy
        customFields
        expireDt
        outboundCallerId
        balances {
          id
          type
          amount
          age
        }
        timeZoneBounds {
          min {
            offset
            dst
          }
          max {
            offset
            dst
          }
        }
        type
        optOuts {
          voice
          sms
          email
        }
        optIns {
          voice
          sms
          email
        }
        attempts
        createdAt
        updatedAt
      }
    }
  }
`;
export const onTenantUpdateActivityLog = /* GraphQL */ `
  subscription OnTenantUpdateActivityLog($tenant: ID!) {
    onTenantUpdateActivityLog(tenant: $tenant) {
      id
      tenant
      type
      disposition
      title
      body
      activityLogContactId
      segment {
        id
        tenant
        externalId
        name
        description
        query {
          qbQuery
          searchFilter
          sort {
            field
            direction
          }
        }
        filter {
          qbQuery
          searchFilter
          sort {
            field
            direction
          }
        }
        fieldsMappingId
        suppressionID
        suppression {
          id
          tenant
          name
          result
          activityLogQuerySuppressionEnabled
          activityLogQuerySuppression {
            query
            rawQuery
            threshold
          }
          pocQuerySuppressionEnabled
          pocQuerySuppression {
            query
            rawQuery
            suppressIfWeekendAndCantCallOnWeekends
          }
          restSuppressionEnabled
          restSuppression {
            endpoint
            method
            params {
              key
              value
              description
            }
            headers {
              key
              value
              description
            }
            auth {
              type
              info
            }
            response {
              path
              type
              value
              options
            }
            suppressOnError
            dispositionOnError
          }
          dncDotComEnabled
          dncDotCom {
            scrub
            state
            numberReassignment
            knownLitigator
            reassignmentSince
          }
          dncListSuppressionEnabled
          dncListSuppressions
          contactFieldSuppressionEnabled
          contactFieldSuppressions
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      campaign {
        id
        tenant
        name
        segments
        segmentSchedule
        campaignSchedule
        static
        sort
        skillId
        skillName
        skillSource
        campaignProfileId
        profile {
          id
          tenant
          name
          useAi
          flowRatio
          scrubber {
            cell
            natDnc
            stateDnc
            orgDnc
            phoneDup
            contactDup
          }
          retry {
            minDuration
            maxAttempts
            maxContacts
            custRetry
            ratio
          }
          ai {
            org
            system
          }
          pushContacts
          startTime
          endTime
          buffer
          channels {
            type
            order
            retryDelay
            maxAttempts
            maxContacts
            custRetry
            paymentProfileId
            paymentProfileName
            dispositions
            cadence
            dialingScript
          }
          ratio
          dontCallOnWeekends
          callWindow {
            days
            tmz {
              offset
              dst
            }
            beginTime {
              hour
              minute
            }
            endTime {
              hour
              minute
            }
            callWindowQuery {
              field
              operator
              value
            }
            default
            order
          }
          clickToCall
          callWindows {
            days
            tmz {
              offset
              dst
            }
            beginTime {
              hour
              minute
            }
            endTime {
              hour
              minute
            }
            callWindowQuery {
              field
              operator
              value
            }
            default
            order
          }
          dispositionScoring {
            noAnswer
            answer
            noAgentAvailableToDial
            noAgentOnAnswer
            overApproving
          }
          overrideDispositionScoring
          overrideDialToAgentRatio
          dialToAgentRatio
          freshLIFO
          retryLIFO
          createdAt
          updatedAt
        }
        status
        expireDate
        stopOnCompletion
        cci
        autoMode
        cciMetaData
        createdAt
        updatedAt
      }
      agent
      contactStatus
      timestamp
      metaData
      provider
      c2aId
      pocActual
      createdAt
      updatedAt
      contact {
        id
        tenant
        externalId
        firstName
        lastName
        source
        phone
        cell
        email
        dateCreated
        address1
        address2
        city
        state
        zip
        dnc
        updatedBy
        customFields
        expireDt
        outboundCallerId
        balances {
          id
          type
          amount
          age
        }
        timeZoneBounds {
          min {
            offset
            dst
          }
          max {
            offset
            dst
          }
        }
        type
        optOuts {
          voice
          sms
          email
        }
        optIns {
          voice
          sms
          email
        }
        attempts
        createdAt
        updatedAt
      }
    }
  }
`;
export const onTenantDeleteActivityLog = /* GraphQL */ `
  subscription OnTenantDeleteActivityLog($tenant: ID!) {
    onTenantDeleteActivityLog(tenant: $tenant) {
      id
      tenant
      type
      disposition
      title
      body
      activityLogContactId
      segment {
        id
        tenant
        externalId
        name
        description
        query {
          qbQuery
          searchFilter
          sort {
            field
            direction
          }
        }
        filter {
          qbQuery
          searchFilter
          sort {
            field
            direction
          }
        }
        fieldsMappingId
        suppressionID
        suppression {
          id
          tenant
          name
          result
          activityLogQuerySuppressionEnabled
          activityLogQuerySuppression {
            query
            rawQuery
            threshold
          }
          pocQuerySuppressionEnabled
          pocQuerySuppression {
            query
            rawQuery
            suppressIfWeekendAndCantCallOnWeekends
          }
          restSuppressionEnabled
          restSuppression {
            endpoint
            method
            params {
              key
              value
              description
            }
            headers {
              key
              value
              description
            }
            auth {
              type
              info
            }
            response {
              path
              type
              value
              options
            }
            suppressOnError
            dispositionOnError
          }
          dncDotComEnabled
          dncDotCom {
            scrub
            state
            numberReassignment
            knownLitigator
            reassignmentSince
          }
          dncListSuppressionEnabled
          dncListSuppressions
          contactFieldSuppressionEnabled
          contactFieldSuppressions
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      campaign {
        id
        tenant
        name
        segments
        segmentSchedule
        campaignSchedule
        static
        sort
        skillId
        skillName
        skillSource
        campaignProfileId
        profile {
          id
          tenant
          name
          useAi
          flowRatio
          scrubber {
            cell
            natDnc
            stateDnc
            orgDnc
            phoneDup
            contactDup
          }
          retry {
            minDuration
            maxAttempts
            maxContacts
            custRetry
            ratio
          }
          ai {
            org
            system
          }
          pushContacts
          startTime
          endTime
          buffer
          channels {
            type
            order
            retryDelay
            maxAttempts
            maxContacts
            custRetry
            paymentProfileId
            paymentProfileName
            dispositions
            cadence
            dialingScript
          }
          ratio
          dontCallOnWeekends
          callWindow {
            days
            tmz {
              offset
              dst
            }
            beginTime {
              hour
              minute
            }
            endTime {
              hour
              minute
            }
            callWindowQuery {
              field
              operator
              value
            }
            default
            order
          }
          clickToCall
          callWindows {
            days
            tmz {
              offset
              dst
            }
            beginTime {
              hour
              minute
            }
            endTime {
              hour
              minute
            }
            callWindowQuery {
              field
              operator
              value
            }
            default
            order
          }
          dispositionScoring {
            noAnswer
            answer
            noAgentAvailableToDial
            noAgentOnAnswer
            overApproving
          }
          overrideDispositionScoring
          overrideDialToAgentRatio
          dialToAgentRatio
          freshLIFO
          retryLIFO
          createdAt
          updatedAt
        }
        status
        expireDate
        stopOnCompletion
        cci
        autoMode
        cciMetaData
        createdAt
        updatedAt
      }
      agent
      contactStatus
      timestamp
      metaData
      provider
      c2aId
      pocActual
      createdAt
      updatedAt
      contact {
        id
        tenant
        externalId
        firstName
        lastName
        source
        phone
        cell
        email
        dateCreated
        address1
        address2
        city
        state
        zip
        dnc
        updatedBy
        customFields
        expireDt
        outboundCallerId
        balances {
          id
          type
          amount
          age
        }
        timeZoneBounds {
          min {
            offset
            dst
          }
          max {
            offset
            dst
          }
        }
        type
        optOuts {
          voice
          sms
          email
        }
        optIns {
          voice
          sms
          email
        }
        attempts
        createdAt
        updatedAt
      }
    }
  }
`;
export const onTenantCreateTenantSettings = /* GraphQL */ `
  subscription OnTenantCreateTenantSettings($id: ID!) {
    onTenantCreateTenantSettings(id: $id) {
      id
      icApp
      icVendor
      icBU
      icUserName
      icPassword
      icGetAuthTokenURL
      icAccessKey
      icAppSecret
      icDiscoveryDomain
      icScriptName
      ftpImport
      ftpExport
      dashBoardCharts
      e2DashCharts
      ppDashCharts
      e2ClientId
      e2ClientSecret
      e2DynamTenantId
      onPremAccessUrl
      onPremVersion
      onPremAuthUrl
      apiKey
      licenses
      customFields {
        name
        displayName
        type
        isPoc
      }
      dynamicsConnection
      dynamicsDispoFilter
      email
      dispositionScoring {
        noAnswer
        answer
        noAgentAvailableToDial
        noAgentOnAnswer
        overApproving
      }
      telephonyProvider
      telephoneProviderInfo {
        amazonConnect {
          accessKey
          secretKey
          instanceId
          region
          queueId
          transferFlowId
        }
        incontact {
          icApp
          icVendor
          icBU
          icUserName
          icPassword
          icGetAuthTokenURL
          icAccessKey
          icAppSecret
          icDiscoveryDomain
          icScriptName
        }
        nobelbiz {
          nobelbizTenantId
          token
          instanceId
          username
          password
          encodedAuth
        }
        genesys {
          clientId
          clientSecret
          cloudRegion
          queueId
          wrapUps
          timerFlow
          chime {
            region
            outboundDialNumber
            sipMediaApplicationId
          }
        }
        webex {
          contactCenter {
            region
            organizationId
            clientId
            clientSecret
          }
          connect {
            region
            serviceKey
          }
          chime {
            region
            outboundDialNumber
            sipMediaApplicationId
          }
        }
      }
      dncDotComSettings {
        orgId
        projectId
        loginId
      }
      ipAuthentication
      ipRanges
      allowAutoMode
      overrideDialToAgentRatio
      dialToAgentRatio
      createdAt
      updatedAt
    }
  }
`;
export const onTenantUpdateTenantSettings = /* GraphQL */ `
  subscription OnTenantUpdateTenantSettings($id: ID!) {
    onTenantUpdateTenantSettings(id: $id) {
      id
      icApp
      icVendor
      icBU
      icUserName
      icPassword
      icGetAuthTokenURL
      icAccessKey
      icAppSecret
      icDiscoveryDomain
      icScriptName
      ftpImport
      ftpExport
      dashBoardCharts
      e2DashCharts
      ppDashCharts
      e2ClientId
      e2ClientSecret
      e2DynamTenantId
      onPremAccessUrl
      onPremVersion
      onPremAuthUrl
      apiKey
      licenses
      customFields {
        name
        displayName
        type
        isPoc
      }
      dynamicsConnection
      dynamicsDispoFilter
      email
      dispositionScoring {
        noAnswer
        answer
        noAgentAvailableToDial
        noAgentOnAnswer
        overApproving
      }
      telephonyProvider
      telephoneProviderInfo {
        amazonConnect {
          accessKey
          secretKey
          instanceId
          region
          queueId
          transferFlowId
        }
        incontact {
          icApp
          icVendor
          icBU
          icUserName
          icPassword
          icGetAuthTokenURL
          icAccessKey
          icAppSecret
          icDiscoveryDomain
          icScriptName
        }
        nobelbiz {
          nobelbizTenantId
          token
          instanceId
          username
          password
          encodedAuth
        }
        genesys {
          clientId
          clientSecret
          cloudRegion
          queueId
          wrapUps
          timerFlow
          chime {
            region
            outboundDialNumber
            sipMediaApplicationId
          }
        }
        webex {
          contactCenter {
            region
            organizationId
            clientId
            clientSecret
          }
          connect {
            region
            serviceKey
          }
          chime {
            region
            outboundDialNumber
            sipMediaApplicationId
          }
        }
      }
      dncDotComSettings {
        orgId
        projectId
        loginId
      }
      ipAuthentication
      ipRanges
      allowAutoMode
      overrideDialToAgentRatio
      dialToAgentRatio
      createdAt
      updatedAt
    }
  }
`;
export const onTenantDeleteTenantSettings = /* GraphQL */ `
  subscription OnTenantDeleteTenantSettings($id: ID!) {
    onTenantDeleteTenantSettings(id: $id) {
      id
      icApp
      icVendor
      icBU
      icUserName
      icPassword
      icGetAuthTokenURL
      icAccessKey
      icAppSecret
      icDiscoveryDomain
      icScriptName
      ftpImport
      ftpExport
      dashBoardCharts
      e2DashCharts
      ppDashCharts
      e2ClientId
      e2ClientSecret
      e2DynamTenantId
      onPremAccessUrl
      onPremVersion
      onPremAuthUrl
      apiKey
      licenses
      customFields {
        name
        displayName
        type
        isPoc
      }
      dynamicsConnection
      dynamicsDispoFilter
      email
      dispositionScoring {
        noAnswer
        answer
        noAgentAvailableToDial
        noAgentOnAnswer
        overApproving
      }
      telephonyProvider
      telephoneProviderInfo {
        amazonConnect {
          accessKey
          secretKey
          instanceId
          region
          queueId
          transferFlowId
        }
        incontact {
          icApp
          icVendor
          icBU
          icUserName
          icPassword
          icGetAuthTokenURL
          icAccessKey
          icAppSecret
          icDiscoveryDomain
          icScriptName
        }
        nobelbiz {
          nobelbizTenantId
          token
          instanceId
          username
          password
          encodedAuth
        }
        genesys {
          clientId
          clientSecret
          cloudRegion
          queueId
          wrapUps
          timerFlow
          chime {
            region
            outboundDialNumber
            sipMediaApplicationId
          }
        }
        webex {
          contactCenter {
            region
            organizationId
            clientId
            clientSecret
          }
          connect {
            region
            serviceKey
          }
          chime {
            region
            outboundDialNumber
            sipMediaApplicationId
          }
        }
      }
      dncDotComSettings {
        orgId
        projectId
        loginId
      }
      ipAuthentication
      ipRanges
      allowAutoMode
      overrideDialToAgentRatio
      dialToAgentRatio
      createdAt
      updatedAt
    }
  }
`;
export const onTenantCreateCampaign = /* GraphQL */ `
  subscription OnTenantCreateCampaign($tenant: ID!) {
    onTenantCreateCampaign(tenant: $tenant) {
      id
      tenant
      name
      segments
      segmentSchedule
      campaignSchedule
      static
      sort
      skillId
      skillName
      skillSource
      campaignProfileId
      profile {
        id
        tenant
        name
        useAi
        flowRatio
        scrubber {
          cell
          natDnc
          stateDnc
          orgDnc
          phoneDup
          contactDup
        }
        retry {
          minDuration
          maxAttempts
          maxContacts
          custRetry
          ratio
        }
        ai {
          org
          system
        }
        pushContacts
        startTime
        endTime
        buffer
        channels {
          type
          order
          retryDelay
          maxAttempts
          maxContacts
          custRetry
          paymentProfileId
          paymentProfileName
          dispositions
          cadence
          dialingScript
        }
        ratio
        dontCallOnWeekends
        callWindow {
          days
          tmz {
            offset
            dst
          }
          beginTime {
            hour
            minute
          }
          endTime {
            hour
            minute
          }
          callWindowQuery {
            field
            operator
            value
          }
          default
          order
        }
        clickToCall
        callWindows {
          days
          tmz {
            offset
            dst
          }
          beginTime {
            hour
            minute
          }
          endTime {
            hour
            minute
          }
          callWindowQuery {
            field
            operator
            value
          }
          default
          order
        }
        dispositionScoring {
          noAnswer
          answer
          noAgentAvailableToDial
          noAgentOnAnswer
          overApproving
        }
        overrideDispositionScoring
        overrideDialToAgentRatio
        dialToAgentRatio
        freshLIFO
        retryLIFO
        createdAt
        updatedAt
      }
      status
      expireDate
      stopOnCompletion
      cci
      autoMode
      cciMetaData
      createdAt
      updatedAt
    }
  }
`;
export const onTenantUpdateCampaign = /* GraphQL */ `
  subscription OnTenantUpdateCampaign($tenant: ID!) {
    onTenantUpdateCampaign(tenant: $tenant) {
      id
      tenant
      name
      segments
      segmentSchedule
      campaignSchedule
      static
      sort
      skillId
      skillName
      skillSource
      campaignProfileId
      profile {
        id
        tenant
        name
        useAi
        flowRatio
        scrubber {
          cell
          natDnc
          stateDnc
          orgDnc
          phoneDup
          contactDup
        }
        retry {
          minDuration
          maxAttempts
          maxContacts
          custRetry
          ratio
        }
        ai {
          org
          system
        }
        pushContacts
        startTime
        endTime
        buffer
        channels {
          type
          order
          retryDelay
          maxAttempts
          maxContacts
          custRetry
          paymentProfileId
          paymentProfileName
          dispositions
          cadence
          dialingScript
        }
        ratio
        dontCallOnWeekends
        callWindow {
          days
          tmz {
            offset
            dst
          }
          beginTime {
            hour
            minute
          }
          endTime {
            hour
            minute
          }
          callWindowQuery {
            field
            operator
            value
          }
          default
          order
        }
        clickToCall
        callWindows {
          days
          tmz {
            offset
            dst
          }
          beginTime {
            hour
            minute
          }
          endTime {
            hour
            minute
          }
          callWindowQuery {
            field
            operator
            value
          }
          default
          order
        }
        dispositionScoring {
          noAnswer
          answer
          noAgentAvailableToDial
          noAgentOnAnswer
          overApproving
        }
        overrideDispositionScoring
        overrideDialToAgentRatio
        dialToAgentRatio
        freshLIFO
        retryLIFO
        createdAt
        updatedAt
      }
      status
      expireDate
      stopOnCompletion
      cci
      autoMode
      cciMetaData
      createdAt
      updatedAt
    }
  }
`;
export const onTenantDeleteCampaign = /* GraphQL */ `
  subscription OnTenantDeleteCampaign($tenant: ID!) {
    onTenantDeleteCampaign(tenant: $tenant) {
      id
      tenant
      name
      segments
      segmentSchedule
      campaignSchedule
      static
      sort
      skillId
      skillName
      skillSource
      campaignProfileId
      profile {
        id
        tenant
        name
        useAi
        flowRatio
        scrubber {
          cell
          natDnc
          stateDnc
          orgDnc
          phoneDup
          contactDup
        }
        retry {
          minDuration
          maxAttempts
          maxContacts
          custRetry
          ratio
        }
        ai {
          org
          system
        }
        pushContacts
        startTime
        endTime
        buffer
        channels {
          type
          order
          retryDelay
          maxAttempts
          maxContacts
          custRetry
          paymentProfileId
          paymentProfileName
          dispositions
          cadence
          dialingScript
        }
        ratio
        dontCallOnWeekends
        callWindow {
          days
          tmz {
            offset
            dst
          }
          beginTime {
            hour
            minute
          }
          endTime {
            hour
            minute
          }
          callWindowQuery {
            field
            operator
            value
          }
          default
          order
        }
        clickToCall
        callWindows {
          days
          tmz {
            offset
            dst
          }
          beginTime {
            hour
            minute
          }
          endTime {
            hour
            minute
          }
          callWindowQuery {
            field
            operator
            value
          }
          default
          order
        }
        dispositionScoring {
          noAnswer
          answer
          noAgentAvailableToDial
          noAgentOnAnswer
          overApproving
        }
        overrideDispositionScoring
        overrideDialToAgentRatio
        dialToAgentRatio
        freshLIFO
        retryLIFO
        createdAt
        updatedAt
      }
      status
      expireDate
      stopOnCompletion
      cci
      autoMode
      cciMetaData
      createdAt
      updatedAt
    }
  }
`;
export const onTenantCreateProfile = /* GraphQL */ `
  subscription OnTenantCreateProfile($tenant: ID!) {
    onTenantCreateProfile(tenant: $tenant) {
      id
      tenant
      name
      useAi
      flowRatio
      scrubber {
        cell
        natDnc
        stateDnc
        orgDnc
        phoneDup
        contactDup
      }
      retry {
        minDuration
        maxAttempts
        maxContacts
        custRetry
        ratio
      }
      ai {
        org
        system
      }
      pushContacts
      startTime
      endTime
      buffer
      channels {
        type
        order
        retryDelay
        maxAttempts
        maxContacts
        custRetry
        paymentProfileId
        paymentProfileName
        dispositions
        cadence
        dialingScript
      }
      ratio
      dontCallOnWeekends
      callWindow {
        days
        tmz {
          offset
          dst
        }
        beginTime {
          hour
          minute
        }
        endTime {
          hour
          minute
        }
        callWindowQuery {
          field
          operator
          value
        }
        default
        order
      }
      clickToCall
      callWindows {
        days
        tmz {
          offset
          dst
        }
        beginTime {
          hour
          minute
        }
        endTime {
          hour
          minute
        }
        callWindowQuery {
          field
          operator
          value
        }
        default
        order
      }
      dispositionScoring {
        noAnswer
        answer
        noAgentAvailableToDial
        noAgentOnAnswer
        overApproving
      }
      overrideDispositionScoring
      overrideDialToAgentRatio
      dialToAgentRatio
      freshLIFO
      retryLIFO
      createdAt
      updatedAt
    }
  }
`;
export const onTenantUpdateProfile = /* GraphQL */ `
  subscription OnTenantUpdateProfile($tenant: ID!) {
    onTenantUpdateProfile(tenant: $tenant) {
      id
      tenant
      name
      useAi
      flowRatio
      scrubber {
        cell
        natDnc
        stateDnc
        orgDnc
        phoneDup
        contactDup
      }
      retry {
        minDuration
        maxAttempts
        maxContacts
        custRetry
        ratio
      }
      ai {
        org
        system
      }
      pushContacts
      startTime
      endTime
      buffer
      channels {
        type
        order
        retryDelay
        maxAttempts
        maxContacts
        custRetry
        paymentProfileId
        paymentProfileName
        dispositions
        cadence
        dialingScript
      }
      ratio
      dontCallOnWeekends
      callWindow {
        days
        tmz {
          offset
          dst
        }
        beginTime {
          hour
          minute
        }
        endTime {
          hour
          minute
        }
        callWindowQuery {
          field
          operator
          value
        }
        default
        order
      }
      clickToCall
      callWindows {
        days
        tmz {
          offset
          dst
        }
        beginTime {
          hour
          minute
        }
        endTime {
          hour
          minute
        }
        callWindowQuery {
          field
          operator
          value
        }
        default
        order
      }
      dispositionScoring {
        noAnswer
        answer
        noAgentAvailableToDial
        noAgentOnAnswer
        overApproving
      }
      overrideDispositionScoring
      overrideDialToAgentRatio
      dialToAgentRatio
      freshLIFO
      retryLIFO
      createdAt
      updatedAt
    }
  }
`;
export const onTenantDeleteProfile = /* GraphQL */ `
  subscription OnTenantDeleteProfile($tenant: ID!) {
    onTenantDeleteProfile(tenant: $tenant) {
      id
      tenant
      name
      useAi
      flowRatio
      scrubber {
        cell
        natDnc
        stateDnc
        orgDnc
        phoneDup
        contactDup
      }
      retry {
        minDuration
        maxAttempts
        maxContacts
        custRetry
        ratio
      }
      ai {
        org
        system
      }
      pushContacts
      startTime
      endTime
      buffer
      channels {
        type
        order
        retryDelay
        maxAttempts
        maxContacts
        custRetry
        paymentProfileId
        paymentProfileName
        dispositions
        cadence
        dialingScript
      }
      ratio
      dontCallOnWeekends
      callWindow {
        days
        tmz {
          offset
          dst
        }
        beginTime {
          hour
          minute
        }
        endTime {
          hour
          minute
        }
        callWindowQuery {
          field
          operator
          value
        }
        default
        order
      }
      clickToCall
      callWindows {
        days
        tmz {
          offset
          dst
        }
        beginTime {
          hour
          minute
        }
        endTime {
          hour
          minute
        }
        callWindowQuery {
          field
          operator
          value
        }
        default
        order
      }
      dispositionScoring {
        noAnswer
        answer
        noAgentAvailableToDial
        noAgentOnAnswer
        overApproving
      }
      overrideDispositionScoring
      overrideDialToAgentRatio
      dialToAgentRatio
      freshLIFO
      retryLIFO
      createdAt
      updatedAt
    }
  }
`;
export const onTenantCreateCampaignLog = /* GraphQL */ `
  subscription OnTenantCreateCampaignLog($tenant: ID!) {
    onTenantCreateCampaignLog(tenant: $tenant) {
      id
      tenant
      campaign
      type
      metaData
      createdAt
      updatedAt
    }
  }
`;
export const onTenantUpdateCampaignLog = /* GraphQL */ `
  subscription OnTenantUpdateCampaignLog($tenant: ID!) {
    onTenantUpdateCampaignLog(tenant: $tenant) {
      id
      tenant
      campaign
      type
      metaData
      createdAt
      updatedAt
    }
  }
`;
export const onTenantDeleteCampaignLog = /* GraphQL */ `
  subscription OnTenantDeleteCampaignLog($tenant: ID!) {
    onTenantDeleteCampaignLog(tenant: $tenant) {
      id
      tenant
      campaign
      type
      metaData
      createdAt
      updatedAt
    }
  }
`;
export const onTenantCreateConfigProfile = /* GraphQL */ `
  subscription OnTenantCreateConfigProfile($tenant: ID!) {
    onTenantCreateConfigProfile(tenant: $tenant) {
      id
      tenant
      name
      direction
      mediaType
      crmEntity
      org {
        name
        id
        url
        version
      }
      connection {
        dynamicsTenant
        appSecret
        clientId
        altUrl
        authUrl
        onPremVersion
      }
      skillIds
      multiSearch
      search
      searchAction
      activityEntityMap
      logging
      postLogging
      loggingAction
      createdAt
      updatedAt
    }
  }
`;
export const onTenantUpdateConfigProfile = /* GraphQL */ `
  subscription OnTenantUpdateConfigProfile($tenant: ID!) {
    onTenantUpdateConfigProfile(tenant: $tenant) {
      id
      tenant
      name
      direction
      mediaType
      crmEntity
      org {
        name
        id
        url
        version
      }
      connection {
        dynamicsTenant
        appSecret
        clientId
        altUrl
        authUrl
        onPremVersion
      }
      skillIds
      multiSearch
      search
      searchAction
      activityEntityMap
      logging
      postLogging
      loggingAction
      createdAt
      updatedAt
    }
  }
`;
export const onTenantDeleteConfigProfile = /* GraphQL */ `
  subscription OnTenantDeleteConfigProfile($tenant: ID!) {
    onTenantDeleteConfigProfile(tenant: $tenant) {
      id
      tenant
      name
      direction
      mediaType
      crmEntity
      org {
        name
        id
        url
        version
      }
      connection {
        dynamicsTenant
        appSecret
        clientId
        altUrl
        authUrl
        onPremVersion
      }
      skillIds
      multiSearch
      search
      searchAction
      activityEntityMap
      logging
      postLogging
      loggingAction
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTenantSettings = /* GraphQL */ `
  subscription OnCreateTenantSettings {
    onCreateTenantSettings {
      id
      icApp
      icVendor
      icBU
      icUserName
      icPassword
      icGetAuthTokenURL
      icAccessKey
      icAppSecret
      icDiscoveryDomain
      icScriptName
      ftpImport
      ftpExport
      dashBoardCharts
      e2DashCharts
      ppDashCharts
      e2ClientId
      e2ClientSecret
      e2DynamTenantId
      onPremAccessUrl
      onPremVersion
      onPremAuthUrl
      apiKey
      licenses
      customFields {
        name
        displayName
        type
        isPoc
      }
      dynamicsConnection
      dynamicsDispoFilter
      email
      dispositionScoring {
        noAnswer
        answer
        noAgentAvailableToDial
        noAgentOnAnswer
        overApproving
      }
      telephonyProvider
      telephoneProviderInfo {
        amazonConnect {
          accessKey
          secretKey
          instanceId
          region
          queueId
          transferFlowId
        }
        incontact {
          icApp
          icVendor
          icBU
          icUserName
          icPassword
          icGetAuthTokenURL
          icAccessKey
          icAppSecret
          icDiscoveryDomain
          icScriptName
        }
        nobelbiz {
          nobelbizTenantId
          token
          instanceId
          username
          password
          encodedAuth
        }
        genesys {
          clientId
          clientSecret
          cloudRegion
          queueId
          wrapUps
          timerFlow
          chime {
            region
            outboundDialNumber
            sipMediaApplicationId
          }
        }
        webex {
          contactCenter {
            region
            organizationId
            clientId
            clientSecret
          }
          connect {
            region
            serviceKey
          }
          chime {
            region
            outboundDialNumber
            sipMediaApplicationId
          }
        }
      }
      dncDotComSettings {
        orgId
        projectId
        loginId
      }
      ipAuthentication
      ipRanges
      allowAutoMode
      overrideDialToAgentRatio
      dialToAgentRatio
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTenantSettings = /* GraphQL */ `
  subscription OnUpdateTenantSettings {
    onUpdateTenantSettings {
      id
      icApp
      icVendor
      icBU
      icUserName
      icPassword
      icGetAuthTokenURL
      icAccessKey
      icAppSecret
      icDiscoveryDomain
      icScriptName
      ftpImport
      ftpExport
      dashBoardCharts
      e2DashCharts
      ppDashCharts
      e2ClientId
      e2ClientSecret
      e2DynamTenantId
      onPremAccessUrl
      onPremVersion
      onPremAuthUrl
      apiKey
      licenses
      customFields {
        name
        displayName
        type
        isPoc
      }
      dynamicsConnection
      dynamicsDispoFilter
      email
      dispositionScoring {
        noAnswer
        answer
        noAgentAvailableToDial
        noAgentOnAnswer
        overApproving
      }
      telephonyProvider
      telephoneProviderInfo {
        amazonConnect {
          accessKey
          secretKey
          instanceId
          region
          queueId
          transferFlowId
        }
        incontact {
          icApp
          icVendor
          icBU
          icUserName
          icPassword
          icGetAuthTokenURL
          icAccessKey
          icAppSecret
          icDiscoveryDomain
          icScriptName
        }
        nobelbiz {
          nobelbizTenantId
          token
          instanceId
          username
          password
          encodedAuth
        }
        genesys {
          clientId
          clientSecret
          cloudRegion
          queueId
          wrapUps
          timerFlow
          chime {
            region
            outboundDialNumber
            sipMediaApplicationId
          }
        }
        webex {
          contactCenter {
            region
            organizationId
            clientId
            clientSecret
          }
          connect {
            region
            serviceKey
          }
          chime {
            region
            outboundDialNumber
            sipMediaApplicationId
          }
        }
      }
      dncDotComSettings {
        orgId
        projectId
        loginId
      }
      ipAuthentication
      ipRanges
      allowAutoMode
      overrideDialToAgentRatio
      dialToAgentRatio
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTenantSettings = /* GraphQL */ `
  subscription OnDeleteTenantSettings {
    onDeleteTenantSettings {
      id
      icApp
      icVendor
      icBU
      icUserName
      icPassword
      icGetAuthTokenURL
      icAccessKey
      icAppSecret
      icDiscoveryDomain
      icScriptName
      ftpImport
      ftpExport
      dashBoardCharts
      e2DashCharts
      ppDashCharts
      e2ClientId
      e2ClientSecret
      e2DynamTenantId
      onPremAccessUrl
      onPremVersion
      onPremAuthUrl
      apiKey
      licenses
      customFields {
        name
        displayName
        type
        isPoc
      }
      dynamicsConnection
      dynamicsDispoFilter
      email
      dispositionScoring {
        noAnswer
        answer
        noAgentAvailableToDial
        noAgentOnAnswer
        overApproving
      }
      telephonyProvider
      telephoneProviderInfo {
        amazonConnect {
          accessKey
          secretKey
          instanceId
          region
          queueId
          transferFlowId
        }
        incontact {
          icApp
          icVendor
          icBU
          icUserName
          icPassword
          icGetAuthTokenURL
          icAccessKey
          icAppSecret
          icDiscoveryDomain
          icScriptName
        }
        nobelbiz {
          nobelbizTenantId
          token
          instanceId
          username
          password
          encodedAuth
        }
        genesys {
          clientId
          clientSecret
          cloudRegion
          queueId
          wrapUps
          timerFlow
          chime {
            region
            outboundDialNumber
            sipMediaApplicationId
          }
        }
        webex {
          contactCenter {
            region
            organizationId
            clientId
            clientSecret
          }
          connect {
            region
            serviceKey
          }
          chime {
            region
            outboundDialNumber
            sipMediaApplicationId
          }
        }
      }
      dncDotComSettings {
        orgId
        projectId
        loginId
      }
      ipAuthentication
      ipRanges
      allowAutoMode
      overrideDialToAgentRatio
      dialToAgentRatio
      createdAt
      updatedAt
    }
  }
`;
export const onCreateFtpImport = /* GraphQL */ `
  subscription OnCreateFtpImport {
    onCreateFtpImport {
      id
      tenant
      name
      host
      username
      password
      path
      privateKey
      port
      fieldMappingId
      lastCheck
      fileNameToSource
      algorithms {
        cipher
      }
      readyTimeout
      reportEmail
      delimiter
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateFtpImport = /* GraphQL */ `
  subscription OnUpdateFtpImport {
    onUpdateFtpImport {
      id
      tenant
      name
      host
      username
      password
      path
      privateKey
      port
      fieldMappingId
      lastCheck
      fileNameToSource
      algorithms {
        cipher
      }
      readyTimeout
      reportEmail
      delimiter
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteFtpImport = /* GraphQL */ `
  subscription OnDeleteFtpImport {
    onDeleteFtpImport {
      id
      tenant
      name
      host
      username
      password
      path
      privateKey
      port
      fieldMappingId
      lastCheck
      fileNameToSource
      algorithms {
        cipher
      }
      readyTimeout
      reportEmail
      delimiter
      createdAt
      updatedAt
    }
  }
`;
export const onCreateFieldsMapping = /* GraphQL */ `
  subscription OnCreateFieldsMapping {
    onCreateFieldsMapping {
      id
      tenant
      name
      mappings {
        fromField
        toField
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateFieldsMapping = /* GraphQL */ `
  subscription OnUpdateFieldsMapping {
    onUpdateFieldsMapping {
      id
      tenant
      name
      mappings {
        fromField
        toField
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteFieldsMapping = /* GraphQL */ `
  subscription OnDeleteFieldsMapping {
    onDeleteFieldsMapping {
      id
      tenant
      name
      mappings {
        fromField
        toField
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateContactMetadataFieldMappings = /* GraphQL */ `
  subscription OnCreateContactMetadataFieldMappings {
    onCreateContactMetadataFieldMappings {
      id
      tenant
      name
      mappings {
        fromField
        toField
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateContactMetadataFieldMappings = /* GraphQL */ `
  subscription OnUpdateContactMetadataFieldMappings {
    onUpdateContactMetadataFieldMappings {
      id
      tenant
      name
      mappings {
        fromField
        toField
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteContactMetadataFieldMappings = /* GraphQL */ `
  subscription OnDeleteContactMetadataFieldMappings {
    onDeleteContactMetadataFieldMappings {
      id
      tenant
      name
      mappings {
        fromField
        toField
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateContactCallWindowsFieldMappings = /* GraphQL */ `
  subscription OnCreateContactCallWindowsFieldMappings {
    onCreateContactCallWindowsFieldMappings {
      id
      tenant
      name
      mappings {
        fromField
        toField
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateContactCallWindowsFieldMappings = /* GraphQL */ `
  subscription OnUpdateContactCallWindowsFieldMappings {
    onUpdateContactCallWindowsFieldMappings {
      id
      tenant
      name
      mappings {
        fromField
        toField
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteContactCallWindowsFieldMappings = /* GraphQL */ `
  subscription OnDeleteContactCallWindowsFieldMappings {
    onDeleteContactCallWindowsFieldMappings {
      id
      tenant
      name
      mappings {
        fromField
        toField
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCampaign = /* GraphQL */ `
  subscription OnCreateCampaign {
    onCreateCampaign {
      id
      tenant
      name
      segments
      segmentSchedule
      campaignSchedule
      static
      sort
      skillId
      skillName
      skillSource
      campaignProfileId
      profile {
        id
        tenant
        name
        useAi
        flowRatio
        scrubber {
          cell
          natDnc
          stateDnc
          orgDnc
          phoneDup
          contactDup
        }
        retry {
          minDuration
          maxAttempts
          maxContacts
          custRetry
          ratio
        }
        ai {
          org
          system
        }
        pushContacts
        startTime
        endTime
        buffer
        channels {
          type
          order
          retryDelay
          maxAttempts
          maxContacts
          custRetry
          paymentProfileId
          paymentProfileName
          dispositions
          cadence
          dialingScript
        }
        ratio
        dontCallOnWeekends
        callWindow {
          days
          tmz {
            offset
            dst
          }
          beginTime {
            hour
            minute
          }
          endTime {
            hour
            minute
          }
          callWindowQuery {
            field
            operator
            value
          }
          default
          order
        }
        clickToCall
        callWindows {
          days
          tmz {
            offset
            dst
          }
          beginTime {
            hour
            minute
          }
          endTime {
            hour
            minute
          }
          callWindowQuery {
            field
            operator
            value
          }
          default
          order
        }
        dispositionScoring {
          noAnswer
          answer
          noAgentAvailableToDial
          noAgentOnAnswer
          overApproving
        }
        overrideDispositionScoring
        overrideDialToAgentRatio
        dialToAgentRatio
        freshLIFO
        retryLIFO
        createdAt
        updatedAt
      }
      status
      expireDate
      stopOnCompletion
      cci
      autoMode
      cciMetaData
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCampaign = /* GraphQL */ `
  subscription OnUpdateCampaign {
    onUpdateCampaign {
      id
      tenant
      name
      segments
      segmentSchedule
      campaignSchedule
      static
      sort
      skillId
      skillName
      skillSource
      campaignProfileId
      profile {
        id
        tenant
        name
        useAi
        flowRatio
        scrubber {
          cell
          natDnc
          stateDnc
          orgDnc
          phoneDup
          contactDup
        }
        retry {
          minDuration
          maxAttempts
          maxContacts
          custRetry
          ratio
        }
        ai {
          org
          system
        }
        pushContacts
        startTime
        endTime
        buffer
        channels {
          type
          order
          retryDelay
          maxAttempts
          maxContacts
          custRetry
          paymentProfileId
          paymentProfileName
          dispositions
          cadence
          dialingScript
        }
        ratio
        dontCallOnWeekends
        callWindow {
          days
          tmz {
            offset
            dst
          }
          beginTime {
            hour
            minute
          }
          endTime {
            hour
            minute
          }
          callWindowQuery {
            field
            operator
            value
          }
          default
          order
        }
        clickToCall
        callWindows {
          days
          tmz {
            offset
            dst
          }
          beginTime {
            hour
            minute
          }
          endTime {
            hour
            minute
          }
          callWindowQuery {
            field
            operator
            value
          }
          default
          order
        }
        dispositionScoring {
          noAnswer
          answer
          noAgentAvailableToDial
          noAgentOnAnswer
          overApproving
        }
        overrideDispositionScoring
        overrideDialToAgentRatio
        dialToAgentRatio
        freshLIFO
        retryLIFO
        createdAt
        updatedAt
      }
      status
      expireDate
      stopOnCompletion
      cci
      autoMode
      cciMetaData
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCampaign = /* GraphQL */ `
  subscription OnDeleteCampaign {
    onDeleteCampaign {
      id
      tenant
      name
      segments
      segmentSchedule
      campaignSchedule
      static
      sort
      skillId
      skillName
      skillSource
      campaignProfileId
      profile {
        id
        tenant
        name
        useAi
        flowRatio
        scrubber {
          cell
          natDnc
          stateDnc
          orgDnc
          phoneDup
          contactDup
        }
        retry {
          minDuration
          maxAttempts
          maxContacts
          custRetry
          ratio
        }
        ai {
          org
          system
        }
        pushContacts
        startTime
        endTime
        buffer
        channels {
          type
          order
          retryDelay
          maxAttempts
          maxContacts
          custRetry
          paymentProfileId
          paymentProfileName
          dispositions
          cadence
          dialingScript
        }
        ratio
        dontCallOnWeekends
        callWindow {
          days
          tmz {
            offset
            dst
          }
          beginTime {
            hour
            minute
          }
          endTime {
            hour
            minute
          }
          callWindowQuery {
            field
            operator
            value
          }
          default
          order
        }
        clickToCall
        callWindows {
          days
          tmz {
            offset
            dst
          }
          beginTime {
            hour
            minute
          }
          endTime {
            hour
            minute
          }
          callWindowQuery {
            field
            operator
            value
          }
          default
          order
        }
        dispositionScoring {
          noAnswer
          answer
          noAgentAvailableToDial
          noAgentOnAnswer
          overApproving
        }
        overrideDispositionScoring
        overrideDialToAgentRatio
        dialToAgentRatio
        freshLIFO
        retryLIFO
        createdAt
        updatedAt
      }
      status
      expireDate
      stopOnCompletion
      cci
      autoMode
      cciMetaData
      createdAt
      updatedAt
    }
  }
`;
export const onCreateProfile = /* GraphQL */ `
  subscription OnCreateProfile {
    onCreateProfile {
      id
      tenant
      name
      useAi
      flowRatio
      scrubber {
        cell
        natDnc
        stateDnc
        orgDnc
        phoneDup
        contactDup
      }
      retry {
        minDuration
        maxAttempts
        maxContacts
        custRetry
        ratio
      }
      ai {
        org
        system
      }
      pushContacts
      startTime
      endTime
      buffer
      channels {
        type
        order
        retryDelay
        maxAttempts
        maxContacts
        custRetry
        paymentProfileId
        paymentProfileName
        dispositions
        cadence
        dialingScript
      }
      ratio
      dontCallOnWeekends
      callWindow {
        days
        tmz {
          offset
          dst
        }
        beginTime {
          hour
          minute
        }
        endTime {
          hour
          minute
        }
        callWindowQuery {
          field
          operator
          value
        }
        default
        order
      }
      clickToCall
      callWindows {
        days
        tmz {
          offset
          dst
        }
        beginTime {
          hour
          minute
        }
        endTime {
          hour
          minute
        }
        callWindowQuery {
          field
          operator
          value
        }
        default
        order
      }
      dispositionScoring {
        noAnswer
        answer
        noAgentAvailableToDial
        noAgentOnAnswer
        overApproving
      }
      overrideDispositionScoring
      overrideDialToAgentRatio
      dialToAgentRatio
      freshLIFO
      retryLIFO
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateProfile = /* GraphQL */ `
  subscription OnUpdateProfile {
    onUpdateProfile {
      id
      tenant
      name
      useAi
      flowRatio
      scrubber {
        cell
        natDnc
        stateDnc
        orgDnc
        phoneDup
        contactDup
      }
      retry {
        minDuration
        maxAttempts
        maxContacts
        custRetry
        ratio
      }
      ai {
        org
        system
      }
      pushContacts
      startTime
      endTime
      buffer
      channels {
        type
        order
        retryDelay
        maxAttempts
        maxContacts
        custRetry
        paymentProfileId
        paymentProfileName
        dispositions
        cadence
        dialingScript
      }
      ratio
      dontCallOnWeekends
      callWindow {
        days
        tmz {
          offset
          dst
        }
        beginTime {
          hour
          minute
        }
        endTime {
          hour
          minute
        }
        callWindowQuery {
          field
          operator
          value
        }
        default
        order
      }
      clickToCall
      callWindows {
        days
        tmz {
          offset
          dst
        }
        beginTime {
          hour
          minute
        }
        endTime {
          hour
          minute
        }
        callWindowQuery {
          field
          operator
          value
        }
        default
        order
      }
      dispositionScoring {
        noAnswer
        answer
        noAgentAvailableToDial
        noAgentOnAnswer
        overApproving
      }
      overrideDispositionScoring
      overrideDialToAgentRatio
      dialToAgentRatio
      freshLIFO
      retryLIFO
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteProfile = /* GraphQL */ `
  subscription OnDeleteProfile {
    onDeleteProfile {
      id
      tenant
      name
      useAi
      flowRatio
      scrubber {
        cell
        natDnc
        stateDnc
        orgDnc
        phoneDup
        contactDup
      }
      retry {
        minDuration
        maxAttempts
        maxContacts
        custRetry
        ratio
      }
      ai {
        org
        system
      }
      pushContacts
      startTime
      endTime
      buffer
      channels {
        type
        order
        retryDelay
        maxAttempts
        maxContacts
        custRetry
        paymentProfileId
        paymentProfileName
        dispositions
        cadence
        dialingScript
      }
      ratio
      dontCallOnWeekends
      callWindow {
        days
        tmz {
          offset
          dst
        }
        beginTime {
          hour
          minute
        }
        endTime {
          hour
          minute
        }
        callWindowQuery {
          field
          operator
          value
        }
        default
        order
      }
      clickToCall
      callWindows {
        days
        tmz {
          offset
          dst
        }
        beginTime {
          hour
          minute
        }
        endTime {
          hour
          minute
        }
        callWindowQuery {
          field
          operator
          value
        }
        default
        order
      }
      dispositionScoring {
        noAnswer
        answer
        noAgentAvailableToDial
        noAgentOnAnswer
        overApproving
      }
      overrideDispositionScoring
      overrideDialToAgentRatio
      dialToAgentRatio
      freshLIFO
      retryLIFO
      createdAt
      updatedAt
    }
  }
`;
export const onCreateManualProfile = /* GraphQL */ `
  subscription OnCreateManualProfile {
    onCreateManualProfile {
      id
      tenant
      name
      dialingProfile
      suppressionProfile
      skills
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateManualProfile = /* GraphQL */ `
  subscription OnUpdateManualProfile {
    onUpdateManualProfile {
      id
      tenant
      name
      dialingProfile
      suppressionProfile
      skills
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteManualProfile = /* GraphQL */ `
  subscription OnDeleteManualProfile {
    onDeleteManualProfile {
      id
      tenant
      name
      dialingProfile
      suppressionProfile
      skills
      createdAt
      updatedAt
    }
  }
`;
export const onCreateSegment = /* GraphQL */ `
  subscription OnCreateSegment {
    onCreateSegment {
      id
      tenant
      externalId
      name
      description
      query {
        qbQuery
        searchFilter
        sort {
          field
          direction
        }
      }
      filter {
        qbQuery
        searchFilter
        sort {
          field
          direction
        }
      }
      fieldsMappingId
      suppressionID
      suppression {
        id
        tenant
        name
        result
        activityLogQuerySuppressionEnabled
        activityLogQuerySuppression {
          query
          rawQuery
          threshold
        }
        pocQuerySuppressionEnabled
        pocQuerySuppression {
          query
          rawQuery
          suppressIfWeekendAndCantCallOnWeekends
        }
        restSuppressionEnabled
        restSuppression {
          endpoint
          method
          params {
            key
            value
            description
          }
          headers {
            key
            value
            description
          }
          auth {
            type
            info
          }
          response {
            path
            type
            value
            options
          }
          suppressOnError
          dispositionOnError
        }
        dncDotComEnabled
        dncDotCom {
          scrub
          state
          numberReassignment
          knownLitigator
          reassignmentSince
        }
        dncListSuppressionEnabled
        dncListSuppressions
        contactFieldSuppressionEnabled
        contactFieldSuppressions
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSegment = /* GraphQL */ `
  subscription OnUpdateSegment {
    onUpdateSegment {
      id
      tenant
      externalId
      name
      description
      query {
        qbQuery
        searchFilter
        sort {
          field
          direction
        }
      }
      filter {
        qbQuery
        searchFilter
        sort {
          field
          direction
        }
      }
      fieldsMappingId
      suppressionID
      suppression {
        id
        tenant
        name
        result
        activityLogQuerySuppressionEnabled
        activityLogQuerySuppression {
          query
          rawQuery
          threshold
        }
        pocQuerySuppressionEnabled
        pocQuerySuppression {
          query
          rawQuery
          suppressIfWeekendAndCantCallOnWeekends
        }
        restSuppressionEnabled
        restSuppression {
          endpoint
          method
          params {
            key
            value
            description
          }
          headers {
            key
            value
            description
          }
          auth {
            type
            info
          }
          response {
            path
            type
            value
            options
          }
          suppressOnError
          dispositionOnError
        }
        dncDotComEnabled
        dncDotCom {
          scrub
          state
          numberReassignment
          knownLitigator
          reassignmentSince
        }
        dncListSuppressionEnabled
        dncListSuppressions
        contactFieldSuppressionEnabled
        contactFieldSuppressions
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSegment = /* GraphQL */ `
  subscription OnDeleteSegment {
    onDeleteSegment {
      id
      tenant
      externalId
      name
      description
      query {
        qbQuery
        searchFilter
        sort {
          field
          direction
        }
      }
      filter {
        qbQuery
        searchFilter
        sort {
          field
          direction
        }
      }
      fieldsMappingId
      suppressionID
      suppression {
        id
        tenant
        name
        result
        activityLogQuerySuppressionEnabled
        activityLogQuerySuppression {
          query
          rawQuery
          threshold
        }
        pocQuerySuppressionEnabled
        pocQuerySuppression {
          query
          rawQuery
          suppressIfWeekendAndCantCallOnWeekends
        }
        restSuppressionEnabled
        restSuppression {
          endpoint
          method
          params {
            key
            value
            description
          }
          headers {
            key
            value
            description
          }
          auth {
            type
            info
          }
          response {
            path
            type
            value
            options
          }
          suppressOnError
          dispositionOnError
        }
        dncDotComEnabled
        dncDotCom {
          scrub
          state
          numberReassignment
          knownLitigator
          reassignmentSince
        }
        dncListSuppressionEnabled
        dncListSuppressions
        contactFieldSuppressionEnabled
        contactFieldSuppressions
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCampaignLog = /* GraphQL */ `
  subscription OnCreateCampaignLog {
    onCreateCampaignLog {
      id
      tenant
      campaign
      type
      metaData
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCampaignLog = /* GraphQL */ `
  subscription OnUpdateCampaignLog {
    onUpdateCampaignLog {
      id
      tenant
      campaign
      type
      metaData
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCampaignLog = /* GraphQL */ `
  subscription OnDeleteCampaignLog {
    onDeleteCampaignLog {
      id
      tenant
      campaign
      type
      metaData
      createdAt
      updatedAt
    }
  }
`;
export const onCreateConfigProfile = /* GraphQL */ `
  subscription OnCreateConfigProfile {
    onCreateConfigProfile {
      id
      tenant
      name
      direction
      mediaType
      crmEntity
      org {
        name
        id
        url
        version
      }
      connection {
        dynamicsTenant
        appSecret
        clientId
        altUrl
        authUrl
        onPremVersion
      }
      skillIds
      multiSearch
      search
      searchAction
      activityEntityMap
      logging
      postLogging
      loggingAction
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateConfigProfile = /* GraphQL */ `
  subscription OnUpdateConfigProfile {
    onUpdateConfigProfile {
      id
      tenant
      name
      direction
      mediaType
      crmEntity
      org {
        name
        id
        url
        version
      }
      connection {
        dynamicsTenant
        appSecret
        clientId
        altUrl
        authUrl
        onPremVersion
      }
      skillIds
      multiSearch
      search
      searchAction
      activityEntityMap
      logging
      postLogging
      loggingAction
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteConfigProfile = /* GraphQL */ `
  subscription OnDeleteConfigProfile {
    onDeleteConfigProfile {
      id
      tenant
      name
      direction
      mediaType
      crmEntity
      org {
        name
        id
        url
        version
      }
      connection {
        dynamicsTenant
        appSecret
        clientId
        altUrl
        authUrl
        onPremVersion
      }
      skillIds
      multiSearch
      search
      searchAction
      activityEntityMap
      logging
      postLogging
      loggingAction
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCurfewXCodes = /* GraphQL */ `
  subscription OnCreateCurfewXCodes {
    onCreateCurfewXCodes {
      id
      minOffSet
      maxOffSet
      dls
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCurfewXCodes = /* GraphQL */ `
  subscription OnUpdateCurfewXCodes {
    onUpdateCurfewXCodes {
      id
      minOffSet
      maxOffSet
      dls
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCurfewXCodes = /* GraphQL */ `
  subscription OnDeleteCurfewXCodes {
    onDeleteCurfewXCodes {
      id
      minOffSet
      maxOffSet
      dls
      createdAt
      updatedAt
    }
  }
`;
export const onCreatePaymentProfile = /* GraphQL */ `
  subscription OnCreatePaymentProfile {
    onCreatePaymentProfile {
      id
      tenant
      name
      vendorId
      apiKey
      descriptor
      ttl
      siteLogo
      header1
      header2
      sitePrimary
      siteSecondary
      minType
      minRate
      supportEmail
      supportPhone
      promptPaySkills
      channels
      c2a
      subject
      sendingEmail
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePaymentProfile = /* GraphQL */ `
  subscription OnUpdatePaymentProfile {
    onUpdatePaymentProfile {
      id
      tenant
      name
      vendorId
      apiKey
      descriptor
      ttl
      siteLogo
      header1
      header2
      sitePrimary
      siteSecondary
      minType
      minRate
      supportEmail
      supportPhone
      promptPaySkills
      channels
      c2a
      subject
      sendingEmail
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePaymentProfile = /* GraphQL */ `
  subscription OnDeletePaymentProfile {
    onDeletePaymentProfile {
      id
      tenant
      name
      vendorId
      apiKey
      descriptor
      ttl
      siteLogo
      header1
      header2
      sitePrimary
      siteSecondary
      minType
      minRate
      supportEmail
      supportPhone
      promptPaySkills
      channels
      c2a
      subject
      sendingEmail
      createdAt
      updatedAt
    }
  }
`;
export const onCreatePaymentTemplate = /* GraphQL */ `
  subscription OnCreatePaymentTemplate {
    onCreatePaymentTemplate {
      id
      tenant
      name
      textBody
      type
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePaymentTemplate = /* GraphQL */ `
  subscription OnUpdatePaymentTemplate {
    onUpdatePaymentTemplate {
      id
      tenant
      name
      textBody
      type
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePaymentTemplate = /* GraphQL */ `
  subscription OnDeletePaymentTemplate {
    onDeletePaymentTemplate {
      id
      tenant
      name
      textBody
      type
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCustomDispoMapping = /* GraphQL */ `
  subscription OnCreateCustomDispoMapping {
    onCreateCustomDispoMapping {
      id
      tenant
      skill
      custDispId
      custDispName
      dispClassId
      dispCode
      dispDescription
      dispClassName
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCustomDispoMapping = /* GraphQL */ `
  subscription OnUpdateCustomDispoMapping {
    onUpdateCustomDispoMapping {
      id
      tenant
      skill
      custDispId
      custDispName
      dispClassId
      dispCode
      dispDescription
      dispClassName
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCustomDispoMapping = /* GraphQL */ `
  subscription OnDeleteCustomDispoMapping {
    onDeleteCustomDispoMapping {
      id
      tenant
      skill
      custDispId
      custDispName
      dispClassId
      dispCode
      dispDescription
      dispClassName
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCustomDispositions = /* GraphQL */ `
  subscription OnCreateCustomDispositions {
    onCreateCustomDispositions {
      id
      tenant
      name
      description
      classification
      previewDispo
      noteRequired
      amountRequired
      rescheduleDateRequired
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCustomDispositions = /* GraphQL */ `
  subscription OnUpdateCustomDispositions {
    onUpdateCustomDispositions {
      id
      tenant
      name
      description
      classification
      previewDispo
      noteRequired
      amountRequired
      rescheduleDateRequired
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCustomDispositions = /* GraphQL */ `
  subscription OnDeleteCustomDispositions {
    onDeleteCustomDispositions {
      id
      tenant
      name
      description
      classification
      previewDispo
      noteRequired
      amountRequired
      rescheduleDateRequired
      createdAt
      updatedAt
    }
  }
`;
export const onCreateDncList = /* GraphQL */ `
  subscription OnCreateDncList {
    onCreateDNCList {
      id
      tenant
      name
      numbers {
        items {
          id
          tenant
          phoneNumber
          dncList
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateDncList = /* GraphQL */ `
  subscription OnUpdateDncList {
    onUpdateDNCList {
      id
      tenant
      name
      numbers {
        items {
          id
          tenant
          phoneNumber
          dncList
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteDncList = /* GraphQL */ `
  subscription OnDeleteDncList {
    onDeleteDNCList {
      id
      tenant
      name
      numbers {
        items {
          id
          tenant
          phoneNumber
          dncList
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateDnc = /* GraphQL */ `
  subscription OnCreateDnc {
    onCreateDNC {
      id
      tenant
      phoneNumber
      dncList
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateDnc = /* GraphQL */ `
  subscription OnUpdateDnc {
    onUpdateDNC {
      id
      tenant
      phoneNumber
      dncList
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteDnc = /* GraphQL */ `
  subscription OnDeleteDnc {
    onDeleteDNC {
      id
      tenant
      phoneNumber
      dncList
      createdAt
      updatedAt
    }
  }
`;
export const onCreateContactFieldSuppressionList = /* GraphQL */ `
  subscription OnCreateContactFieldSuppressionList {
    onCreateContactFieldSuppressionList {
      id
      tenant
      name
      contactField
      fields {
        items {
          id
          tenant
          contactFieldValue
          contactFieldSuppressionList
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateContactFieldSuppressionList = /* GraphQL */ `
  subscription OnUpdateContactFieldSuppressionList {
    onUpdateContactFieldSuppressionList {
      id
      tenant
      name
      contactField
      fields {
        items {
          id
          tenant
          contactFieldValue
          contactFieldSuppressionList
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteContactFieldSuppressionList = /* GraphQL */ `
  subscription OnDeleteContactFieldSuppressionList {
    onDeleteContactFieldSuppressionList {
      id
      tenant
      name
      contactField
      fields {
        items {
          id
          tenant
          contactFieldValue
          contactFieldSuppressionList
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateContactFieldSuppression = /* GraphQL */ `
  subscription OnCreateContactFieldSuppression {
    onCreateContactFieldSuppression {
      id
      tenant
      contactFieldValue
      contactFieldSuppressionList
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateContactFieldSuppression = /* GraphQL */ `
  subscription OnUpdateContactFieldSuppression {
    onUpdateContactFieldSuppression {
      id
      tenant
      contactFieldValue
      contactFieldSuppressionList
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteContactFieldSuppression = /* GraphQL */ `
  subscription OnDeleteContactFieldSuppression {
    onDeleteContactFieldSuppression {
      id
      tenant
      contactFieldValue
      contactFieldSuppressionList
      createdAt
      updatedAt
    }
  }
`;
export const onCreateSuppression = /* GraphQL */ `
  subscription OnCreateSuppression {
    onCreateSuppression {
      id
      tenant
      name
      result
      activityLogQuerySuppressionEnabled
      activityLogQuerySuppression {
        query
        rawQuery
        threshold
      }
      pocQuerySuppressionEnabled
      pocQuerySuppression {
        query
        rawQuery
        suppressIfWeekendAndCantCallOnWeekends
      }
      restSuppressionEnabled
      restSuppression {
        endpoint
        method
        params {
          key
          value
          description
        }
        headers {
          key
          value
          description
        }
        auth {
          type
          info
        }
        response {
          path
          type
          value
          options
        }
        suppressOnError
        dispositionOnError
      }
      dncDotComEnabled
      dncDotCom {
        scrub
        state
        numberReassignment
        knownLitigator
        reassignmentSince
      }
      dncListSuppressionEnabled
      dncListSuppressions
      contactFieldSuppressionEnabled
      contactFieldSuppressions
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSuppression = /* GraphQL */ `
  subscription OnUpdateSuppression {
    onUpdateSuppression {
      id
      tenant
      name
      result
      activityLogQuerySuppressionEnabled
      activityLogQuerySuppression {
        query
        rawQuery
        threshold
      }
      pocQuerySuppressionEnabled
      pocQuerySuppression {
        query
        rawQuery
        suppressIfWeekendAndCantCallOnWeekends
      }
      restSuppressionEnabled
      restSuppression {
        endpoint
        method
        params {
          key
          value
          description
        }
        headers {
          key
          value
          description
        }
        auth {
          type
          info
        }
        response {
          path
          type
          value
          options
        }
        suppressOnError
        dispositionOnError
      }
      dncDotComEnabled
      dncDotCom {
        scrub
        state
        numberReassignment
        knownLitigator
        reassignmentSince
      }
      dncListSuppressionEnabled
      dncListSuppressions
      contactFieldSuppressionEnabled
      contactFieldSuppressions
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSuppression = /* GraphQL */ `
  subscription OnDeleteSuppression {
    onDeleteSuppression {
      id
      tenant
      name
      result
      activityLogQuerySuppressionEnabled
      activityLogQuerySuppression {
        query
        rawQuery
        threshold
      }
      pocQuerySuppressionEnabled
      pocQuerySuppression {
        query
        rawQuery
        suppressIfWeekendAndCantCallOnWeekends
      }
      restSuppressionEnabled
      restSuppression {
        endpoint
        method
        params {
          key
          value
          description
        }
        headers {
          key
          value
          description
        }
        auth {
          type
          info
        }
        response {
          path
          type
          value
          options
        }
        suppressOnError
        dispositionOnError
      }
      dncDotComEnabled
      dncDotCom {
        scrub
        state
        numberReassignment
        knownLitigator
        reassignmentSince
      }
      dncListSuppressionEnabled
      dncListSuppressions
      contactFieldSuppressionEnabled
      contactFieldSuppressions
      createdAt
      updatedAt
    }
  }
`;
export const onCreateOAuthLookUp = /* GraphQL */ `
  subscription OnCreateOAuthLookUp {
    onCreateOAuthLookUp {
      id
      clientSecret
      cognitoUserName
      cognitoPassword
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateOAuthLookUp = /* GraphQL */ `
  subscription OnUpdateOAuthLookUp {
    onUpdateOAuthLookUp {
      id
      clientSecret
      cognitoUserName
      cognitoPassword
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteOAuthLookUp = /* GraphQL */ `
  subscription OnDeleteOAuthLookUp {
    onDeleteOAuthLookUp {
      id
      clientSecret
      cognitoUserName
      cognitoPassword
      createdAt
      updatedAt
    }
  }
`;
export const onCreateContact = /* GraphQL */ `
  subscription OnCreateContact {
    onCreateContact {
      id
      tenant
      externalId
      firstName
      lastName
      source
      phone
      cell
      email
      dateCreated
      address1
      address2
      city
      state
      zip
      dnc
      updatedBy
      customFields
      expireDt
      outboundCallerId
      balances {
        id
        type
        amount
        age
      }
      timeZoneBounds {
        min {
          offset
          dst
        }
        max {
          offset
          dst
        }
      }
      type
      optOuts {
        voice
        sms
        email
      }
      optIns {
        voice
        sms
        email
      }
      attempts
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateContact = /* GraphQL */ `
  subscription OnUpdateContact {
    onUpdateContact {
      id
      tenant
      externalId
      firstName
      lastName
      source
      phone
      cell
      email
      dateCreated
      address1
      address2
      city
      state
      zip
      dnc
      updatedBy
      customFields
      expireDt
      outboundCallerId
      balances {
        id
        type
        amount
        age
      }
      timeZoneBounds {
        min {
          offset
          dst
        }
        max {
          offset
          dst
        }
      }
      type
      optOuts {
        voice
        sms
        email
      }
      optIns {
        voice
        sms
        email
      }
      attempts
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteContact = /* GraphQL */ `
  subscription OnDeleteContact {
    onDeleteContact {
      id
      tenant
      externalId
      firstName
      lastName
      source
      phone
      cell
      email
      dateCreated
      address1
      address2
      city
      state
      zip
      dnc
      updatedBy
      customFields
      expireDt
      outboundCallerId
      balances {
        id
        type
        amount
        age
      }
      timeZoneBounds {
        min {
          offset
          dst
        }
        max {
          offset
          dst
        }
      }
      type
      optOuts {
        voice
        sms
        email
      }
      optIns {
        voice
        sms
        email
      }
      attempts
      createdAt
      updatedAt
    }
  }
`;
export const onCreateActivityLog = /* GraphQL */ `
  subscription OnCreateActivityLog {
    onCreateActivityLog {
      id
      tenant
      type
      disposition
      title
      body
      activityLogContactId
      segment {
        id
        tenant
        externalId
        name
        description
        query {
          qbQuery
          searchFilter
          sort {
            field
            direction
          }
        }
        filter {
          qbQuery
          searchFilter
          sort {
            field
            direction
          }
        }
        fieldsMappingId
        suppressionID
        suppression {
          id
          tenant
          name
          result
          activityLogQuerySuppressionEnabled
          activityLogQuerySuppression {
            query
            rawQuery
            threshold
          }
          pocQuerySuppressionEnabled
          pocQuerySuppression {
            query
            rawQuery
            suppressIfWeekendAndCantCallOnWeekends
          }
          restSuppressionEnabled
          restSuppression {
            endpoint
            method
            params {
              key
              value
              description
            }
            headers {
              key
              value
              description
            }
            auth {
              type
              info
            }
            response {
              path
              type
              value
              options
            }
            suppressOnError
            dispositionOnError
          }
          dncDotComEnabled
          dncDotCom {
            scrub
            state
            numberReassignment
            knownLitigator
            reassignmentSince
          }
          dncListSuppressionEnabled
          dncListSuppressions
          contactFieldSuppressionEnabled
          contactFieldSuppressions
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      campaign {
        id
        tenant
        name
        segments
        segmentSchedule
        campaignSchedule
        static
        sort
        skillId
        skillName
        skillSource
        campaignProfileId
        profile {
          id
          tenant
          name
          useAi
          flowRatio
          scrubber {
            cell
            natDnc
            stateDnc
            orgDnc
            phoneDup
            contactDup
          }
          retry {
            minDuration
            maxAttempts
            maxContacts
            custRetry
            ratio
          }
          ai {
            org
            system
          }
          pushContacts
          startTime
          endTime
          buffer
          channels {
            type
            order
            retryDelay
            maxAttempts
            maxContacts
            custRetry
            paymentProfileId
            paymentProfileName
            dispositions
            cadence
            dialingScript
          }
          ratio
          dontCallOnWeekends
          callWindow {
            days
            tmz {
              offset
              dst
            }
            beginTime {
              hour
              minute
            }
            endTime {
              hour
              minute
            }
            callWindowQuery {
              field
              operator
              value
            }
            default
            order
          }
          clickToCall
          callWindows {
            days
            tmz {
              offset
              dst
            }
            beginTime {
              hour
              minute
            }
            endTime {
              hour
              minute
            }
            callWindowQuery {
              field
              operator
              value
            }
            default
            order
          }
          dispositionScoring {
            noAnswer
            answer
            noAgentAvailableToDial
            noAgentOnAnswer
            overApproving
          }
          overrideDispositionScoring
          overrideDialToAgentRatio
          dialToAgentRatio
          freshLIFO
          retryLIFO
          createdAt
          updatedAt
        }
        status
        expireDate
        stopOnCompletion
        cci
        autoMode
        cciMetaData
        createdAt
        updatedAt
      }
      agent
      contactStatus
      timestamp
      metaData
      provider
      c2aId
      pocActual
      createdAt
      updatedAt
      contact {
        id
        tenant
        externalId
        firstName
        lastName
        source
        phone
        cell
        email
        dateCreated
        address1
        address2
        city
        state
        zip
        dnc
        updatedBy
        customFields
        expireDt
        outboundCallerId
        balances {
          id
          type
          amount
          age
        }
        timeZoneBounds {
          min {
            offset
            dst
          }
          max {
            offset
            dst
          }
        }
        type
        optOuts {
          voice
          sms
          email
        }
        optIns {
          voice
          sms
          email
        }
        attempts
        createdAt
        updatedAt
      }
    }
  }
`;
export const onUpdateActivityLog = /* GraphQL */ `
  subscription OnUpdateActivityLog {
    onUpdateActivityLog {
      id
      tenant
      type
      disposition
      title
      body
      activityLogContactId
      segment {
        id
        tenant
        externalId
        name
        description
        query {
          qbQuery
          searchFilter
          sort {
            field
            direction
          }
        }
        filter {
          qbQuery
          searchFilter
          sort {
            field
            direction
          }
        }
        fieldsMappingId
        suppressionID
        suppression {
          id
          tenant
          name
          result
          activityLogQuerySuppressionEnabled
          activityLogQuerySuppression {
            query
            rawQuery
            threshold
          }
          pocQuerySuppressionEnabled
          pocQuerySuppression {
            query
            rawQuery
            suppressIfWeekendAndCantCallOnWeekends
          }
          restSuppressionEnabled
          restSuppression {
            endpoint
            method
            params {
              key
              value
              description
            }
            headers {
              key
              value
              description
            }
            auth {
              type
              info
            }
            response {
              path
              type
              value
              options
            }
            suppressOnError
            dispositionOnError
          }
          dncDotComEnabled
          dncDotCom {
            scrub
            state
            numberReassignment
            knownLitigator
            reassignmentSince
          }
          dncListSuppressionEnabled
          dncListSuppressions
          contactFieldSuppressionEnabled
          contactFieldSuppressions
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      campaign {
        id
        tenant
        name
        segments
        segmentSchedule
        campaignSchedule
        static
        sort
        skillId
        skillName
        skillSource
        campaignProfileId
        profile {
          id
          tenant
          name
          useAi
          flowRatio
          scrubber {
            cell
            natDnc
            stateDnc
            orgDnc
            phoneDup
            contactDup
          }
          retry {
            minDuration
            maxAttempts
            maxContacts
            custRetry
            ratio
          }
          ai {
            org
            system
          }
          pushContacts
          startTime
          endTime
          buffer
          channels {
            type
            order
            retryDelay
            maxAttempts
            maxContacts
            custRetry
            paymentProfileId
            paymentProfileName
            dispositions
            cadence
            dialingScript
          }
          ratio
          dontCallOnWeekends
          callWindow {
            days
            tmz {
              offset
              dst
            }
            beginTime {
              hour
              minute
            }
            endTime {
              hour
              minute
            }
            callWindowQuery {
              field
              operator
              value
            }
            default
            order
          }
          clickToCall
          callWindows {
            days
            tmz {
              offset
              dst
            }
            beginTime {
              hour
              minute
            }
            endTime {
              hour
              minute
            }
            callWindowQuery {
              field
              operator
              value
            }
            default
            order
          }
          dispositionScoring {
            noAnswer
            answer
            noAgentAvailableToDial
            noAgentOnAnswer
            overApproving
          }
          overrideDispositionScoring
          overrideDialToAgentRatio
          dialToAgentRatio
          freshLIFO
          retryLIFO
          createdAt
          updatedAt
        }
        status
        expireDate
        stopOnCompletion
        cci
        autoMode
        cciMetaData
        createdAt
        updatedAt
      }
      agent
      contactStatus
      timestamp
      metaData
      provider
      c2aId
      pocActual
      createdAt
      updatedAt
      contact {
        id
        tenant
        externalId
        firstName
        lastName
        source
        phone
        cell
        email
        dateCreated
        address1
        address2
        city
        state
        zip
        dnc
        updatedBy
        customFields
        expireDt
        outboundCallerId
        balances {
          id
          type
          amount
          age
        }
        timeZoneBounds {
          min {
            offset
            dst
          }
          max {
            offset
            dst
          }
        }
        type
        optOuts {
          voice
          sms
          email
        }
        optIns {
          voice
          sms
          email
        }
        attempts
        createdAt
        updatedAt
      }
    }
  }
`;
export const onDeleteActivityLog = /* GraphQL */ `
  subscription OnDeleteActivityLog {
    onDeleteActivityLog {
      id
      tenant
      type
      disposition
      title
      body
      activityLogContactId
      segment {
        id
        tenant
        externalId
        name
        description
        query {
          qbQuery
          searchFilter
          sort {
            field
            direction
          }
        }
        filter {
          qbQuery
          searchFilter
          sort {
            field
            direction
          }
        }
        fieldsMappingId
        suppressionID
        suppression {
          id
          tenant
          name
          result
          activityLogQuerySuppressionEnabled
          activityLogQuerySuppression {
            query
            rawQuery
            threshold
          }
          pocQuerySuppressionEnabled
          pocQuerySuppression {
            query
            rawQuery
            suppressIfWeekendAndCantCallOnWeekends
          }
          restSuppressionEnabled
          restSuppression {
            endpoint
            method
            params {
              key
              value
              description
            }
            headers {
              key
              value
              description
            }
            auth {
              type
              info
            }
            response {
              path
              type
              value
              options
            }
            suppressOnError
            dispositionOnError
          }
          dncDotComEnabled
          dncDotCom {
            scrub
            state
            numberReassignment
            knownLitigator
            reassignmentSince
          }
          dncListSuppressionEnabled
          dncListSuppressions
          contactFieldSuppressionEnabled
          contactFieldSuppressions
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      campaign {
        id
        tenant
        name
        segments
        segmentSchedule
        campaignSchedule
        static
        sort
        skillId
        skillName
        skillSource
        campaignProfileId
        profile {
          id
          tenant
          name
          useAi
          flowRatio
          scrubber {
            cell
            natDnc
            stateDnc
            orgDnc
            phoneDup
            contactDup
          }
          retry {
            minDuration
            maxAttempts
            maxContacts
            custRetry
            ratio
          }
          ai {
            org
            system
          }
          pushContacts
          startTime
          endTime
          buffer
          channels {
            type
            order
            retryDelay
            maxAttempts
            maxContacts
            custRetry
            paymentProfileId
            paymentProfileName
            dispositions
            cadence
            dialingScript
          }
          ratio
          dontCallOnWeekends
          callWindow {
            days
            tmz {
              offset
              dst
            }
            beginTime {
              hour
              minute
            }
            endTime {
              hour
              minute
            }
            callWindowQuery {
              field
              operator
              value
            }
            default
            order
          }
          clickToCall
          callWindows {
            days
            tmz {
              offset
              dst
            }
            beginTime {
              hour
              minute
            }
            endTime {
              hour
              minute
            }
            callWindowQuery {
              field
              operator
              value
            }
            default
            order
          }
          dispositionScoring {
            noAnswer
            answer
            noAgentAvailableToDial
            noAgentOnAnswer
            overApproving
          }
          overrideDispositionScoring
          overrideDialToAgentRatio
          dialToAgentRatio
          freshLIFO
          retryLIFO
          createdAt
          updatedAt
        }
        status
        expireDate
        stopOnCompletion
        cci
        autoMode
        cciMetaData
        createdAt
        updatedAt
      }
      agent
      contactStatus
      timestamp
      metaData
      provider
      c2aId
      pocActual
      createdAt
      updatedAt
      contact {
        id
        tenant
        externalId
        firstName
        lastName
        source
        phone
        cell
        email
        dateCreated
        address1
        address2
        city
        state
        zip
        dnc
        updatedBy
        customFields
        expireDt
        outboundCallerId
        balances {
          id
          type
          amount
          age
        }
        timeZoneBounds {
          min {
            offset
            dst
          }
          max {
            offset
            dst
          }
        }
        type
        optOuts {
          voice
          sms
          email
        }
        optIns {
          voice
          sms
          email
        }
        attempts
        createdAt
        updatedAt
      }
    }
  }
`;
export const onCreateAgentScript = /* GraphQL */ `
  subscription OnCreateAgentScript {
    onCreateAgentScript {
      id
      tenant
      name
      level
      type
      textBody
      isRoot
      createdAt
      updatedAt
      children {
        items {
          id
          parentId
          childId
          createdAt
          updatedAt
          parent {
            id
            tenant
            name
            level
            type
            textBody
            isRoot
            createdAt
            updatedAt
            children {
              items {
                id
                parentId
                childId
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          child {
            id
            tenant
            name
            level
            type
            textBody
            isRoot
            createdAt
            updatedAt
            children {
              items {
                id
                parentId
                childId
                createdAt
                updatedAt
              }
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const onUpdateAgentScript = /* GraphQL */ `
  subscription OnUpdateAgentScript {
    onUpdateAgentScript {
      id
      tenant
      name
      level
      type
      textBody
      isRoot
      createdAt
      updatedAt
      children {
        items {
          id
          parentId
          childId
          createdAt
          updatedAt
          parent {
            id
            tenant
            name
            level
            type
            textBody
            isRoot
            createdAt
            updatedAt
            children {
              items {
                id
                parentId
                childId
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          child {
            id
            tenant
            name
            level
            type
            textBody
            isRoot
            createdAt
            updatedAt
            children {
              items {
                id
                parentId
                childId
                createdAt
                updatedAt
              }
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const onDeleteAgentScript = /* GraphQL */ `
  subscription OnDeleteAgentScript {
    onDeleteAgentScript {
      id
      tenant
      name
      level
      type
      textBody
      isRoot
      createdAt
      updatedAt
      children {
        items {
          id
          parentId
          childId
          createdAt
          updatedAt
          parent {
            id
            tenant
            name
            level
            type
            textBody
            isRoot
            createdAt
            updatedAt
            children {
              items {
                id
                parentId
                childId
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          child {
            id
            tenant
            name
            level
            type
            textBody
            isRoot
            createdAt
            updatedAt
            children {
              items {
                id
                parentId
                childId
                createdAt
                updatedAt
              }
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const onCreateAgentScriptConnector = /* GraphQL */ `
  subscription OnCreateAgentScriptConnector {
    onCreateAgentScriptConnector {
      id
      parentId
      childId
      createdAt
      updatedAt
      parent {
        id
        tenant
        name
        level
        type
        textBody
        isRoot
        createdAt
        updatedAt
        children {
          items {
            id
            parentId
            childId
            createdAt
            updatedAt
            parent {
              id
              tenant
              name
              level
              type
              textBody
              isRoot
              createdAt
              updatedAt
              children {
                nextToken
              }
            }
            child {
              id
              tenant
              name
              level
              type
              textBody
              isRoot
              createdAt
              updatedAt
              children {
                nextToken
              }
            }
          }
          nextToken
        }
      }
      child {
        id
        tenant
        name
        level
        type
        textBody
        isRoot
        createdAt
        updatedAt
        children {
          items {
            id
            parentId
            childId
            createdAt
            updatedAt
            parent {
              id
              tenant
              name
              level
              type
              textBody
              isRoot
              createdAt
              updatedAt
              children {
                nextToken
              }
            }
            child {
              id
              tenant
              name
              level
              type
              textBody
              isRoot
              createdAt
              updatedAt
              children {
                nextToken
              }
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const onUpdateAgentScriptConnector = /* GraphQL */ `
  subscription OnUpdateAgentScriptConnector {
    onUpdateAgentScriptConnector {
      id
      parentId
      childId
      createdAt
      updatedAt
      parent {
        id
        tenant
        name
        level
        type
        textBody
        isRoot
        createdAt
        updatedAt
        children {
          items {
            id
            parentId
            childId
            createdAt
            updatedAt
            parent {
              id
              tenant
              name
              level
              type
              textBody
              isRoot
              createdAt
              updatedAt
              children {
                nextToken
              }
            }
            child {
              id
              tenant
              name
              level
              type
              textBody
              isRoot
              createdAt
              updatedAt
              children {
                nextToken
              }
            }
          }
          nextToken
        }
      }
      child {
        id
        tenant
        name
        level
        type
        textBody
        isRoot
        createdAt
        updatedAt
        children {
          items {
            id
            parentId
            childId
            createdAt
            updatedAt
            parent {
              id
              tenant
              name
              level
              type
              textBody
              isRoot
              createdAt
              updatedAt
              children {
                nextToken
              }
            }
            child {
              id
              tenant
              name
              level
              type
              textBody
              isRoot
              createdAt
              updatedAt
              children {
                nextToken
              }
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const onDeleteAgentScriptConnector = /* GraphQL */ `
  subscription OnDeleteAgentScriptConnector {
    onDeleteAgentScriptConnector {
      id
      parentId
      childId
      createdAt
      updatedAt
      parent {
        id
        tenant
        name
        level
        type
        textBody
        isRoot
        createdAt
        updatedAt
        children {
          items {
            id
            parentId
            childId
            createdAt
            updatedAt
            parent {
              id
              tenant
              name
              level
              type
              textBody
              isRoot
              createdAt
              updatedAt
              children {
                nextToken
              }
            }
            child {
              id
              tenant
              name
              level
              type
              textBody
              isRoot
              createdAt
              updatedAt
              children {
                nextToken
              }
            }
          }
          nextToken
        }
      }
      child {
        id
        tenant
        name
        level
        type
        textBody
        isRoot
        createdAt
        updatedAt
        children {
          items {
            id
            parentId
            childId
            createdAt
            updatedAt
            parent {
              id
              tenant
              name
              level
              type
              textBody
              isRoot
              createdAt
              updatedAt
              children {
                nextToken
              }
            }
            child {
              id
              tenant
              name
              level
              type
              textBody
              isRoot
              createdAt
              updatedAt
              children {
                nextToken
              }
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const onCreateCryptoPassport = /* GraphQL */ `
  subscription OnCreateCryptoPassport {
    onCreateCryptoPassport {
      id
      token
      ttl
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCryptoPassport = /* GraphQL */ `
  subscription OnUpdateCryptoPassport {
    onUpdateCryptoPassport {
      id
      token
      ttl
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCryptoPassport = /* GraphQL */ `
  subscription OnDeleteCryptoPassport {
    onDeleteCryptoPassport {
      id
      token
      ttl
      createdAt
      updatedAt
    }
  }
`;
export const onCreateContactFieldMeta = /* GraphQL */ `
  subscription OnCreateContactFieldMeta {
    onCreateContactFieldMeta {
      id
      tenant
      contact
      field
      type
      poc {
        cell
        consent
        writtenConsent
        optOut
        preferred
        thirdParty
        dontCallOnWeekends
        callWindows {
          days
          tmz {
            offset
            dst
          }
          beginTime {
            hour
            minute
          }
          endTime {
            hour
            minute
          }
          callWindowQuery {
            field
            operator
            value
          }
          default
          order
        }
      }
      updatedBy
      createdAt
      updatedAt
      contactModel {
        id
        tenant
        externalId
        firstName
        lastName
        source
        phone
        cell
        email
        dateCreated
        address1
        address2
        city
        state
        zip
        dnc
        updatedBy
        customFields
        expireDt
        outboundCallerId
        balances {
          id
          type
          amount
          age
        }
        timeZoneBounds {
          min {
            offset
            dst
          }
          max {
            offset
            dst
          }
        }
        type
        optOuts {
          voice
          sms
          email
        }
        optIns {
          voice
          sms
          email
        }
        attempts
        createdAt
        updatedAt
      }
    }
  }
`;
export const onUpdateContactFieldMeta = /* GraphQL */ `
  subscription OnUpdateContactFieldMeta {
    onUpdateContactFieldMeta {
      id
      tenant
      contact
      field
      type
      poc {
        cell
        consent
        writtenConsent
        optOut
        preferred
        thirdParty
        dontCallOnWeekends
        callWindows {
          days
          tmz {
            offset
            dst
          }
          beginTime {
            hour
            minute
          }
          endTime {
            hour
            minute
          }
          callWindowQuery {
            field
            operator
            value
          }
          default
          order
        }
      }
      updatedBy
      createdAt
      updatedAt
      contactModel {
        id
        tenant
        externalId
        firstName
        lastName
        source
        phone
        cell
        email
        dateCreated
        address1
        address2
        city
        state
        zip
        dnc
        updatedBy
        customFields
        expireDt
        outboundCallerId
        balances {
          id
          type
          amount
          age
        }
        timeZoneBounds {
          min {
            offset
            dst
          }
          max {
            offset
            dst
          }
        }
        type
        optOuts {
          voice
          sms
          email
        }
        optIns {
          voice
          sms
          email
        }
        attempts
        createdAt
        updatedAt
      }
    }
  }
`;
export const onDeleteContactFieldMeta = /* GraphQL */ `
  subscription OnDeleteContactFieldMeta {
    onDeleteContactFieldMeta {
      id
      tenant
      contact
      field
      type
      poc {
        cell
        consent
        writtenConsent
        optOut
        preferred
        thirdParty
        dontCallOnWeekends
        callWindows {
          days
          tmz {
            offset
            dst
          }
          beginTime {
            hour
            minute
          }
          endTime {
            hour
            minute
          }
          callWindowQuery {
            field
            operator
            value
          }
          default
          order
        }
      }
      updatedBy
      createdAt
      updatedAt
      contactModel {
        id
        tenant
        externalId
        firstName
        lastName
        source
        phone
        cell
        email
        dateCreated
        address1
        address2
        city
        state
        zip
        dnc
        updatedBy
        customFields
        expireDt
        outboundCallerId
        balances {
          id
          type
          amount
          age
        }
        timeZoneBounds {
          min {
            offset
            dst
          }
          max {
            offset
            dst
          }
        }
        type
        optOuts {
          voice
          sms
          email
        }
        optIns {
          voice
          sms
          email
        }
        attempts
        createdAt
        updatedAt
      }
    }
  }
`;
export const onCreateDailyLicenseCount = /* GraphQL */ `
  subscription OnCreateDailyLicenseCount {
    onCreateDailyLicenseCount {
      id
      ownerTenant
      tenant
      licenseType
      ncBusinessUnit
      Date
      LicenseCount
      SmsCount
      EmailCount
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateDailyLicenseCount = /* GraphQL */ `
  subscription OnUpdateDailyLicenseCount {
    onUpdateDailyLicenseCount {
      id
      ownerTenant
      tenant
      licenseType
      ncBusinessUnit
      Date
      LicenseCount
      SmsCount
      EmailCount
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteDailyLicenseCount = /* GraphQL */ `
  subscription OnDeleteDailyLicenseCount {
    onDeleteDailyLicenseCount {
      id
      ownerTenant
      tenant
      licenseType
      ncBusinessUnit
      Date
      LicenseCount
      SmsCount
      EmailCount
      createdAt
      updatedAt
    }
  }
`;

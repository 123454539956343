import { useContext, useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { UserContext } from 'src/contexts/UserContext';
import { post } from 'aws-amplify/api';
import { Cache, I18n } from 'aws-amplify/utils';

const STRINGS = {
	'en-US': { // English (United States)
		sdkErrorTitle: 'Webex SDK Error',
		sdkErrorMessage: 'The Webex CC Desktop SDK could not be initialized. Please try again later.',
		sessionErrorTitle: 'Webex Session Error',
		sessionErrorMessage: 'Your Webex CC session appears to be invalid. Please close the widget and try again.',
	},
	'es-419': { // Spanish (Latin America)
		sdkErrorTitle: 'Error del SDK de Webex',
		sdkErrorMessage: 'No se pudo inicializar el SDK de escritorio de Webex CC. Por favor, inténtelo de nuevo más tarde.',
		sessionErrorTitle: 'Error de sesión de Webex',
		sessionErrorMessage: 'Tu sesión de Webex CC parece ser inválida. Por favor, cierra el widget e inténtalo de nuevo.',
	},
	'pt-BR': { // Portuguese (Brazil)
		sdkErrorTitle: 'Erro do SDK do Webex',
		sdkErrorMessage: 'Não foi possível inicializar o SDK de desktop do Webex CC. Por favor, tente novamente mais tarde.',
		sessionErrorTitle: 'Erro de sessão do Webex',
		sessionErrorMessage: 'Sua sessão do Webex CC parece ser inválida. Por favor, feche o widget e tente novamente.',
	}
};
I18n.putVocabularies(STRINGS);

/**
 * @typedef {Object} Props
 * @property {string} agentId The ID of the agent to verify.
 * @property {Function} onError The function to call when an error occurs.
 * @property {Function} onAgentConnected The function to call when the agent is successfully connected.
 */

/**
 * Displays a dialog to verify the user's account for Webex.
 *
 * @param {Props} props The props for the component.
 */
export function WebexDialog(props) {
	const userContext = useContext(UserContext);

	/** Whether something is currently being loaded in the page. */
	const [loading, setLoading] = useState(false);

	/**
	 * Attempts to authorize the user with Webex CC.
	 */
	useEffect(() => {
		authorizeWebex();
	}, []);

	/**
	 * Checks if the user is already logged in to Webex CC.
	 */
	async function authorizeWebex() {
		setLoading(true);

		// Initialize the desktop SDK.
		try {
			const { Desktop } = await import('@wxcc-desktop/sdk');
			await Desktop.config.init({
				widgetName: 'Velocity Click 2 Call',
				widgetProvider: 'Customer Dynamics',
			});
		} catch (initError) {
			props.onError(
				I18n.get('sdkErrorTitle', STRINGS['en-US'].sdkErrorTitle),
				I18n.get('sdkErrorMessage', STRINGS['en-US'].sdkErrorMessage),
			);
			throw new Error('Error initializing the desktop:', initError);
		}

		// If user has already been authorized, skip the authorization process.
		const isAuthorized = await Cache.getItem('webexAuthorized');
		if (isAuthorized) {
			console.debug('User has already been authorized with Webex.');
			props.onAgentConnected(props.agentId);
			setLoading(false);
			return;
		}

		// Validate the Webex session.
		try {
			const isSessionValid = await post({
				apiName: 'cdyxoutreach',
				path: '/approver/webex/agent-session',
				options: {
					headers: {
						Authorization: `Bearer ${userContext.idToken}`,
						'x-api-key': userContext.apiKey,
						'Content-Type': 'application/json',
					},
					body: {
						agentId: props.agentId,
					}
				}
			}).response;
			const isSessionValidBody = await isSessionValid.body.json();
			if (!isSessionValidBody.isActive) {
				throw new Error('No response body returned from the API.');
			}
			await Cache.setItem('webexAuthorized', true, {
				expires: Date.now + 60 * 60 * 1000, // 1 hour
			});
		} catch (sessionError) {
			props.onError(
				I18n.get('sessionErrorTitle', STRINGS['en-US'].sessionErrorTitle),
				I18n.get('sessionErrorMessage', STRINGS['en-US'].sessionErrorMessage),
				true
			);
			throw new Error('Error validating the Webex session:', sessionError);
		}

		// Successfully connected to Webex.
		props.onAgentConnected(props.agentId);
		setLoading(false);
	}

	return (
		<>
			{/* Loading */}
			{loading && (
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						height: '100vh',
					}}
				>
					<CircularProgress
						variant="indeterminate"
						color="primary"
					></CircularProgress>
				</div>
			)}
		</>
	);
}

import {
	useEffect,
	useState,
	useRef,
	memo,
	useCallback,
	forwardRef,
} from 'react';
import { Box, Grid, TextField, Typography } from '@mui/material';

export function RetryDelayPicker(props) {
	const [time, setTime] = useState({
		days: 0,
		hours: 0,
		minutes: 0,
	});
	const timeRef = useRef({ ...time }); // To avoid callback recreation for memoization.

	useEffect(() => {
		setTime({
			days: Math.floor(props.formikProps.values.retryDelay / 1440),
			hours: Math.floor((props.formikProps.values.retryDelay % 1440) / 60),
			minutes: props.formikProps.values.retryDelay % 60,
		});
		timeRef.current = {
			days: Math.floor(props.formikProps.values.retryDelay / 1440),
			hours: Math.floor((props.formikProps.values.retryDelay % 1440) / 60),
			minutes: props.formikProps.values.retryDelay % 60,
		};

	}, [props.formikProps]);

	// Set the time value and update the parent to be the total minutes.
	const onTimeChange = (value, key) => {
		const tempTime = { ...timeRef.current };
		tempTime[key] = value;
		timeRef.current = { ...tempTime };

		setTime({ ...tempTime });

		const retryDelay = (tempTime.days * 1440) + (tempTime.hours * 60) + tempTime.minutes;

		props.formikProps.setFieldValue(
			'retryDelay',
			retryDelay
		);
	}


	return (
		<Box>
			<Typography variant="overline">
				Retry Delay
			</Typography>
			<Box display="grid" gridTemplateColumns="1fr 1fr 1fr" gap={2}>
				<TextField
					label="Days"
					value={time.days}
					onChange={(e) => onTimeChange(e.target.value, 'days')}
					type="number"
					InputLabelProps={{
						shrink: true,
					}}
					inputProps={{
						min: 0
					}}
					variant="outlined"
					size="small"
				/>
				<TextField
					label="Hours"
					value={time.hours}
					onChange={(e) => onTimeChange(e.target.value, 'hours')}
					type="number"
					InputLabelProps={{
						shrink: true,
					}}
					inputProps={{
						min: 0,
						max: 23
					}}
					variant="outlined"
					size="small"
				/>
				<TextField
					label="Minutes"
					value={time.minutes}
					onChange={(e) => onTimeChange(e.target.value, 'minutes')}
					type="number"
					InputLabelProps={{
						shrink: true,
					}}
					inputProps={{
						min: 0,
						max: 59
					}}
					variant="outlined"
					size="small"
				/>
			</Box>
		</Box>

	);
}

const RetryDelayPickerColumn = memo((props) => {
	const {
		min,
		max,
		unitLabelText,
		unitToMinutesRatio,
		value,
		onChange,
		unitKey,
	} = props;

	const [values, setValues] = useState([]);
	const columnRef = useRef();
	const columnMaskRef = useRef();
	const isMouseDown = useRef(false);
	const scrollStartingOffset = useRef(0);

	useEffect(() => {
		for (let i = min; i <= max; i++) {
			values.push(i);
		}

		document.documentElement.addEventListener('mouseup', handleMouseUp);

		setValues([...values]);

		return () => {
			document.documentElement.removeEventListener('mouseup', handleMouseUp);
		};
	}, []);

	useEffect(() => {
		const heightPerValue = columnRef.current.offsetHeight / values.length;
		const normalizedPosition =
			(value / unitToMinutesRatio - 1) * heightPerValue;
		setColumnScrollPosition(-normalizedPosition);
	}, [value, columnRef.current?.offsetHeight]);

	const handleMouseMove = useCallback((e) => {
		if (isMouseDown.current) {
			setColumnScrollPosition(e.y - scrollStartingOffset.current);
		}
	}, []);

	const handleMouseDown = useCallback(
		(e) => {
			isMouseDown.current = true;
			scrollStartingOffset.current = e.clientY - getColumnScrollPosition();
			document.documentElement.addEventListener('mousemove', handleMouseMove);
		},
		[handleMouseMove],
	);

	let handleMouseUp = useCallback(() => {
		document.documentElement.removeEventListener('mousemove', handleMouseMove);
		isMouseDown.current = false;
		const heightPerValue = columnRef.current.offsetHeight / values.length;
		let selectedValue = Math.round(-getColumnScrollPosition() / heightPerValue);
		selectedValue = selectedValue < min - 1 ? min - 1 : selectedValue;
		selectedValue = selectedValue > max - 1 ? max - 1 : selectedValue;
		onChange((selectedValue + 1) * unitToMinutesRatio, unitKey);

		const normalizedPosition = selectedValue * heightPerValue;
		setColumnScrollPosition(-normalizedPosition);
	}, [
		handleMouseMove,
		max,
		min,
		onChange,
		unitKey,
		unitToMinutesRatio,
		values.length,
	]);

	function getColumnScrollPosition() {
		return Number(
			columnRef.current.style.top.substring(
				0,
				columnRef.current.style.top.length - 2,
			),
		);
	}

	function setColumnScrollPosition(position) {
		columnRef.current.style.top = `${position}px`;
	}

	return (
		<div style={{ userSelect: 'none', display: 'flex' }}>
			<div ref={columnMaskRef}>
				<Column values={values} ref={columnRef} onMouseDown={handleMouseDown} />
			</div>
			<div
				sx={{
					height: '20px',
					width: '20px',
					borderTop: 'solid',
					borderBottom: 'solid',
					borderWidth: '1px',
					borderRadius: '2px',
					marginTop: '19px',
					marginLeft: '-18px',
				}}
			/>
			<div style={{ color: 'grey', marginTop: '18px' }}>
				<i>{unitLabelText}</i>
			</div>
		</div>
	);
});

RetryDelayPickerColumn.displayName = 'RetryDelayPickerColumn';

let ColumnWithRef = forwardRef(({ onMouseDown, values }, ref) => {
	return (
		<Grid
			container
			direction="column"
			spacing={0}
			ref={ref}
			style={{ position: 'relative', textAlign: 'center' }}
			onMouseDown={onMouseDown}
		>
			{values.map((value) => (
				<Grid key={value} item>
					{value}
				</Grid>
			))}
		</Grid>
	);
});

ColumnWithRef.displayName = 'ColumnWithRef';

const Column = memo(ColumnWithRef); // This is black magic. The parameters that memo hands to the component don't include a ref. However, doing it this way magically makes it work.

/**
 * Traverses a JSON object following the object keys and array indexes specified by dotPath. Returns the resulting value.
 * Returns null or a user specified value if the path doesn't exist.
 * E.G. with source = { "a": { "b": [1, { "c": 5 }] } }, calling evaluateDotPath(source, "a.b[1].c") will return 5.
 * @param {any} source The source JSON object.
 * @param {string} dotPath The path with object keys delimited by dots and array indexes surrounded by brackets.
 * @param {any} defaultValue The default value to return if the path doesn't exist. Defaults to null if no argument is provided.
 * @returns {any | null} The resulting value.
 */
export default function evaluateDotPath(source, dotPath, defaultValue = null) {
    return dotPath.replace(/\[(\d+)\]/g, '.$1').replace(/^\./, '').split('.').reduce((currentObject, currentPathKey) => currentObject?.[currentPathKey], source) ?? defaultValue;
}
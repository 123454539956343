export const getCreateContactData = `query GetEnums {
    contactStatus: __type(name: "ContStatus") {
      enumValues {
        name
      }
    }
  }`;

export const getCreateProfileData = `query GetEnums {
    segments:
      listSegments(limit: 100) {
        items {
          id
          name
        }
      }
    profiles:
      listProfiles(limit: 100) {
        items {
          id
          name
        }
      }
    campaigns:
      listCampaigns(limit: 100) {
        items {
          id
          skillId
          status
        }
      }
    }`;


export const getCreateAccountData = `query GetEnums {
    
    accountStatus: __type(name: "AcctStatus") {
      enumValues {
        name
      }
    }

    accountIndustry: __type(name: "AcctIndustry") {
      enumValues {
        name
      }
    }
    
    
  }`;

export const getCreateCampaignData = `query GetEnums {
    campaignStatus: __type(name: "CampaignStatus") {
      enumValues {
        name
      }
    }
   }`;

export const getWorkBundleStatus = `query GetEnums {
    workBundleStatus: __type(name: "WorkBundleStatus") {
      enumValues {
        name
      }
    }
  }`;

export const listAccounts = `query ListAccounts(
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        dateCreated
        industry
        status
        address1
        address2
        city
        state
        zip
        timeZoneID
        timeZone {
          id
          abbrev
          name
          offSet
        }
        website
        ticker
      }
      nextToken
    }
  }
  `;

export const createActivityLogDeleteContact =
  `mutation CreateActivityLog(
    $input: CreateActivityLogInput!
    $condition: ModelActivityLogConditionInput
  ) {
    createActivityLog(input: $input, condition: $condition) {
      id
      tenant
      type
      disposition
      title
      body
      activityLogContactId
      agent
      timestamp
      metaData
    }
  }
  `;

export const screenPop =
  `query ScreenPop($tenant: ID!, $contactId: ID!) {
    getTenantSettings(id: $tenant) {
      id
      apiKey
      customFields {
        name
        displayName
        type
        isPoc
      }
      icApp
      icVendor
      icBU
      icUserName
      icPassword
      icGetAuthTokenURL
      icAccessKey
      icAppSecret
      icDiscoveryDomain
      icScriptName
    }
    getContact(id: $contactId) {
      id
        tenant
        externalId
        firstName
        lastName
        source
        phone
        cell
        email
        dateCreated
        address1
        address2
        city
        state
        zip
        dnc
        updatedBy
        customFields
        expireDt
        outboundCallerId
    }
    getContactActivity(activityLogContactId: $contactId) {
      items {
          id
          tenant
          type
          disposition
          title
          body
          activityLogContactId
          contact {
            id
            tenant
            externalId
            firstName
            lastName
            source
            phone
            cell
            email
            dateCreated
            address1
            address2
            city
            state
            zip
            dnc
            updatedBy
            customFields
            expireDt
            outboundCallerId
          }
          segment {
            id
            tenant
            name
            description
          }
          agent
          timestamp
          metaData
        }
        nextToken
      
    }
  }`;
  
  export const onActivityLogCreate = `
  subscription OnTenantCreateActivityLog($tenant: ID!) {
    onTenantCreateActivityLog(tenant: $tenant) {
      id
      tenant
      type
      disposition
      title
      body
      activityLogContactId
      agent
      metaData
    }
  }
`;

export const pocMeta = `query PocMeta(
  $tenant: ID
  $contactField: ModelContactFieldMetaPocMetaCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelContactFieldMetaFilterInput
  $limit: Int
  $nextToken: String
) {
  PocMeta(
    tenant: $tenant
    contactField: $contactField
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tenant
      contact
      field
      type
      poc {
        cell
        consent
        dontCallOnWeekends
        writtenConsent
        optOut
        preferred
        thirdParty
        callWindows {
          beginTime{
            hour
            minute
          }
          days
          endTime{
            hour
            minute
          }
          tmz{
            dst
            offset
          }
        }
      }
      createdAt
      updatedAt
      contactModel {
        id
        tenant
        externalId
        firstName
        lastName
        source
        phone
        cell
        email
        dateCreated
        address1
        address2
        city
        state
        zip
        dnc
        updatedBy
        customFields
        expireDt
        outboundCallerId
        balances {
          id
          type
          amount
          age
        }
        type
        optOuts {
          voice
          sms
          email
        }
        optIns {
          voice
          sms
          email
        }
        createdAt
        updatedAt
      }
    }
    nextToken
  }
}`
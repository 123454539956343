import { minimalTimezoneSet } from 'compact-timezone-list';

export const timezones = [];
for (const timezone of minimalTimezoneSet) {
	timezones.push({ ...timezone });
	const units = timezone.offset.split(':').map((x) => +x);
	timezones[timezones.length - 1].offset =
		Math.sign(units[0]) * (Math.abs(units[0]) + units[1] / 60);
}

/**
 * An object whose keys are the offsets and whose values are the timezones themselves.
 */
export const offsetLookup = timezones.reduce((acc, cur) => {
	acc[cur.offset] = cur;
	return acc;
}, {});

/**
 * An object whose keys are the original string offsets and whose values are the timezones themselves.
 */
export const stringOffsetLookup = minimalTimezoneSet.reduce(
	(acc, cur) => Object.assign(acc, { [cur.offset]: cur }),
	{},
);

/**
 * An object whose keys are the labels and whose values are the timezones themselves.
 */
export const labelLookup = timezones.reduce((acc, cur) => {
	acc[cur.label] = cur;
	return acc;
}, {});

import { Grid, FormControlLabel, Switch, Card, CardHeader, CardContent, TextField, Typography, Checkbox } from '@mui/material';
import { LocalLaundryService, Settings } from '@mui/icons-material';
import moment from "moment";
import { useState } from "react";

export default function DNCSuppression(props) {
    const { formikProps, config } = props;
    const [isRelativeReassignmentDate, setIsRelativeReassignmentDate] = useState(formikProps.values.dncDotCom.reassignmentSince[0] === 'n');

    function isRelativeChange(isRelative) {
        setIsRelativeReassignmentDate(isRelative);

        if (isRelative) {
            formikProps.setFieldValue('dncDotCom.reassignmentSince', 'now-60');
        } else {
            formikProps.setFieldValue('dncDotCom.reassignmentSince', moment().format("YYYY-MM-DD"));
        }
    }

    function reassignmentFieldChange(e) {
        if (isRelativeReassignmentDate) {
            formikProps.setFieldValue('dncDotCom.reassignmentSince', `now-${e.target.value}`);
        } else {
            formikProps.setFieldValue('dncDotCom.reassignmentSince', e.target.value);
        }
    }

    return (
        <Grid container direction='column' spacing={2}>
            <Grid item>
                <FormControlLabel
                    label='Enabled'
                    control={<Switch
                        checked={formikProps.values.dncDotComEnabled}
                        onChange={formikProps.handleChange}
                        color='primary'
                        name='dncDotComEnabled' />}
                />
            </Grid>
            <Grid item>
                <Card variant='outlined'>
                    <CardHeader title='Configuration' avatar={<Settings color='primary' fontSize='small' />} titleTypographyProps={{ style: { marginBottom: '4px' } }} />
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item>
                                <TextField
                                    variant='outlined'
                                    label='Organization ID'
                                    value={config.orgId}
                                    disabled
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    variant='outlined'
                                    label='Project ID'
                                    value={config.projectId}
                                    disabled
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item>
                <Card variant='outlined'>
                    <CardHeader title='Services' avatar={<LocalLaundryService color='primary' fontSize='small' />} titleTypographyProps={{ style: { marginBottom: '4px' } }} />
                    <CardContent>
                        <>
                            <Grid container spacing={2} direction='column'>
                                {formikProps.errors.dncDotCom?.scrub &&
                                <Grid item>
                                    <Typography variant='body1' color='error'>You must enable at least one service.</Typography>
                                </Grid>}
                                <Grid item>
                                    <FormControlLabel label='National and State DNC Scrub' control={<Switch checked={formikProps.values.dncDotCom?.scrub} onChange={formikProps.handleChange} color='primary' name='dncDotCom.scrub' />} />
                                </Grid>
                                <Grid item>
                                    <FormControlLabel label='Holiday/Emergency Declaration Check' control={<Switch checked={formikProps.values.dncDotCom?.state} onChange={formikProps.handleChange} color='primary' name='dncDotCom.state' />} />
                                </Grid>
                                <Grid item>
                                    <Grid container alignItems='center' spacing={2}>
                                        <Grid item>
                                            <FormControlLabel label='Number Reassignment' control={<Switch checked={formikProps.values.dncDotCom?.numberReassignment} onChange={formikProps.handleChange} color='primary' name='dncDotCom.numberReassignment' />} />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant='body1'>since</Typography>
                                        </Grid>
                                        {isRelativeReassignmentDate &&
                                        <>
                                            <Grid item>
                                                <Typography variant='body1' color='primary'>now</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant='body1' color='primary'>-</Typography>
                                            </Grid>
                                        </>}
                                        <Grid item>
                                            <TextField
                                                variant='standard'
                                                type={isRelativeReassignmentDate ? "number" : 'date'}
                                                value={formikProps.values.dncDotCom.reassignmentSince[0] === 'n' ? formikProps.values.dncDotCom.reassignmentSince.substring(4) : formikProps.values.dncDotCom.reassignmentSince}
                                                name="dncDotCom.reassignmentSince"
                                                onChange={reassignmentFieldChange}
                                                error={formikProps.touched.dncDotCom?.reassignmentSince && formikProps.errors.dncDotCom?.reassignmentSince}
                                            />
                                        </Grid>
                                        {isRelativeReassignmentDate &&
                                        <Grid item>
                                            <Typography variant='body1'>days</Typography>
                                        </Grid>}
                                        <Grid item>
                                            <FormControlLabel label='Relative' control={<Checkbox checked={isRelativeReassignmentDate} onChange={() => isRelativeChange(!isRelativeReassignmentDate)} color='primary' />} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <FormControlLabel label='Known Litigator' control={<Switch checked={formikProps.values.dncDotCom?.knownLitigator} onChange={formikProps.handleChange} color='primary' name='dncDotCom.knownLitigator' />} />
                                </Grid>
                            </Grid>
                        </>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}

import { useEffect, useContext, useState } from 'react';
import {
	Box
} from '@mui/material';
import {
	EditOutlined,
	DeleteOutlineOutlined,
	ScheduleOutlined,
	VisibilityOutlined,
	LayersClearOutlined,
	PlayArrowOutlined,
	ControlPointDuplicateOutlined,
	StopOutlined,
	PauseCircleOutline,
	RestoreOutlined,
	DashboardOutlined,
} from '@mui/icons-material';
import MaterialTable from '@material-table/core';
import { UserContext } from '../../contexts/UserContext';
import { del, generateClient, post } from 'aws-amplify/api';
import { deleteCampaign, updateCampaign } from '../../graphql/mutations';
import { campaignsByTenant } from '../../graphql/queries';
import { PageAppBar } from 'src/components/pageAppBar';
import { ConfirmDialog } from 'src/components/confirmDialog/confirmDialog';
import { fetchAuthSession } from 'aws-amplify/auth';
import { useSnackbar } from 'notistack';
import { CampaignDialog } from '../../components/engagement/campaignDialog';
import { onCreateCampaign, onDeleteCampaign, onUpdateCampaign } from 'src/graphql/subscriptions';
import CampaignStatus from 'src/components/engagement/campaignStatus';
import CampaignScheduleDialog from 'src/components/engagement/campaignScheduleDialog';

export function Campaigns() {
	const [stopConfirmOpen, setStopConfirmOpen] = useState(false);
	const client = generateClient();
	const { enqueueSnackbar } = useSnackbar();
	const userContext = useContext(UserContext);
	const [loading, setLoading] = useState(false);
	const [campaignRealtimeStatus, setCampaignRealtimeStatus] = useState({});
	const [campaigns, setCampaigns] = useState([]);
	const [toDelete, setToDelete] = useState(null);
	const [toUpdate, setToUpdate] = useState(null);
	const [campaignDialogOpen, setCampaignDialogOpen] = useState(false);
	const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
	const [campaignStatusOpen, setCampaignStatusOpen] = useState(false);
	const [scheduleOpen, setScheduleOpen] = useState(false);
	const [scheduleCampaign, setScheduleCampaign] = useState(null);
	const [resetConfirmOpen, setResetConfirmOpen] = useState(false);
	const [confirmClearCompletedOpen, setConfirmClearCompletedOpen] = useState(false);

	useEffect(() => {
		async function getData() {
			setLoading(true);
			const savedCampaigns = await client.graphql({
				query: campaignsByTenant,
				variables: { tenant: userContext.tenantId },
			});
			if (savedCampaigns && savedCampaigns.data) {
				setCampaigns(savedCampaigns.data.campaignsByTenant.items.sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt)));
			}
			setLoading(false);

			const createCampaignSubscription = client
				.graphql({ query: onCreateCampaign })
				.subscribe({
					next: (data) => {
						const newCampaign = data.data.onCreateCampaign;
						setCampaigns((prevCampaigns) => [newCampaign, ...prevCampaigns]);
						enqueueSnackbar('New Campaign Created', {
							variant: 'success',
							autoHideDuration: 5000,
						});
					},
				});

			const updateCampaignSubscription = client
				.graphql({ query: onUpdateCampaign })
				.subscribe({
					next: (data) => {
						const updatedCampaign = data.data.onUpdateCampaign;
						setCampaigns((prevCampaigns) => {
							const index = prevCampaigns.findIndex(
								(campaign) => campaign.id === updatedCampaign.id,
							);
							const newCampaigns = [...prevCampaigns];
							newCampaigns[index] = updatedCampaign;
							return newCampaigns;
						});
						enqueueSnackbar(`Campaign ${updatedCampaign.name} Updated`, {
							variant: 'success',
							autoHideDuration: 5000,
						});
					},
				});

			const deleteCampaignSubscription = client
				.graphql({ query: onDeleteCampaign })
				.subscribe({
					next: (data) => {
						const deletedCampaign = data.data.onDeleteCampaign;
						setCampaigns((prevCampaigns) =>
							prevCampaigns.filter(
								(campaign) => campaign.id !== deletedCampaign.id,
							),
						);
						enqueueSnackbar(`Campaign ${deletedCampaign.name} Deleted`, {
							variant: 'success',
							autoHideDuration: 5000,
						});
					},
				});

			return () => {
				createCampaignSubscription.unsubscribe();
				updateCampaignSubscription.unsubscribe();
				deleteCampaignSubscription.unsubscribe();
			};
		}
		if (userContext.tenantId) {
			getData();
		}
	}, [userContext.tenantId]);

	const getPlayActionInfo = (rowData) => {
		const isMidStatus = Boolean(campaignRealtimeStatus[rowData.id]);
		switch (rowData.status) {
			case "Pending":
				return {
					disabled: isMidStatus,
					icon: () => (<PlayArrowOutlined color={isMidStatus ? "disabled" : "primary"} />),
					tooltip: isMidStatus ? '' : 'Start Campaign',
					onClick: (_, rowData) => { startCampaign(rowData); }
				};

			case "Active":
				return {
					disabled: isMidStatus,
					icon: () => (<PauseCircleOutline color={isMidStatus ? "disabled" : "primary"} />),
					tooltip: isMidStatus ? '' : 'Pause Campaign',
					onClick: (_, rowData) => { pauseCampaign(rowData); }
				};

			case "Paused":
				return {
					disabled: isMidStatus,
					icon: () => (<PlayArrowOutlined color={isMidStatus ? "disabled" : "primary"} />),
					tooltip: isMidStatus ? '' : 'Restart Campaign',
					onClick: (_, rowData) => { restartCampaign(rowData); }
				};

			default:
				return {
					disabled: true,
					icon: () => (<PlayArrowOutlined color='disabled' />),
				};
		}
	}

	const getTerminateActionInfo = (rowData) => {
		const disabled = rowData.status === "Pending" || rowData.status === "Completed" || rowData.status === "Expired" || Boolean(campaignRealtimeStatus[rowData.id]);

		return {
			disabled,
			icon: () => (<StopOutlined color={disabled ? 'disabled' : 'primary'} />),
			tooltip: disabled ? '' : 'End Campaign',
			onClick: (_, rowData) => {
				setToUpdate(rowData);
				setStopConfirmOpen(true);
			}
		}
	}

	async function checkCampaignIntegrity(campaignData) {
		const campaigns = await client.graphql({
			query: campaignsByTenant,
			variables: {
				tenant: userContext.tenantId,
				filter: {
					or: [{ status: { eq: 'Active' } }, { status: { eq: 'Paused' } }],
				},
			},
		});

		let campaignDataSegments = campaignData.segments;
		if (!Array.isArray(campaignDataSegments)) {
			campaignDataSegments = JSON.parse(campaignDataSegments);
		}

		if (campaigns.data?.campaignsByTenant?.items) {
			return !campaigns.data.campaignsByTenant.items.some(
				(x) =>
					x.id !== campaignData.id &&
					((+x.skillId && +x.skillId === +campaignData.skillId) ||
						campaignDataSegments.some((curSegment) =>
							JSON.parse(x.segments).some(
								(segment) => segment.segmentId === curSegment.segmentId,
							),
						)),
			);
		}
	}

	function schedulingCampaign(campaignData) {
		setScheduleCampaign(campaignData);
		setScheduleOpen(true);
	}

	const cloneCampaign = (data) => {
		const cloneCampaign = JSON.parse(JSON.stringify(data));
		cloneCampaign.name += ' - Clone';
		cloneCampaign.status = 'Pending';
		delete cloneCampaign.id;
		delete cloneCampaign.campaignSchedule;
		delete cloneCampaign.segmentSchedule;
		setToUpdate(cloneCampaign)
		setCampaignDialogOpen(true);
	}

	async function handleDeleteCampaign() {
		setLoading(true);
		await client.graphql({
			query: deleteCampaign,
			variables: { input: { id: toDelete.id } },
		});
		// Delete Campaign Schedules if they exist
		if (toDelete.segmentSchedule) {
			const schedules = JSON.parse(toDelete.segmentSchedule);
			for (const schedule of schedules) {
				const deleteSchedule = post({
					apiName: 'cdyxoutreach',
					path: '/approver/createSchedule',
					options: {
						headers: {
							Authorization: `Bearer ${(await fetchAuthSession()).tokens.idToken}`,
							'x-api-key': userContext.apiKey,
						},
						body: {
							delete: true,
							event: schedule,
							tenant: userContext.tenantId,
							campaignId: toDelete.id,
						},
					}
				});

				const { body } = await deleteSchedule.response;
				const response = await body.json();
			}
		}

		setConfirmDeleteOpen(false);
		setLoading(false);
		setToDelete(null);
	}

	async function handleEndCampaign(campaignData, newStatus = "Completed") {
		setConfirmDeleteOpen(false);
		setCampaignRealtimeStatus({ ...campaignRealtimeStatus, [campaignData.id]: "Updating status" });
		//set status to completed
		await client.graphql({
			query: updateCampaign,
			variables: {
				input: {
					id: campaignData.id,
					status: newStatus,
				},
			},
		});

		setCampaignRealtimeStatus({ ...campaignRealtimeStatus, [campaignData.id]: "Clearing cache" });
		await del({
			apiName: 'cdyxoutreach',
			path: `/cache/delete/${campaignData.id}/${campaignData.skillId}`,
			options: {
				headers: {
					Authorization: `Bearer ${(await fetchAuthSession()).tokens.idToken}`,
					'x-api-key': userContext.apiKey,
				},
			},
		}).response;

		enqueueSnackbar("Campaign successfully ended", { variant: 'success' });

		delete campaignRealtimeStatus[campaignData.id];
		setCampaignRealtimeStatus({ ...campaignRealtimeStatus });

		setStopConfirmOpen(false);
	}

	async function handleResetCampaign() {
		try {
			const result = await post({
				apiName: 'cdyxoutreach',
				path: `/cache/reset/${toUpdate.id}`,
				options: {
					headers: {
						Authorization: `Bearer ${(await fetchAuthSession()).tokens.idToken}`,
						'x-api-key': userContext.apiKey,
					},
				},
			}).response;

			enqueueSnackbar('Campaign reset successfully', { variant: 'success' });

		} catch (err) {
			console.log('Error resetting campaign', err)
			enqueueSnackbar('Error resetting campaign', { variant: 'error' });
		}

		setResetConfirmOpen(false);
		setToUpdate(null);

	}

	async function handleClearCompletedContacts() {
		try {
			const result = await del({
				apiName: 'cdyxoutreach',
				path: `/cache/clear-completed/${toUpdate.id}/`,
				options: {
					headers: {
						Authorization: `Bearer ${(await fetchAuthSession()).tokens.idToken}`,
						'x-api-key': userContext.apiKey,
					},
				},
			}).response;

			enqueueSnackbar('Completed contacts cleared successfully', { variant: 'success' });
		} catch (err) {
			console.log('Error clearing completed contacts', err)
			enqueueSnackbar('Error clearing completed contacts', { variant: 'error' });
		}
		setConfirmClearCompletedOpen(false);
		setToUpdate(null);
	}

	function handleCancelDelete() {
		setToDelete(null);
		setConfirmDeleteOpen(false);
	}

	function handleSearchChange(value) {
		window.sessionStorage.setItem('ProfilesSearch', value);
	}

	async function handleCampaignStatusChange(campaign) {
		setLoading(true);
		const campaignStatus = campaign.status;
		switch (campaignStatus) {
			case 'Pending':
				// Start Campaign
				startCampaign(campaign);
				break;
			case 'Active':
				// Pause Campaign
				pauseCampaign(campaign);
				break;
			case 'Paused':
				// Restart Campaign
				restartCampaign(campaign);
				break;
			default:
				break;
		}
	}

	async function pauseCampaign(campaign) {
		await client.graphql({
			query: updateCampaign,
			variables: {
				input: {
					id: campaign.id,
					status: 'Paused',
				},
			},
		});

		const pause = post({
			apiName: 'cdyxoutreach',
			path: `/cache/pause/${campaign.id}/${campaign.skillId}`,
			options: {
				headers: {
					Authorization: `Bearer ${(await fetchAuthSession()).tokens.idToken}`,
					'x-api-key': userContext.apiKey,
				},
			}
		});

		const { body } = await pause.response;
		const response = await body.json();
	}

	async function restartCampaign(campaign) {
		await client.graphql({
			query: updateCampaign,
			variables: {
				input: {
					id: campaign.id,
					status: 'Active',
				},
			},
		});

		const restart = post({
			apiName: 'cdyxoutreach',
			path: `/cache/restart/${campaign.id}/${campaign.skillId}`,
			options: {
				headers: {
					Authorization: `Bearer ${(await fetchAuthSession()).tokens.idToken}`,
					'x-api-key': userContext.apiKey,
				},
			}

		});

		const { body } = await restart.response;
		const response = await body.json();
	}

	async function startCampaign(campaign) {
		const ready = await checkCampaignIntegrity(campaign);

		if (ready) {
			await client.graphql({
				query: updateCampaign,
				variables: {
					input: {
						id: campaign.id,
						status: 'Active',
					},
				},
			});

			const start = await post({
				apiName: 'cdyxoutreach',
				path: `/cache/create/`,
				options: {
					headers: {
						Authorization: `Bearer ${(await fetchAuthSession()).tokens.idToken}`,
						'x-api-key': userContext.apiKey,
					},
					body: {
						campaignId: campaign.id,
						dataSource: 'beast'
					},
				}
			}).response

			const response = start.body.json();

			enqueueSnackbar('Campaign Started', {
				variant: 'success',
				autoHideDuration: 5000,
			});

		} else {
			enqueueSnackbar(
				'The skill and/or one or more segments are in use by another campaign',
				{ variant: 'error', autoHideDuration: 5000 },
			);
		}
	}

	return (
		<Box>
			<>
				<PageAppBar
					title="Campaigns"
					description="View, Create, Edit, and Delete Contact Campaigns"
					actionOneText={!campaignStatusOpen ? "+ Campaign" : "View All Campaigns"}
					actionOneHandler={() => {
						if (!campaignStatusOpen) {
							setToUpdate(null);
							setCampaignDialogOpen(true);
						} else {
							setCampaignStatusOpen(false);
							setToUpdate(null);
						}
					}}
				/>
				{!campaignStatusOpen && (<MaterialTable
					components={{
						Container: (props) => <Box {...props} elevation={0} />,
					}}
					title=""
					data={campaigns.filter((campaign) => campaign.tenant === userContext.tenantId)}
					columns={[
						{ title: 'Name', field: 'name' },
						{ title: 'Skill', field: 'skillName' },
						{ title: 'Profile', field: 'profile.name' },
						{
							title: 'Status', field: 'status',
						},
					]}
					isLoading={loading}
					options={{
						actionsColumnIndex: -1,
						pageSize: 10,
						searchText: window.sessionStorage.getItem('CampaignsSearch'),
						searchFieldStyle: {
							marginBottom: '16px',
							marginleft: '-28px',
						},
						headerStyle: {
							borderTop: '1px solid #e0e0e0',
							padding: '16px',
						},
						searchFieldVariant: 'outlined',
						paginationType: 'stepped',
					}}
					onSearchChange={handleSearchChange}
					actions={[
						rowData => function () {
							const disabled = rowData.status === 'Pending' || rowData.status === 'Completed' || rowData.status === 'Expired';
							return {
								disabled,
								icon: () => (<DashboardOutlined color={disabled ? 'disabled' : 'primary'} />),
								tooltip: disabled ? '' : 'Campaign Dashboard',
								onClick: (event, rowData) => {
									setCampaignStatusOpen(true);
									setToUpdate(rowData);
								}
							};
						}(),
						rowData => getPlayActionInfo(rowData),
						rowData => getTerminateActionInfo(rowData),
						rowData => function () {
							const disabled = rowData.status === 'Pending' || rowData.status === 'Completed' || rowData.status === 'Expired';
							return ({
								disabled,
								icon: () => (<LayersClearOutlined color={disabled ? "disabled" : "primary"} />),
								tooltip: disabled ? '' : 'Clear Completed Contacts from Campaign',
								onClick: (_, rowData) => {
									setToUpdate(rowData);
									setConfirmClearCompletedOpen(true);
								}
							});
						}(),
						rowData => function () {
							const disabled = rowData.status === 'Completed' || rowData.status === 'Expired';
							return ({
								disabled,
								icon: () => (
									<RestoreOutlined color={disabled ? "disabled" : "primary"} />
								),
								tooltip: disabled ? '' : 'Reset Campaign',
								onClick: (_, rowData) => {
									setResetConfirmOpen(true);
									setToUpdate(rowData);
								}
							});
						}(),
						rowData => function () {
							const disabled = rowData.status === 'Completed' || rowData.status === 'Expired';
							return ({
								disabled,
								icon: () => (
									<ScheduleOutlined color={disabled ? "disabled" : "primary"} />
								),
								tooltip: disabled ? '' : 'Schedule Campaign',
								onClick: (_, rowData) => {
									schedulingCampaign(rowData);
								}
							});
						}(),
						rowData => function () {
							const disabled = Boolean(campaignRealtimeStatus[rowData.id]);
							return ({
								disabled,
								icon: () => (<ControlPointDuplicateOutlined color={disabled ? "disabled" : "primary"} />),
								tooltip: disabled ? '' : 'Clone Campaign',
								onClick: (_, rowData) => {
									cloneCampaign(rowData);

								}
							});
						}(),
						rowData => function () {
							const disabled = rowData.status === 'Completed' || rowData.status === "Expired" || Boolean(campaignRealtimeStatus[rowData.id]);
							return ({
								disabled,
								icon: () => (<EditOutlined color={disabled ? 'disabled' : 'primary'} />),
								tooltip: disabled ? '' : 'Edit Campaign',
								onClick: (_, rowData) => {
									setToUpdate(rowData);
									setCampaignDialogOpen(true);
								}
							});
						}(),
						rowData => function () {
							const disabled = Boolean(campaignRealtimeStatus[rowData.id]);
							return ({
								disabled,
								icon: () => (<DeleteOutlineOutlined color={disabled ? "disabled" : "primary"} />),
								tooltip: disabled ? '' : 'Delete Campaign',
								onClick: (_, rowData) => {
									setToDelete(rowData);
									setConfirmDeleteOpen(true);
								}
							});
						}(),
					]}
					onRowClick={(event, rowData) => {
						console.log(rowData);
					}}
				/>)}
			</>
			<ConfirmDialog
				open={confirmDeleteOpen}
				title="Delete Campaign"
				description="Are you sure you want to delete this campaign?"
				actionOneText="Delete"
				actionOneHandler={() => {
					handleDeleteCampaign();
				}}
				actionTwoText="Cancel"
				actionTwoHandler={() => {
					handleCancelDelete();
				}}
			/>

			{campaignStatusOpen && <CampaignStatus
				campaign={toUpdate}
			/>}
			<ConfirmDialog
				open={stopConfirmOpen}
				title="End Campaign"
				description="Are you sure you want to end this campaign?"
				actionOneText="End"
				actionOneHandler={() => {
					handleEndCampaign(toUpdate);
				}}
				actionTwoText="Cancel"
				actionTwoHandler={() => {
					setStopConfirmOpen(false);
				}}
			/>
			<ConfirmDialog
				open={resetConfirmOpen}
				title="Reset Campaign"
				description="Are you sure you want to reset this campaign?"
				actionOneText="Reset"
				actionOneHandler={() => {
					handleResetCampaign();
				}}
				actionTwoText="Cancel"
				actionTwoHandler={() => {
					setResetConfirmOpen(false);
				}}
			/>
			<ConfirmDialog
				open={confirmClearCompletedOpen}
				title="Clear Completed Contacts"
				description="Are you sure you want to clear completed contacts from this campaign?"
				actionOneText="Clear"
				actionOneHandler={() => {
					handleClearCompletedContacts();
				}}
				actionTwoText="Cancel"
				actionTwoHandler={() => {
					setConfirmClearCompletedOpen(false);
				}}
			/>
			<CampaignDialog
				open={campaignDialogOpen}
				setOpen={setCampaignDialogOpen}
				campaign={toUpdate}
			/>
			<CampaignScheduleDialog
				open={scheduleOpen}
				campaign={scheduleCampaign}
				onCancel={() => setScheduleOpen(false)}
			/>
		</Box>
	);
}

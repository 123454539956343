import React, { useRef, useEffect } from 'react';
import { CAMPAIGN_COLOR_1, CAMPAIGN_COLOR_2, CAMPAIGN_COLOR_3 } from '../../models/campaignColors';
import { useTheme } from '@mui/material';

const canvasStyles = {
	animation: 'fadeIn 0.5s',
	'@keyframes fadeIn': {
		'0%': {
			opacity: 0,
		},
		'100%': {
			opacity: 1,
		},
	},
};

/** The number of milliseconds between each spawn interval where the squares are refreshed. */
const REFRESH_INTERVAL_MILLISECONDS = 3000;

/** The font size for the larger text in the squares. */
const LARGE_FONT_SIZE = 22;

/** The font size for the smaller text in the squares. */
const SMALL_FONT_SIZE = 16;

/** The number of columns in the grid. */
const NUMBER_OF_COLUMNS = 3;

/** The number of rows in the grid. */
const NUMBER_OF_ROWS = 3;

/** The total number of squares for the grid. */
const TOTAL_SQUARES = NUMBER_OF_COLUMNS * NUMBER_OF_ROWS;

/** The color of the squares if there is no contact. */
const EMPTY_SQUARE_COLOR_LIGHT = '#EEEEEE';

/** The color of the squares if there is no contact. */
const EMPTY_SQUARE_COLOR_DARK = '#333333';

/** The color of the lines between the squares. */
const STROKE_COLOR_LIGHT = '#FFFFFF';

/** The color of the lines between the squares. */
const STROKE_COLOR_DARK = '#000000';

class Square {
	/** @type {Contact | null} The contact displayed in the square, if any. */
	contact = null;

	/** Whether this square is currently being hovered. */
	isHovered = false;

	color = '';
}

/**
 *
 * @param {{
 *   parentElement: React.RefObject<any>,
 *   selectedCampaigns: Campaign[],
 *   contactsPerCampaign: Map<string, Contact[]>,
 *   numberOfContactsToDisplay: number,
 *   onContactClicked: (contact: Contact) => void,
 * }} props
 */
export function WhackAMoleGame(props) {
	const theme = useTheme();

	/** Reference to the canvas element. */
	const canvasRef = useRef();

	/** The list of all squares to be drawn. */
	const squares = useRef([]);

	/** The list of selected campaigns. */
	const selectedCampaigns = useRef(props.selectedCampaigns);

	/** The list of contacts to display in the game. */
	const contactsToDisplay = useRef([]);

	let strokeColor = STROKE_COLOR_LIGHT;
	let emptySquareColor = EMPTY_SQUARE_COLOR_LIGHT;

	/** The current width of the canvas. */
	let canvasWidth = 0;

	/** The current height of the canvas. */
	let canvasHeight = 0;

	/** Interval that runs to populate the grid. */
	let loopInterval;

	/**
	 * Sets up the game on initial load.
	 */
	useEffect(() => {
		setUpGame();
	}, []);

	/**
	 * Change the color and redraw the squares when the theme changes.
	 */
	useEffect(() => {
		strokeColor = theme.palette.mode === 'dark' ? STROKE_COLOR_DARK : STROKE_COLOR_LIGHT;
		emptySquareColor = theme.palette.mode === 'dark' ? EMPTY_SQUARE_COLOR_DARK : EMPTY_SQUARE_COLOR_LIGHT;
		drawSquares();
	}, [theme]);

	/**
	 * Destroys the game on unmount.
	 */
	useEffect(() => () => {
		clearInterval(loopInterval);
		window.removeEventListener('resize', setCanvasSize);
	}, []);

	/**
	 * Sets the selected campaigns anytime they change.
	 */
	useEffect(() => {
		selectedCampaigns.current = [...props.selectedCampaigns];
	}, [props.selectedCampaigns]);

	/**
	 * Recalculates the contacts to display anytime the contacts change.
	 */
	useEffect(() => {
		calculateContactsToDisplay(props.contactsPerCampaign);
	}, [props.contactsPerCampaign, props.numberOfContactsToDisplay]);

	/**
	 * Populates the grid and redraws the squares anytime the number of contacts to display change.
	 */
	useEffect(() => {
		// FIXME: Why do we need to set the empty square color again here?
		emptySquareColor = theme.palette.mode === 'dark' ? EMPTY_SQUARE_COLOR_DARK : EMPTY_SQUARE_COLOR_LIGHT;
		window.requestAnimationFrame(populateGridAndDraw);
	}, [props.numberOfContactsToDisplay]);

	/**
	 * Resets the game to its initial state.
	 */
	function setUpGame() {
		setCanvasSize();

		// Add event listeners
		canvasRef.current.addEventListener('click', handleClick);
		canvasRef.current.addEventListener('mousemove', handleMouseMove);
		let resizeTimeout;
		const resizeObserver = new ResizeObserver(() => {
			clearInterval(resizeTimeout);
			resizeTimeout = setTimeout(() => {
				setCanvasSize();
			}, 1);
		});
		resizeObserver.observe(props.parentElement.current);

		// Draw and loop the game
		window.requestAnimationFrame(populateGridAndDraw);
		loopInterval = setInterval(() => {
			window.requestAnimationFrame(populateGridAndDraw);
		}, REFRESH_INTERVAL_MILLISECONDS);
	}

	/**
	 * Sets the canvas size based on the parent element size.
	 *
	 * NOTE: This this will run on every resize event, so don't do anything too expensive here!
	 */
	function setCanvasSize() {
		canvasWidth = props.parentElement.current.clientWidth;
		canvasHeight = props.parentElement.current.clientHeight;
		const devicePixelRatio = window.devicePixelRatio || 1;
		canvasRef.current.width = canvasWidth * devicePixelRatio;
		canvasRef.current.height = canvasHeight * devicePixelRatio;
		const canvasContext = canvasRef.current.getContext('2d');
		canvasContext.scale(devicePixelRatio, devicePixelRatio);
		window.requestAnimationFrame(drawSquares);
	}

	/**
	 * Calculates the list of contacts to display in the game based on the selected campaigns.
	 */
	function calculateContactsToDisplay(contactsPerCampaign) {
		const numberOfSelectedCampaigns = selectedCampaigns.current.length;
		const newContactsToDisplay = [];

		// No need to calculate contacts if there are no selected campaigns
		if (numberOfSelectedCampaigns === 0) {
			contactsToDisplay.current = [];
			return;
		}

		// Round robin through the selected campaigns to get the contacts to display
		const campaignIdsToSkip = [];
		for (let i = 0; i < props.numberOfContactsToDisplay; i++) {
			const campaignIndex = i % numberOfSelectedCampaigns;
			const campaignId = selectedCampaigns.current[campaignIndex].id;
			const contactIndex = Math.floor(i / numberOfSelectedCampaigns);

			// If a campaign doesn't have any contacts left to display, skip contacts from this campaign and retry the current index.
			if (contactsPerCampaign.get(campaignId).length <= contactIndex && !campaignIdsToSkip.includes(campaignId)) {
				campaignIdsToSkip.push(campaignId);
				i--;
				continue;
			}
			const contactToPush = contactsPerCampaign.get(campaignId)[contactIndex];
			if (contactToPush) {
				newContactsToDisplay.push(contactToPush);
			}
		}
		contactsToDisplay.current = newContactsToDisplay;
	}

	/**
	 * Populates the grid with contacts and draws them.
	 */
	function populateGridAndDraw() {
		const newSquares = [];

		for (let i = 0; i < TOTAL_SQUARES; i++) {
			const newSquare = new Square();
			newSquare.contact = contactsToDisplay.current[i];
			newSquare.color = newSquare.contact ? getCampaignColor(newSquare.contact.campaignId) : emptySquareColor;
			newSquares.push(newSquare);
		}
		newSquares.sort(() => Math.random() - 0.5);
		squares.current = newSquares;
		drawSquares();
	}

	/**
	 * Clears the canvas and draws all of the squares.
	 */
	function drawSquares() {
		if (!canvasRef.current) {
			return;
		}
		canvasRef.current.getContext('2d').clearRect(0, 0, canvasWidth, canvasHeight);
		squares.current.forEach((square, i) => {
			drawSquare(square, i);
		});
	}

	/**
	 * Draws a single square on the canvas.
	 *
	 * @param {Square} square The square to draw.
	 * @param {number} i The index of the square within the list.
	 */
	function drawSquare(square, i) {
		/** @type {CanvasRenderingContext2D} */
		let canvasContext = canvasRef.current.getContext('2d');

		const squareStartX = (i % NUMBER_OF_COLUMNS) * (canvasWidth / NUMBER_OF_COLUMNS);
		const squareStartY = Math.floor(i / NUMBER_OF_ROWS) * (canvasHeight / NUMBER_OF_ROWS);

		canvasContext.beginPath();
		canvasContext.fillStyle = square.color;
		canvasContext.rect(squareStartX, squareStartY, canvasWidth / NUMBER_OF_COLUMNS, canvasHeight / NUMBER_OF_ROWS);
		canvasContext.fill();
		canvasContext.shadowColor = 'rgba(0,0,0,0)';
		canvasContext.strokeStyle = strokeColor;
		canvasContext.stroke();

		if (square.contact) {
			if (square.isHovered) {
				// Draw hover effect
				canvasContext.beginPath();
				canvasContext.fillStyle = 'rgba(0, 0, 0, 0.15)';
				canvasContext.rect(squareStartX, squareStartY, canvasWidth / NUMBER_OF_COLUMNS, canvasHeight / NUMBER_OF_ROWS);
				canvasContext.fill();
			}
			// Draw contact text
			const squareCenterX = squareStartX + canvasWidth / NUMBER_OF_COLUMNS / 2;
			const squareCenterY = squareStartY + canvasHeight / NUMBER_OF_ROWS / 2;
			canvasContext.fillStyle = 'white';
			canvasContext.shadowOffsetY = 1;
			canvasContext.shadowColor = 'rgba(0,0,0,0.6)';
			canvasContext.shadowBlur = 4;
			canvasContext.textAlign = 'center';
			canvasContext.font = `bold ${LARGE_FONT_SIZE}px Arial`;
			canvasContext.fillText(`${square.contact.firstName} ${square.contact.lastName}`, squareCenterX, squareCenterY - LARGE_FONT_SIZE, canvasWidth / NUMBER_OF_COLUMNS);
			canvasContext.font = `${SMALL_FONT_SIZE}px Arial`;
			canvasContext.fillText(getFormattedPhoneNumber(square.contact.phone), squareCenterX, squareCenterY, canvasWidth / NUMBER_OF_COLUMNS);
			canvasContext.fillText(getCampaignName(square.contact), squareCenterX, squareCenterY + LARGE_FONT_SIZE, canvasWidth / NUMBER_OF_COLUMNS);
		}
	}

	/**
	 * Handles a click on the canvas and determines if a call needs to be made.
	 *
	 * @param {MouseEvent} event The mouse event for the click.
	 */
	function handleClick(event) {
		const contact = getSquareAtCoordinates(event.offsetX, event.offsetY).contact;
		if (contact) {
			props.onContactClicked(contact);

			// Remove the contact from the squares and redraw
			const clickedSquareIndex = getIndexAtCoordinates(event.offsetX, event.offsetY);
			squares.current[clickedSquareIndex].contact = null;
			squares.current[clickedSquareIndex].color = emptySquareColor;
			window.requestAnimationFrame(drawSquares);
		}
	}

	/**
	 * Handles a mouse move event to show a hover effect.
	 *
	 * NOTE: This event runs on every mouse move within the canvas, so don't do anything too expensive here!
	 *
	 * @param {MouseEvent} event The mouse event for the move.
	 */
	function handleMouseMove(event) {
		const hoveredSquare = getSquareAtCoordinates(event.offsetX, event.offsetY);
		canvasRef.current.style.cursor = hoveredSquare.contact ? 'pointer' : 'default';

		// If square is not hovered, set it to active and redraw
		if (!hoveredSquare.isHovered) {
			squares.current.forEach(square => square.isHovered = false);
			hoveredSquare.isHovered = true;
			window.requestAnimationFrame(drawSquares);
		}
	}

	/**
	 * Gets a square at the given coordinates.
	 *
	 * @param {number} x The x coordinate.
	 * @param {number} y The y coordinate.
	 * @returns {Square} The square at the given coordinates.
	 */
	function getSquareAtCoordinates(x, y) {
		const column = Math.floor(x / (canvasWidth / NUMBER_OF_COLUMNS));
		let row = Math.floor(y / (canvasHeight / NUMBER_OF_ROWS));
		// TODO: Figure out if there's a more elegant way to handle this. Row is being calculated incorrectly for the very bottom row of pixels.
		if (y === canvasHeight) {
			row--;
		}
		const index = column + row * NUMBER_OF_COLUMNS;
		if (index < 0 || index >= squares.current.length) {
			console.error(`Couldn't find square at coordinates (${x}, ${y})`);
			return new Square();
		}
		return squares.current[index];
	}

	/**
	 * Gets the index of the square at the given coordinates.
	 *
	 * @param {number} x The x coordinate.
	 * @param {number} y The y coordinate.
	 * @returns {number} The index of the square.
	 */
	function getIndexAtCoordinates(x, y) {
		const column = Math.floor(x / (canvasWidth / NUMBER_OF_COLUMNS));
		let row = Math.floor(y / (canvasHeight / NUMBER_OF_ROWS));
		// TODO: Figure out if there's a more elegant way to handle this. Row is being calculated incorrectly for the very bottom row of pixels.
		if (y === canvasHeight) {
			row--;
		}
		return column + row * NUMBER_OF_COLUMNS;
	}

	/**
	 * Gets the campaign color for the given campaign ID.
	 *
	 * @param {string} campaignId The campaign ID.
	 * @returns {string} The hex color as a string.
	 */
	function getCampaignColor(campaignId) {
		const colors = [CAMPAIGN_COLOR_1, CAMPAIGN_COLOR_2, CAMPAIGN_COLOR_3];
		const colorIndex = selectedCampaigns.current.map((campaign) => campaign.id).indexOf(campaignId);
		return colors[colorIndex] ?? emptySquareColor;
	}

	/**
	 * Gets the campaign name for the given contact.
	 *
	 * @param {Contact} contact The contact to get the campaign name for.
	 * @returns {string} The campaign name.
	 */
	function getCampaignName(contact) {
		const campaignId = contact.campaignId;
		const campaign = selectedCampaigns.current.find((campaign) => campaign.id === campaignId);
		return campaign.name;
	}

	/**
	 * Formats a phone number to be in the format +# (###) ###-####.
	 *
	 * @param {string} phoneNumber The phone number to format.
	 * @returns {string} A formatted phone number string.
	 */
	function getFormattedPhoneNumber(phoneNumber) {
		try {
			const cleaned = ('' + phoneNumber).replace(/\D/g, '');
			const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
			const intlCode = (match[1] ? '+1 ' : '');
			return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
		} catch (error) {
			return phoneNumber;
		}
	}

	return (
		<canvas ref={canvasRef} style={{
			width: '100%',
			borderRadius: '4px'
		}} sx={canvasStyles} />
	);
}

import { useEffect, useState, useContext, useRef } from "react";
import { UserContext } from "../../contexts/UserContext";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Drawer,
	IconButton,
	Card,
	CardHeader,
	Slide,
	CardContent,
	Grid,
	CircularProgress,
	Tabs,
	Tab,
	Typography,
} from '@mui/material';
import { ChevronLeftOutlined, ChevronRightOutlined } from "@mui/icons-material";
import { generateClient, post } from "aws-amplify/api";
import { fetchAuthSession } from "aws-amplify/auth";
import { getContact } from "src/graphql/queries";

export default function ActiveCalls(props) {
	const client = generateClient();
	const userContext = useContext(UserContext);
	const [activeCalls, setActiveCalls] = useState(null);
	const interval = useRef(null);
	const [previewContactId, setPreviewContactId] = useState(null);
	const [previewContact, setPreviewContact] = useState(null);
	const [currentTab, setCurrentTab] = useState(0);

	useEffect(() => {
		async function getContactDetails() {
			try {
				const contact = await client.graphql({
					query: getContact,
					variables: { id: previewContactId }
				});
				let contactData = contact.data.getContact;
				let customFields = JSON.parse(contactData.customFields);
				contactData.customFields = [];
				for (const field in customFields) {
					contactData.customFields.push({ key: field, value: customFields[field] });
				}
				setPreviewContact(contactData);
			} catch (error) {
				console.error('Error getting contact', error);
			}
		}
		if (previewContactId) {
			getContactDetails();
		}
	}, [previewContactId]);


	async function getActiveCalls() {
		try {
			const getCallsResponse = await post({
				apiName: 'cdyxoutreach',
				path: '/cache/activeCalls',
				options: {
					headers: {
						Authorization: `Bearer ${(await fetchAuthSession()).tokens.idToken}`,
						'x-api-key': userContext.apiKey
					},
					body: {
						campaignId: props.campaignId
					}
				}
			}).response;

			let calls = [];

			if (await getCallsResponse.body.text()) {
				const getCalls = await getCallsResponse.body.json();

				for (const call in getCalls) {
					calls.push(JSON.parse(getCalls[call]));
				}
			}

			console.log('Active Calls', calls);

			// Sort calls by lowest start time
			calls.sort((a, b) => {
				return JSON.parse(a.startTime) - JSON.parse(b.startTime);
			});

			setActiveCalls(calls);

		} catch (error) {
			console.error('Error getting active calls', error);
		}
	}

	useEffect(() => {
		try {
			if (props.campaignId && props.open) {
				interval.current = setInterval(() => {
					getActiveCalls();
				}, 5000);
			} else {
				clearInterval(interval.current);
			}
		} catch (error) {
			console.error('Error getting active calls', error);
		}
	}, [props.campaignId, props.open]);

	function convertStoMs(startTime) {
		try {
			//Date in epoch
			let now = new Date().getTime() / 1000;
			let seconds = now - startTime;
			let minutes = Math.floor(seconds / 60);
			let extraSeconds = Math.floor(seconds % 60);
			minutes = minutes < 10 ? "0" + minutes : minutes;
			extraSeconds = extraSeconds < 10 ? "0" + extraSeconds : extraSeconds;
			return minutes + " : " + extraSeconds
		} catch (error) {
			console.error('Error converting seconds to minutes', error);
		}
	}


	return (
		<>
			<Drawer
				sx={{ width: 400 }}
				anchor="right"
				open={props.open}
				onClose={props.onClose}
			>
				{!previewContact &&
					<Card>
						<CardHeader title={`Active Calls for ${props.campaignName} (${activeCalls ? activeCalls.length : '0'})`} />
						<CardContent>
							{activeCalls && activeCalls.length > 0 && <Table>
								<TableHead>
									<TableRow>
										<TableCell>Agent</TableCell>
										<TableCell align="center">{`${userContext.telephonyProvider} Contact Id`}</TableCell>
										<TableCell align="center">Time</TableCell>
										<TableCell align="center">View Contact</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{activeCalls.map((call) => (
										<TableRow key={call.cciContactId}>
											<TableCell>{call.agentName ? `${call.agentName} - ${call.agentId}` : call.agentId}</TableCell>
											<TableCell align="center">{call.cciContactId}</TableCell>
											<TableCell align="center">{convertStoMs((JSON.parse(call.startTime)))}</TableCell>
											<TableCell align="center">
												<IconButton onClick={() => {
													setPreviewContactId(call.contactId);
												}}>
													<ChevronRightOutlined />
												</IconButton>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>}
							{activeCalls && activeCalls.length === 0 && <Typography variant="subtitle1" >No active calls</Typography>}
							{!activeCalls && <CircularProgress color="primary" />}
						</CardContent>
					</Card>}
				<Slide direction="left" in={previewContact ? true : false} mountOnEnter unmountOnExit>
					<Card>
						<CardHeader title={previewContact?.firstName + ' ' + previewContact?.lastName}
							subheader={<Tabs indicatorColor='primary' value={currentTab} onChange={(e, value) => setCurrentTab(value)}>
								<Tab label="Basic Info" value={0} />
								<Tab label="Custom Fields" value={1} />
								<Tab label="Contact Activity" value={2} />
							</Tabs>}
							action={
								<IconButton onClick={() => {
									setPreviewContactId(null);
									setPreviewContact(null);
								}}>
									<ChevronLeftOutlined />
								</IconButton>
							}
						/>
						<CardContent>
							{currentTab === 0 && <Grid container spacing={2}>
								{previewContact?.externalId && <Grid item xs={6}>
									<strong>External Id</strong>: {previewContact.externalId}
								</Grid>}
								{previewContact?.phone && <Grid item xs={6}>
									<strong>Phone</strong>: {previewContact.phone}
								</Grid>}
								{previewContact?.cell && <Grid item xs={6}>
									<strong>Cell</strong>: {previewContact.cell}
								</Grid>}
								{previewContact?.email && <Grid item xs={6}>
									<strong>Email</strong>: {previewContact.email}
								</Grid>}
								{previewContact?.source && <Grid item xs={6}>
									<strong>Source</strong>: {previewContact.source}
								</Grid>}
								{previewContact?.address1 && <Grid item xs={6}>
									<strong>Address 1</strong>: {previewContact.address1}
								</Grid>}
								{previewContact?.address2 && <Grid item xs={6}>
									<strong>Address 2</strong>: {previewContact.address2}
								</Grid>}
								{previewContact?.city && <Grid item xs={6}>
									<strong>City</strong>: {previewContact.city}
								</Grid>}
								{previewContact?.state && <Grid item xs={6}>
									<strong>State</strong>: {previewContact.state}
								</Grid>}
								{previewContact?.zip && <Grid item xs={6}>
									<strong>Zip</strong>: {previewContact.zip}
								</Grid>}
							</Grid>}
							{currentTab === 1 && <Grid container spacing={2}>
								{previewContact?.customFields?.map(field =>
									<Grid item xs={6} key={field.key}>
										<strong>{field.key}</strong>: {field.value}
									</Grid>
								)}
							</Grid>}
							{currentTab === 2 &&
								<ActivityLog contactId={previewContactId} />
							}
						</CardContent>
					</Card>
				</Slide>
			</Drawer >
		</>
	)
}

import {
	Card,
	CardHeader,
	CardContent,
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableBody,
	TableCell,
	Box,
} from '@mui/material';
import { ArrowRightAlt, ExpandMoreOutlined } from '@mui/icons-material';

export function FieldMappingPreview(props) {
	const { mappings } = props;

	return (
		<Box>
			<Card elevation={0}>
				<CardHeader variant='h6' title="Field Mapping Preview" />
				<CardContent>
				<TableContainer>
					<Table size="small">
						<TableHead>
							<TableRow>
								<TableCell>From</TableCell>
								<TableCell></TableCell>
								<TableCell>To</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{mappings.map((mapping, index) => (
								<TableRow key={index}>
									<TableCell>{mapping.fromField}</TableCell>
									<TableCell>
										<ArrowRightAlt color="primary" fontSize="small" />
									</TableCell>
									<TableCell>{mapping.toField}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
				</CardContent>
			</Card>

		</Box>



	);
}

import { Buffer } from 'buffer';

		/**
     * Decodes the Base64-encoded auth info.
     *
     * @param {string} base64AuthInfo The Base64-encoded auth info to decode.
     * @returns {{username: string, password: string}} The decoded auth info or undefined if there is no auth info.
     */
    export function decodeAuthInfo(base64AuthInfo) {
			const authInfo = Buffer.from(base64AuthInfo, 'base64').toString('ascii');
			const username = authInfo.split(':')[0];
			const password = authInfo.split(':').slice(1).join(':');
			if (!username || !password) {
					throw new Error('No username or password provided.');
			}
			return {
					username,
					password
			}
	}

import { useContext, createContext } from 'react';

export const ConfirmDialogContext = createContext({
	/**
	 * Shows the dialog.
	 * @param {{ confirmTxt: String, cancelTxt: String, onCancel: Function, onConfirm: Function, children: ReactNode}} options Dialog options.
	 * @returns {Promise<DialogResult>} The dialog result wrapped in a promise.
	 */
	showDialog: () => {},
});

export function useConfirmDialog() {
	return useContext(ConfirmDialogContext);
}

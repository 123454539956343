import { useState, useContext, useEffect, useRef } from 'react';
import { generateClient, post } from 'aws-amplify/api';
import { fetchAuthSession } from 'aws-amplify/auth';
import { enqueueSnackbar } from 'notistack';
import {
	Select,
	MenuItem,
	FormControl,
	InputLabel,
	Box,
	TextField,
	Typography,
	Card,
	CardContent,
	Button,
	CardActions,
	useMediaQuery
} from '@mui/material';
import { actionTwoButtonStyle, cardStyle } from 'src/theme';
import { Formik } from 'formik';
import { UserContext } from '../../contexts/UserContext';
import {
	tenantMappings,
	getTenantSettings,
} from '../../graphql/queries';
import { actionOneButtonStyle, formFieldStyle, selectInputStyle } from '../../theme';
// import { useSnackbar } from 'notistack';
import { object, string, number } from 'yup';
import { TextAreaField } from '@aws-amplify/ui-react';


/**
 * This page component handles uploading CSV files to an S3 bucket so we can then process them with
 * a lambda function and insert the rows in the database.
 *
 * The UI contains the ability to specify how fields from their files can be mapped to our existing fields.
 * @category Pages
 * @component
 * @param {any} props React props object
 */
export function SFTPImport() {
	const useSingleColumn = useMediaQuery('(max-width:845px)');
	const client = generateClient();
	const userContext = useContext(UserContext);
	const [importSettings, setImport] = useState({ host: '', username: '', password: '', path: '', privateKey: '', port: '', fieldMappingId: '', reportEmail: '' });
	const [fieldMappings, setFieldMappings] = useState([]);
	const [testSuccessful, setTestSuccessful] = useState(false);

	useEffect(() => {
		async function getData() {
			const promises = [];

			promises.push(client.graphql({
				query: getTenantSettings,
				variables: { id: userContext.tenantId }
			}));

			promises.push(client.graphql({
				query: tenantMappings,
				variables: { tenant: userContext.tenantId }
			}));

			await Promise.all(promises).then((results) => {
				const settings = results[0].data.getTenantSettings.ftpImport;
				if (settings) {
					setImport(JSON.parse(settings));
				}
				setFieldMappings(results[1].data.tenantMappings.items);
			});

		}

		if (userContext.tenantId) {
			getData();
		}

	}, [userContext.tenantId]);

	const testConnection = (values) => async () => {
		if (values.username === '' || values.host === '' || (values.password === '' && values.privateKey === '')) {
			enqueueSnackbar('You must provide a URL, UserName, and either a sftp key or a password for a valid connection', {
				variant: 'error',
				autoHideDuration: 5000
			});
		}
		else {
			try {
				setTestSuccessful(false);
				const conxResponse = await post({
					apiName: 'cdyxoutreach',
					path: '/ftpcheck',
					options: {
						headers: {
							Authorization: `Bearer ${(await fetchAuthSession()).tokens.idToken}`,
							'x-api-key': userContext.apiKey
						},
						body: values
					}
				}).response;

				console.log(conxResponse);
				if (conxResponse.success) {
					//alert(conxPromise.Promise.v)
					enqueueSnackbar('Connection Test Successful!', {
						autoHideDuration: 3500
					});
					setTestSuccessful(true);
				}
				else {
					enqueueSnackbar('Connection unsuccessful, Please check connection settings.', {
						variant: 'error',
						autoHideDuration: 5000
					});
					setTestSuccessful(false);
				}
				setTesting(false);
			} catch (err) {
				console.log(err);
				enqueueSnackbar('Connection unsuccessful, Please check connection settings.', {
					variant: 'error',
					autoHideDuration: 5000
				});
				setTestSuccessful(false);
			}
		}
	}


	return (
		<Box>
			<Formik
				initialValues={importSettings}
				enableReinitialize={true}
				validationSchema={object(
					{
						host: string()
							.min(4, 'The Import Host must be a valid domain or IP')
							.max(80, 'The Import Host must be a valid domain or IP'),
						username: string()
							.required('You must provide a user name'),
						privateKey: string()
							.min(256, 'The Import Key must be at least 256 Characters long'),
						password: string()
							.min(8, 'the password must be at least 8 characters'),
						port: number()
							.min(0, "Ports must be at least 0.")
							.max(65535, 'Ports must be at most 65535'),
						fieldMappingId: string()
							.required('A mapping is required to handle importing contacts')
					}
				)}
				onSubmit={async values => {
					//dynamodb doesn't like empty strings turn empty into null
					try {
						let sftpConfig = { id: tenant, ftpImport: JSON.stringify({ ...values, lastCheck: moment().unix() }) };
						for (const key in sftpConfig) {
							sftpConfig[key] = sftpConfig[key] || null;
						}

						console.log(sftpConfig);
						await API.graphql(graphqlOperation(mutations.updateTenantSettings, { input: sftpConfig }));
						enqueueSnackbar('Save Successful.')

					} catch (err) {
						enqueueSnackbar('Save Failed. ' + err);
					}

				}}
			>
				{formikprops => (
					<form onSubmit={formikprops.handleSubmit}>

						<Card
							style={cardStyle}
							elevation={0}
						>
							<CardContent>
								<Box display='flex' sx={{
									flexDirection: 'column',
								}} >
									<Typography variant="overline">Connection</Typography>
									<Box display='flex' flexDirection='column'>
										<Box display="grid"
											gridTemplateColumns={useSingleColumn ? "1fr" : "1fr 1fr 1fr"}
											gap="20px">
											<TextField
												label="SFTP Host"
												color="primary"
												margin="dense"
												name="host"
												type="text"
												onChange={formikprops.handleChange}
												onBlur={formikprops.handleBlur}
												value={formikprops.values.host}
												error={formikprops.errors.host && formikprops.touched.host}
												helperText={formikprops.errors.host} />

											<TextField
												label="Port"
												color="primary"
												margin="dense"
												aria-label='Port'
												name="port"
												type="number"
												onChange={formikprops.handleChange}
												onBlur={formikprops.handleBlur}
												value={formikprops.values.port}
												error={formikprops.errors.port && formikprops.touched.port}
												helperText={formikprops.errors.port} />

											<TextField
												label="Path"
												color="primary"
												margin="dense"
												aria-label='Folder'
												name="path"
												type="text"
												onChange={formikprops.handleChange}
												onBlur={formikprops.handleBlur}
												value={formikprops.values.path}
												error={formikprops.errors.path && formikprops.touched.path}
												helperText={formikprops.errors.path} />

										</Box>
										<Box display="grid"
											gridTemplateColumns={useSingleColumn ? "1fr" : "1fr 1fr"}
											gap="20px">
											<TextField
												color="primary"
												margin="dense"
												name="username"
												label="User Name"
												type="text"
												onChange={formikprops.handleChange}
												onBlur={formikprops.handleBlur}
												value={formikprops.values.username}
												error={formikprops.errors.username && formikprops.touched.username}
												helperText={formikprops.errors.username} />

											<TextField
												color="primary"
												margin="dense"
												name="password"
												label="Password"
												type="password"
												onChange={formikprops.handleChange}
												onBlur={formikprops.handleBlur}
												value={formikprops.values.password}
												error={formikprops.errors.password && formikprops.touched.password}
												helperText={formikprops.errors.password} />

										</Box>


										<TextAreaField
											sx={{ width: '100%' }}
											color="primary"
											margin="dense"
											name="privateKey"
											label="Private Key"
											type="text"
											onChange={formikprops.handleChange}
											onBlur={formikprops.handleBlur}
											value={formikprops.values.privateKey}
											error={formikprops.errors.privateKey && formikprops.touched.privateKey}
											helperText={formikprops.errors.privateKey} />

										<br></br><br></br>

										<Typography variant="overline">Configuration</Typography>

										<Box display="grid" alignItems='center'
											gridTemplateColumns={useSingleColumn ? "1fr" : "1fr 1fr"}
											gap="20px">
											<TextField
												color="primary"
												margin="dense"
												name="reportEmail"
												label="Report Email"
												type="email"
												onChange={formikprops.handleChange}
												onBlur={formikprops.handleBlur}
												value={formikprops.values.reportEmail}
												error={formikprops.errors.reportEmail && formikprops.touched.reportEmail}
												helperText={formikprops.errors.reportEmail} />

											<FormControl>
												<InputLabel id="fieldMappingId">Field Mapping</InputLabel>
												<Select
													labelId="fieldMappingId"
													label="Field Mapping"
													value={formikprops.values.fieldMappingId}
													onChange={formikprops.handleChange}
													onBlur={formikprops.handleBlur}
													sx={{ minWidth: '200px' }}
													error={formikprops.errors.fieldMappingId && formikprops.touched.fieldMappingId}
												>
													{fieldMappings.map((mapping, index) => (
														<MenuItem key={index} value={mapping.id}>{mapping.name}</MenuItem>
													))}
												</Select>
											</FormControl>
										</Box>
									</Box>
								</Box>

							</CardContent>
							<CardActions>
								<Box width="100%"
									display="flex"
									flexDirection="row"
									justifyContent="end"
									alignItems="center">
									<Button sx={actionTwoButtonStyle} onClick={testConnection(formikprops.values)}>Test Connection</Button>
									<Button sx={actionOneButtonStyle} disabled={!testSuccessful} type='submit' >Save</Button>
								</Box>
							</CardActions>
						</Card>
					</form>
				)}
			</Formik>

		</Box >
	);
}

import { get, post } from 'aws-amplify/api';

export class Webex {
	static skillLabel = 'Queue';

	static async getQueues(token, apiKey) {
		try {
			const queues = await get({
				apiName: 'cdyxoutreach',
				path: '/cci/webex/contact-center/queues',
				options: {
					headers: {
						Authorization: `Bearer ${token}`,
						'x-api-key': apiKey,
						'Content-Type': 'application/json',
					}
				}
			}).response;
			return queues;
		} catch (err) {
			console.error('Error getting queues', err);
		}
	}

	static async getDialNumbers(token, apiKey) {
		try {
			const dialNumbers = await get({
				apiName: 'cdyxoutreach',
				path: '/cci/webex/contact-center/dial-numbers',
				options: {
					headers: {
						Authorization: `Bearer ${token}`,
						'x-api-key': apiKey,
						'Content-Type': 'application/json',
					}
				}
			}).response;
			return dialNumbers.body.json();
		} catch (err) {
			console.error('Error getting dial numbers', err);
		}
	}

	static async getEntryPoints(token, apiKey) {
		try {
			const entryPoints = await get({
				apiName: 'cdyxoutreach',
				path: '/cci/webex/contact-center/entry-points',
				options: {
					headers: {
						Authorization: `Bearer ${token}`,
						'x-api-key': apiKey,
						'Content-Type': 'application/json',
					}
				}
			}).response;

			return entryPoints.body.json();
		} catch (err) {
			console.error('Error getting entry points', err);
		}
	}

	static async getWrapUpCodes(token, apiKey) {
		try {
			const queues = await get({
				apiName: 'cdyxoutreach',
				path: `/cci/webex/contact-center/wrap-up-codes`,
				options: {
					headers: {
						Authorization: `Bearer ${token}`,
						'x-api-key': apiKey,
						'Content-Type': 'application/json',
					}
				}
			}).response;

			return queues;
		} catch (err) {
			console.error('Error getting queues', err);
		}
	}

	static async getAvailableChimeNumbers(token, apiKey, state, city) {
		const queryParams = {
			state,
			region: 'us-east-1',
		};
		if (city) {
			queryParams.city = city;
		}

		const availableNumbers = await get({
			apiName: 'cdyxoutreach',
			path: `/cci/chime/available-phone-numbers`,
			options: {
				headers: {
					Authorization: `Bearer ${token}`,
					'x-api-key': apiKey,
					'Content-Type': 'application/json',
				},
				queryParams: queryParams
			}
		}).response;

		return availableNumbers.numbers;
	}

	static async provisionChimeNumber(token, apiKey, tenantId, phoneNumber) {

		const response = await post({
			apiName: 'cdyxoutreach',
			path: '/cci/chime/phone-number',
			options: {
				headers: {
					Authorization: `Bearer ${token}`,
					'x-api-key': apiKey,
					'Content-Type': 'application/json',
				},
				body: {
					tenantId,
					region: 'us-east-1',
					phoneNumber,
				},
			}
		}).response;

		return response;
	}
}

import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { InContact } from '../../contexts/InContact';
import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from '@mui/material';
import { UserContext, loadContext } from '../../contexts/UserContext';
import NiceDialog from '../../components/click2call/niceDialog';
import { decodeAuthInfo } from '../../tools/authHelpers';
import { fetchAuthSession, signIn, signOut } from 'aws-amplify/auth';
import { WebexDialog } from 'src/components/click2call/webexDialog';
import { AmazonConnectDialog } from 'src/components/click2call/amazonConnectDialog';
import { GenesysDialog } from 'src/components/click2call/genesysDialog';
import { Callbacks } from '../engagement/callback';

const CCI_OPTION = {
	AmazonConnect: 'AmazonConnect',
	Nice: 'NiC',
	Genesys: 'Genesys',
	Webex: 'Webex',
};

export function Callback(props) {
	/** The user context to be used for the approver interface. */
	const [userContextState, setUserContextState] = useState({
		tenant: '',
		tenantRole: '',
		company: '',
		name: '',
		apiKey: '',
		cognitoToken: '',
		licenses: [],
		icSkills: [],
		customFields: [],
		queryFields: [],
		rights: [],
		icPushContacts: false,
		telephonyProvider: '',
	});

	/** The CCI to use for Click2Call. */
	const [cci, setCci] = useState('');

	/** The telephone provider info for the specific CCI. */
	const [cciInfo, setCciInfo] = useState(null);

	/** The path params at the end of the URL. Represents a Base64-encoded string of username:password. */
	let { auth64 } = useParams();

	/** Whether something is currently being loaded in the page. */
	const [loading, setLoading] = useState(true);

	/** The title to be displayed to the user in case of error. */
	const [errorTitle, setErrorTitle] = useState('');

	/** The text to be displayed to the user in case of error. */
	const [errorText, setErrorText] = useState('');

	/** Whether the error is persistent and should not be dismissed. */
	const [persistentError, setPersistentError] = useState(false);

	/** The ID of the agent using Click2Call. */
	const [agentId, setAgentId] = useState('');

	/** The IDs of the skills available for the agent. */
	const [agentSkillIds, setAgentSkillIds] = useState([]);

	/** Whether the agent is connected and should have access to the approver interface. */
	const [agentConnected, setAgentConnected] = useState(false);

	/**
	 * Grabs the credentials from the params and logs in to SafeSelect.
	 */
	useEffect(() => {
		authorizeSafeSelect();
	}, []);

	/**
	 * If the user context is loaded, show the first dialog.
	 */
	useEffect(() => {
		if (!agentConnected && userContextState.apiKey) {
			setLoading(false);
		}
	}, [userContextState]);

	/**
	 * Attempts to log in to SafeSelect.
	 */
	async function authorizeSafeSelect() {
		let authCode;
		if (props.velocityAuth) {
			auth64 = props.velocityAuth;
		} else {
			authCode = window.location.search.split('code=')[1];
		}

		// Missing credentials; unable to sign in
		if (!auth64 && !authCode) {
			displayError(
				'Authentication Error',
				'You are not signed in and credentials are not available on this URL, so we are unable to sign you in. Please check your custom workspace configuration and try again.',
				true,
			);
			return;
		}

		/*
				Re-authenticate each time, unless the URL has a code param. Since the code param won't include the username and password,
				we need to stay signed in.
		*/
		if (!authCode) {
			await signOut();
			try {
				const authInfo = decodeAuthInfo(auth64);
				await signIn({
					username: authInfo.username,
					password: authInfo.password,
				});
			} catch (error) {
				console.error('Error signing in', error);
				displayError(
					'Credentials Error',
					'Unable to decode a valid username and password from the credentials provided in the URL. Please check your configuration and try again.',
					true,
				);
				return;
			}
		}
		getSafeSelectTenantSettings();
	}

	/**
	 * Gets the tenant settings for the provided tenant ID and sets the values for the user context.
	 */
	async function getSafeSelectTenantSettings() {
		try {
			const session = await fetchAuthSession();
			const idToken = session.tokens.idToken;
			const { tenant, queryFields } = await loadContext(
				session.tokens.idToken.payload['custom:tenant'],
			);
			const rights = idToken.payload['custom:securityRights'].split('|');
			setCciInfo(tenant.telephoneProviderInfo.genesys);

			let context = {
				tenantId: idToken.payload['custom:tenant'],
				tenantRole: idToken.payload['custom:tenantRole'],
				company: idToken.payload['custom:company'],
				name: idToken.payload['name'],
				idToken,
				apiKey: tenant.apiKey,
				icSkills: [],
				customFields: tenant.customFields,
				queryFields,
				licenses: tenant.licenses,
				rights: rights,
				icPushContacts: tenant.icPushContacts,
				telephonyProvider: tenant.telephonyProvider,
			};

			if (tenant.telephonyProvider === 'InContact') {
				InContact.icSettings = tenant;
			}
			setCci(tenant.telephonyProvider);
			setUserContextState(context);
		} catch (error) {
			console.error('Error getting SafeSelect tenant settings', error);
		}
	}

	/**
	 * Displays an error dialog.
	 *
	 * @param {string} errorTitle The title of the error dialog.
	 * @param {string} errorText The text of the error dialog.
	 * @param {boolean} persistentError Whether the error dialog should be persistent (i.e. not closable by the user).
	 */
	function displayError(errorTitle, errorText, persistentError = false) {
		setErrorTitle(errorTitle);
		setErrorText(errorText);
		setPersistentError(persistentError);
		setLoading(false);
	}

	/**
	 * Closes the opened error dialog.
	 */
	function closeErrorDialog() {
		if (errorText) {
			setErrorTitle('');
			// Workaround to avoid jarring dialog closing animation
			setTimeout(() => {
				setErrorText('');
			}, 200);
		}
	}

	/**
	 * Proceeds to the approver interface.
	 *
	 * @param {string} agentId The agent ID of the agent that is logged in.
	 */
	function proceedToApproverInterface(agentId, agentSkillIds = []) {
		setAgentId(agentId || props.agentId);
		setAgentSkillIds(agentSkillIds);
		setAgentConnected(true);
		setLoading(false);
	}



	return (
		<>
			{/* Error */}
			<Dialog open={errorTitle !== '' && !loading} maxWidth="xs">
				<DialogTitle>{errorTitle ? errorTitle : 'Error'}</DialogTitle>
				<DialogContent>
					<DialogContentText gutterBottom>{errorText}</DialogContentText>
				</DialogContent>
				{!persistentError && (
					<DialogActions>
						<Button
							color="primary"
							onClick={closeErrorDialog}
						>
							OK
						</Button>
					</DialogActions>
				)}
			</Dialog>

			{agentConnected && (
				<UserContext.Provider value={userContextState}>
					<Callbacks {...props} />
				</UserContext.Provider>
			)}

			{/* Loading */}
			{loading && (
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						height: '100vh',
					}}
				>
					<CircularProgress
						variant="indeterminate"
						color="primary"
					></CircularProgress>
				</div>
			)}

			{/* CCI Dialogs */}
			{!loading && !agentConnected && (
				<UserContext.Provider value={userContextState}>
					{cci === CCI_OPTION.AmazonConnect && (
						<AmazonConnectDialog
							cciInfo={cciInfo}
							onError={displayError}
							onAgentConnected={proceedToApproverInterface}
						/>
					)}
					{cci === CCI_OPTION.Nice && (
						<NiceDialog
							cciInfo={cciInfo}
							onError={displayError}
							onAgentConnected={proceedToApproverInterface}
						/>
					)}
					{cci === CCI_OPTION.Genesys && (
						<GenesysDialog
							cciInfo={cciInfo}
							onError={displayError}
							onAgentConnected={proceedToApproverInterface}
						/>
					)}
					{cci === CCI_OPTION.Webex && (
						<WebexDialog
							cciInfo={cciInfo}
							onError={displayError}
							onAgentConnected={proceedToApproverInterface}
							agentId={props.agentId}
						/>
					)}
				</UserContext.Provider>
			)}
		</>
	);
}

import { useEffect, useState, useContext, useRef } from 'react';
import { UserContext } from '../../contexts/UserContext';
import {
	Typography,
	TextField,
	Tooltip,
	Grid,
	Button,
	FormControlLabel,
	Switch,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Card,
	CardHeader,
	CardContent,
	Checkbox,
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton,
	ToggleButton,
	ToggleButtonGroup,
	Box,
	Slider,
	TableContainer,
	TableCell,
	DialogActions
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { generateClient } from 'aws-amplify/api';
import { getProfile } from '../../graphql/queries';
import { updateProfile, createProfile } from '../../graphql/mutations';
import { Formik } from 'formik';
import { object, string, number, array, mixed, lazy } from 'yup';
import { enqueueSnackbar } from 'notistack';
import {
	DeleteOutlineOutlined,
	EditOutlined,
	InfoOutlined,
	CheckCircleOutlineOutlined,
	ControlPointDuplicateOutlined,
	HelpOutlineOutlined,
} from '@mui/icons-material';
import { RetrySlider } from '../../components/retrySlider';
import { timezones } from '../../models/timezones';
import { timeToCallWindow } from '../../tools/timeToCallWindow';
import { useConfirmDialog } from '../../components/confirmDialog/context';
import { DialogResult } from '../../models/dialogResults';
import { ProfileChannels } from '../../components/engagement/profileChannels';
import { PageAppBar } from 'src/components/pageAppBar';
import { actionOneButtonStyle, actionTwoButtonStyle } from 'src/theme';
import { Table, TableBody, TableHead, TableRow } from '@aws-amplify/ui-react';

const newProfile = {
	id: '',
	tenant: '',
	name: '',
	flowRatio: 3,
	ratio: 20,
	scrubber: {
		cell: false,
		natDnc: false,
		stateDnc: false,
		orgDnc: false,
		phoneDup: false,
		contactDup: false,
	},
	channels: [],
	retry: {
		minDuration: 0,
		maxAttempts: 0,
		maxContacts: 0,
		custRetry: {},
		ratio: 20,
	},
	freshLIFO: false,
	retryLIFO: false,
	startTime: '08:00',
	endTime: '21:00',
	buffer: 5,
	clickToCall: false,
	callWindows: [
		{
			default: true,
			days: [1, 2, 3, 4, 5],
			beginTime: { hour: 8, minute: 0 },
			endTime: { hour: 21, minute: 0 },
		}
	],
	overrideDispositionScoring: false,
	dispositionScoring: {
		noAnswer: 0,
		answer: 1.0,
		noAgentAvailableToDial: 3.0,
		noAgentOnAnswer: 5.0,
		overApproving: 1.0,
	},
	overrideDialToAgentRatio: false,
	dialToAgentRatio: 3.0,
};

const queryOperations = [
	{ op: 'ne', display: 'Not equal' },
	{ op: 'eq', display: 'Equal' },
	{ op: 'le', display: 'Less than or equal to' },
	{ op: 'lt', display: 'Less than' },
	{ op: 'ge', display: 'Greater than or equal to' },
	{ op: 'gt', display: 'Greater than' },
	{ op: 'beginsWith', display: 'Begins with' },
];

export function Profile() {
	const client = generateClient();
	const userContext = useContext(UserContext);
	const { showDialog } = useConfirmDialog();
	const navigate = useNavigate();
	const { id } = useParams();
	const [saving, setSaving] = useState(false);
	const [profile, setProfile] = useState(newProfile);
	const [selCustRetries, setCustRetries] = useState([]);
	const [openNewCallWindow, setOpenNewCallWindow] = useState(false);
	const isNew = useRef(false);

	//New Call Windows
	const [callWindows, setCallWindows] = useState([]);

	const [newCallWindowDays, setNewCallWindowDays] = useState([]);
	const [newCallWindowTmzOffset, setNewCallWindowTmzOffset] = useState();
	const [newCallWindowTmzDst, setNewCallWindowTmzDst] = useState(false);
	const [newCallWindowBeginTime, setNewCallWindowBeginTime] = useState(newProfile.startTime);
	const [newCallWindowEndTime, setNewCallWindowEndTime] = useState(newProfile.endTime);
	const [newCallWindowQueryField, setNewCallWindowQueryField] = useState();
	const [newCallWindowQueryOperator, setNewCallWindowQueryOperator] = useState();
	const [newCallWindowQueryValue, setNewCallWindowQueryValue] = useState();
	const [newCallWindowDefaultFlag, setNewCallWindowDefaultFlag] = useState(false);
	const [callWindowOrderNumber, setCallWindowOrderNumber] = useState();

	const [queryFields, setQueryFields] = useState([]);

	const [dialingRatio, setDialingRatio] = useState('dynamic');

	useEffect(() => {
		async function getData() {
			const promises = [];
			promises.push(
				client.graphql({
					query: getProfile,
					variables: { id },
				}),
			);

			const results = await Promise.all(promises);
			const savedProfile = results[0];

			if (savedProfile?.data?.getProfile) {
				// Force non-null values for the profile (in the case of a legacy profile).
				setProfile(profile => {
					profile = {};
					for (const key of Object.keys(newProfile)) {
						if (savedProfile.data.getProfile[key] != null) {
							profile[key] = savedProfile.data.getProfile[key];
						} else {
							profile[key] = newProfile[key];
						}
					}

					return profile;
				});

				if (savedProfile.data.getProfile.channels) {
					setProfile(profile => {
						profile.channels = savedProfile.data.getProfile.channels;
						return profile;
					});
				}
				if (savedProfile.data.getProfile.callWindows) {
					setCallWindows(savedProfile.data.getProfile.callWindows)
				}

				if (savedProfile.data.getProfile.clickToCall) {
					setDialingRatio('single');
				} else if (savedProfile.data.getProfile.overrideDialToAgentRatio) {
					setDialingRatio('static');
				} else {
					setDialingRatio('dynamic');
				}
			}
		}

		if (userContext?.tenantId && id) {
			if (id !== 'new' && !isNew.current) {
				isNew.current = false;
				getData();
			} else if (id === 'new' && userContext.telephonyProvider === "Nic") {
				isNew.current = true;
				setProfile(newProfile);
				setCustRetries([]);
			} else if (id === 'new' && userContext.telephonyProvider) {
				isNew.current = true;
				setProfile(newProfile);
				setCustRetries([]);
			}
			if (userContext.queryFields) {
				setQueryFields(userContext.queryFields.list);
			}
		}
	}, [id, userContext]);

	async function handleClose(dirty) {
		if (!dirty || await showDialog({
			confirmTxt: 'Yes',
			children: 'You have unsaved changes. Are you sure you want to leave this page?'
		}) === DialogResult.Confirm) {
			navigate("/profiles")
		}
	}

	const saveCallWindow = (formikProps) => () => {
		const window = {
			days: newCallWindowDays,
			tmz: {
				offset: newCallWindowTmzOffset,
				dst: newCallWindowTmzDst
			},
			beginTime: timeToCallWindow(newCallWindowBeginTime),
			endTime: timeToCallWindow(newCallWindowEndTime),
			callWindowQuery: {
				field: newCallWindowQueryField,
				operator: newCallWindowQueryOperator,
				value: newCallWindowQueryValue
			},
			default: newCallWindowDefaultFlag,
			order: callWindowOrderNumber
		};

		if (!newCallWindowTmzOffset) {
			delete window.tmz
		}

		if (newCallWindowDefaultFlag) {
			delete window.callWindowQuery
		} else {
			if ((window.callWindowQuery.field === "phone" || window.callWindowQuery.field === "cell") && (!window.callWindowQuery.value.startsWith("+1"))) {
				window.callWindowQuery.value = '+1' + window.callWindowQuery.value;
			}
		}



		if (!newCallWindowDefaultFlag && (!newCallWindowQueryField || !newCallWindowQueryOperator || !newCallWindowQueryValue)) {
			enqueueSnackbar('Please finish query or set window to default', { variant: 'error' });
		} else if (newCallWindowDays.length < 1) {
			enqueueSnackbar('Please add at least one day for call window', { variant: 'error' });
		} else {
			const tempCallWindows = callWindows;
			tempCallWindows[callWindowOrderNumber] = window;

			setCallWindows([...tempCallWindows]);
			setOpenNewCallWindow(false);
			formikProps.setFieldValue('callWindows', tempCallWindows)

			//reset fields
			setNewCallWindowDays([]);
			setNewCallWindowTmzOffset();
			setNewCallWindowTmzDst(false);
			setNewCallWindowBeginTime(newProfile.startTime);
			setNewCallWindowEndTime(newProfile.endTime);
			setNewCallWindowQueryField();
			setNewCallWindowQueryOperator();
			setNewCallWindowQueryValue();
			setNewCallWindowDefaultFlag(false);
			setCallWindowOrderNumber();
		}


	}

	function stringifyTimewindowTime(time) {
		return `${time.hour > 12 ? time.hour - 12 : time.hour === 0 ? 12 : time.hour}:${time.minute.toString().padStart(2, '0')}${time.hour >= 12 ? "PM" : "AM"}`;
	}

	function onEditWindow(window) {
		setNewCallWindowDays(window.days);
		setNewCallWindowDefaultFlag(window.default);
		setNewCallWindowBeginTime(`${window.beginTime.hour.toString().padStart(2, '0')}:${window.beginTime.minute.toString().padStart(2, '0')}`);
		setNewCallWindowEndTime(`${window.endTime.hour.toString().padStart(2, '0')}:${window.endTime.minute.toString().padStart(2, '0')}`);
		if (window.callWindowQuery) {
			setNewCallWindowQueryField(window.callWindowQuery.field);
			setNewCallWindowQueryOperator(window.callWindowQuery.operator);
			setNewCallWindowQueryValue(window.callWindowQuery.value);
		}
		if (window.tmz) {
			setNewCallWindowTmzDst(window.tmz.dst);
			setNewCallWindowTmzOffset(window.tmz.offset);
		}
		setCallWindowOrderNumber(window.order ? window.order : 0);
		setOpenNewCallWindow(true)
	}

	function onCloneWindow(window) {
		setNewCallWindowDays(window.days);
		setNewCallWindowDefaultFlag(window.default);
		setNewCallWindowBeginTime(`${window.beginTime.hour.toString().padStart(2, '0')}:${window.beginTime.minute.toString().padStart(2, '0')}`);
		setNewCallWindowEndTime(`${window.endTime.hour.toString().padStart(2, '0')}:${window.endTime.minute.toString().padStart(2, '0')}`);
		if (window.callWindowQuery) {
			setNewCallWindowQueryField(window.callWindowQuery.field);
			setNewCallWindowQueryOperator(window.callWindowQuery.operator);
			setNewCallWindowQueryValue(window.callWindowQuery.value);
		}
		if (window.tmz) {
			setNewCallWindowTmzDst(window.tmz.dst);
			setNewCallWindowTmzOffset(window.tmz.offset);
		}
		setCallWindowOrderNumber(callWindows.length);
		setOpenNewCallWindow(true)
	}

	function onDeleteWindow(deleted) {
		const filtered = callWindows.filter(x => x.order !== deleted.order);
		for (const window of filtered) {
			if (window.order > deleted.order) {
				--window.order;
			}
		}

		setCallWindows(filtered);
	}

	return (
		<Formik
			initialValues={profile}
			enableReinitialize
			validationSchema={object({
				name: string()
					.required("A Profile name is required")
					.matches(/[a-zA-Z0-9]/),
				flowRatio: number()
					.max(10, "This value must be less than 10.")
					.min(1, "This value must be greater than 1.")
					.required("This is a required field."),
				buffer: number()
					.max(55, "This value must be at most 55.")
					.min(5, "This value must be at least 5.")
					.required("This is a required field."),
				channels: array().required("You must have at least one channel."),
				startTime: mixed().test({
					name: "Proper curfew time bounds",
					message: "The start time must be before the end time",
					test: function (value) {
						const start = new Date(
							0,
							0,
							0,
							value.substring(0, 2),
							value.substring(3)
						);
						const end = new Date(
							0,
							0,
							0,
							this.parent.endTime.substring(0, 2),
							this.parent.endTime.substring(3)
						);
						return start < end;
					},
				}),
			})}
			onSubmit={async (values, formikBag) => {
				setSaving(true);

				let successMsg = "Profile Updated";
				let mutation = updateProfile;
				const profileValues = { ...values };
				profileValues.callWindows = callWindows;
				profileValues.retry.custRetry = JSON.stringify(selCustRetries);
				delete profileValues.createdAt;
				delete profileValues.updatedAt;
				if (id === "new") {
					mutation = createProfile;
					profileValues.tenant = userContext.tenantId;
					successMsg = "Profile Created";
					delete profileValues.id;
				}
				try {
					const profileResult = await client.graphql({
						query: mutation,
						variables: { input: profileValues },
					});
					const savedProfile =
						profileResult.data[
						id === "new" ? "createProfile" : "updateProfile"
						];
					setProfile(savedProfile);
					if (savedProfile.id !== id) {
						navigate(`/profiles/${savedProfile.id}`);
					}
					enqueueSnackbar(successMsg, { variant: "success" });
				} catch (err) {
					console.error(err);
					enqueueSnackbar("Profile could not be saved", { variant: "error" });
				}

				setSaving(false);
			}}
		>
			{(formikProps) => (
				<form onSubmit={formikProps.handleSubmit}>
					<PageAppBar
						title="Communication Profile"
						description="Set up Call Windows, Cadence, Attempts, and More"
						actionOneText="Save"
						actionOneHandler={formikProps.submitForm}
						actionTwoText="Cancel"
						actionTwoHandler={() => handleClose(formikProps.dirty)}
						actionTwoDisabled={saving}
					/>

					<Box display="grid" gap="20px" >

						<TextField
							color="primary"
							margin="dense"
							name="name"
							label="Profile Name"
							type="text"
							required={true}
							value={formikProps.values.name}
							onChange={formikProps.handleChange}
							onBlur={formikProps.handleBlur}
							error={formikProps.errors.name && formikProps.touched.name}
						/>

						<Box display="grid"
							gridTemplateColumns="1fr 2fr"
							gap="20px" alignItems='center'>
							<Card variant="outlined" style={{ height: "100%" }}>
								<CardHeader
									title="Contact Flow"
									titleTypographyProps={{
										variant: "body2",
										color: "textSecondary",
									}}
								/>
								<CardContent>
									<Box>
										<RetrySlider
											label="Retry vs. Fresh"
											name="ratio"
											valueLabelDisplay="on"
											valueLabelFormat={(value) => `${value}%`}
											type="number"
											value={formikProps.values.ratio || 0}
											onChange={formikProps.handleChange}
											min={0}
											max={100}
										/>
										<Box>
											<Typography color="primary">Fresh</Typography>

											<Box display="flex" alignItems="center">
												<Typography>First In - First Out</Typography>

												<Switch
													name="freshLIFO"
													checked={formikProps.values.freshLIFO}
													onChange={formikProps.handleChange}
													onBlur={formikProps.handleBlur}
													value={formikProps.values.freshLIFO}
													color="primary" />

												<Typography>Last In - First Out</Typography>
											</Box>
										</Box>
										<Box>
											<Typography color="primary">Retry</Typography>

											<Box display="flex" alignItems="center">
												<Typography>First In - First Out</Typography>

												<Switch
													name="retryLIFO"
													checked={formikProps.values.retryLIFO}
													onChange={formikProps.handleChange}
													onBlur={formikProps.handleBlur}
													value={formikProps.values.retryLIFO}
													color="primary" />

												<Typography>Last In - First Out</Typography>
											</Box>
										</Box>

									</Box>
								</CardContent>
							</Card>
							<Card variant="outlined" style={{ height: "100%" }}>
								<CardHeader
									title="Dialing Ratio"
									titleTypographyProps={{
										variant: "body2",
										color: "textSecondary",
									}}
								/>
								<CardContent>
									<Box display="grid"
										gridTemplateColumns={dialingRatio === 'dynamic' ? '1fr 1fr 2fr' : '1fr 2fr'}
										gap="20px" alignItems='flex-start'>
										<Box>
											<ToggleButtonGroup value={dialingRatio} color="primary" orientation='vertical' exclusive>
												<Tooltip placement="right" title="Agents click to dial at a 1:1 ratio"><ToggleButton onClick={() => {
													setDialingRatio('single');
													formikProps.setFieldValue('clickToCall', true);
												}} value="single">Click To Call</ToggleButton></Tooltip>
												<Tooltip placement="right" title="Velocity spawns calls at a standard ratio per agents available"><ToggleButton onClick={() => {
													setDialingRatio('static');
													formikProps.setFieldValue('overrideDialToAgentRatio', true);
													formikProps.setFieldValue('clickToCall', false);
												}} value="static">Static Dialing Ratio</ToggleButton></Tooltip>
												<Tooltip placement="right" title="Velocity adjusts the dialing ratio based on past call history">
													<ToggleButton onClick={() => {
														setDialingRatio('dynamic');
														formikProps.setFieldValue('overrideDialToAgentRatio', false);
														formikProps.setFieldValue('clickToCall', false);
													}} value="dynamic">Dynamic Dialing Ratio</ToggleButton></Tooltip>
											</ToggleButtonGroup>
										</Box>
										<Box>
											{dialingRatio === 'dynamic' && <Box display="grid" gridTemplateColumns="1fr">
												<Box display="grid" gridTemplateColumns="3fr 1fr" gap={2} alignItems="center">
													<TextField
														color="primary"
														margin="dense"
														name="flowRatio"
														label="Call History Size"
														type="number"
														required
														value={formikProps.values.flowRatio}
														onChange={formikProps.handleChange}
														onBlur={formikProps.handleBlur}
														InputProps={{
															inputProps: {
																max: 10,
																min: 1,
															},
														}}
														error={
															formikProps.errors.flowRatio &&
															formikProps.touched.flowRatio
														}
														helperText={
															formikProps.touched.flowRatio &&
															formikProps.errors.flowRatio
														}
													/>
													<Tooltip title="Velocity calculates how many contacts to present to approvers based off of past call history. The higher the number, the more call history we look at.">
														<InfoOutlined color="primary" />
													</Tooltip>
												</Box>
												<FormControlLabel
													label="Use Custom Scoring"
													control={
														<Switch
															checked={
																formikProps.values.overrideDispositionScoring
															}
															name="overrideDispositionScoring"
															onChange={formikProps.handleChange}
															color="primary"
														/>
													}
												/>
											</Box>}

											{dialingRatio === 'static' &&

												<Grid item>
													<Typography variant="body1">
														Dial to Agent Ratio
														<Tooltip
															placement="top-start"
															leaveDelay={500}
															title='The set ratio of dials that will be placed for each available agent.'
														>
															<IconButton color="primary" size="small">
																<HelpOutlineOutlined fontSize="inherit" />
															</IconButton>
														</Tooltip>
														: &nbsp;{formikProps.values.dialToAgentRatio}
													</Typography>
													<Slider
														name="dialToAgentRatio"
														value={formikProps.values.dialToAgentRatio}
														step={.1}
														min={1}
														max={15}
														marks
														valueLabelDisplay="on"
														onChange={(e, value) => {
															formikProps.setFieldValue('dialToAgentRatio', value);
														}
														}
													/>
												</Grid>}
										</Box>
										{dialingRatio === 'dynamic' && formikProps.values.overrideDispositionScoring && (
											<Grid item container direction="column" spacing={2}>
												<Grid item>
													<Typography variant="body1">
														No Answer
														<Tooltip
															placement="top-start"
															leaveDelay={500}
															title='The score for dial attempts that had no
																							agent connected.'
														>
															<IconButton color="primary" size="small">
																<HelpOutlineOutlined fontSize="inherit" />
															</IconButton>
														</Tooltip>
														: &nbsp;
														{formikProps.values.dispositionScoring.noAnswer}
													</Typography>
													<Slider
														name="dispositionScoring.noAnswer"
														marks
														value={
															formikProps.values.dispositionScoring.noAnswer
														}
														step={0.1}
														min={-2.0}
														max={1.0}
														valueLabelDisplay="on"
														onChange={(e, value) => {
															formikProps.setFieldValue('dispositionScoring.noAnswer', value);
														}}
													/>
												</Grid>
												<Grid item>
													<Typography variant="body1">
														Answer
														<Tooltip
															placement="top-start"
															leaveDelay={500}
															title='The score for dial attempts that had an
																							agent connected.'
														>
															<IconButton color="primary" size="small">
																<HelpOutlineOutlined fontSize="inherit" />
															</IconButton>
														</Tooltip>
														: &nbsp;
														{formikProps.values.dispositionScoring.answer}
													</Typography>
													<Slider
														value={
															formikProps.values.dispositionScoring.answer
														}
														name="dispositionScoring.answer"
														step={0.1}
														min={0.0}
														max={2.0}
														marks
														valueLabelDisplay="on"
														onChange={(e, value) => {
															formikProps.setFieldValue('dispositionScoring.answer', value);
														}}
													/>
												</Grid>
												<Grid item>
													<Typography variant="body1">
														No Agent Available To Dial
														<Tooltip
															placement="top-start"
															leaveDelay={500}
															title='The score for a dial attempt that had no
																							agents available when the dial was about
																							to be attempted.'
														>
															<IconButton color="primary" size="small">
																<HelpOutlineOutlined fontSize="inherit" />
															</IconButton>
														</Tooltip>
														:{" "}
														{
															formikProps.values.dispositionScoring
																.noAgentAvailableToDial
														}
													</Typography>
													<Slider
														value={
															formikProps.values.dispositionScoring
																.noAgentAvailableToDial
														}
														name="dispositionScoring.noAgentAvailableToDial"
														step={0.1}
														min={1.0}
														max={5.0}
														marks
														valueLabelDisplay="on"
														onChange={(e, value) => {
															formikProps.setFieldValue('dispositionScoring.noAgentAvailableToDial', value);
														}}
													/>
												</Grid>
												<Grid item>
													<Typography variant="body1">
														No Agent On Answer
														<Tooltip
															placement="top-start"
															leaveDelay={500}
															title='
																							The score for a dial attempt that had no
																							agents available when the dial was
																							answered.'
														>
															<IconButton color="primary" size="small">
																<HelpOutlineOutlined fontSize="inherit" />
															</IconButton>
														</Tooltip>
														:{" "}
														{
															formikProps.values.dispositionScoring
																.noAgentOnAnswer
														}
													</Typography>
													<Slider
														value={
															formikProps.values.dispositionScoring
																.noAgentOnAnswer
														}
														name="dispositionScoring.noAgentOnAnswer"
														step={0.1}
														min={1.0}
														max={6.0}
														marks
														valueLabelDisplay="on"
														onChange={(e, value) => {
															formikProps.setFieldValue('dispositionScoring.noAgentOnAnswer', value);
														}}
													/>
												</Grid>
												<Grid item>
													<Typography variant="body1">
														Over Approving
														<Tooltip
															placement="top-start"
															leaveDelay={500}
															title='The score for a dial approval that would
																							allow more dials than the currently
																							allowed limit.'
														>
															<IconButton color="primary" size="small">
																<HelpOutlineOutlined fontSize="inherit" />
															</IconButton>
														</Tooltip>
														:{" "}
														{
															formikProps.values.dispositionScoring
																.overApproving
														}
													</Typography>
													<Slider
														value={
															formikProps.values.dispositionScoring.overApproving
														}
														name="dispositionScoring.overApproving"
														step={0.1}
														min={0.1}
														max={4.0}
														marks
														valueLabelDisplay="on"
														onChange={(e, value) => {
															formikProps.setFieldValue('dispositionScoring.overApproving', value);
														}}
													/>
												</Grid>
											</Grid>
										)}


									</Box>
								</CardContent>
							</Card>

						</Box>
						<Box display="grid"
							gridTemplateColumns="1fr 2fr"
							gap="20px" alignItems='center'>
							<Card variant="outlined" style={{ height: "100%" }}>
								<CardHeader
									title="Curfew"
									titleTypographyProps={{
										variant: "body2",
										color: "textSecondary",
									}}
								/>
								<CardContent>
									<Box display="grid" gap="20px" >
										<Box display="grid"
											gridTemplateColumns="1fr 1fr"
											gap="20px" alignItems='center'>
											<Box>
												<TextField
													sx={{ width: "100%" }}
													color="primary"
													label="Start Time"
													name="startTime"
													type="time"
													required
													value={formikProps.values.startTime}
													onChange={formikProps.handleChange}
													error={formikProps.errors.startTime}
													helperText={
														(formikProps.touched.startTime ||
															formikProps.touched.endTime) &&
														formikProps.errors.startTime
													}
												/>
											</Box>
											<Box>
												<TextField
													sx={{ width: "100%" }}
													color="primary"
													label="End Time"
													name="endTime"
													type="time"
													required
													value={formikProps.values.endTime}
													onChange={formikProps.handleChange}
													error={formikProps.errors.startTime}
													helperText={
														(formikProps.touched.startTime ||
															formikProps.touched.endTime) &&
														formikProps.errors.startTime
													}
												/>
											</Box>
										</Box>
										<Box>
											<TextField
												sx={{ width: "100%" }}
												color="primary"
												margin="dense"
												label="Buffer (minutes)"
												name="buffer"
												type="number"
												required
												InputProps={{
													inputProps: { min: 5, max: 55 },
												}}
												value={formikProps.values.buffer}
												onChange={formikProps.handleChange}
												onBlur={formikProps.handleBlur}
												error={
													formikProps.errors.buffer &&
													formikProps.touched.buffer
												}
												helperText={
													formikProps.touched?.buffer &&
													formikProps.errors.buffer
												}
											/>
										</Box>
									</Box>
								</CardContent>
							</Card>
							<Card variant="outlined" style={{ height: "100%" }}>
								<CardHeader
									title={<Grid
										container
										justifyContent="space-between"
										alignItems="center"
									>
										<Grid item>Call Windows</Grid>
										<Grid item>
											<Button
												sx={actionOneButtonStyle}
												onClick={() => {
													setOpenNewCallWindow(true);
													setCallWindowOrderNumber(
														callWindows.length
													);
												}}
											>
												+ New Window
											</Button>
										</Grid>
									</Grid>}
									titleTypographyProps={{
										variant: "body2",
										color: "textSecondary",
									}}
								/>
								<CardContent>
									<Box display="grid" gridTemplateColumns="1fr" alignItems="center">
										{callWindows.length < 1 && (
												<Typography variant="body1">
													No call windows specified
												</Typography>
										)}
										{callWindows.length > 0 && (
											<Box>
												<TableContainer>
													<Table>
														<TableHead>
															<TableRow>
																<TableCell>Default</TableCell>
																<TableCell align='center' >Query Field</TableCell>
																<TableCell align='center'>Days</TableCell>
																<TableCell align='center'>Begin Time</TableCell>
																<TableCell align='center'>End Time</TableCell>
																<TableCell align='center'>Time Zone</TableCell>
																<TableCell align='center'>Time Zone in DST</TableCell>
																<TableCell align='center'>Actions</TableCell>
															</TableRow>
														</TableHead>
														<TableBody>
															{callWindows?.map(
																(window, index) => (
																	<TableRow key={index}>
																		<TableCell align='center'>
																			{window.default ? (
																				<CheckCircleOutlineOutlined color="primary" />
																			) : (
																				""
																			)}
																		</TableCell>
																		<TableCell align='center'>
																			{window.callWindowQuery
																				? `${window.callWindowQuery.field} ${window.callWindowQuery.operator} ${window.callWindowQuery.value}`
																				: "-"}
																		</TableCell>
																		<TableCell align='center'>
																			{window.days.includes(0) && (
																				<ToggleButton
																					style={{
																						width: "1em",
																						height: "1em",
																					}}
																				>
																					SU.
																				</ToggleButton>
																			)}
																			{window.days.includes(1) && (
																				<ToggleButton
																					style={{
																						width: "1em",
																						height: "1em",
																					}}
																				>
																					MO.
																				</ToggleButton>
																			)}
																			{window.days.includes(2) && (
																				<ToggleButton
																					style={{
																						width: "1em",
																						height: "1em",
																					}}
																				>
																					TU.
																				</ToggleButton>
																			)}
																			{window.days.includes(3) && (
																				<ToggleButton
																					style={{
																						width: "1em",
																						height: "1em",
																					}}
																				>
																					WE.
																				</ToggleButton>
																			)}
																			{window.days.includes(4) && (
																				<ToggleButton
																					style={{
																						width: "1em",
																						height: "1em",
																					}}
																				>
																					TH.
																				</ToggleButton>
																			)}
																			{window.days.includes(5) && (
																				<ToggleButton
																					style={{
																						width: "1em",
																						height: "1em",
																					}}
																				>
																					FR.
																				</ToggleButton>
																			)}
																			{window.days.includes(6) && (
																				<ToggleButton
																					style={{
																						width: "1em",
																						height: "1em",
																					}}
																				>
																					SA.
																				</ToggleButton>
																			)}
																		</TableCell>
																		<TableCell align='center'>
																			{stringifyTimewindowTime(
																				window.beginTime
																			)}
																		</TableCell>
																		<TableCell align='center'>
																			{stringifyTimewindowTime(
																				window.endTime
																			)}
																		</TableCell>
																		<TableCell align='center'>
																			{window.tmz
																				? timezones.find(
																					(x) =>
																						x.offset ===
																						window.tmz.offset
																				).label
																				: "-"}
																		</TableCell>
																		<TableCell align='center'>
																			{window.tmz?.dst ? (
																				<CheckCircleOutlineOutlined color="primary" />
																			) : (
																				"-"
																			)}
																		</TableCell>
																		<TableCell align='center'>
																			<IconButton
																				onClick={() => onEditWindow(window)}
																			>
																				<EditOutlined color="primary" />
																			</IconButton>
																			<IconButton
																				onClick={() => onCloneWindow(window)}
																			>
																				<ControlPointDuplicateOutlined color="primary" />
																			</IconButton>
																			<IconButton
																				onClick={() => onDeleteWindow(window)}
																			>
																				<DeleteOutlineOutlined color="primary" />
																			</IconButton>
																		</TableCell>
																	</TableRow>
																)
															)}
														</TableBody>
													</Table>
												</TableContainer>
											</Box>
										)}
									</Box>
								</CardContent>
							</Card>
						</Box>

						<Box>
							<ProfileChannels
								channels={formikProps.values.channels}
								setFieldValue={formikProps.setFieldValue}
							/>
						</Box>
					</Box>

					<Dialog open={openNewCallWindow} maxWidth="lg" maxHeight="lg">
						<DialogTitle>
							<Box display="flex" justifyContent="space-between">
								<Typography variant="h6">
									Call Window
								</Typography>
								<Box display="flex" gap={2} alignItems="center">
										<FormControlLabel
											control={
												<Switch
													checked={newCallWindowDefaultFlag}
													onChange={() =>
														setNewCallWindowDefaultFlag(
															!newCallWindowDefaultFlag
														)
													}
													color="primary"
												/>
											}
											label="Default Call Window"
										/>
										<Tooltip title="Default call windows only apply to contacts that did NOT match a contact query call window.">
											<InfoOutlined color="primary" />
										</Tooltip>
									</Box>
							</Box>
						</DialogTitle>
						<DialogContent>
							<Box>
								<Box display="flex" flexDirection="column" gap={2}>

									{!newCallWindowDefaultFlag && (
										<Card variant="outlined">
											<CardHeader
												title="Contact Query"
												titleTypographyProps={{
													variant: "body2",
													color: "textSecondary",
												}}
											/>
											<CardContent>
												<Box display="grid" gridTemplateColumns="1fr 1fr 1fr" gap={2} alignItems="center">
												<FormControl>
															<InputLabel>Field Name</InputLabel>
															<Select
																label="Field Name"
																name="fieldName"
																value={newCallWindowQueryField}
																onChange={(e) =>
																	setNewCallWindowQueryField(e.target.value)
																}
															>
																{userContext.queryFields.list?.map(
																	(field, index) => (
																		<MenuItem
																			key={index}
																			value={field.name}
																		>
																			{field.label}
																		</MenuItem>
																	)
																)}
															</Select>
														</FormControl>
														<FormControl
															color="primary"
															style={{ minWidth: "150px" }}
														>
															<InputLabel>Operation</InputLabel>
															<Select
																label="Operation"
																value={newCallWindowQueryOperator}
																onChange={(e) =>
																	setNewCallWindowQueryOperator(
																		e.target.value
																	)
																}
															>
																{queryOperations?.map((op) => (
																	<MenuItem key={op.op} value={op.op}>
																		{op.display}
																	</MenuItem>
																))}
															</Select>
														</FormControl>
														<TextField
															label="Value"
															variant="outlined"
															color="primary"
															value={newCallWindowQueryValue}
															onChange={(e) =>
																setNewCallWindowQueryValue(e.target.value)
															}
														/>
												</Box>
											</CardContent>
										</Card>
									)}

									<Card variant="outlined">
										<CardHeader
											title="Call Window"
											titleTypographyProps={{
												variant: "body2",
												color: "textSecondary",
											}}
										/>
										<CardContent>
											<Grid item container spacing={2} alignItems="center">
												<Grid item>
													<ToggleButtonGroup
														value={newCallWindowDays}
														onChange={(e, days) =>
															setNewCallWindowDays(days)
														}
													>
														<ToggleButton value={0}>SU.</ToggleButton>
														<ToggleButton value={1}>MO.</ToggleButton>
														<ToggleButton value={2}>TU.</ToggleButton>
														<ToggleButton value={3}>WE.</ToggleButton>
														<ToggleButton value={4}>TH.</ToggleButton>
														<ToggleButton value={5}>FR.</ToggleButton>
														<ToggleButton value={6}>SA.</ToggleButton>
													</ToggleButtonGroup>
												</Grid>
												<Grid item>
													<TextField
														label="Begin Time"
														variant="outlined"
														type="time"
														value={newCallWindowBeginTime}
														onChange={(e) =>
															setNewCallWindowBeginTime(e.target.value)
														}
													/>
												</Grid>
												<Grid item>
													<TextField
														label="End Time"
														variant="outlined"
														type="time"
														value={newCallWindowEndTime}
														onChange={(e) =>
															setNewCallWindowEndTime(e.target.value)
														}
													/>
												</Grid>
											</Grid>
										</CardContent>
									</Card>
									<Card variant="outlined">
										<CardHeader
											title="Time Zone"
											titleTypographyProps={{
												variant: "body2",
												color: "textSecondary",
											}}
										/>
										<CardContent>
											<Box display="flex" gap={2} alignItems="center">
													<Tooltip title="Call Windows will be checked using this timezone if selected. If no timezone is selected, the contacts calculated boundries will be used.">
														<InfoOutlined color="primary" />
													</Tooltip>
													<FormControl variant="outlined">
														<InputLabel>Time Zone</InputLabel>
														<Select
															label="Time Zone"
															name="callWindow.tmz.offset"
															style={{ minWidth: "150px" }}
															value={newCallWindowTmzOffset}
															onChange={(e) =>
																setNewCallWindowTmzOffset(e.target.value)
															}
														>
															{timezones?.map((tmz, index) => (
																<MenuItem key={index} value={tmz.offset}>
																	{tmz.label}
																</MenuItem>
															))}
														</Select>
													</FormControl>
													<FormControlLabel
														control={
															<Checkbox
																checked={newCallWindowTmzDst}
																onChange={() =>
																	setNewCallWindowTmzDst(
																		!newCallWindowTmzDst
																	)
																}
																color="primary"
															/>
														}
														label="Daylight Savings Time?"
													/>
											</Box>
										</CardContent>
									</Card>
								</Box>
							</Box>
						</DialogContent>
						<DialogActions>
							<Button
								sx={actionTwoButtonStyle}
								variant="outlined"
								color="primary"
								onClick={() => setOpenNewCallWindow(false)}
							>
								Close
							</Button>
							<Button
								sx={actionOneButtonStyle}
								variant="contained"
								color="primary"
								onClick={saveCallWindow(formikProps)}
							>
								Save
							</Button>
						</DialogActions>
					</Dialog>
				</form>
			)
			}
		</Formik >
	);
}

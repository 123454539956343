import { useContext, useEffect, useState } from "react";
import { Formik } from "formik";
import { enqueueSnackbar } from "notistack";
import { Button, Card, CardContent, CardHeader, TextField, Select, MenuItem, InputLabel, CardActions, FormControl, Typography, CircularProgress, Box, useMediaQuery, FormHelperText, Tooltip } from "@mui/material";
import { getTenantSettings } from "src/graphql/queries";
import { UserContext } from "src/contexts/UserContext";
import { generateClient, get, post } from "aws-amplify/api";
import { cardStyle } from "src/theme";
import { webexCCValidation, webexConnectValidation } from "../yupValidations";
import { fetchAuthSession } from "aws-amplify/auth";
import awsmobile from "src/aws-exports";

const WEBEX_CONTACT_CENTER_REGIONS = {
	us1: 'USA (US1)',
	anz1: 'ANZ',
	eu1: 'UK',
	eu2: 'EU (Frankfurt)',
	jp1: 'Japan',
	ca1: 'Canada',
	sg1: 'Singapore'
};

// New endpoints. Do we need to support old as well?
// https://developers.imiconnect.io/reference/endpoints
const WEBEX_CONNECT_REGIONS = {
	us: 'AWS Oregon (US)',
	us1: 'Azure USA (US1)',
};

export function Webex() {
	const client = generateClient();
	const useSingleColumn = useMediaQuery('(max-width:845px)');

	const userContext = useContext(UserContext);

	const [webexSettings, setWebexSettings] = useState({
		contactCenter: {
			region: 'us1',
			organizationId: '',
			clientId: '',
			clientSecret: '',
		},
		connect: {
			region: '',
			serviceKey: '',
			outboundDialNumber: '',
		}
	});

	const [contactCenterDialNumbers, setContactCenterDialNumbers] = useState([]);
	const [wrapUpCodes, setWrapUpCodes] = useState([]);

	const [testingContactCenterConnection, setTestingContactCenterConnection] = useState(false);
	const [contactCenterConnectionSucceeded, setContactCenterConnectionSucceeded] = useState(false);
	const [savingContactCenterValues, setSavingContactCenterValues] = useState(false);
	const [contactCenterTokens, setContactCenterTokens] = useState({});

	const [testingConnectConnection, setTestingConnectConnection] = useState(false);
	const [connectConnectionSucceeded, setConnectConnectionSucceeded] = useState(false);
	const [savingConnectValues, setSavingConnectValues] = useState(false);

	const [loginWindow, setLoginWindow] = useState(null);
	const [redirectUri, setRedirectUri] = useState('');

	// Loads the saved values when the component is first rendered.
	useEffect(() => {
		async function getData() {
			try {
				const graphqlResponse = await client.graphql({
					query: getTenantSettings,
					variables: { id: userContext.tenantId }
				});
				const tenantSettings = graphqlResponse.data.getTenantSettings;
				if (tenantSettings.telephoneProviderInfo && tenantSettings.telephoneProviderInfo.webex) {
					setWebexSettings((previousWebexSettings) => {
						return {
							contactCenter: tenantSettings.telephoneProviderInfo.webex.contactCenter ?? previousWebexSettings.contactCenter,
							connect: tenantSettings.telephoneProviderInfo.webex.connect ?? previousWebexSettings.connect,
						};
					});

					// Get Contact Center options.
					if (tenantSettings.telephoneProviderInfo.webex.contactCenter && tenantSettings.telephoneProviderInfo.webex.contactCenter.clientSecret) {
						await getWrapUpCodes();
						await getContactCenterDialNumbers();
					}
				}
			} catch (error) {
				console.error('Error loading Webex tenant settings', error);
				enqueueSnackbar('An unexpected error occurred while loading your Webex settings. Please try again later.', {
					variant: 'error',
					autoHideDuration: 5000
				});
			}
		}
		if (userContext.telephonyProvider === 'Webex') {
			getData();
		}
	}, [userContext.telephonyProvider]);


	// When a login window is opened, continually check for an auth code.
	useEffect(() => {
		// Nothing to do if there's no login window.
		if (!loginWindow) {
			return;
		}

		// Continually check for authorization code.
		const timer = setInterval(async () => {

			// Nothing to do once the login window is closed.
			if (!loginWindow) {
				timer && clearInterval(timer);
				return;
			}

			// Check for the authorization code.
			let currentUrl;
			try {
				currentUrl = loginWindow.location.href;
			} catch (error) { }

			if (!currentUrl) {
				return;
			}
			const searchParams = new URL(currentUrl).searchParams;
			const error = searchParams.get('error');

			if (error) {
				const errorDescription = searchParams.get('error_description');
				console.error('Error authenticating with Webex:', error);
				enqueueSnackbar(`Webex Contact Center Error: ${errorDescription}`, {
					variant: 'error',
					autoHideDuration: 10000
				});
				loginWindow.close();
				clearInterval(timer);
				setLoginWindow(null);
			}

			const authorizationCode = searchParams.get('code');
			if (!authorizationCode) {
				return;
			}
			loginWindow.close();

			testContactCenterConnection(authorizationCode, webexSettings.contactCenter);
			clearInterval(timer);
			setLoginWindow(null);
		}, 500);
	}, [loginWindow]);

	/**
	 * Tests the connection to Webex Contact Center.
	 *
	 * @param {string} authorizationCode The authorization code returned from the Webex login window.
	 * @param {object} authSettings The Webex authentication settings.
	 */
	async function testContactCenterConnection(authorizationCode, authSettings) {
		setTestingContactCenterConnection(true);
		try {
			const testResponse = await post({
				apiName: 'cdyxoutreach',
				path: '/cci/webex/contact-center/test-connection',
				options: {
					body: {
						authorizationCode,
						...authSettings,
						redirectUrl: redirectUri,
					},
					headers: {
						Authorization: `Bearer ${(await fetchAuthSession()).tokens.idToken}`,
						'x-api-key': userContext.apiKey,
						'Content-Type': 'application/json',
					}
				}
			}).response;
			const responseJson = await testResponse.body.json();
			setContactCenterTokens(responseJson.tokens);
			setContactCenterDialNumbers(responseJson.dialNumbers);
			setWrapUpCodes(responseJson.wrapUpCodes);
			setContactCenterConnectionSucceeded(true);
			enqueueSnackbar('Successfully authenticated with Webex Contact Center. Please provide and save your configuration values.', {
				variant: 'success',
				autoHideDuration: 5000,
			});
		} catch (connectionTestError) {
			console.error('Error authenticating with Webex:', connectionTestError);
			setContactCenterConnectionSucceeded(false);
			enqueueSnackbar('Unable to authenticate with Webex Contact Center. Please check your connection settings.', {
				variant: 'error',
				autoHideDuration: 5000
			});
		}
		setTestingContactCenterConnection(false);
	}

	/**
	 * Saves the entered settings for Webex Contact Center.
	 *
	 * @param {object} contactCenterSettings The Webex Contact Center settings.
	 */
	async function saveContactCenterSettings(contactCenterSettings) {
		setSavingContactCenterValues(true);
		try {
			await post({
				apiName: 'cdyxoutreach',
				path: '/cci/webex/contact-center/save-connection',
				options: {
					body: {
						tokens: contactCenterTokens,
						contactCenterSettings,
						apiEndpoint: awsmobile.aws_cloud_logic_custom.find((item) => item.name === 'cdyxoutreach')?.endpoint
					},
					headers: {
						Authorization: `Bearer ${(await fetchAuthSession()).tokens.idToken}`,
						'x-api-key': userContext.apiKey,
						'Content-Type': 'application/json',
					}
				}
			}).response;
			enqueueSnackbar('Your Webex Contact Center settings have been saved.', {
				variant: 'success',
				autoHideDuration: 5000,
			});
		} catch (error) {
			enqueueSnackbar('An unexpected error occurred while saving your Contact Center settings. Please make a note of your values and try again later.', {
				variant: 'error',
				autoHideDuration: 5000
			});
		}
		setSavingContactCenterValues(false);
	}

	/**
	 * Tests the connection to Webex Connect.
	 *
	 * @param {object} authSettings The Webex authentication settings.
	 */
	async function testConnectConnection(authSettings) {
		setTestingConnectConnection(true);
		try {
			await post({
				apiName: 'cdyxoutreach', path: '/cci/webex/connect/test-connection',
				options: {
					body: {
						...authSettings
					},
					headers: {
						Authorization: `Bearer ${(await fetchAuthSession()).tokens.idToken}`,
						'x-api-key': userContext.apiKey,
						'Content-Type': 'application/json',
					}
				}
			}).response;
			setConnectConnectionSucceeded(true);
			enqueueSnackbar('Successfully authenticated with Webex Connect.', {
				variant: 'success',
				autoHideDuration: 5000,

			});
		} catch (connectionTestError) {
			console.error('Error authenticating with Webex:', connectionTestError);
			setConnectConnectionSucceeded(false);
			enqueueSnackbar('Unable to authenticate with Webex Connect. Please check your connection settings.', {
				variant: 'error',
				autoHideDuration: 5000
			});
		}
		setTestingConnectConnection(false);
	}

	/**
	 * Saves the entered settings for Webex Connect.
	 */
	async function saveConnectSettings(connectSettings) {
		setSavingConnectValues(true);
		try {
			await post({
				apiName: 'cdyxoutreach',
				path: '/cci/webex/connect/save-connection',
				options: {
					body: {
						connectSettings,
					},
					headers: {
						Authorization: `Bearer ${(await fetchAuthSession()).tokens.idToken}`,
						'x-api-key': userContext.apiKey,
						'Content-Type': 'application/json',
					}
				}
			}).response;
			enqueueSnackbar('Your Webex Connect settings have been saved.', {
				variant: 'success',
				autoHideDuration: 5000,
			});
		} catch (error) {
			enqueueSnackbar('An unexpected error occurred while saving your Connect settings. Please make a note of your values and try again later.', {
				variant: 'error',
				autoHideDuration: 5000
			});
		}
		setSavingConnectValues(false);
	}

	/**
	 * Gets a list of wrap up codes for the tenant.
	 */
	async function getWrapUpCodes() {
		const response = await get({
			apiName: 'cdyxoutreach',
			path: '/cci/webex/contact-center/wrap-up-codes',
			options: {
				headers: {
					Authorization: `Bearer ${(await fetchAuthSession()).tokens.idToken}`,
					'x-api-key': userContext.apiKey,
					'Content-Type': 'application/json',
				}
			}
		}).response;
		const wrapUpCodes = await response.body.json();
		setWrapUpCodes(wrapUpCodes);
	}

	/**
	 * Gets a list of Contact Center dial numbers for the tenant.
	 */
	async function getContactCenterDialNumbers() {
		const response = await get({
			apiName: 'cdyxoutreach',
			path: '/cci/webex/contact-center/dial-numbers',
			options: {
				headers: {
					Authorization: `Bearer ${(await fetchAuthSession()).tokens.idToken}`,
					'x-api-key': userContext.apiKey,
					'Content-Type': 'application/json',
				}
			}
		}).response;
		const dialNumbers = await response.body.json();
		setContactCenterDialNumbers(dialNumbers);
	}

	/**
	 * Opens a new login window popup to prompt the user to sign into their Webex Contact Center account.
	 *
	 * @param {object} values The Webex authentication settings.
	 */
	function openLoginWindow(values) {
		// If there is already a login window open, close it.
		if (loginWindow) {
			loginWindow.close();
		}
		setWebexSettings(existingValues => {
			return {
				...existingValues,
				contactCenter: values,
			};
		});

		const popWidth = 500;
		const popHeight = 400;
		const left = window.screenX + (window.outerWidth - popWidth) / 2;
		const top = window.screenY + (window.outerHeight - popHeight) / 2.5;
		const title = `Webex Contact Center Auth`;

		const currentUrl = new URL(window.location.href);
		const redirectUrl = `${currentUrl.protocol}//${currentUrl.host}/callback`;
		const redirectUri = encodeURIComponent(redirectUrl);
		setRedirectUri(redirectUrl);
		const clientId = webexSettings.contactCenter.clientId;
		const scope = 'cloud-contact-center:pod_conv spark:kms cjp:user spark:people_read cjp:config_write cjp:config cjp:config_read cjds:admin_org_read cjds:admin_org_write';
		const encodedScope = encodeURIComponent(scope);
		const url = `https://webexapis.com/v1/authorize?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}&scope=${encodedScope}&state=set_state_here`;
		const popup = window.open(url, title, `width=${popWidth},height=${popHeight},left=${left},top=${top}`);
		setLoginWindow(popup);
	}

	return (
		<>
			{/* Webex Contact Center */}
			<Formik
				initialValues={webexSettings.contactCenter}
				enableReinitialize={true}
				validationSchema={webexCCValidation}
				onSubmit={values => {
					saveContactCenterSettings(values);
				}}
			>
				{formikProps => (
					<Card
						style={cardStyle}
						sx={{ marginBottom: '24px' }}
						elevation={0}
					>
						<CardHeader title='Webex Contact Center' />

						<CardContent>
							<Typography variant="body2" gutterBottom>Voice channel settings required for handling answered outbound calls.</Typography>

							<Typography variant="overline">Authentication</Typography>
							<form key="webex" onSubmit={formikProps.handleSubmit}>

								<Box
									display="grid"
									gridTemplateColumns={useSingleColumn ? "1fr" : "1fr 1fr"}
									gap="20px"
								>

									{/* Region */}
									<FormControl required>
										<InputLabel id="cc-region-label" htmlFor="cc-region" color="primary">Region</InputLabel>
										<Select
											value={formikProps.values.region}
											inputProps={{ id: 'cc-region' }}
											name="region"
											label="Region"
											labelId="cc-region-label"
											autoComplete="off"
											color="primary"
											onBlur={formikProps.handleBlur}
											onChange={formikProps.handleChange}
										>
											{Object.keys(WEBEX_CONTACT_CENTER_REGIONS).map((regionKey) => (
												<MenuItem key={regionKey} value={regionKey}>{WEBEX_CONTACT_CENTER_REGIONS[regionKey]}</MenuItem>
											))}
										</Select>
										<FormHelperText>
											{
												formikProps.errors.region &&
													formikProps.touched.region ? formikProps.errors.region :
													'The region where your Webex Contact Center is hosted'
											}
										</FormHelperText>
									</FormControl>

									{/* Organization ID */}
									<TextField
										color="primary"
										name="organizationId"
										label="Organization ID"
										type="text"
										onBlur={formikProps.handleBlur}
										onChange={formikProps.handleChange}
										value={formikProps.values.organizationId}
										error={formikProps.errors.organizationId && formikProps.touched.organizationId}
										helperText={
											formikProps.errors.organizationId &&
												formikProps.touched.organizationId ? formikProps.errors.organizationId :
												'The ID of your Webex Contact Center organization'
										}
										required
									/>

									{/* Client ID */}
									<TextField
										color="primary"
										name="clientId"
										label="Client ID"
										type="text"
										onBlur={formikProps.handleBlur}
										onChange={formikProps.handleChange}
										value={formikProps.values.clientId}
										error={formikProps.errors.clientId && formikProps.touched.clientId}
										helperText={
											formikProps.errors.clientId &&
												formikProps.touched.clientId ? formikProps.errors.clientId :
												'The client ID of your Webex Contact Center application'
										}
										required
									/>

									{/* Client Secret */}
									<TextField
										color="primary"
										name="clientSecret"
										label="Client Secret"
										type="password"
										autoComplete="off"
										onBlur={formikProps.handleBlur}
										onChange={formikProps.handleChange}
										value={formikProps.values.clientSecret}
										error={formikProps.errors.clientSecret && formikProps.touched.clientSecret}
										helperText={
											formikProps.errors.clientSecret &&
												formikProps.touched.clientSecret ? formikProps.errors.clientSecret :
												'The client secret of your Webex Contact Center application'
										}
										required
									/>
								</Box>
							</form>
						</CardContent>
						<CardActions>
							<Box
								width="100%"
								display="flex"
								flexDirection="row"
								justifyContent="end"
								alignItems="center"
							>
								{testingContactCenterConnection &&
									<CircularProgress variant="indeterminate" color="primary" size="25px" />
								}
								<Button size="small"
									onClick={() => openLoginWindow(formikProps.values)}
									disabled={formikProps.values.region === '' || formikProps.values.organizationId === '' || formikProps.values.clientId === '' || formikProps.values.clientSecret === '' || testingContactCenterConnection || savingContactCenterValues}
								>
									{testingContactCenterConnection ? 'Testing Connection...' : 'Test Connection'}
								</Button>
								{savingContactCenterValues &&
									<CircularProgress variant="indeterminate" color="primary" size="25px" />
								}
								<Button size="small" disabled={!formikProps.isValid || testingContactCenterConnection || savingContactCenterValues || !contactCenterConnectionSucceeded} type="submit" color="primary" onClick={formikProps.handleSubmit}>
									{savingContactCenterValues ? 'Saving...' : 'Save'}
								</Button>
							</Box>
						</CardActions>
					</Card>
				)}
			</Formik>

			{/* Webex Connect */}
			<Formik
				enableReinitialize={true}
				initialValues={webexSettings.connect}
				onSubmit={values => {
					saveConnectSettings(values);
				}}
				validationSchema={webexConnectValidation}
			>
				{formikProps => (
					<Card
						style={cardStyle}
						elevation={0}
					>
						<CardHeader title='Webex Connect' />
						<CardContent>
							<form key="webex" onSubmit={formikProps.handleSubmit}>
								<Typography variant="body2" gutterBottom>Omnichannel settings required for outbound SMS and email.</Typography>
								<Typography variant="overline">Authentication</Typography>

								<Box
									display="grid"
									gridTemplateColumns={useSingleColumn ? "1fr" : "1fr 1fr"}
									gap="20px"
								>

									{/* Region */}
									<FormControl required>
										<InputLabel id="connect-region-label" htmlFor="connect-region" color="primary">Region</InputLabel>
										<Select
											value={formikProps.values.region}
											inputProps={{ id: 'connect-region' }}
											name="region"
											autoComplete="off"
											color="primary"
											onBlur={formikProps.handleBlur}
											onChange={formikProps.handleChange}
											label="Region"
										>
											{Object.keys(WEBEX_CONNECT_REGIONS).map((regionKey) => (
												<MenuItem id={regionKey} key={regionKey} value={regionKey}>{WEBEX_CONNECT_REGIONS[regionKey]}</MenuItem>
											))}
										</Select>
										<FormHelperText>
											{
												formikProps.errors.region &&
													formikProps.touched.region ? formikProps.errors.region :
													'The region where your Webex Connect instance is hosted'
											}
										</FormHelperText>
									</FormControl>

									{/* Service Key */}
									<TextField
										color="primary"
										name="serviceKey"
										label="Service Key"
										type="text"
										required
										onBlur={formikProps.handleBlur}
										onChange={formikProps.handleChange}
										value={formikProps.values.serviceKey}
										error={formikProps.errors.serviceKey && formikProps.touched.serviceKey}
										helperText={
											formikProps.errors.serviceKey &&
												formikProps.touched.serviceKey ? formikProps.errors.serviceKey :
												'The service key for your Webex Connect instance'
										}
									/>
								</Box>
							</form>
						</CardContent>
						<CardActions>
							<Box
								width="100%"
								display="flex"
								flexDirection="row"
								justifyContent="end"
								alignItems="center"
							>
								{(testingConnectConnection || savingConnectValues) &&
									<CircularProgress variant="indeterminate" color="primary" size="25px" />
								}
								<Button size="small"
									onClick={() => testConnectConnection(formikProps.values)}
									disabled={!formikProps.isValid || testingConnectConnection || savingConnectValues}
								>
									{testingConnectConnection ? 'Testing Connection...' : 'Test Connection'}
								</Button>
								<Button size="small" disabled={!formikProps.isValid || !connectConnectionSucceeded || testingConnectConnection || savingConnectValues} type="submit" color="primary" onClick={formikProps.handleSubmit}>
									{savingConnectValues ? 'Saving...' : 'Save'}
								</Button>
							</Box>
						</CardActions>
					</Card>
				)}
			</Formik>
		</>
	);
}

import { useState, useContext, useRef } from 'react';
import {
	Grid, TextField, FormControl, Tabs, Tab, Select, MenuItem, IconButton,
	Divider, Tooltip, FormControlLabel, Switch, Menu, InputLabel, TableContainer, Table, TableHead,
	TableBody, TableRow, TableCell, Button, Typography
} from "@mui/material";
import { UserContext } from '../../../contexts/UserContext';
import { FieldArray } from 'formik';
import { AddCircleOutlineOutlined, Clear, InfoOutlined, Person, Visibility, VisibilityOff } from '@mui/icons-material';
import PostmanTextField from './postmanTextField';
import JSONPretty from 'react-json-pretty';
import 'react-json-pretty/themes/monikai.css';
import { AuthTypes } from '../../../models/suppressionAuthTypes';
// import ReactJson from 'react-json-view';
import { SuppressionErrorDefaults } from "../../../models/suppressionResults";

export const methods = {
	POST: "POST",
	GET: "GET"
};
export const responseTypes = {
	Boolean: "Boolean",
	Number: "Number",
	String: "String"
};
export const stringTransformations = {
	ToUpper: {
		name: "ToUpper",
		display: "To uppercase",
		transform: value => value.toUpperCase()
	},
	ToLower: {
		name: "ToLower",
		display: "To lowercase",
		transform: value => value.toLowerCase()
	},
	None: {
		name: "None",
		display: "None",
		transform: value => value
	}
};

const exampleContact = {
	"zip": "14472",
	"customFields": {
		"balance": 656
	},
	"lastName": "Cooper",
	"createdAt": "2021-06-10T20:10:38.270Z",
	"source": "CDYX",
	"updatedBy": "joseph.cooper@what.com",
	"firstName": "Joseph",
	"state": "NY",
	"city": "Honeoy Falls",
	"timeZoneBounds": {
		"max": {
			"offset": "-7",
			"dst": true
		},
		"min": {
			"offset": "-5",
			"dst": true
		}
	},
	"externalId": "13406",
	"updatedAt": "2021-06-24T18:01:03.117Z",
	"phone": "9990370004",
	"cell": "9990370008"
};


// This UI was eyeballed off of Postman 😛
export default function RestService(props) {
	const { formikProps, logs, setLogs } = props;
	const [viewPassword, setViewPassword] = useState(false);
	const [selectedTab, setSelectedTab] = useState(0);
	const userContext = useContext(UserContext);
	const contactAttributePlaceholders = [
		{ displayName: 'First Name', name: 'firstName' },
		{ displayName: 'Last Name', name: 'lastName' },
		{ displayName: 'Source', name: 'source' },
		{ displayName: 'Phone', name: 'phone' },
		{ displayName: 'Cell', name: 'cell' },
		{ displayName: 'Email', name: 'email' },
		{ displayName: 'City', name: 'city' },
		{ displayName: 'State', name: 'state' },
		{ displayName: 'Zip', name: 'zip' },
		...userContext.customFields
	];

	function handleAuthTypeChange(e, formikProps) {
		formikProps.handleChange(e);

		switch (e.target.value) {
			case AuthTypes[1]:
				formikProps.setFieldValue('restSuppression.auth.info', { ...formikProps.values.restSuppression.auth.info, key: 'x-api-key', value: '', addTo: 'header' });
				break;
			case AuthTypes[2]:
				formikProps.setFieldValue('restSuppression.auth.info', { ...formikProps.values.restSuppression.auth.info, token: '' });
				break;
			case AuthTypes[3]:
				formikProps.setFieldValue('restSuppression.auth.info', { ...formikProps.values.restSuppression.auth.info, username: '', password: '' });
				break;

			default:
				break;
		}
	}

	function handleResponseTypeChange(e, formikProps) {
		formikProps.handleChange(e);

		switch (e.target.value) {
			case responseTypes.Boolean:
				formikProps.setFieldValue('restSuppression.response.value.value', true);
				break;
			case responseTypes.Number:
				formikProps.setFieldValue('restSuppression.response.value.value', 0);
				break;
			case responseTypes.String:
				formikProps.setFieldValue('restSuppression.response.value.value', '', false);
				break;

			default:
				break;
		}
	}

	return (
		<Grid container spacing={2} direction='column'>
			<Grid item>
				<FormControlLabel
					label='Enabled'
					control={<Switch
						checked={formikProps.values.restSuppressionEnabled}
						onChange={formikProps.handleChange}
						color='primary'
						name='restSuppressionEnabled' />}
				/>
			</Grid>
			<Grid item>
				<Grid container direction='row'>
					<Grid item>
						<FormControl>
							<Select
								variant='outlined'
								style={{ borderRadius: '4px 0px 0px 4px', width: '100px' }}
								SelectDisplayProps={{
									style: {
										borderRadius: '4px 0px 0px 4px'
									}
								}}
								value={formikProps.values.restSuppression.method}
								onChange={formikProps.handleChange}
								onBlur={formikProps.handleBlur}
								name='restSuppression.method'
							>
								{Object.keys(methods).map((method, index) =>
									<MenuItem key={index} value={method}>{method}</MenuItem>)}
							</Select>
						</FormControl>
					</Grid>
					<Grid item style={{ flexGrow: 1 }}>
						<TextField
							variant='outlined'
							label='Endpoint'
							value={formikProps.values.restSuppression.endpoint}
							name='restSuppression.endpoint'
							onChange={formikProps.handleChange}
							onBlur={formikProps.handleBlur}
							error={formikProps.errors?.restSuppression?.endpoint && formikProps.touched?.restSuppression?.endpoint}
							helperText={formikProps.touched?.restSuppression?.endpoint && formikProps.errors?.restSuppression?.endpoint}
							InputProps={{
								style: {
									borderTopLeftRadius: '0px',
									borderBottomLeftRadius: '0px'
								}
							}}
						/>
					</Grid>
				</Grid>
			</Grid>
			<Grid item>
				<Tabs value={selectedTab} onChange={(_, newValue) => setSelectedTab(newValue)}>
					<Tab value={0} label='Authorization' />
					<Tab value={1} label='Headers' />
					<Tab value={4} label='Body' disabled={formikProps.values.restSuppression.method !== "POST"} />
					<Tab value={2} label='Params' />
					<Tab value={3} label='Settings' />
					<Tab value={5} label='Logs' />
				</Tabs>
				<Divider />
			</Grid>
			{selectedTab === 0 &&
				<Grid item>
					<Grid container spacing={4}>
						<Grid item>
							<FormControl variant='outlined'>
								<InputLabel id='auth-type-label'>Type</InputLabel>
								<Select
									defaultValue='None'
									labelId='auth-type-label'
									label='Type'
									onChange={e => handleAuthTypeChange(e, formikProps)}
									name='restSuppression.auth.type'
									value={formikProps.values.restSuppression.auth.type}
								>
									{AuthTypes.map((type, index) =>
										<MenuItem key={index} value={type}>{type.replace(/_/g, ' ')}</MenuItem>)}
								</Select>
							</FormControl>
						</Grid>
						{formikProps.values.restSuppression.auth.type !== AuthTypes[0] &&
							<Grid item>
								<Divider orientation='vertical' />
							</Grid>}
						{formikProps.values.restSuppression.auth.type === AuthTypes[1] &&
							<Grid item>
								<Grid container spacing={2}>
									<Grid item>
										<TextField
											label='Key'
											value={formikProps.values.restSuppression.auth.info.key ?? ''}
											onChange={e => formikProps.setFieldValue('restSuppression.auth.info', { ...formikProps.values.restSuppression.auth.info, key: e.target.value })}
										/>
									</Grid>
									<Grid item>
										<TextField
											label='Value'
											value={formikProps.values.restSuppression.auth.info.value ?? ''}
											onChange={e => formikProps.setFieldValue('restSuppression.auth.info', { ...formikProps.values.restSuppression.auth.info, value: e.target.value })}
										/>
									</Grid>
									<Grid item>
										<FormControl>
											<InputLabel id='add-to-label'>Add to</InputLabel>
											<Select
												defaultValue='header'
												labelId='add-to-label'
												label='Add to'
												onChange={e => formikProps.setFieldValue('restSuppression.auth.info', { ...formikProps.values.restSuppression.auth.info, addTo: e.target.value })}
												value={formikProps.values.restSuppression.auth.info.addTo ?? 'header'}
											>
												<MenuItem value='header'>Header</MenuItem>
												<MenuItem value='queryParams'>Query Params</MenuItem>
											</Select>
										</FormControl>
									</Grid>
								</Grid>
							</Grid>}
						{formikProps.values.restSuppression.auth.type === AuthTypes[2] &&
							<Grid item style={{ flexGrow: 1 }}>
								<TextField
									label='Token'
									style={{ width: '100%' }}
									onChange={e => formikProps.setFieldValue('restSuppression.auth.info', { token: e.target.value })}
									value={formikProps.values.restSuppression.auth.info.token ?? ''}
								/>
							</Grid>}
						{formikProps.values.restSuppression.auth.type === AuthTypes[3] &&
							<Grid item>
								<Grid container spacing={2}>
									<Grid item>
										<TextField
											label='Username'
											onChange={e => formikProps.setFieldValue('restSuppression.auth.info', { ...formikProps.values.restSuppression.auth.info, username: e.target.value })}
											InputProps={{ inputProps: { autoComplete: "new-password" } }}
											value={formikProps.values.restSuppression.auth.info.username ?? ''}
										/>
									</Grid>
									<Grid item>
										<Grid container alignItems='center' alignContent='center'>
											<Grid item>
												<TextField
													label='Password'
													type={viewPassword ? 'text' : 'password'}
													onChange={e => formikProps.setFieldValue('restSuppression.auth.info', { ...formikProps.values.restSuppression.auth.info, password: e.target.value })}
													InputProps={{ inputProps: { autoComplete: "new-password" } }}
													value={formikProps.values.restSuppression.auth.info.password ?? ''}
												/>
											</Grid>
											<Grid item>
												<Tooltip title={viewPassword ? 'Hide Password' : 'View Password'}>
													<IconButton onClick={() => setViewPassword(!viewPassword)}>
														{viewPassword &&
															<VisibilityOff color='primary' />}
														{!viewPassword &&
															<Visibility color='primary' />}
													</IconButton>
												</Tooltip>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>}
					</Grid>
				</Grid>}
			{selectedTab === 1 &&
				<Grid item>
					<Grid container spacing={2} direction='column'>
						<FieldArray
							validateOnChange={false}
							name='restSuppression.headers'
							render={helpers =>
								<>
									<Grid item>
										<Grid container spacing={2} alignItems='center'>
											<Grid item>
												<Typography variant="h6">Headers</Typography>
											</Grid>
											<Grid item>
												<Tooltip title='Add New Header'>
													<IconButton onClick={() => helpers.push({ key: '', value: '', description: '' })} size='small'>
														<AddCircleOutlineOutlined color='primary' />
													</IconButton>
												</Tooltip>
											</Grid>
										</Grid>
									</Grid>
									<Grid item>
										<KeyValueDescriptionFields formikProps={formikProps} propertyName='headers' helpers={helpers} contactAttributePlaceholders={contactAttributePlaceholders} userContext={userContext} />
									</Grid>
								</>}
						/>
					</Grid>
				</Grid>}
			{selectedTab === 2 &&
				<Grid item>
					<Grid container spacing={2} direction='column'>
						<FieldArray
							validateOnChange={false}
							name='restSuppression.params'
							render={helpers =>
								<>
									<Grid item>
										<Grid container spacing={2} alignItems='center'>
											<Grid item>
												<Typography variant="h6">Query Params</Typography>
											</Grid>
											<Grid item>
												<Tooltip title='Add New Query Param'>
													<IconButton onClick={() => helpers.push({ key: '', value: '', description: '' })} size='small'>
														<AddCircleOutlineOutlined color='primary' />
													</IconButton>
												</Tooltip>
											</Grid>
										</Grid>
									</Grid>
									<Grid item>
										<KeyValueDescriptionFields formikProps={formikProps} propertyName='params' helpers={helpers} contactAttributePlaceholders={contactAttributePlaceholders} userContext={userContext} />
									</Grid>
								</>}
						/>
					</Grid>
				</Grid>}
			{selectedTab === 3 &&
				<Grid item>
					<Grid container spacing={2} direction='column'>
						<Grid item>
							<Grid container alignItems='center'>
								<Grid item>
									<TextField
										label='Response key'
										onChange={formikProps.handleChange}
										onBlur={formikProps.handleBlur}
										value={formikProps.values.restSuppression?.response?.path}
										name='restSuppression.response.path'
										error={formikProps.errors?.restSuppression?.response?.path && formikProps.touched?.restSuppression?.response?.path}
										helperText={formikProps.touched?.restSuppression?.response?.path && formikProps.errors?.restSuppression?.response?.path}
									/>
								</Grid>
								<Grid item>
									<Tooltip title={<>Dot paths and array indexes are supported. The absence of this key in the response will be considered an error.</>}>
										<InfoOutlined color='primary' />
									</Tooltip>
								</Grid>
							</Grid>
						</Grid>
						<Grid item>
							<FormControl>
								<InputLabel id='suppress-on-label'>Response type</InputLabel>
								<Select name='restSuppression.response.type' onChange={e => handleResponseTypeChange(e, formikProps)} value={formikProps.values.restSuppression?.response?.type}>
									{Object.keys(responseTypes).map(x =>
										<MenuItem key={x} value={x}>{x}</MenuItem>)}
								</Select>
							</FormControl>
						</Grid>
						<Grid item>
							{function () {
								switch (formikProps.values.restSuppression?.response?.type) {
									case responseTypes.Boolean:
										return (
											<FormControl>
												<InputLabel id='suppress-on-label'>Response value</InputLabel>
												<Select name='restSuppression.response.value.value' onChange={formikProps.handleChange} value={formikProps.values.restSuppression?.response?.value?.value}>
													<MenuItem value={true}>True</MenuItem>
													<MenuItem value={false}>False</MenuItem>
												</Select>
											</FormControl>
										);
									case responseTypes.Number:
										return (
											<TextField
												label='Response value'
												onChange={formikProps.handleChange}
												onBlur={formikProps.handleBlur}
												value={formikProps.values.restSuppression?.response?.value?.value}
												name='restSuppression.response.value.value'
												type='number'
												error={formikProps.errors?.restSuppression?.response?.value?.value && formikProps.touched?.restSuppression?.response?.value?.value}
												helperText={formikProps.touched?.restSuppression?.response?.value?.value && formikProps.errors?.restSuppression?.response?.value?.value}
											/>
										);
									case responseTypes.String:
										return (
											<Grid container spacing={2} alignItems='center'>
												<Grid item>
													<TextField
														label='Response value'
														onChange={formikProps.handleChange}
														onBlur={formikProps.handleBlur}
														value={formikProps.values.restSuppression?.response?.value?.value}
														name='restSuppression.response.value.value'
														error={formikProps.errors?.restSuppression?.response?.value?.value && formikProps.touched?.restSuppression?.response?.value?.value}
														helperText={formikProps.touched?.restSuppression?.response?.value?.value && formikProps.errors?.restSuppression?.response?.value?.value}
													/>
												</Grid>
												<Grid item>
													<FormControl>
														<InputLabel>Response value transformation</InputLabel>
														<Select name='restSuppression.response.options.responseStringTransformation' onChange={formikProps.handleChange} value={formikProps.values.restSuppression?.response?.options?.responseStringTransformation}>
															{Object.keys(stringTransformations).map(transformation =>
																<MenuItem key={transformation} value={transformation}>{stringTransformations[transformation].display}</MenuItem>)}
														</Select>
													</FormControl>
												</Grid>
											</Grid>
										);
									default:
										return null;
								}
							}()}
						</Grid>
						<Grid item>
							<FormControlLabel
								label='Suppress on error'
								control={<Switch checked={formikProps.values.restSuppression.suppressOnError} onChange={formikProps.handleChange} name='restSuppression.suppressOnError' color='primary' />}
							/>
						</Grid>
						{formikProps.values.restSuppression.suppressOnError &&
							<Grid item>
								<FormControl>
									<InputLabel id='disposition-on-error-label'>Disposition On Error</InputLabel>
									<Select name='restSuppression.dispositionOnError' onChange={formikProps.handleChange} value={formikProps.values.restSuppression.dispositionOnError}>
										{SuppressionErrorDefaults.map((result, index) =>
											<MenuItem key={index} value={result.dynamo}>{result.display}</MenuItem>)}
									</Select>
								</FormControl>
							</Grid>}
					</Grid>
				</Grid>}
			{selectedTab === 4 && formikProps.values.restSuppression.method === "POST" &&
				<Grid item>
					<Grid container direction='column' spacing={2}>
						<Grid item>
							<Grid container spacing={2} alignContent='center'>
								<Grid item>
									<InfoOutlined color='primary' />
								</Grid>
								<Grid item>
									<Typography variant='body1'>The post body will contain all the contact info with the exception of the id and the tenant. Please see below for an example.</Typography>
								</Grid>
							</Grid>
						</Grid>
						<Grid item>
							<JSONPretty id="json-pretty" data={exampleContact} />
						</Grid>
					</Grid>
				</Grid>}
			{selectedTab === 5 &&
				<Grid item>
					{logs.length === 0 &&
						<Typography variant='h6'>No data to display</Typography>}
					{logs.length > 0 &&
						<>
							<Grid container direction='column' spacing={2}>
								<Grid item>
									<Grid container justifyContent='space-between'>
										<Grid item>
											<Grid container spacing={2} alignItems='center'>
												<Grid item>
													<InfoOutlined color='primary' />
												</Grid>
												<Grid item>
													<Typography variant='body1'>These logs are only for localized testing. They do not get stored anywhere once you leave this page.</Typography>
												</Grid>
											</Grid>
										</Grid>
										<Grid item>
											<Button style={{ marginTop: '16px' }} variant='contained' color='primary' onClick={() => setLogs([])}>Clear</Button>
										</Grid>
									</Grid>
								</Grid>
								<Grid item style={{ overflowY: 'auto', maxHeight: '600px', marginBottom: '15px' }}>
									<TableContainer>
										<Table>
											<TableHead>
												<TableRow>
													<TableCell>Timestamp</TableCell>
													<TableCell>Contact</TableCell>
													<TableCell>Result</TableCell>
													<TableCell>Reason</TableCell>
													<TableCell>Response/Error</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{logs.map((log, index) => {
													const clone = {};
													for (const key in log.data) {
														clone[key] = log.data[key];
													}
													log.data = clone;

													return (
														<TableRow key={index} style={{ verticalAlign: 'baseline' }}>
															<TableCell style={{ width: '10%' }}>{(new Date(log.timestamp)).toLocaleString()}</TableCell>
															<TableCell style={{ width: '10%' }}>{`${log.contact.firstName} ${log.contact.lastName}`}</TableCell>
															<TableCell style={{ width: '10%' }}>{log.result}</TableCell>
															<TableCell style={{ width: '20%' }}>{log.reason}</TableCell>
															{/* <TableCell style={{ width: '50%' }}><ReactJson id="json-pretty2" src={log.data} collapsed={true} theme="monokai" style={{ overflowWrap: 'anywhere' }} /></TableCell> */}
														</TableRow>
													)
												})}
											</TableBody>
										</Table>
									</TableContainer>
								</Grid>
							</Grid>
						</>}
				</Grid>}
		</Grid>
	);
}



function KeyValueDescriptionFields(props) {
	const { formikProps, propertyName, helpers, contactAttributePlaceholders, userContext } = props;

	const [openMenuAnchorIndex, setOpenMenuAnchorIndex] = useState(-1);
	const deleteButtonRefs = useRef({});
	const insertPlaceholderButtonRefs = useRef({});

	let names = []
	for (const customField of userContext.customFields) {
		names.push(customField.name);
	}

	function handleDeleteMouseEnter(index) {
		deleteButtonRefs.current[index].style.display = 'block';
	}

	function handleDeleteMouseLeave(index) {
		deleteButtonRefs.current[index].style.display = 'none';
	}

	function handleInsertPlaceholderMouseEnter(index) {
		insertPlaceholderButtonRefs.current[index].style.display = 'block';
	}

	function handleInsertPlaceholderMouseLeave(index) {
		insertPlaceholderButtonRefs.current[index].style.display = 'none';
	}

	function handleInsertPlaceholder(text) {
		if (names.includes(text)) {
			formikProps.setFieldValue(`restSuppression.${propertyName}[${openMenuAnchorIndex}].value`, `${formikProps.values.restSuppression[propertyName][openMenuAnchorIndex].value}{{custom:${text}}}`);
		} else {
			formikProps.setFieldValue(`restSuppression.${propertyName}[${openMenuAnchorIndex}].value`, `${formikProps.values.restSuppression[propertyName][openMenuAnchorIndex].value}{{${text}}}`);
		}
		setOpenMenuAnchorIndex(-1);
	}

	return (
		<div>
			<table style={{ width: '100%' }}>
				<thead>
					<tr>
						<td>Key</td>
						<td>Value</td>
						<td>Description</td>
					</tr>
				</thead>
				<tbody>
					{formikProps.values?.restSuppression && formikProps.values.restSuppression[propertyName].map((_, index) =>
						<tr key={index} onMouseEnter={() => handleDeleteMouseEnter(index)} onMouseLeave={() => handleDeleteMouseLeave(index)}>
							<td>
								<PostmanTextField
									value={formikProps.values.restSuppression[propertyName][index].key}
									name={`restSuppression.${propertyName}[${index}].key`}
									onChange={formikProps.handleChange}
									onBlur={formikProps.handleBlur}
									placeholder='Key'
								/>
							</td>
							<td onMouseEnter={() => handleInsertPlaceholderMouseEnter(index)} onMouseLeave={() => handleInsertPlaceholderMouseLeave(index)}>
								<div>
									<PostmanTextField
										value={formikProps.values.restSuppression[propertyName][index].value}
										name={`restSuppression.${propertyName}[${index}].value`}
										onChange={formikProps.handleChange}
										onBlur={formikProps.handleBlur}
										placeholder='Value'
									/>
									<div ref={ref => { insertPlaceholderButtonRefs.current[index] = ref; }} style={{ display: 'none' }}>
										<Tooltip title='Insert Placeholder' TransitionProps={{ timeout: 0 }} leaveTouchDelay={0} enterTouchDelay={0} enterDelay={0}>
											<IconButton size='small' onClick={() => setOpenMenuAnchorIndex(index)}>
												<Person fontSize='small' />
											</IconButton>
										</Tooltip>
									</div>
								</div>
							</td>
							<td>
								<div>
									<PostmanTextField
										value={formikProps.values.restSuppression[propertyName][index].description}
										name={`restSuppression.${propertyName}[${index}].description`}
										onChange={formikProps.handleChange}
										onBlur={formikProps.handleBlur}
										placeholder='Description'
									/>
									<div ref={ref => { deleteButtonRefs.current[index] = ref; }} style={{ display: 'none' }}>
										<IconButton size='small' onClick={() => { delete deleteButtonRefs.current[index]; delete insertPlaceholderButtonRefs.current[index]; helpers.remove(index); }}>
											<Clear fontSize='small' />
										</IconButton>
									</div>
								</div>
							</td>
						</tr>)}
				</tbody>
			</table>
			<Menu anchorEl={insertPlaceholderButtonRefs.current[openMenuAnchorIndex]} open={Boolean(insertPlaceholderButtonRefs.current[openMenuAnchorIndex])} onClose={() => setOpenMenuAnchorIndex(-1)}>
				{contactAttributePlaceholders.map((attribute, index) =>
					<MenuItem key={index} value={attribute.name} onClick={() => handleInsertPlaceholder(attribute.name)}>{attribute.displayName}</MenuItem>)}
			</Menu>
		</div>
	);
}

import { useContext, useEffect, useState } from 'react';
import { Card, CardHeader, CardContent, Grid, CircularProgress, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, FormControl, MenuItem, Select, Tooltip, IconButton, InputLabel, Typography } from '@mui/material';
// import { CenterToggleContainer, CenterToggleItem, CenterToggleChild } from 'react-center-toggle';
import _ from 'lodash';
import axios from 'axios';
import { AuthTypes } from '../../../models/suppressionAuthTypes';
import { ExploreOutlined, PlayCircleOutline, InfoOutlined } from '@mui/icons-material';
import evaluateDotPath from '../../../tools/evaluateDotPath';
import { responseTypes, stringTransformations } from './restService';
import DNCDotCom from '../../../models/dnc';
import { createActivityLog } from 'src/graphql/mutations';
import { UserContext } from '../../../contexts/UserContext';
import phone from 'phone';
import { IS_PROD } from '../../../models/globals';
import { generateClient } from 'aws-amplify/api';
import { activityLogSuppressionSearch, dncByList, expandedContactSearch, listSegments, pocMeta, segmentByTenant } from 'src/graphql/queries';
const moment = require('moment');

// These exist to normalize values between POC metadata and the filter expression.
const booleanFieldTransformer = value => !!value;
const transformers = {
	"poc.writtenConsent": value => {
		if (typeof value === 'string' && value.includes('now')) {
			const now = new Date();
			now.setDate(now.getDate() + (/\d+/.exec(value)[0] * (value[3] === '-' ? -1 : 1)));
			return now;
		}

		return new Date(value);
	},
	"poc.cell": booleanFieldTransformer,
	"poc.consent": booleanFieldTransformer,
	"poc.optOut": booleanFieldTransformer,
	"poc.preferred": booleanFieldTransformer,
	"poc.thirdParty": booleanFieldTransformer
};

export default function Tester(props) {
	const client = generateClient();
	const { formikProps, logs, setLogs, dncDotComSettings } = props;

	const userContext = useContext(UserContext);
	const [loadingSegments, setLoadingSegments] = useState(false);
	const [selectedSegment, setSelectedSegment] = useState('');
	const [loadingContacts, setLoadingContacts] = useState(false);
	const [contactTestMap, setContactTestMap] = useState({});
	const [availablePocFieldsToTest, setAvailablePocFieldsToTest] = useState([]);
	const [cadenceField, setCadenceField] = useState('phone');
	const [segments, setSegments] = useState([]);

	const contactTableColumns = [
		{ header: "Name", dynamo: "firstName|lastName" },
		{ header: "Source", dynamo: "source " },
		{ header: "Phone", dynamo: "phone" },
		{ header: "Cell", dynamo: "cell" },
		{ header: "Email", dynamo: "email" },
		{ header: "Address", dynamo: "address1" },
		{ header: "City", dynamo: "city" },
		{ header: "State", dynamo: "state" },
		{ header: "Zipcode", dynamo: "zip" },
	];

	useEffect(() => {
		setAvailablePocFieldsToTest([
			"phone",
			"cell",
			"email",
			...userContext.customFields.filter(x => x.isPoc).map(x => x.name)
		]);
		if (userContext.tenantId) {
			getSegments();
		}
	}, [userContext]);

	useEffect(() => {
		async function getContacts() {
			setLoadingContacts(true);
			for (const key in contactTestMap) delete contactTestMap[key];
			const segment = segments.find(x => x.id === selectedSegment);
			const contacts = await client.graphql({
				query: expandedContactSearch,
				variables: {
					filter: segment.query.searchFilter,
					limit: 10
				}
			});

			if (contacts?.data?.expandedContactSearch?.items) {
				for (const contact of contacts.data.expandedContactSearch.items) {
					contactTestMap[contact.id] = contact;
				}

				setContactTestMap({ ...contactTestMap });
			}

			setLoadingContacts(false);
		}

		if (selectedSegment) {
			getContacts();
		}

	}, [selectedSegment]);

	useEffect(() => {
		clearContactsTested();

	}, [formikProps.dirty]);

	async function getSegments() {
		setLoadingSegments(true);

		const savedSegments = await client.graphql({
			query: segmentByTenant,
			variables: { tenant: userContext.tenantId },
		});
		if (savedSegments && savedSegments.data) {
			setSegments(
				savedSegments.data.segmentByTenant.items.sort(
					(a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt),
				),
			);
		}

		setLoadingSegments(false);
	}


	function clearContactsTested() {
		for (const id in contactTestMap) {
			if (contactTestMap[id]?.suppression) {
				contactTestMap[id].suppression.activityLog.message = "Not Tested";
				contactTestMap[id].suppression.activityLog.class = "notTested";

				contactTestMap[id].suppression.poc.message = "Not Tested";
				contactTestMap[id].suppression.poc.class = "notTested";

				contactTestMap[id].suppression.rest.message = "Not Tested";
				contactTestMap[id].suppression.rest.class = "notTested";

				contactTestMap[id].suppression.dnc.message = "Not Tested";
				contactTestMap[id].suppression.dnc.class = "notTested";
			}
		}

		setContactTestMap({ ...contactTestMap });
	}

	function fillPlaceholders(contact, string) {
		return string.replace(/{{.*?}}/g, match => {
			let trimmed = match.substring(2, match.length - 2);
			if (trimmed.startsWith('custom:')) {
				trimmed = trimmed.replace('custom:', '');
				const safeCustomFields = ensureJSON(contact.customFields);
				if (trimmed in safeCustomFields) {
					return safeCustomFields[trimmed] ? safeFormatPlaceholder(safeCustomFields[trimmed]) : '';
				}
			} else {
				if (trimmed in contact) {
					return contact[trimmed] ? safeFormatPlaceholder(contact[trimmed]) : '';
				}
			}

			return match;
		});
	}

	function ensureJSON(value) {
		if (typeof (value) === 'string') {
			return JSON.parse(value);
		}

		return value;
	}

	function safeFormatPlaceholder(value) {
		if (typeof (value) === 'object') {
			return JSON.stringify(value);
		} else {
			return value;
		}
	}

	function evaluateFilterExpressionAgainstObject(query, source) {
		for (const key in query) { // This should only ever loop once.
			const lowercaseKey = key.toLowerCase();

			if (lowercaseKey === 'or' || lowercaseKey === 'and') {
				// If the key is a combinator.
				const isAnd = lowercaseKey === 'and';

				for (let i = 0; i < query[key].length; ++i) {
					const ruleResult = evaluateFilterExpressionAgainstObject(query[key][i], source);
					if (ruleResult === "trim") {
						// Trim an empty ruleset.
						query[key].splice(i--, 1);
					} else if (ruleResult ^ isAnd) {
						return !isAnd;
					}
				}

				// If the rule set is empty, return "trim" so that the parent can trim it from the tree.
				if (query[key].length === 0) return "trim";

				return isAnd;
			} else {
				// If the key is a dot path.
				const objectValue = transformers[key](key.split('.').reduce((currentObject, currentPathKey) => {
					if (typeof currentObject === 'object' && currentObject != null && currentPathKey in currentObject) {
						return currentObject[currentPathKey];
					}

					throw key;
				}, source));

				const operator = Object.keys(query[key])[0];
				const queryValue = transformers[key](query[key][operator]);

				switch (operator) {
					case 'eq':
						return objectValue == queryValue; // eslint-disable-line eqeqeq
					case 'ne':
						return objectValue != queryValue; // eslint-disable-line eqeqeq
					case 'lt':
						return objectValue < queryValue;
					case 'gt':
						return objectValue > queryValue;
					case 'lte':
						return objectValue <= queryValue;
					case 'gte':
						return objectValue >= queryValue;
					default:
						return false;
				}
			}
		}
	}

	async function writeToActivityLog(title, body, contactId, type) {
		await client.graphql({
			query: createActivityLog,
			variables: {
				input: {
					tenant: userContext.tenantId,
					type,
					title,
					body,
					activityLogContactId: contactId,
					agent: "System",
					timestamp: new Date().toISOString(),
					contactStatus: `${type}#suppressed#${moment().toISOString(true)}`
				}
			}
		});
	}

	async function testContact(id, formikProps) {
		contactTestMap[id].testing = true;
		setContactTestMap({ ...contactTestMap });

		contactTestMap[id].suppression = {
			activityLog: {
				suppressed: false,
				message: 'Not Tested',
				class: 'notTested'
			},
			rest: {
				suppressed: false,
				message: 'Not Tested',
				class: 'notTested'
			},
			poc: {
				suppressed: false,
				message: 'Not Tested',
				class: 'notTested'
			},
			dnc: {
				suppressed: false,
				message: 'Not Tested',
				class: 'notTested'
			},
			dncLists: {
				suppressed: false,
				message: 'Not Tested',
				class: 'notTested'
			}
		};

		// Test the activity log query suppression (if applicable)
		if (formikProps.values.activityLogQuerySuppressionEnabled) {
			const result = await client.graphql({
				query: activityLogSuppressionSearch,
				variables: {
					filter: JSON.stringify(formikProps.values.activityLogQuerySuppression.query),
					contactId: id
				}
			});
			contactTestMap[id].suppression.activityLog = result.data.activityLogSuppressionSearch.count >= formikProps.values.activityLogQuerySuppression.threshold ? { suppressed: true, message: 'Suppressed', class: 'suppressed' } : { suppressed: false, message: 'Not Suppressed', class: 'notSuppressed' };
		} else {
			contactTestMap[id].suppression.activityLog.message = "Skipped";
		}

		// Test the poc query suppression (if applicable)
		if (formikProps.values.pocQuerySuppressionEnabled) {
			const result = await client.graphql({
				query: pocMeta,
				variables: {
					tenant: contactTestMap[id].tenant,
					contactField: {
						eq: {
							contact: id,
							field: cadenceField
						}
					}
				}
			});


			const sourceObject = result?.data?.PocMeta?.items[0];
			if ([0, 6].includes((new Date()).getDay()) && sourceObject.poc.dontCallOnWeekends && formikProps.values.pocQuerySuppression.suppressIfWeekendAndCantCallOnWeekends) {
				contactTestMap[id].suppression.poc = { suppressed: true, message: 'Suppressed. It is a weekend.', class: 'suppressed' }
			} else {
				const firstKeyOfQuery = Object.keys(formikProps.values.pocQuerySuppression.query)[0]; // This is here because of casing.

				try {
					const evaluationResult = evaluateFilterExpressionAgainstObject(formikProps.values.pocQuerySuppression.query[firstKeyOfQuery][3], sourceObject);
					if (evaluationResult !== "trim" && evaluationResult) {
						contactTestMap[id].suppression.poc = { suppressed: true, message: 'Suppressed. Query evaluation returned true.', class: 'suppressed' };
					} else {
						contactTestMap[id].suppression.poc = { suppressed: false, message: 'Not Suppressed. Query evaluation returned false.', class: 'notSuppressed' };
					}
				} catch (err) {
					contactTestMap[id].suppression.poc = { suppressed: true, message: 'Suppressed. Metadata was missing fields.', class: 'suppressed' };
				}
			}
		} else {
			contactTestMap[id].suppression.poc.message = "Skipped";
		}

		// Test the rest suppression (if applicable)
		if (formikProps.values.restSuppressionEnabled) {
			const contactData = _.cloneDeep(contactTestMap[id]);
			delete contactData.id;
			delete contactData.tenant;
			let paramsString = '';
			const headers = _.cloneDeep(formikProps.values.restSuppression.headers).reduce((acc, cur) => { acc[fillPlaceholders(contactData, cur.key)] = fillPlaceholders(contactData, cur.value); return acc; }, {});

			if (formikProps.values.restSuppression.params.length > 0) {
				paramsString = `?${formikProps.values.restSuppression.params.map(x => `${encodeURIComponent(fillPlaceholders(contactData, x.key))}=${encodeURIComponent(fillPlaceholders(contactData, x.value))}`).join('&')}`;
			}

			switch (formikProps.values.restSuppression.auth.type) {
				case AuthTypes[1]:
					if (formikProps.values.restSuppression.auth.info.addTo === 'header') {
						headers[formikProps.values.restSuppression.auth.info.key] = formikProps.values.restSuppression.auth.info.value;
					} else {
						if (paramsString === '') {
							paramsString += '?';
						} else {
							paramsString += "&";
						}
						paramsString += `${encodeURIComponent(formikProps.values.restSuppression.auth.info.key)}=${encodeURIComponent(formikProps.values.restSuppression.auth.info.value)}`;
					}
					break;
				case AuthTypes[2]:
					headers.Authorization = `Bearer ${formikProps.values.restSuppression.auth.info.token}`;
					break;
				case AuthTypes[3]:
					headers.Authorization = `Basic ${btoa(`${formikProps.values.restSuppression.auth.info.username}:${formikProps.values.restSuppression.auth.info.password}`)}`;
					break;

				default:
					break;
			}

			let result;
			try {
				const URL = `${formikProps.values.restSuppression.endpoint}${paramsString}`;
				switch (formikProps.values.restSuppression.method) {
					case "GET":
					default:
						result = await axios.get(URL, { headers });
						break;
					case "POST":
						result = await axios.post(URL, contactData, { headers });
						break;
				}

				let evaluatedResult = evaluateDotPath(result.data, formikProps.values.restSuppression.response.path);
				if (evaluatedResult != null) {
					switch (formikProps.values.restSuppression.response.type) {
						case responseTypes.Boolean:
							if (typeof evaluatedResult === 'string') {
								evaluatedResult = evaluatedResult.toLocaleLowerCase() !== 'false';
							} else {
								evaluatedResult = Boolean(evaluatedResult);
							}
							break;
						case responseTypes.Number:
							evaluatedResult = Number(evaluatedResult);
							if (isNaN(evaluatedResult)) {
								evaluatedResult = null;
							}
							break;
						case responseTypes.String:
							evaluatedResult = stringTransformations[formikProps.values.restSuppression.response.options.responseStringTransformation].transform(`${evaluatedResult}`);
							break;

						default:
							break;
					}
				}

				if (evaluatedResult != null) {
					if (evaluatedResult === formikProps.values.restSuppression.response.value.value) {
						logs.push({
							timestamp: (new Date()).toISOString(),
							contact: contactTestMap[id],
							result: 'Suppressed',
							reason: `Call succeeded and the response value specified by the response key was equivalent to the value specified by the suppression profile.`,
							data: result
						});
						contactTestMap[id].suppression.rest = { message: 'Suppressed', class: 'suppressed' };
					} else {
						logs.push({
							timestamp: (new Date()).toISOString(),
							contact: contactTestMap[id],
							result: 'Not Suppressed',
							reason: `Call succeeded but the response value specified by the response key was not equivalent to the value specified by the suppression profile.`,
							data: result
						});
						contactTestMap[id].suppression.rest = { message: 'Not Suppressed', class: 'notSuppressed' };
					}
				} else if (formikProps.values.restSuppression.suppressOnError) {
					logs.push({
						timestamp: (new Date()).toISOString(),
						contact: contactTestMap[id],
						result: 'Suppressed',
						reason: `The response didn't contain the specified key or was unable to coerce the result and was configured to suppress on errors.`,
						data: result
					});
					contactTestMap[id].suppression.rest = { message: 'Suppressed - See Log', class: 'suppressed' };
				} else {
					logs.push({
						timestamp: (new Date()).toISOString(),
						contact: contactTestMap[id],
						result: 'Not Suppressed',
						reason: `The response didn't contain the specified key or was unable to coerce the result and was configured not to suppress on errors.`,
						data: result
					});
					contactTestMap[id].suppression.rest = { message: 'Not Suppressed - See Log', class: 'notSuppressed' };
				}
			} catch (err) {
				console.log(err);
				if (formikProps.values.restSuppression.suppressOnError) {
					logs.push({
						timestamp: (new Date()).toISOString(),
						contact: contactTestMap[id],
						result: 'Suppressed',
						reason: `The call failed and was configured to suppress on errors.`,
						data: err
					});
					contactTestMap[id].suppression.rest = { message: 'Suppressed - See Log', class: 'suppressed' };
				} else {
					logs.push({
						timestamp: (new Date()).toISOString(),
						contact: contactTestMap[id],
						result: 'Suppressed',
						reason: `The call failed and was configured not to suppress on errors.`,
						data: err
					});
					contactTestMap[id].suppression.rest = { message: 'Not Suppressed - See Log', class: 'notSuppressed' };
				}
			}

			setLogs([...logs]);
		} else {
			contactTestMap[id].suppression.rest.message = "Skipped";
		}

		// Test DNCList suppression if enabled.
		if (formikProps.values.dncListSuppressionEnabled) {
			let suppressed = false;

			const e164 = phone(contactTestMap[id][cadenceField] || (contactTestMap[id]?.customFields[cadenceField]), { validateMobilePrefix: IS_PROD })?.phoneNumber; // The full E.164 phone number (for DNCList suppression).

			if (!e164) {
				contactTestMap[id].suppression.dncLists = { message: 'No Number Found', class: 'notSuppressed' };
			} else {
				for (const list of formikProps.values.dncListSuppressions) {
					const result = await client.graphql({
						query: dncByList,
						variables: {
							dncList: list,
							phoneNumber: {
								eq: e164
							}
						}
					});

					if (result.data.dncByList.items?.length > 0) {
						contactTestMap[id].suppression.dncLists = { message: 'Suppressed', class: 'suppressed' };
						suppressed = true;
						break;
					}
				}

				if (!suppressed) {
					contactTestMap[id].suppression.dncLists = { message: 'Not Suppressed', class: 'notSuppressed' };

				}
			}
		} else {
			contactTestMap[id].suppression.dncLists.message = "Skipped";
		}

		if (formikProps.values.dncDotComEnabled) {
			let suppressed = false;
			let error = false;
			const promises = [];
			if (formikProps.values.dncDotCom.scrub) {
				const body = new FormData();
				body.append('phoneList', contactTestMap[id].phone);
				body.append('version', '5');
				body.append('output', 'json');

				try {
					const scrubResponse = await axios.post(`${DNCDotCom.ScrubURL}?${DNCDotCom.generateAuthAndProjectIDParams(dncDotComSettings.loginId, dncDotComSettings.projectId)}`, body, { headers: { 'Content-Type': `multipart/form-data; boundary=${body._boundary}` } });

					promises.push(writeToActivityLog("DNC.com scrub API invocation", "The suppression engine called the DNC.com scrub API on this contact.", id, "DNCScrubInvocation"));

					if (DNCDotCom.ScrubStatusCodes[scrubResponse.data[0].ResultCode]) {
						suppressed = true;
						contactTestMap[id].suppression.dnc = { message: 'Suppressed by National or State Scrub', class: 'suppressed' };
					} else if (scrubResponse.data[0].DoNotCallToday == 1) { // eslint-disable-line eqeqeq
						suppressed = true;
						contactTestMap[id].suppression.dnc = { message: 'Holiday or State of Emergency', class: 'suppressed' };
					}
				} catch (err) {
					error = true;
					console.error(err);
					contactTestMap[id].suppression.dnc = { message: `Error in Scrub Call - ${err.response.status}`, class: 'error' };
				}
			}

			if (formikProps.values.dncDotCom.numberReassignment && !suppressed && !error) {
				try {
					const reassignmentResponse = await axios.get(`${DNCDotCom.ReassignmentURL}?${DNCDotCom.generateAuthAndProjectIDParams(dncDotComSettings.loginId, dncDotComSettings.projectId)}&${DNCDotCom.generateReassignmentParams(contactTestMap[id], contactTestMap[id].phone, formikProps.values.dncDotCom.reassignmentSince)}`);

					promises.push(writeToActivityLog("DNC.com reassignment API invocation", "The suppression engine called the DNC.com reassignment API on this contact.", id, "DNCReassignmentInvocation"));

					if (DNCDotCom.ReassignmentStatusCodes[reassignmentResponse.data.VerificationCode]) {
						suppressed = true;
						contactTestMap[id].suppression.dnc = { message: 'Suppressed by Reassignment', class: 'suppressed' };
					}
				} catch (err) {
					error = true;
					console.error(err);
					contactTestMap[id].suppression.dnc = { message: `Error in Reassignment Call - ${err.response.status}`, class: 'error' };
				}
			}

			if (formikProps.values.dncDotCom.knownLitigator && !suppressed && !error) {
				try {
					const litigatorResponse = await axios.get(`${DNCDotCom.LitigatorURL}?${DNCDotCom.generateAuthAndProjectIDParams(dncDotComSettings.loginId, dncDotComSettings.projectId)}&phoneList=${contactTestMap[id].phone}`);

					promises.push(writeToActivityLog("DNC.com litigator API invocation", "The suppression engine called the DNC.com litigator API on this contact.", id, "DNCLitigatorInvocation"));

					if (litigatorResponse.data[0][DNCDotCom.LitigatorKey]) {
						suppressed = true;
						contactTestMap[id].suppression.dnc = { message: 'Suppressed by Litigator', class: 'suppressed' };
					}
				} catch (err) {
					error = true;
					console.error(err);
					contactTestMap[id].suppression.dnc = { message: `Error in Litigator Call - ${err.response.status}`, class: 'error' };
				}
			}

			await Promise.all(promises);

			if (!suppressed && !error) {
				contactTestMap[id].suppression.dnc = { message: 'Not Suppressed', class: 'notSuppressed' };
			}
		} else {
			contactTestMap[id].suppression.dnc.message = "Skipped";
		}

		contactTestMap[id].testing = false;

		setContactTestMap({ ...contactTestMap });
	}

	return (
		<Card variant='outlined'>
			<CardHeader title='Suppression Tester' titleTypographyProps={{ variant: 'h6', style: { paddingBottom: '7px' } }} avatar={<ExploreOutlined color='primary' />} />
			<CardContent>
				<Grid container spacing={2} direction='column'>
					<Grid item>
						<Grid container spacing={2} alignItems='center' alignContent='center'>
							<Grid item>
								<FormControl>
									<InputLabel>Segment</InputLabel>
									<Select
										label="Segment"
										sx={{ minWidth: '200px' }}
										value={selectedSegment}
										onChange={e => setSelectedSegment(e.target.value)}>
										{segments.map(segment =>
											<MenuItem key={segment.id} id={segment.id} value={segment.id}>{segment.name}</MenuItem>)}
									</Select>
								</FormControl>
							</Grid>
							<Grid item>
								<FormControl>
									<InputLabel>Cadence Field</InputLabel>
									<Select
										label="Cadence Field"
										sx={{ minWidth: '200px' }}
										value={cadenceField}
										onChange={e => setCadenceField(e.target.value)}>
										{availablePocFieldsToTest.map(poc =>
											<MenuItem key={poc} value={poc}>{poc}</MenuItem>)}
									</Select>
								</FormControl>
							</Grid>
							<Grid item style={{ flexGrow: 1 }}>
								<Grid container spacing={1} alignItems='center' justifyContent='flex-end'>
									<Grid item>
										<InfoOutlined color='primary' />
									</Grid>
									<Grid item>
										<Typography variant='body2'>Only the first 10 contacts for the selected segment are shown.</Typography>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Grid item>
						<TableContainer>
							<Table size='small'>
								<TableHead>
									<TableRow>
										{contactTableColumns.map((column, index) =>
											<TableCell key={index}>{column.header}</TableCell>)}
										<TableCell>
											<Tooltip title='Test all contacts'>
												<div>
													<IconButton disabled={!formikProps.isValid || Object.keys(contactTestMap).length === 0} onClick={() => { for (const key in contactTestMap) testContact(key, formikProps) }}>
														<PlayCircleOutline color={!formikProps.isValid || Object.keys(contactTestMap).length === 0 ? 'disabled' : 'primary'} />
													</IconButton>
												</div>
											</Tooltip>
										</TableCell>
										<TableCell>Activity Log</TableCell>
										<TableCell>Point of Contact</TableCell>
										<TableCell>REST</TableCell>
										<TableCell>DNC List</TableCell>
										<TableCell>DNC.com</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{Object.keys(contactTestMap).map(id =>
										<TableRow key={id}>
											{contactTableColumns.map((column, index) => {
												const dynamoList = column.dynamo.split('|');
												let result = "";
												for (const dynamo of dynamoList) {
													result += contactTestMap[id][dynamo] + " ";
												}

												return (
													<TableCell key={index}>{result}</TableCell>
												);
											})}
											<TableCell>
												{contactTestMap[id].testing &&
													<CircularProgress variant='indeterminate' color='primary' size={20} />}
												{!contactTestMap[id].testing &&
													<Tooltip title='Test contact'>
														<div>
															<IconButton size='small' disabled={!formikProps.isValid} onClick={() => testContact(id, formikProps)}>
																<PlayCircleOutline color={!formikProps.isValid ? 'disabled' : 'primary'} fontSize='small' />
															</IconButton>
														</div>
													</Tooltip>}
											</TableCell>
											{['activityLog', 'poc', 'rest', 'dncLists', 'dnc'].map((type, index) =>
												<TableCell key={index}>
													{!('suppression' in contactTestMap[id]) &&
														<span>Not Tested</span>}
													{'suppression' in contactTestMap[id] &&
														<span>{contactTestMap[id].suppression[type].message}</span>}
												</TableCell>)}
										</TableRow>)}
								</TableBody>
							</Table>
						</TableContainer>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
}
